import React, { useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import ImportJobTable from './ImportJobTable';

import { getImportJob } from '../../../services/api/queryService';
import { IMPORT_STATUSES, PRODUCT_IMPORT_OPTIONS } from '../../../lib/constants/fields';
import routes from '../../../lib/constants/routes';

import './ImportJob.scss';

const importTypeMap = {
  product: 'productTemps',
  cid: 'productCidsImportTable',
  price: 'productPriceImportTable',
  bundle: 'productBundleImportTable',
  product_update: 'productTemps',
  product_price_update: 'productPriceImportTable',
  content_import: 'productContentImportTable',
  amazon: 'productContentImportTable',
  ebay: 'productContentImportTable',
  kaufland: 'productContentImportTable',
  otto: 'productContentImportTable',
  cid_update: 'productCidsImportTable',
};

const ImportJob = ({ match, location }) => {
  const importJobId = match.params.id;
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const first = params.get('count') || 10;
  const input = params.get('keyword') || '';

  const { data, refetch, error } = useQuery(getImportJob, {
    variables: { id: importJobId, first, page, product_name: input },
    fetchPolicy: 'no-cache'
  });

  let importData;
  let tableData;
  let paginatorInfo;

  useEffect(() => {
    let intervalId;
    const status = data && data.importJob && data.importJob.status;
    if (status && status === 6) {
      intervalId = setInterval(() => {
        refetch();
      }, 9000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [data, refetch]);

  if (!data) {
    return <div className="spinner absolute" />;
  } else {
    const importJobData = data.importJob;

    if (!importJobData) {
      return <Redirect to="/" />;
    }
    
    data.importJob.marketplace = data.importJob.type

    if(data.importJob.type == 'amazon' || data.importJob.type == 'ebay' || data.importJob.type == 'kaufland' || data.importJob.type == 'otto'){
      data.importJob.type = 'content_import'
    }


    importData = {
      name: importJobData.name,
      type: PRODUCT_IMPORT_OPTIONS.find(i => i.value === importJobData.type)?.label,
      createdBy: `${importJobData.user.first_name} ${importJobData.user.last_name}`,
      createdAt: importJobData.created_at,
      status: importJobData.status
    };

    tableData = [...importJobData[importTypeMap[importJobData.type]].data];
    tableData.forEach(i => delete i.__typename);

    paginatorInfo = importJobData[importTypeMap[importJobData.type]].paginatorInfo;
  }

  const isPreview = importData.status === 2;
  const isImporting = importData.status === 3;
  const isCanceled = importData.status === 6;

  if (error) {
    throw new Error(error);
  }


  return (
    <>
      {/* HEADING */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">{`${
              isPreview ? 'Please confirm import' : 'Import details for'
            } "${importData.name}"`}</h4>

            <div className="page-title-left">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/">PIM</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`${routes.products.index}?tab=import`}>Products</Link>
                </li>
                <li className="breadcrumb-item active">Import</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* DETAILS */}
      <div className="d-flex justify-content-between">
        <div>
          <strong>Import type:</strong> {importData.type}
        </div>
        <div>
          <strong>Created by:</strong> {importData.createdBy}
        </div>
        <div>
          <strong>Created at:</strong> {importData.createdAt}
        </div>
        <div>
          <strong style={{ marginRight: '20px' }}>Status:</strong>{' '}
          {IMPORT_STATUSES[importData.status]}
        </div>
      </div>

      {/* BODY */}
      <ImportJobTable
        importJobId={importJobId}
        importType={data.importJob.type}
        marketplace={data.importJob.marketplace}
        tableData={tableData}
        params={params}
        first={first}
        input={input}
        paginatorInfo={{ ...paginatorInfo, search: input }}
        isPreview={isPreview}
        isImporting={isImporting}
        isCanceled={isCanceled}
        refetch={refetch}
      />
    </>
  );
};

export default ImportJob;
