import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ModalError from '../../../components/modal/ModalError';
import { updateProductCategory } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

import { getProductCategoriesSettings } from '../../../services/api/queryService';


const ProductCategorizationForm = ({ selectData, parentProduct, productData, refetch, update, updateFormData, errors }) => {
  const [updateProductCategoryMutation, { loading, error: updateError }] = useMutation(
    updateProductCategory
  );

  const {data: productCategories } = useQuery(getProductCategoriesSettings, {
    variables: { order: 'id' }
  });

  if (productCategories) {
    productCategories.productCategories.forEach(item => {
      if(item.category.toLowerCase() == 'pharmacy'){
        if (!selectData.pharmacyCategory.find(x => +x.id !== +item.id)) {
          selectData.pharmacyCategory.push({ id: item.id, name: item.name })
        }
      } 
      if(item.category.toLowerCase() == 'hazmat'){
        if (!selectData.hazmatCategory.find(x => x.id !== +item.id) ) {
          selectData.hazmatCategory.push({ id: item.id, name: item.name })
        }
      } 
      if(item.category.toLowerCase() == 'electronics'){
        if (!selectData.electronicsCategory.find(x => +x.id !== +item.id)) {
           selectData.electronicsCategory.push({ id: item.id, name: item.name }) 
        }
      } 
      if(item.category.toLowerCase() == 'food') {
        if (!selectData.foodCategory.find(x => +x.id !== +item.id)) {
          selectData.foodCategory.push({ id: item.id, name: item.name })
        }
      } 
    })

  }

  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    product_category_pharmacy: productData.product_category_pharmacy
      ? selectData.pharmacyCategory.filter(i => productData.product_category_pharmacy.find(t => t  == i.id))
      : [],
    product_category_hazmat: productData.product_category_hazmat
      ? selectData.hazmatCategory.filter(i => productData.product_category_hazmat.find(t => t  == i.id))
      : [],
    product_category_electronics: productData.product_category_electronics
      ? selectData.electronicsCategory.filter(i => productData.product_category_electronics.find(t => t  == i.id))
      : [],
    product_category_food: productData.product_category_food
      ? selectData.foodCategory.filter(i => productData.product_category_food.find(t => t  == i.id))
      : []
  });
 
  const [changesMade, setChangesMade] = useState(false);
  const [errorArray, setErrorArray] = useState([]);
  const [errorsList, setErrorsList] = useState([]);

  useEffect(() => {
    setErrorsList(errors)
  }, [errors])

  const submitHandler = async event => {
    
    const arr = [];

    event.preventDefault();

    if(productData.pzn && productData.pzn.length > 0 && formData.product_category_pharmacy.length === 0){
      arr.push('PZN is entered, “Pharmacy category” is mandatory');
    }

    setErrorArray([...arr])
    
    let priceCheckError = false;

    if(!silentError){
      if(productData.product_category_hazmat == null || productData.product_category_hazmat.length == 0){
        if(!window.confirm('I confirm that product doesn’t fall under the hazmat category')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }

      if(productData.productPrices == null || productData.productPrices.length == 0){
        if(!window.confirm('I confirm that this product has no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }else{
        productData.productPrices.forEach((value, index) => {
          if(value.price_buy_net_actual_ct == null || value.price_buy_net_actual_ct == 0){
            priceCheckError = true
          }
        })
      }

      if(priceCheckError) {
        if(!window.confirm('Some prices have no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }
    }
    
    setChangesMade(false);
    const preparedFormData = {};

    Object.keys(formData).forEach(key => {

      if (formData[key] && key !== 'product_category_amazon' && key !== 'product_category_ebay' && key !== 'product_category_kaufland' && key !== 'product_category_otto') {
        preparedFormData[key] = JSON.stringify(
          formData[key].map(item => {
            let name = 'pharmacyCategory';
            if (key === 'product_category_hazmat') {
              name = 'hazmatCategory';
            }
            if (key === 'product_category_electronics') {
              name = 'electronicsCategory';
            }
            if (key === 'product_category_food') {
              name = 'foodCategory';
            }

            if(typeof(item) == 'number') return selectData[name].find(value => value.id === item);
            
            return selectData[name].find(value => value.id === item.id);
          })
        );
      }else{
        if(typeof(formData[key]) == 'number' ){
          preparedFormData[key] = formData[key]
        }else{
          preparedFormData[key] = formData[key] == null ? null : formData[key].id
        }
      }
    });

    if(arr.length === 0){
      try {
        const { data } = await updateProductCategoryMutation({
          variables: { ...preparedFormData,product_parent_id: productData.product_parent_id }
        });

        if (!data.updateProductCategory) {
          throw new Error('Server responded with no data');
        }

        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully updated'
          }
        });
        refetch();
      } catch (err) {
        setError(err);
      }
    }
  };


  const changeMultiSelectHandler = (options, data) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [data.name]: options ? options.map(option => option.value) : []
    });

    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == data.name)
    if(d){
      delete err[data.name]

      setErrorsList({...err})
    }


  };

  const changeSelectHandler = (option, data) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [data.name]: option ? option.value : null
    });


  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  const activeAmazonSKU = productData.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU' && x.marketplace_id < 22)
  const activeEbaySKU = productData.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU' && x.marketplace_id == 22)
  const activeKauflandSKU = productData.productMarketplaces.filter(x => x.marketplace_id_type == 'SKU' && x.marketplace_id == 23)

  useEffect(() => {

    const preparedFormData = {};

    Object.keys(formData).forEach(key => {
      preparedFormData[key] = 
        formData[key].map(item => {
          let name = 'pharmacyCategory';
          if (key === 'product_category_hazmat') {
            name = 'hazmatCategory';
          }
          if (key === 'product_category_electronics') {
            name = 'electronicsCategory';
          }
          if (key === 'product_category_food') {
            name = 'foodCategory';
          }

          if(typeof(item) == 'number') return selectData[name].find(value => value.id === item).id;
          
          return selectData[name].find(value => value.id === item.id).id;
        })
      
    })

    updateFormData({productCategories: preparedFormData})
  }, [formData])

  const showError = key => {
    if(!errorsList[key]) return null

    return (
      <div className="custom_error_container">
        <img src="/assets/images/info-button.png" alt="" />
        <p>{errorsList[key]}</p>
      </div>
    )
  }


  return (
    <>
    {!parentProduct && (
      <form onSubmit={submitHandler}>
        <div className="row row-cols-4">
          <div className="col-md-3">
            <label htmlFor="product_category_pharmacy" className='tooltipHeading'>
              Pharmacy Categories
              <div className="u_infoButton">
                  <img src="/assets/images/info-button.png" alt="" />
                  
                  <div className="infoBox">
                      <span>Mandatory for pharmacy products</span>
                  </div>
              </div>  
            </label>
            <Select
              name="product_category_pharmacy"
              className={`${errorsList && errorsList['product_category_pharmacy'] ? 'invalid' : ''} `}
              options={convertDataToOptions(selectData.pharmacyCategory)}
              defaultValue={formData.product_category_pharmacy.map(i => createDefaultOption(i))}
              onChange={changeMultiSelectHandler}
              isMulti
              isDisabled={!update || isArchived}
            />
            {showError('product_category_pharmacy')}
          </div>
          <div className="col-md-3">
            <label className='tooltipHeading' htmlFor="product_category_hazmat">
              Hazmat Categories
              <div className="u_infoButton">
                  <img src="/assets/images/info-button.png" alt="" />
                  
                  <div className="infoBox">
                      <span>Mandatory for Hazmat products</span>
                      <span>e.g Spray, Chemical products</span>
                  </div>
              </div>  
            </label>
            <Select
              name="product_category_hazmat"
              options={convertDataToOptions(selectData.hazmatCategory)}
              defaultValue={formData.product_category_hazmat.map(i => createDefaultOption(i))}
              onChange={changeMultiSelectHandler}
              isMulti
              isDisabled={!update || isArchived}
            />
          </div>
          <div className="col-md-3">
            <label className='tooltipHeading' htmlFor="product_category_electronics">
              Electronics Categories
              <div className="u_infoButton">
                  <img src="/assets/images/info-button.png" alt="" />
                  
                  <div className="infoBox">
                      <span>Electronic products categories</span>
                      <span>e.g coffee machine</span>
                  </div>
              </div>    
            </label>
            <Select
              name="product_category_electronics"
              options={convertDataToOptions(selectData.electronicsCategory)}
              defaultValue={formData.product_category_electronics.map(i => createDefaultOption(i))}
              onChange={changeMultiSelectHandler}
              isMulti
              isDisabled={!update || isArchived}
            />
          </div>
          <div className="col-md-3">
            <label className='tooltipHeading' htmlFor="product_category_food">
              Food Categories
              <div className="u_infoButton">
                  <img src="/assets/images/info-button.png" alt="" />
                  
                  <div className="infoBox">
                      <span>Food supplements products</span>
                  </div>
              </div>  
            </label>
            <Select
              name="product_category_food"
              options={convertDataToOptions(selectData.foodCategory)}
              defaultValue={formData.product_category_food.map(i => createDefaultOption(i))}
              onChange={changeMultiSelectHandler}
              isMulti
              isDisabled={!update || isArchived}
            />
          </div>
          
          <div className="col-12 mt-2">
              <hr style={{margin: 0}} />
          </div>
          
        </div>

      </form>
      )}
      {parentProduct && (
        <form>
          <div className="overflow-auto">
            <table className="table mt-0">
              <thead>
                <tr>
                  <th>Amazon categories</th>
                  <th>Ebay categories</th>
                  <th>Kaufland categories</th>
                  <th>Otto categories</th>
                </tr>
              </thead>
              <tbody>
                {productData.linkedItems.map((row, index) => row.product != null && (
                  <tr key={index} >
                    <td style={{padding: '15px'}}>
                       { selectData.amazonCategory.find(i => i.id == row.product.product_category_amazon)?.name }
                    </td>
                    <td style={{padding: '15px'}}>
                       { selectData.ebayCategory.find(i => i.id == row.product.product_category_ebay)?.name }
                    </td>
                    <td style={{padding: '15px'}}>
                       { selectData.kauflandCategory.find(i => i.id == row.product.product_category_kaufland)?.name }
                    </td>
                    <td style={{padding: '15px'}}>
                       { selectData.ottoCategory.find(i => i.id == row.product.product_category_otto)?.name }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </form>
      )}
    </>
  );
};

export default ProductCategorizationForm;
