import React from 'react';
import { NavLink } from 'react-router-dom';

import './Fallback.scss';

const Fallback = ({ clearError, is404, customError = null }) => {
  return (
    <div className="fallback">
      <div className="fallback__wrapper">
        <div className="fallback__logo-wrapper">
          <img className="fallback__logo" src="/assets/images/logo_cropped.png" alt="" />
        </div>
        { customError ? (
          <>
            <p className="fallback__primary-text">
              { customError.title }
            </p>
            <div style={{ margin: '15px 0' }}>
              <p className="text-error-normal">{ customError.subtitle??"" }</p>
            </div>
            <ul>
              {customError.errorList && customError.errorList.map(err => (
                <li className='text-error-normal'>{ err }</li>
              )) }
            </ul>
            <p className="fallback__secondary-text">
              Please contact our support or go back to product page
            </p>
            <NavLink className="btn btn-primary" style={{marginBottom: '40px'}} to={customError.link??"/"} onClick={clearError}>
              Go back to product page
            </NavLink>
          </>
        ) : (
          <>
            <p className="fallback__primary-text">
              {is404 ? 'Page was not found' : 'Something went wrong!'}
            </p>
            <p className="fallback__primary-text">{`(${is404 ? '404' : 'Internal Server'} Error)`}</p>
            <p className="fallback__secondary-text">
              {is404 ? '' : 'Please contact our support or refresh the page'}
            </p>
            <NavLink className="btn btn-primary" to="/" onClick={clearError}>
              Go back home
            </NavLink>
          </>
        ) }

      </div>
    </div>
  );
};

export default Fallback;
