import React from 'react';
import { Link } from 'react-router-dom';

/** Components */
import DefaultForm from '../../components/form/DefaultForm';

/** import fields */
import { addManufacturer as fields } from '../../lib/constants/fields';

/** Routes */
import Routes from '../../lib/constants/routes';

/** service */
import { createManufacturer, deleteManufacturer } from '../../services/api/mutationService';

const description = {
  module: 'manufacturers',
  req: 'CreateManufacturer'
};

const AddManufacturer = () => {
  return (
    <>
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Create Manufacturer</h4>

            <div className="page-title-left">
              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/">PIM</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={Routes.manufacturers.index}>Manufacturers</Link>
                </li>
                <li className="breadcrumb-item active">Create Manufacturer</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* end page title  */}``

      <DefaultForm
        service={createManufacturer}
        deleteService={deleteManufacturer}
        fields={fields}
        moduleName={description.module}
        req={description.req}
      />
    </>
  );
};

export default AddManufacturer;
