import React, { useState } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../../services/util/dataHandlerService';
import { createUser, updateUser, deleteUser } from '../../../../services/api/mutationService';
import { STATUS_OPTIONS, USER_TYPE_OPTIONS } from '../../../../lib/constants/fields';
import ModalError from '../../../../components/modal/ModalError';

const UsersFormFields = ({ itemId, data, roles, manufacturers, sellers, setOpenModalId, refetch }) => {

  const [formData, setFormData] = useState({
    first_name: data && data.first_name,
    last_name: data && data.last_name,
    email: null,
    email_repeat: null,
    password: null,
    password_repeat: null,
    user_type: data && data.user_type,
    roles: (data && data.roles.map(i => i.id)) || [],
    manufacturers: (data && data.manufacturers.map(i => i.id)) || [],
    sellers: data && data.sellers ? data.sellers.map(i => i.id) : [],
    status: data ? data.status : STATUS_OPTIONS[1].id
  });
  const dispatch = useDispatch();
  const [createUserMutation, { loading: createLoading, error: createError }] = useMutation(
    createUser
  );
  const [updateUserMutation, { loading: updateLoading, error: updateError }] = useMutation(
    updateUser
  );
  const [deleteUserMutation, {}] = useMutation(deleteUser);

  const [mutationError, setMutationError] = useState();
  const [acceptDelete, setAcceptDelete] = useState(false)

  const [errors, setErrors] = useState({
    email_repeat: null,
    password: null,
    password_repeat: null,
    create: null
  });

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.id]: false
    });
  };

  const changeSelectHandler = (option, data) => {
    let newValue;
    if (option) {
      newValue = option.value !== undefined ? option.value : option.map(i => i.value);
    } else {
      newValue = [];
    }

    let fd = {...formData}

    if(data && data.name == 'user_type'){
      if(option.value != formData.user_type){
        fd.roles = []
        fd.manufacturers = []
        fd.sellers = []
      }
    }

    fd[data.name] = newValue

    setFormData({...fd});
  };

  const checkboxChangeHandler = event => {
    const { checked } = event.target;
    setAcceptDelete(checked)
  };

  const submitDelete = async e => {
    if(itemId){
      const { data } = await deleteUserMutation({
        variables: { 
          id: itemId
        }
      });

      if(!data.error){
        refetch();
        setOpenModalId(null);
      }
    }
  }

  const submitHandler = async event => {
    event.preventDefault();

    if (!itemId) {
      const errors = {
        email_repeat: formData.email !== formData.email_repeat && 'Emails must match',
        password: formData.password.length < 8 && 'Password should be at least 8 characters',
        password_repeat: formData.password !== formData.password_repeat && 'Passwords must match'
      };

      if (Object.keys(errors).some(key => errors[key])) {
        setErrors(errors);
        return;
      }
    }

    const preparedFormData = {
      ...formData,
      roles: [...formData.roles],
      manufacturers: [...formData.manufacturers],
      sellers: [...formData.sellers],
    };
    if (preparedFormData.user_type === 2) {
      preparedFormData.manufacturers = [];
    }
    if (itemId) {
      delete preparedFormData.email;
      delete preparedFormData.password;
    }
    delete preparedFormData.password_repeat;
    delete preparedFormData.email_repeat;

    try {
      if (itemId !== null) {
        const { data } = await updateUserMutation({
          variables: {
            input: { ...preparedFormData, id: itemId }
          }
        });

        if (!data.updateUser) {
          throw new Error('Server responded with no data');
        }
      } else {
        const { data } = await createUserMutation({
          variables: {
            input: preparedFormData
          }
        });
        if (!data.createUser) {
          throw new Error('Server responded with no data');
        }
        const errorMessage = data.createUser.error;
        if (errorMessage) {
          setErrors({
            ...errors,
            create: errorMessage
          });
          return;
        }
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setOpenModalId(null);
  };

  if (createError || updateError || mutationError) {
    throw new Error(createError || updateError || mutationError);
  }

  console.log(formData.roles)
  
  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {`${itemId ? 'Edit' : 'Add'} user`}
        </h4>
        <button type="button" className="close" onClick={() => setOpenModalId(null)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError error={errors.email_repeat} />
        <ModalError error={errors.password} />
        <ModalError error={errors.password_repeat} />
        <ModalError error={errors.create} />
        <div className="d-flex justify-content-between">
          <div className="form-group w-100 pr-1">
            <label htmlFor="first_name">
              First name <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="first_name"
              className="form-control"
              required
              defaultValue={formData.first_name}
              onChange={changeFieldHandler}
              disabled={data && data.status === 0}
            />
          </div>
          <div className="form-group w-100 pl-1">
            <label htmlFor="last_name">
              Last name <span style={{ color: 'red' }}>*</span>
            </label>
            <input
              type="text"
              id="last_name"
              className="form-control"
              required
              defaultValue={formData.last_name}
              onChange={changeFieldHandler}
              disabled={data && data.status === 0}
            />
          </div>
        </div>
        {!itemId && (
          <div className="d-flex justify-content-between">
            <div className="form-group w-100 pr-1">
              <label htmlFor="email">
                Email <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="email"
                id="email"
                className="form-control"
                required
                defaultValue={formData.email}
                onChange={changeFieldHandler}
                disabled={data && data.status === 0}
              />
            </div>
            <div className="form-group w-100 pl-1">
              <label htmlFor="email_repeat">
                Email repeat <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="email"
                id="email_repeat"
                className="form-control"
                required
                defaultValue={formData.email_repeat}
                onChange={changeFieldHandler}
                disabled={data && data.status === 0}
              />
            </div>
          </div>
        )}
        {!itemId && (
          <div className="d-flex justify-content-between">
            <div className="form-group w-100 pr-1">
              <label htmlFor="password">
                Password <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="password"
                id="password"
                className="form-control"
                required
                defaultValue={formData.password}
                onChange={changeFieldHandler}
                disabled={data && data.status === 0}
              />
            </div>
            <div className="form-group w-100 pl-1">
              <label htmlFor="password_repeat">
                Password repeat <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="password"
                id="password_repeat"
                className="form-control"
                required
                defaultValue={formData.password_repeat}
                onChange={changeFieldHandler}
                disabled={data && data.status === 0}
              />
            </div>
          </div>
        )}
        <div>
          <label htmlFor="user_type">
            User type <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="user_type"
            options={USER_TYPE_OPTIONS}
            defaultValue={USER_TYPE_OPTIONS.find(i => i.value === formData.user_type)}
            onChange={changeSelectHandler}
            isDisabled={data && data.status === 0}
          />
          <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={!formData.user_type} />
        </div>
        <div>
          <label htmlFor="roles">
            Roles <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="roles"
            options={convertDataToOptions(roles.filter(x => x.user_type == formData.user_type))}
            value={roles
              .filter(i => formData.roles.some(j => i.id === j))
              .map(i => ({ label: i.name, value: i.id }))}
            onChange={changeSelectHandler}
            isMulti
            isDisabled={data && data.status === 0}
          />
          <input
            tabIndex={-1}
            style={{ opacity: 0, height: 0 }}
            required={!formData.roles.length}
          />
        </div>
        {formData.user_type === 4 || formData.user_type === 1 && (
          <div>
            <label htmlFor="sellers">
              Sellers <span style={{ color: 'red' }}>*</span>
            </label>
            <Select
              name="sellers"
              options={convertDataToOptions(sellers)}
              value={sellers
                .filter(i => formData.sellers.some(j => i.id === j))
                .map(i => ({ label: i.name, value: i.id }))}
              onChange={changeSelectHandler}
              isMulti
              isDisabled={data && data.status === 0}
            />
          </div>
        )}
        {formData.user_type !== 2 &&  formData.user_type !== 4 && (
          <>
            <label htmlFor="manufacturers">
              Manufacturers <span style={{ color: 'red' }}>*</span>
            </label>
            <Select
              name="manufacturers"
              options={convertDataToOptions(manufacturers)}
              value={manufacturers
                .filter(i => formData.manufacturers.some(j => i.id === j))
                .map(i => ({ label: i.name, value: i.id }))}
              onChange={changeSelectHandler}
              isMulti
              isDisabled={data && data.status === 0}
            />
            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0 }}
              required={!formData.manufacturers.length}
            />
          </>
        )}
        <div className="form-group">
          <label htmlFor="status">
            Status <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="status"
            options={convertDataToOptions(STATUS_OPTIONS)}
            defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id === formData.status))}
            onChange={changeSelectHandler}
          />
        </div>
      </div>
      <div className="modal-footer justify-content-start">
        {createLoading || updateLoading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <div>
            <div>
              <input id="deletion" type="checkbox" className="mr-1" onChange={checkboxChangeHandler} />
              <label htmlFor="deletion">Confirm deletion</label>
            </div>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button type="button" onClick={() => submitDelete(formData)} disabled={!acceptDelete} className="btn btn-danger ml-1">
            Delete
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default UsersFormFields;
