import React, { useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import Select from 'react-select';

import ModalError from '../../components/modal/ModalError';
import { updatePowerBI, removePowerBILink } from '../../services/api/mutationService';
import { getSection } from '../../services/api/queryService';

const ReportsModal = ({setModalOpen, itemId }) => {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(false);
  const [tabsData, setTabsdata] = useState([]);
  const [updateData, setUpdateData] = useState()
  const [subsections, setSubsections] = useState({})
  const [sections, setSections] = useState([
    {
      value: 'Retail',
      label: 'Retail'
    },
    {
      value: 'Media',
      label: 'Media'
    },
    {
      value: 'Inventory',
      label: 'Inventory'
    },
    {
      value: 'Pricing',
      label: 'Pricing'
    },
    {
      value: 'Operations',
      label: 'Operations'
    },
    {
      value: 'Controlling',
      label: 'Controlling'
    },
    {
      value: 'Digital Shelf',
      label: 'Digital Shelf'
    },
    {
      value: 'Billing',
      label: 'Billing'
    },
    {
      value: 'Accounting',
      label: 'Accounting'
    },
    {
      value: 'PnL',
      label: 'PnL'
    },
    
  ])
  const [subsectionFilled, setSubsectionFilled] = useState(false)
  const [checked, setChecked] = useState(false)


  useEffect(() => {
    if (itemId) {

      getSectionsData({
        variables: {
          section: '',
          subsection: '',
          filter: 0
        },
        fetchPolicy: 'no-cache'

      })

      setChecked(itemId.showFilter)
      
      let data = {
        section: '',
        subsection: '',
        tab: '',
        userType: '',
        id: itemId.id
      }
      if (itemId.userType === "Internal") {
        data.userType = 1
      }
      if (itemId.userType === "Manufacturer") {
        data.userType = 2
      }
      if (itemId.embeddCopyedLocation) {

        const sectionFromData = [...itemId.embeddCopyedLocation.split('?')][1].split('&')[0].split('=')[1]
        if(sectionFromData){
          data.section = sectionFromData
        }
        
        const subsectionFromData = [...itemId.embeddCopyedLocation.split('?')][1].split('&')[1].split('=')[1]
        setSubsectionFilled(subsectionFromData)

        if(subsectionFromData){
          data.subsection = subsectionFromData.replaceAll('-', ' ')
        }
        
        var tab = itemId.embeddCopyedLocation.substr(itemId.embeddCopyedLocation.indexOf('tab=')).replace('tab=', '')
        if (tab) {
          data.tab = tab.replaceAll('-', ' ')
        }
      }
      data.showFilter = !!itemId.showFilter
      data.showFlag = !!itemId.showFlag
      data.showExplanation = !!itemId.showExplanation
      data.explanation_url = itemId.explanation_url == '-' ? "" : itemId.explanation_url


      setUpdateData(data)
      setFormData(itemId)
    }
  }, [itemId])

  const [getSectionsData, { called, loading, data }] = useLazyQuery(getSection, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    setTabsdata([])
     if (data) {

      if (data.getSection && data.getSection.length) {
          const arr = []; 
          const tempSubsection = [];
          data.getSection.forEach(el => {
            if(el.subsection){
              tempSubsection.push({
                value: el.subsection,
                label: el.subsection
              })
            }

            if (updateData && updateData.subsection != ""){
              if( updateData.subsection.toLowerCase() == el.subsection.toLowerCase() && el.tab && el.status == "1"){
                arr.push({
                  value: el.tab.toLowerCase(),
                  label: el.tab
                })
              }
            }else{
              arr.push({
                value: el.tab.toLowerCase(),
                label: el.tab
              })
            }
          });
          
          const unique = [...new Set(tempSubsection.map(x => x.value))]
          const subs = unique.map((x) => {
            return { label: x, value: x }
          })

        setSubsections(subs)
        setTabsdata(arr)


      } else {
        setTabsdata([])
      }

    }
  }, [called, loading, data])

  const changeSelectHandler = (option, data) => {
    setUpdateData({
      ...updateData,
      [data.name]: option && option.value
    });

    let filterVal = Object.assign({}, updateData)
    
    if (data.name === 'section') {
      filterVal = Object.assign(filterVal, {'section': option.value})
      getSectionsData({
        variables: {
          section: filterVal.section,
          subsection: '',
          filter: 0
        }
      })
    }    
    if (data.name === 'subsection') {
      filterVal = Object.assign(filterVal, {'subsection': option.value})

      getSectionsData({
        variables: {
          section: filterVal.section,
          subsection: filterVal.subsection,
          filter: 0
        }
      })
    }


  };

  const changeFieldHandler = event => {
    setUpdateData({
      ...updateData,
      [event.target.id]: event.target.value
    });
  };

  const [updateReportMutation, { }] = useMutation(
    updatePowerBI
  );

  const [removeLinkMutation, { }] = useMutation(
    removePowerBILink
  );



  const userTypes = [
    {
      value: 1,
      label: 'Internal'
    },
    {
      value: 2, 
      label: 'Manufacturer'
    }
]

  const removeLink = async event => {
    event.preventDefault();
    const { data } = await removeLinkMutation({
      variables: {
        id: Number(updateData.id),
      },
    });
    if (data) {
      if(data.removePowerBILink.error){
        setError(data.removePowerBILink.error)
      }else{
        setModalOpen(false);
      }
    }
  }

  const submitHandler = async event => {
    event.preventDefault();

    setUpdateData({
      ...updateData,
      ['showFilter']: checked
    });

    try {
      const { data } = await updateReportMutation({
        variables: {
          input: updateData
        }
      })
      if (data && data.updatePowerBI) {
        if (data.updatePowerBI.error) {
          setError('This tab is already assigned to a report')
        } else {
          setModalOpen(false);
        }
      }
    } catch (err) {
      setError(err);
    }
  };

  const checkboxChangeHandler = event => {
    const { checked } = event.target;


    setUpdateData({
      ...updateData,
      [event.target.id]: checked
    });

    setChecked(checked)
  };


  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          Embed report
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <div className="form-group">
          <ModalError className="modal-thin" error={error} setError={setError} />
          <h4>PowerBI</h4>
          <hr className="btn-outline-secondary"/>
          <div>
            <strong style={{width: '140px', display: 'inline-block'}}>Report name</strong>
            <span>{formData.reportName}</span>
          </div>
          <div>
            <strong style={{width: '140px', display: 'inline-block'}}>Report page</strong>
            <span>{formData.reportPage}</span>
          </div>
          <div>
            <strong style={{width: '140px', display: 'inline-block'}}>Report ID</strong>
            <span>{formData.reportId}</span>
          </div>
          <hr className="btn-outline-secondary" />

          <h4>Embeding settings</h4>
          <p>Please note all reportings and data for manufacturers is filtered by manufacturer.</p>
          
          {updateData && updateData.id && sections.length && (
            <>
              <div className="form-group">
                <label htmlFor="section">
                  Navigation section
                </label>
                <Select
                  type="text"
                  id="section"
                  name="section"
                  defaultValue={sections.find(option => option.value.toLowerCase() == updateData.section.toLowerCase())}
                  onChange={changeSelectHandler}
                  options={sections}
                />
              </div>

              <div className="form-group">
                <label htmlFor="subsection">
                  Navigation subsection
                </label>
                { subsections.length && (
                  <Select
                  type="text"
                  id="subsection"
                  name="subsection"
                  defaultValue={subsections.find(option => option.value.toLowerCase() == updateData.subsection.toLowerCase())}
                  onChange={changeSelectHandler}
                  options={subsections}
                />
                )}
                {!subsections.length && (
                  <Select
                  type="text"
                  id="subsection"
                  name="subsection"
                  onChange={changeSelectHandler}
                  options={subsections}
                />
                ) }
                
              </div>

              <div className="form-group">
                <label htmlFor="tab">
                  Section tab
                </label>
                {tabsData.length > 0 && updateData.tab.length > 0 && (
                  <Select
                    type="text"
                    id="tab"
                    name="tab"
                    defaultValue={tabsData.find(option => option.value === updateData.tab)}
                    onChange={changeSelectHandler}
                    options={tabsData}
                  />
                )}
                {updateData.tab === '' && (
                  <Select
                    type="text"
                    id="tab"
                    name="tab"
                    onChange={changeSelectHandler}
                    options={tabsData}
                  />
                )}
              </div>
              
              <h4>Report add on settings</h4>

              <div className="form-group">
                {/* <div style={{flex: 1}}>
                  <label htmlFor="showFilter" className="mb-0">
                    Manufacturer filter
                  </label>
                  <>
                    <input id="showFilter" type="checkbox" className="ml-2" name="showFilter" checked={!!updateData.showFilter} onChange={checkboxChangeHandler} />
                  </>  
                </div>   */}
                <div style={{flex: 1, marginBottom: '15px'}}>
                  <>
                    <input id="showFlag" type="checkbox" className="mr-2" name="showFlag" checked={!!updateData.showFlag} onChange={checkboxChangeHandler} />
                  </>  
                  <label htmlFor="showFilter" className="mb-0">
                    Show report beta flag
                  </label>
                  
                </div>

              </div>
              

              {/* <div className="form-group">
                <label htmlFor="userType">
                  User type 
                </label>
                <>
                  <Select
                    type="text"
                    id="userType"
                    name="userType"
                    required={true}
                    defaultValue={userTypes.find(option => option.value === updateData.userType)}
                    onChange={changeSelectHandler}
                    options={userTypes}
                  />
                  <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{ opacity: 0, height: 0 }}
                    value={updateData.userType}
                    required={true}
                  />
                </>    
              </div> */}
              
            </>
          )}
        </div>


      </div>
      <div className="modal-footer justify-content-start">
        <button type="submit" className="btn btn-primary">
            Save
        </button>
        {updateData && updateData.tab && (
          <button type="button" onClick={removeLink} className="btn btn-danger">
            Unassigning a tab from the report
          </button>
        )}
      </div>
    </form>
  );
};

export default ReportsModal;
