import React, { useState } from 'react';

import ModalForm from '../../components/modal/ModalForm';
import BrandFormFields from '../../view/pimSettings/formFields/DefaultDimensionFormFields';

import { createMDF, updateMDF } from '../../services/api/mutationService';
import { STATUSES } from '../../lib/constants/fields';
import moment from 'moment';

const ManufacturerMDFSection = ({ data, manufacturerId, brands, refetch, create, update }) => {
  const [itemId, setItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const numberWithCommas = x => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const renderTableHeading = key => {
    if (key === '__typename' || key === 'slug' || key === 'id' || key == 'period_to' ) {
      return;
    }
    if (key === 'period_date') {
      return (
        <th key={key} className="text-capitalize">
          Period
        </th>
      );
    }
    if (key === 'brand_id') {
      return (
        <th key={key} className="text-capitalize">
          ID
        </th>
      );
    }
    if (key === 'brand') {
      return (
        <th key={key} className="text-capitalize">
          Name
        </th>
      );
    }
    if (key === 'amount') {
      return (
        <th key={key} className="text-capitalize">
          MDF
        </th>
      );
    }
    if (key === 'period_from') {
      return (
        <th key={key} className="text-capitalize">
          Period
        </th>
      );
    }
    if (key === 'revenue') {
      return (
        <th key={key} className="text-capitalize">
          Revenue target
        </th>
      );
    }
    return (
      <th key={key} className="text-capitalize">
        {key.replace('_', ' ')}
      </th>
    );
  };

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

  const renderTableField = (key, item) => {
    if (key === '__typename' || key === 'slug' || key === 'id' || key == 'period_to') {
      return;
    }
    if (key === 'period_date') {
      return <td key={key}>{monthNames[new Date(item[key]).getMonth()]} {new Date(item[key]).getFullYear()}</td>;
    }
    if (key === 'revenue' || key === 'amount') {
      return <td key={key}>{numberWithCommas(item[key])}&euro;</td>;
    }
    if (key === 'status') {
      return <td key={key}>{STATUSES[item[key]]}</td>;
    }
    if (key === 'brand_id') {
      return <td key={key}>{item['brand'].slug}</td>;
    }

    if (key === 'brand') {
      return <td key={key}>{item[key].name}</td>;
    }

    if (key === 'period_from') {
      return (
        <td key={key}>{moment(item[key]).format('MM/YYYY')}-{moment(item['period_to']).format('MM/YYYY')}</td>
      )
    }

    return <td key={key}>{item[key]}</td>;
  };

  return (
    <>
      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="card">
          <div className="card-body">
            <div>
              {create && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setItemId(null);
                    setModalOpen(true);
                  }}
                >
                  Create New
                </button>
              )}
            </div>

            {!!data.length && (
              <div className="table-responsive">
                <table className="table mt-3 mb-0">
                  <thead>
                    <tr>
                      {Object.keys(data[0]).map(key => renderTableHeading(key))}
                      {update && <th className="text-center">Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => (
                      <tr key={item.id}>
                        {Object.keys(item).map(key => renderTableField(key, item))}
                        {update && (
                          <td className="text-center">
                            <i
                              id={item.id}
                              className="fas fa-edit"
                              style={{ cursor: 'pointer' }}
                              data-toggle="modal"
                              data-target="#modal"
                              onClick={e => {
                                setItemId(+e.target.id);
                                setModalOpen(true);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {modalOpen && (
            <ModalForm setModalOpen={setModalOpen}>
              <BrandFormFields
                itemId={itemId}
                brands={brands}
                createMutationId={createMDF.definitions[0].name.value}
                updateMutationId={updateMDF.definitions[0].name.value}
                data={data.find(item => +item.id === +itemId)}
                setModalOpen={setModalOpen}
                name="Add/Edit MDF Budget"
                manufacturerId={manufacturerId}
                createMutation={createMDF}
                updateMutation={updateMDF}
                refetch={refetch}
                isMDF={true}
              />
            </ModalForm>
          )}
        </div>
      )}
    </>
  );
};

export default ManufacturerMDFSection;
