import React from 'react';
import { Link, Redirect } from 'react-router-dom';

import UsersList from './users/UsersList';
import RolesList from './roles/RolesList';

import './UserManagement.scss';

const UserManagement = ({ location, history, create, update }) => {
  const selectedTab = new URLSearchParams(location.search).get('tab');

  if (!selectedTab) {
    return <Redirect to="/user-management?tab=users" />;
  }

  const tabClickHandler = tabId => {
    history.push(`/user-management?tab=${tabId}`);
  };

  const renderContent = () => {
    switch (selectedTab) {
      case 'users':
        return <UsersList location={location} history={history} create={create} update={update} />;
      case 'roles':
        return <RolesList create={create} update={update} />;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Header */}
      <div className="page-title-box">
        <h4 className="page-title">User management</h4>
        <ol className="breadcrumb m-0">
          <li className="breadcrumb-item">
            <Link to="/">Settings</Link>
          </li>
          <li className="breadcrumb-item active">User management</li>
        </ol>
      </div>

      {/* Tabs */}
      <div className="user-tabs">
        <div
          className={`tab ${selectedTab === 'users' ? 'active' : ''}`}
          onClick={tabClickHandler.bind(null, 'users')}
        >
          Users
        </div>
        <div
          className={`tab ${selectedTab === 'roles' ? 'active' : ''}`}
          onClick={tabClickHandler.bind(null, 'roles')}
        >
          Roles
        </div>
        <div className="tab-bar" />
      </div>

      {/* Content */}
      {renderContent()}
    </>
  );
};

export default UserManagement;
