import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Apollo */
import { useQuery } from '@apollo/react-hooks';

/** Components */
import Table from '../../components/table/Table';

/** Services */
import { getManufacturers as QUERY } from '../../services/api/queryService';
import { queryStringHandler, fetchData, capitalize } from '../../services/util/dataHandlerService';

/** Routes */
import Routes from '../../lib/constants/routes';

const description = {
  module: 'manufacturers',
  description: 'Manufacturers',
  req: 'searchManufacturers',
  url: Routes.manufacturers.index,
  is_product: false
};

const Manufacturer = ({ location, create, canDelete }) => {
  const { user_type, manufacturers } = useSelector(state => state.auth.user);
  const { first, page, keyword } = queryStringHandler(location.search);
  const [ activeManufacturers, setActiveManufacturers] = useState([]);
  const [ filter, setFilter] = useState(''); 

  useEffect(() => {
    const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    if(storageActiveManufacturers){
      setActiveManufacturers(storageActiveManufacturers);
    }
  }, []);

  window.addEventListener('activeManufacturerChange', (e) => {
    setActiveManufacturers(JSON.parse(localStorage.getItem('active_manufacturers')));
  }); 

  useEffect(() => { 
    if(user_type === 1){
        setFilter(manufacturers.map(id => `id[]=${id}`).join('&'))
      }
    if(user_type === 2 && activeManufacturers){
        setFilter(activeManufacturers.filter(x => parseInt(x.id)).map(aManu => `id[]=${aManu.id}`).join('&'))
      }
  }, [activeManufacturers]);

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'id',
      filter: filter
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if(data){
      data.searchManufacturers.data.map(x => {
        if(x.commission_type !== 'Goods consignment'){
          x.commission_percentage = 0
        }

        return x
      })
    }
  }, [data])

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      {/* Start Content */}
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">{description.description}</h4>

            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  {create && (
                    <button
                      type="button"
                      className="page-title-right btn btn-primary mr-1 mb-3 mt-0 px-3"
                      style={{ borderRadius: '20px' }}
                    >
                      <Link
                        to={`/${description.module}/create`}
                        style={{ width: '100px' }}
                        className="text-white"
                      >
                        Create New
                      </Link>
                    </button>
                  )}
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item active">{capitalize(description.module)}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}

      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <Table
          openNewTab={true}
          loading={loading}
          refetch={refetch}
          description={description}
          pagerData={{ ...fetchData(description.req, data).pagerData, search: keyword }}
          tableData={fetchData(description.req, data).data}
        />
      )}
    </>
  );
};

export default Manufacturer;
