import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateListing } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

import { getProductCategoriesSettings } from '../../../services/api/queryService';
import { number } from 'prop-types';

const ProductSubtitle = ({ selectData, productData, refetch, update, onLisitingChange }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [marketplaceID, setMarketplaceID] = useState(
    productData.marketplace_id
  );

  const [formData, setFormData] = useState({
    subtitle: productData ? productData.subtitle : null
  });

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });

    const newData = {
      [event.target.id] : event.target.value
    }

    productData.subtitle = event.target.value

    onLisitingChange(newData)
  };

  useEffect(() => {
    setFormData({
      subtitle: productData.subtitle
    })
  }, [productData])


  const submitHandler = async event => {
    event.preventDefault();

    try {
      const { data } = await updateListingMutation({
        variables: {
          input: formData,
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }
  
  return (
    <form onSubmit={submitHandler}>
      <div className="row row-cols-4">
        <div className="col-12">
          <label htmlFor="title">Name</label>
          {productData && (

            <input
            type="text"
            id="subtitle"
            className="form-control"
            value={formData.subtitle}
            onChange={changeFieldHandler}
            maxLength={200}
            />
          )}
        </div>
      </div>
      {update && (
        <>
          {loading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <button type="submit" className="btn btn-primary px-3 mt-3">
              Save
            </button>
          )}
        </>
      )}
    </form>
  );
};

export default ProductSubtitle;
