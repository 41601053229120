import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

/** Apollo */
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import Routes from '../../lib/constants/routes';
import Table from '../../components/table/Table';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';


import { STATUSES_ENUM, IMPORT_STATUSES } from '../../lib/constants/fields';

/** Components */
import {
getCopyData as QUERY,
} from '../../services/api/queryService';
// import ContentSettingForm from './formFields/contentSettingForm'

import './backup.scss';

const description = {
module: 'copydata',
description: 'CopyData',
req: 'getCopyData',
url: Routes.copyData.index,
};

const CopyData = () => {
const [ changedData, setChangedData ] = useState({});
const [ loader, setLoader ] = useState(true);

const history = useHistory();
const location = useLocation();
const [marketplaces, setMarketplaces] = useState(null)
const [activeMarketplace, setActiveMarketplaces] = useState(null)

// const selectedTab = new URLSearchParams(location.search).get('tab');

let { loading, data, refetch, error } = useQuery(QUERY, {
variables: {
filter: false
},
fetchPolicy: 'no-cache'
});

const diffForHumans = seconds => {
const format = val => `0${Math.floor(val)}`.slice(-2)
const minutes = (seconds % 3600) / 60

return [minutes, seconds % 60].map(format).join('m : ') + 's'
}

const getMinutesBetweenDates = ((startDate, endDate) => {
var diff = new Date(endDate).getTime() - new Date(startDate).getTime();
var seconds = diff / 1000;
return diffForHumans(seconds);
})



useEffect(() => {
refetch()
if (data) {

const list = []

data.getCopyData.map((item, index) => {
  list.push({
    '' : null,
    'copy_start' : item.started_at,
    'copy_end' : item.finished_at,
    'timelaps' : getMinutesBetweenDates(item.started_at, item.finished_at),
    'row_count' : `${item.row_count} rows were created/updated`,
    'status': item.error ? STATUSES_ENUM.error : STATUSES_ENUM.ok,
    'error_message' : item.error
  })
})

const newData = {
    getCopyData: {
        data: list
    }
  }
  setChangedData(newData)
  setLoader(false)
}

}, [data])


return (
<>

  <div className="row">
    <div className="col-12">
      <div className="page-title-box">
        <h4 className="page-title">Copy Data</h4>

        <div className="row">
          <div className="col-12">
            <div className="page-title-box">

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item">
                  <Link to="/backup">Settings</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/copy-data">Copy data</Link>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  {loader ? (
  <div className="spinner absolute">Spinner</div>
  ) : (
  <div className="reports-page-table sections-page-table" id="backupList">
    <div class="reports-page-table sections-page-table" id="backupList">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="d-flex mb-2"></div>
              <div class="table-responsive">
                <table id="basic-datatable" class="table table-hover dt-responsive nowrap">
                  <thead>
                    <tr>
                      <th width="0"></th>
                      <th>Copy start</th>
                      <th>Copy end</th>
                      <th>Timelaps</th>
                      <th>Rows created/updated</th>
                      <th>Status</th>
                      <th>Error message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {changedData.getCopyData && changedData.getCopyData.data.map((x, i) => (
                      <tr key={i}>
                        <td></td>
                        <td><span>{ x.copy_start }</span></td>
                        <td><span>{ x.copy_end }</span></td>
                        <td><span>{ x.timelaps }</span></td>
                        <td><span>{ x.row_count }</span></td>
                        {x.error_message ? (
                          <td><span className="status-badge status-badge-canceled">Error</span></td>
                        ) : (
                          <td><span className="status-badge status-badge-active">OK</span></td>
                        )}
                        <td><span>{ x.error_message }</span></td>
                      </tr>
                    )) }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )}
</>
);

}

export default CopyData;