import React, { useState } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';

import { exportProduct } from '../../services/api/mutationService';
import { CONTENT_EXPORT_OPTIONS, PRODUCT_EXPORT_OPTIONS } from '../../lib/constants/fields';

const ContentExportModal = ({ selectedItems, setModalOpen }) => {
  const [formData, setFormData] = useState({
    type: CONTENT_EXPORT_OPTIONS[0].value
  });
  const [exportProductMutation, { loading, error }] = useMutation(exportProduct);

  const submitHandler = async event => {
    event.preventDefault();

    const { data } = await exportProductMutation({
      variables: { input: { ...formData, productIds: selectedItems } }
    });
    const a = document.createElement('a');
    a.href = `data:image/png;base64,${data.exportProduct}`;
    a.download = `${formData.type}_export_${new Date()
      .toISOString()
      .slice(0, 10)
      .replaceAll('-', '')}_${+new Date()}.xlsx`;
    a.click();

    setModalOpen(false);
  };

  const changeSelectHandler = (option, data) => {
    if (option) {
      setFormData({
        ...formData,
        [data.name]: option.value
      });
    }
  };

  const backdropClickHandler = event => {
    if (event.target.id === 'Content') {
      setModalOpen(false);
    }
  };

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="exportModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={submitHandler}>
              <div className="modal-header">
                <h4 className="modal-title">Export products</h4>
                <button type="button" className="close" onClick={() => setModalOpen(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pb-4">
                <label htmlFor="status" style={{ fontWeight: 600, marginBottom: '0.3rem' }}>
                  Type
                </label>
                <Select
                  name="type"
                  options={CONTENT_EXPORT_OPTIONS}
                  defaultValue={CONTENT_EXPORT_OPTIONS[0]}
                  onChange={changeSelectHandler}
                />
              </div>
              <div className="modal-footer justify-content-start">
                {loading ? (
                  <div className="spinner">Spinner</div>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!selectedItems.length}
                  >
                    Export
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentExportModal;
