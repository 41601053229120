import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import ModalError from '../../components/modal/ModalError';
import { deleteProduct } from '../../services/api/mutationService';

const DeleteModal = ({ selectedItems, setModalOpen }) => {
  const [deleteProductMutation, {}] = useMutation(deleteProduct);
  const [loader, setLoader] = useState(true)
  const [acceptDelete, setAcceptDelete] = useState(false)
  const [deletedProducts, setDeletedProducts] = useState([])
  const [error, setError] = useState(null);
  const [isBundledItems, setIsBundledItems] = useState(false);
  
  const submitHandler = async (items, event) => {
    const { data } = await deleteProductMutation({
      variables: { 
        id: items, 
        softDelete: event 
      }
    });
    if (data) {
      if (data.deleteProduct) {
        const errorFound = data.deleteProduct.find(item => item.hasParentProducts.length > 0)
        if (errorFound) {
          setError('The selected product/s - is/are part of a bundle item/s. The bundle item/s status is going to be changed to “Archived”. All info of the selected product/s is going to be lost !!!')
        } else {
          setError('The selected product/s and all related information are going to be deleted. Please confirm deletion below')
        }
        setDeletedProducts(data.deleteProduct)
        setLoader(false)

        data.deleteProduct.forEach(item => {
          if(item.hasParentProducts.length) setIsBundledItems(true)
        });
        
        if (event) {
          setModalOpen(false);
        }
      }
    }
    // setModalOpen(false);
  };

  const submitDelete = () => {
    submitHandler(selectedItems, true)
  }

  useEffect(() => {
    if (selectedItems) {
      submitHandler(selectedItems, false)
    }
  }, [selectedItems])

  const backdropClickHandler = event => {
    if (event.target.id === 'exportModal') {
      setModalOpen(false);
    }
  };

  const checkboxChangeHandler = event => {
    const { checked } = event.target;
    setAcceptDelete(checked)
  };

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="exportModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Warning before deleting products</h4>
              <button type="button" className="close" onClick={() => setModalOpen(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {loader ? (
                <div className="spinner absolute">Spinner</div>
              ) : (
                <div>
                  <ModalError className="modal-thin" error={error} setError={setError} />
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th style={{width: '65px'}}>PID</th>
                          <th style={{width: '380px', paddingLeft: '50px'}}>Name</th>
                          <th className="text-center">Details</th>
                          <th className="text-center">Packing unit</th>
                          <th className="text-center">Marketplace IDs</th>
                          { isBundledItems ? (
                            <th className="text-center">Bundled items name</th>
                          ) : ""}
                          
                        </tr>
                      </thead>
                      <tbody>
                        {deletedProducts && deletedProducts.map(item => 
                          <tr key={item.product_parent_id}>
                            <td>
                              <a target="_blank" href={`/products/${item.slug}/edit`}>{item.slug}</a>
                            </td>
                            <td>
                              <a target="_blank" href={`/products/${item.slug}/edit`}>{item.product_name}</a>
                            </td>
                            <td className="text-center">{item.product_details}</td>
                            <td className="text-center">{item.packagingUnit ? item.packagingUnit.name : ''}</td>
                            <td className="text-center">{item.productMarketplaces ? item.productMarketplaces.map(item => item.marketplace_product_id + ',') : ''} </td>
                            <td className="text-center">{item.hasParentProducts ? item.hasParentProducts.map(item => item.parentProduct ? item.parentProduct.product_name + ',' : '') : ''} </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  <div>
                    <input id="deletion" type="checkbox" className="mr-1" onChange={checkboxChangeHandler} />
                    <label htmlFor="deletion">Confirm deletion</label>
                  </div>
                </div>
              )}
            </div>
            
            <div className="modal-footer justify-content-start">
                <button onClick={() => submitDelete()} disabled={!acceptDelete} className="btn btn-danger">
                  Delete
                </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default DeleteModal;
