import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

/** Apollo */
import { useQuery } from '@apollo/react-hooks';

/** Components */
import DefaultForm from '../../components/form/DefaultForm';
import DetailsHeader from '../../components/details-header/DetailsHeader';
import ManufacturerProductsTab from './ManufacturerProductsTab';
import ManufacturerBrandsTab from './ManufacturerBrandsTab';
import ManufacturerTargetsTab from './ManufacturerTargetsTab';

/** import fields */
import { editManufacturer as fields } from '../../lib/constants/fields';

/** Routes */
import Routes from '../../lib/constants/routes';

/** service */
import { advertisingChannels, getManufacturer } from '../../services/api/queryService';
import {
  updateManufacturer as editMutation,
  deleteManufacturer as deleteMutation
} from '../../services/api/mutationService';

import { fillFieldsData } from '../../services/util/dataHandlerService';
import ManufacturerSettings from './ManufacturerSettings';
import ManufacturerPortfolios from './ManufacturerPortfolios';
import ManufacturerMDFSection from './ManufacturerMDFSection';

const description = {
  module: 'manufacturers',
  action: 'edit',
  req: 'updateManufacturer'
};

const EditManufacturer = ({ match, location, create, update, canDelete, productsTab, brandsTab, targetsTab, settingTab, getManufacturerTitle }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const [selectedTab, setSelectedTab] = useState('products');
  const [manufacturerData, setManufacturerData] = useState();
  const [brandsData, setBrandsData] = useState(null);
  const [salesTargetsData, setSalesTargetsData] = useState(null);
  const [channels, setChannels] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const [mdfs, setMdfs] = useState([]);

  const { loading, data, refetch } = useQuery(getManufacturer, {
    variables: { slug: match.params.id }
  });

  const { loading: channelLoading, data: channelData} = useQuery(advertisingChannels);

  useEffect(() => {
    if(channelData && !channelLoading){
      setChannels([...channelData.advertising_channels])
    }
  }, [channelLoading, channelData])

  useEffect(() => {
    if (!loading && data) {
      const res = { ...data.manufacturer };
      setBrandsData(res.brands);
      setSalesTargetsData(res.salesTargets);
      setMdfs(res.mdfs);
      res.brands = res.brands.map(brand => brand.name);
      setManufacturerData(res);
      fillFieldsData(fields, res);
      setShouldRender(true);
      setPortfolios(res.portfolios)

      getManufacturerTitle(data.manufacturer.name)

    }
  }, [loading, data]);

  return (
    <>
      {shouldRender ? (
        <>
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">{fields.name.value}</h4>

                <div className="page-title-left">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={Routes.manufacturers.index}>Manufacturers</Link>
                    </li>
                    <li className="breadcrumb-item active">{fields.name.value}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title  */}
          <DetailsHeader data={manufacturerData} renderFor={description.module} />

          {/* Tabs */}
          <div>
            <ul className="nav nav-tabs nav-bordered mb-3">
              {productsTab.read && (
                <li className="nav-item">
                  <a
                    href="#products-tab"
                    className="nav-link active"
                    data-toggle="tab"
                    onClick={() => setSelectedTab('products')}
                  >
                    Products
                  </a>
                </li>
              )}

              {brandsTab.read && (
              <li className="nav-item">
                <a
                  href="#brands-tab"
                  className="nav-link"
                  data-toggle="tab"
                  onClick={() => setSelectedTab('brands')}
                >
                  Brands
                </a>
              </li>
              )}

              {targetsTab.read && (
              <li className="nav-item">
                <a
                  href="#targets-tab"
                  className="nav-link"
                  data-toggle="tab"
                  onClick={() => setSelectedTab('targets')}
                >
                  Targets
                </a>
              </li>
              )}

              {targetsTab.read && (
              <li className="nav-item">
                <a
                  href="#mdf-section"
                  className="nav-link"
                  data-toggle="tab"
                  onClick={() => setSelectedTab('mdf_section')}
                >
                  MDF
                </a>
              </li>
              )}

              {settingTab.read && update &&(
                <li className="nav-item">
                  <a
                    href="#settings-tab"
                    className="nav-link"
                    data-toggle="tab"
                    onClick={() => setSelectedTab('settings')}
                  >
                    Settings
                  </a>
                </li>
              )}

              {settingTab.read && update &&(
                <li className="nav-item">
                  <a
                    href="#portfolios-tab"
                    className="nav-link"
                    data-toggle="tab"
                    onClick={() => setSelectedTab('portfolios')}
                  >
                    Portfolios
                  </a>
                </li>
              )}
            </ul>
          </div>

          <div className="tab-content p-0">
            <div className="tab-pane show active" id="products-tab">
              {selectedTab === 'products' && productsTab.read && (
                <ManufacturerProductsTab
                  location={location}
                  canDelete={canDelete}
                  description={description}
                  slug={manufacturerData.slug}
                  manufacturerId={manufacturerData.id}
                />
              )}
            </div>
            <div className="tab-pane show" id="brands-tab">
              {selectedTab === 'brands' && (
                <ManufacturerBrandsTab
                  data={brandsData}
                  manufacturerId={manufacturerData.id}
                  refetch={refetch}
                  create={brandsTab.create}
                  update={brandsTab.update}
                />
              )}
            </div>
            <div className="tab-pane show" id="targets-tab">
              {selectedTab === 'targets' && (
                <ManufacturerTargetsTab
                  data={salesTargetsData}
                  manufacturerId={manufacturerData.id}
                  refetch={refetch}
                  create={targetsTab.create}
                  update={targetsTab.update}
                />
              )}
            </div>
            <div className="tab-pane show" id="mdf-section">
              {selectedTab === 'mdf_section' && update && (
                <ManufacturerMDFSection
                  data={mdfs}
                  brands={brandsData}
                  deleteService={deleteMutation}
                  id={manufacturerData.id}
                  manufacturerId={manufacturerData.id}
                  refetch={refetch}
                  update={targetsTab.update}
                  create={targetsTab.create}
                />
              )}
            </div>
            <div className="tab-pane show" id="settings-tab">
              {selectedTab === 'settings' && update && (
                <ManufacturerSettings
                  data={manufacturerData}
                  channels={channels}
                  service={editMutation}
                  deleteService={deleteMutation}
                  id={manufacturerData.id}
                  req={description.req}
                  refetch={refetch}
                  update={settingTab.update}
                />
              )}
            </div>
            <div className="tab-pane show" id="portfolios-tab">
              {selectedTab === 'portfolios' && update && (
                <ManufacturerPortfolios
                  data={portfolios}
                  service={editMutation}
                  deleteService={deleteMutation}
                  id={manufacturerData.id}
                  req={description.req}
                  refetch={refetch}
                  manufacturerId={manufacturerData.id}
                  update={settingTab.update}
                />
              )}
            </div>
            
          </div>
        </>
      ) : (
        <div className="spinner absolute">Spinner</div>
      )}
    </>
  );
};

export default EditManufacturer;
