import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

/** Apollo */
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import Routes from '../../../lib/constants/routes';
import Table from '../../../components/table/Table';
import { queryStringHandler, fetchData } from '../../../services/util/dataHandlerService';

import { STATUSES_ENUM, IMPORT_STATUSES } from '../../../lib/constants/fields';

/** Components */
import { getExchangeRates, getWarehouseCosts as QUERY } from '../../../services/api/queryService';
import { render } from 'react-dom';
import ModalForm from '../../../components/modal/ModalForm';
import WarehouseCostModal from './WarehouseCostModal';

import "../costSettings.scss"

const WarehouseCost = () => {
    const [ changedData, setChangedData ] = useState({});
    const [ loader, setLoader ] = useState(true);
    const [ itemId, setItemId ] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [exchangeRates, setExchangeRates] = useState([]);

    const history = useHistory();
    const location = useLocation();


    let { loading, data, refetch, error } = useQuery(QUERY);
    let { data : getExchangeRatesData } = useQuery(getExchangeRates);

    useEffect(() => {
        if (data) {
            setChangedData([...data.warehouseCosts])
            setLoader(false)
        }

    }, [data])

    useEffect(() => {
        if (getExchangeRatesData) {
            setExchangeRates([...getExchangeRatesData.exchange_rates])
        }
    }, [getExchangeRatesData])

    return (
        <>
            {loader ? (
                <div className="spinner absolute">Spinner</div>
            ) : (
                <div className="sections-page-table" id="shippingCost">
                    <div className="row">
                        <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {/* <div className="d-flex mb-2">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setItemId(null);
                                            setOpenModal(true);
                                        }}
                                        >
                                        Create New
                                    </button>
                                </div> */}
                                <div className="table-responsive">
                                    <table id="basic-datatable" className="table table-hover dt-responsive nowrap warehouse-costs">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '50px' }}>ID</th>
                                                <th>Fulfillment vendor</th>
                                                <th>Period</th>
                                                <th>Warehouse buy costs/m3 month</th>
                                                <th>Margin</th>
                                                <th>Sell price</th>
                                                <th></th>
                                                <th>Edit</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {changedData && changedData.length && changedData.map((x, i) => (
                                                <tr key={i}>
                                                    <td>{ x.id }</td>
                                                    <td><span>{ x.vendor.name }</span></td>
                                                    <td><span>{ x.period }</span></td>
                                                    <td><span>{ exchangeRates && exchangeRates.length ? parseFloat(x.cost / exchangeRates.find(w => w.currency_id == x.currency).value).toFixed(2) : x.cost }&euro;</span></td>
                                                    <td><span>{ x.margin_percentage }%</span></td>
                                                    <td><span>{ exchangeRates && exchangeRates.length ? parseFloat(x.cost_sell / exchangeRates.find(w => w.currency_id == x.currency).value).toFixed(2) : x.cost_sell }&euro;</span></td>
                                                    <td></td>
                                                    <td>
                                                        <span>
                                                            <i
                                                                id={x.id}
                                                                className="fas fa-edit"
                                                                style={{ cursor: 'pointer' }}
                                                                data-toggle="modal"
                                                                data-target="#modal"
                                                                onClick={e => {
                                                                    setItemId(x);
                                                                    setOpenModal(true);
                                                                }}
                                                            />
                                                        </span>
                                                    </td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            )}

            {openModal && (
                <ModalForm setModalOpen={setOpenModal}>
                    <WarehouseCostModal
                        itemId={itemId}
                        sections={[]}
                        setModalOpen={() => {setOpenModal(false); } }
                        refetch={refetch} 
                    />
                </ModalForm>
            )}
        </>

        );
}

export default WarehouseCost;