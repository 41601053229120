import React, { useRef, useEffect, useState } from "react";

/** Redux */
import { useDispatch } from 'react-redux';

/** Apollo */
import { useMutation } from '@apollo/react-hooks';

/** Services */
import {
    login as AUTH, hardLogout
} from '../../services/api/mutationService';
import AuthModal from "./authModal";

const setCookie = (cookieName, cookieValue, hourToExpire) => {
    let date = new Date();
    date.setTime(date.getTime()+(hourToExpire*60*60*1000));
    document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
}

const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

const Auth = () => {

    /** initial value of inputs */
    const email = useRef('');
    const password = useRef('');
    

    const dispatch = useDispatch();

    const [login, { loading, data, error }] = useMutation(AUTH);
    const [hardLogOut, { data: logoutData }] = useMutation(hardLogout);
    const [authModalOpen, setAuthModalOpen] = useState(false)


    useEffect(() => {
        hardLogOut({
            variables: {
                apiToken: getCookie('_user_token'),
            }
        })
    }, [])

    
    useEffect(() => {

        if(loading){

            console.log('loading...');

        } else if(data){

            if(data.login){
                localStorage.setItem('auth_token',data.login.api_token);

                setCookie('_user_token', data.login.api_token, 24*7)
            
                setTimeout(()=>{
                    window.location.href = '/';
                }, 100)
            }else{
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        type: 'error',
                        message: 'Invalid Credentials'
                    }
                })
            }
            

        } else if(error){

            if(error.message == 'GraphQL error: User is already logged in'){
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        type: 'error',
                        message: 'Your account is already logged in on a different device. Please first logout to proceed on this device.'
                    }
                })
            } else {
                dispatch({
                    type: 'UPDATE',
                    payload: {
                        type: 'error',
                        message: 'Invalid Credentials'
                    }
                })
            }
        } else {
            const token = localStorage.getItem('auth_token');

            if(token){
                window.location.href = '/';
            }
        }

    } , [ loading , data , dispatch , error ])


    /** Login Handler */
    const submitHandler = e => {

        e.preventDefault();

        login({
            variables: {
                email: email.current.value,
                password: password.current.value
            }
        })
    }

    return (
        <>


            <div className="account-pages mt-5 mb-5"  >
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="card bg-pattern">
                                <div className="card-body p-4">
                                    <div className="text-center w-75 m-auto">
                                        <a href="/">
                                            <span><img src="assets/images/logo.png" alt="" height="200" /></span>
                                        </a>
                                        <p className="text-muted">Enter your email address and password to access admin panel.</p>
                                    </div>
                                    <form action="" onSubmit={submitHandler}>

                                        <div className="form-group mb-3">
                                            <label htmlFor="emailaddress">Email address</label>
                                            <input className="form-control" autoFocus ref={email} type="email" id="emailaddress" required={true} placeholder="Enter your email" />
                                        </div>

                                        <div className="form-group mb-3">
                                            <label htmlFor="password">Password</label>
                                            <input className="form-control" ref={password} type="password" required={true} id="password" placeholder="Enter your password" />
                                        </div>

                                        <div className="form-group mb-3 text-center">
                                            <button style={{ background : "#19315A" }}  className="btn btn-primary btn-block"  type="submit"> Log In </button>
                                        </div>

                                        <div className="form-group mb-0 text-center">
                                            <button style={{ color : "#000" }}  className="btn btn-link"  type="button" onClick={() => { setAuthModalOpen(true); } }> Cannot log in? Restore your login here </button>
                                        </div>

                                        { !loading ?  <input type="hidden" className="res" defaultValue={ !data ? 'error':'data'  } /> : null}
                                    </form>

                                    { authModalOpen && (
                                        <AuthModal  setModalOpen={() => { setAuthModalOpen(false); } } />
                                    )} 
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Auth;
