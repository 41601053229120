import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import ProductFormCard from './ProductFormCard';
import ProductInformationForm from './forms/ProductInformationForm';
import Routes from '../../lib/constants/routes';

import { getProductSettings } from '../../services/api/queryService';

const AddProduct = ({ create }) => {
  const { data, error } = useQuery(getProductSettings);
  if (error) {
    throw new Error(error);
  }
  return (
    <>
      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <>
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">Create Product</h4>

                <div className="page-title-left">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`${Routes.products.index}?tab=search`}>Products</Link>
                    </li>
                    <li className="breadcrumb-item active">Create Product</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title  */} 

          <ProductFormCard id="productInformation" formPreOpen={true}>
            <ProductInformationForm
              updateFormData={() => console.log('create')}
              selectData={JSON.parse(data.productSettings)}
              productData={{}}
              create={create}
              update={true}
              req={'createProduct'}
            />
          </ProductFormCard>
        </>
      )}
    </>
  );
};

export default AddProduct;
