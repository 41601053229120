import React, {useEffect, useState} from 'react';
import { useQuery } from '@apollo/react-hooks';
import Table from '../../components/table/Table';
import AddSectionFields from './AddSectionFields';
import { getSections as QUERY } from '../../services/api/queryService';
import { fetchData } from '../../services/util/dataHandlerService';
import Routes from '../../lib/constants/routes';
import ModalForm from '../../components/modal/ModalForm';

const description = {
  module: 'analyticsSections',
  description: 'analyticsSections',
  req: 'getSections',
  url: Routes.products.index,
  tab: 'search'
};

const SelectTab = ({ location, reloadData }) => {
  const [ changedData, setChangedData ] = useState({});
  const [ loader, setLoader ] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState(null)
  const [ sections, setSections ] = useState({});

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      filter: 0
    }
  });


  useEffect(() => {
    refetch()
    if (data) {
      const d = data.getSections.map(x => {
        delete x.showFilter;
        delete x.position;
        delete x.user_tab_position;
        return x
      })
      const newData = {
        getSections: {
          data: d,
        }
      }
      setChangedData(newData)
      setLoader(false)
    }
  }, [data, reloadData])

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      {loader ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="reports-page-table sections-page-table">
          <Table
            loading={loading}
            refetch={refetch}
            description={description}
            pagerData={{
              ...fetchData(description.req, changedData).pagerData
            }}
            tableData={fetchData(description.req, changedData).data}
            expandedHeader={false}
            notClickable={true}
            hasExport={false}
            filter={false}
            hasAction={true}
            onAction={(item) => { setItem(item); setOpenModal(true)}}
          />

          {openModal && (
            <ModalForm setModalOpen={setOpenModal}>
              <AddSectionFields
                itemId={item}
                sections={changedData.getSections.data}
                setModalOpen={() => {setOpenModal(false); refetch() } } 
              />
            </ModalForm>
          )}
        </div>
      )}
    </>
  );
};

export default SelectTab;
