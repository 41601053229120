import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ModalError from '../../../components/modal/ModalError';
import { STATUS_OPTIONS } from '../../../lib/constants/fields';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import "../costSettings.scss"

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { createWarehouseCost, updateWarehouseCost } from '../../../services/api/mutationService';
import { 
  getFulfillmentVendorsSettings, 
} from '../../../services/api/queryService';

const WarehouseCostModal = ({ setModalOpen, itemId, sections, refetch }) => {


  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [fulfilments, setFulfilments] = useState([]);
  const [shippingCarriers, setShippingCarriers] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const currencies = [
    {
      id: 0,
      name: 'EUR'
    },
    {
      id: 1,
      name: 'GPB'
    }
  ];

  const [formData, setFormData] = useState(itemId ? {...itemId} :{
    fulfilment_vendor: null,
    currency: 0,
    period: null,
    cost: 0,
  });

  let { data : getFullfilmetns } = useQuery(getFulfillmentVendorsSettings, {
    variables: {
        order: 'id',
        filter: 'status'
    }
  });


  useEffect(() => {
    if(getFullfilmetns){
      setFulfilments([...getFullfilmetns.fulfillmentVendors])
    }

  }, [getFullfilmetns])

  useEffect(() => {
    if(itemId){
      let r = itemId.period.split('-')[0].split('/')
      let k = itemId.period.split('-')[1].split('/')
      let s = new Date(`${r[2]}-${r[1]}-${r[0]}`)
      let e = new Date(`${k[2]}-${k[1]}-${k[0]}`)
      setDateRange([s, e])
    }
  }, [itemId])

  useEffect(() => {
    setFormData({
      ...formData,
      ['period']: `${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`
    });
  }, [dateRange])


  const [createFulfilmetnMutation, { }] = useMutation(
    createWarehouseCost
  );

  const [updateFulfilmentMutation, {}] = useMutation(
    updateWarehouseCost
  );

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {

    setFormData({
      ...formData,
      [data.name]: option && option.value
    });
  };

  const submitHandler = async event => {
    event.preventDefault();

    try {
      if (itemId) {
        delete formData.__typename
        delete formData.error
        delete formData.vendor
        const { data } = await updateFulfilmentMutation({
          variables: {
            input: formData,
            id: itemId.id,
          },
          id: itemId.id
        });

        if (!data.id) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data.updateRole.error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createFulfilmetnMutation({
          variables: {
            input: formData
          }
        });
        if (!data.id) {
          throw new Error('Server responded with no data');
        }
      }    

    } catch (err) {
      console.log(err)
      // setError('Internal server error');
    }

    dispatch({
      type: 'UPDATE',
      payload: {
        type: 'success',
        message: `Recored was successfully ${itemId ? 'updated' : 'created'}`
      }
    });
    refetch();

    setModalOpen(false);
  };
  

  return (
    <form onSubmit={submitHandler} className="roles-form">
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {itemId ? 'Edit' : 'Add'} Warehouse Costs
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError className="modal-thin" error={error} setError={setError} />

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Fulfilment vendor</label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="fulfilment_vendor"
                    name="fulfilment_vendor"
                    value={createDefaultOption(fulfilments.filter(x => x.id == formData.fulfilment_vendor)[0])}
                    onChange={changeSelectHandler}
                    options={convertDataToOptions(fulfilments)}
                    isDisabled={true}
                />
                <input
                  tabIndex={-1}
                  style={{ opacity: 0, height: 0 }}
                  required={!formData.fulfilment_vendor}
                />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Currency </label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="currency"
                    name="currency"
                    value={createDefaultOption(currencies.filter(x => x.id == formData.currency)[0])}
                    onChange={changeSelectHandler}
                    options={convertDataToOptions(currencies)}
                    isDisabled={true}
                />
                                <input
                  tabIndex={-1}
                  style={{ opacity: 0, height: 0 }}
                  required={formData.currency == null}
                />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Validity period</label>
            <div className="calendarInput" style={{ width: '300px' }}>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  isClearable={false}
                  selected={startDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  required={true}
                  className="form-control"
                  disabled={true}
                />
                <img className="calendarIcon" src="/assets/images/calendar-2.png" alt="" />
            </div>
        </div>  
        
        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px', borderBottom: '1px solid #b2b2b2'}}>
            <label htmlFor="tab"></label>
            <div style={{ width: '300px' }} className="dividerDiv">
              <div style={{ width: '100px' }}>
                  <p>Buy price</p>
              </div>
              <div style={{ width: '100px' }}>
                  <p>Margin</p>
              </div>
              <div style={{ width: '100px' }}>
                  <p>Sell price</p>
              </div>
            </div>
        </div>
        
        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab"> Warehouse cost/ m3 month</label>
              <div style={{ width: '300px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '90px' }}>
                    <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        id="cost"
                        name="cost"
                        value={formData.cost}
                        onChange={changeFieldHandler}
                        required={!formData.cost}
                        disabled={true}
                    />
                                        <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0 }}
                        required={+formData.cost == 0}
                      />
                </div>
                <div style={{ width: '90px' }}>
                  <Select
                      type="text"
                      id="margin"
                      name="margin"
                      value={{ label: '15%', value: 15 }}
                      options={[
                        { label: '15%', value: 15 }
                      ]}
                      isDisabled={true}
                  />
                </div>
                <div style={{ width: '90px' }}>
                    <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        value={Number(formData.cost * 1.15).toFixed(2)}
                        disabled={true}
                    />
                </div>
              </div>
        </div>  

  
      </div>
      
      <div className="modal-footer justify-content-start">
        { loader? (
          <div className="spinner absolute">Spinner</div>
        ) : (
          //   <button  type="submit" className="btn btn-primary">
          //   Save
          // </button>
          <></>
        ) }        
      </div>
    </form>
  )
};

export default WarehouseCostModal;
