import React, { useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

/** Components */
import ProductSearchTab from './ProductSearchTab';
import ImportJobList from './import/ImportJobList';
import ImportModal from './import/ImportModal';

import './Product.scss';
import ListingSearchTab from '../listings/ListingSearchTab';
import ContentImportModal from '../listings/import/ContentImportModal';

const Product = ({ canImport, create, update, canDelete, marketplacePermission, listingPermissions }) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const selectedTab = new URLSearchParams(location.search).get('tab');


  if (!selectedTab) {
    return <Redirect to="/products?tab=search" />;
  }

  const tabClickHandler = tabId => {
    history.push(`/products?tab=${tabId}`);
  };

  return (
    <>
      {/* Start Content */}
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Products</h4>

            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  {/* {(selectedTab === 'listings' || selectedTab === 'import') && create &&  (
                    <button
                      type="button"
                      className="page-title-right btn btn-primary mr-1 mb-1 mt-0 px-3"
                      style={{ borderRadius: '20px' }}
                      onClick={() => setImportModalOpen(true)}
                    >
                      Import
                    </button>
                  )} */}
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item active">Products</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}

      {/* Tabs */}
      <div className="product-tabs">
        <div
          className={`tab ${selectedTab === 'search' ? 'active' : ''}`}
          onClick={tabClickHandler.bind(null, 'search')}
        >
          Products
        </div>
        {listingPermissions.read && (
          <div
            className={`tab ${selectedTab === 'listings' ? 'active' : ''}`}
            onClick={tabClickHandler.bind(null, 'listings')}
          >
            Content
          </div>
        )}
        {canImport && (
          <div
            className={`tab ${selectedTab === 'import' ? 'active' : ''}`}
            onClick={tabClickHandler.bind(null, 'import')}
          >
            Import
          </div>
        )}
        <div className="tab-bar" />
      </div>

      {selectedTab === 'search' && <ProductSearchTab canDelete={canDelete} location={location} />}

      {selectedTab === 'listings' && (
        <ListingSearchTab location={location} canDelete={false} />
      )}

      {selectedTab === 'import' && create && (
        <ImportJobList canImport={canImport} location={location} importModalOpen={importModalOpen} />
      )}

      {/* Import Modal */}
      {selectedTab === 'listings' && importModalOpen && <ContentImportModal setModalOpen={setImportModalOpen} />}
      {selectedTab === 'import' && importModalOpen && <ImportModal setModalOpen={setImportModalOpen} />}
    </>
  );
};

export default Product;
