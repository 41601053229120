import React, { memo, useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import BaseSelect from "react-select";
import FixRequiredSelect from '../../../components/ui-components/select/FixRequiredSelect';

import { updateProductMarketplaces, deleteProductMarketplace } from '../../../services/api/mutationService';
import { MARKETPLACE_STATUS_OPTIONS, marketplace_id_typeS } from '../../../lib/constants/fields';
import { convertDataToOptions, createDefaultOption } from '../../../services/util/dataHandlerService';
import Error from '../../../components/modal/ModalError';
import ModalError from '../../../components/modal/ModalError';
import MarketplaceIssuesModal from '../MarketplaceIssuesModal';
import { getAmazonListingsStatuses as GET_AMAZON_LISTINGS_STATUSES } from '../../../services/api/queryService';
import { AmazonStatus, AmazonStatusError } from '../AmazonStatus';

function useAmazonListings(amazonListingsInput) {
  const { loading, data, refetch, error } = useQuery(GET_AMAZON_LISTINGS_STATUSES, {
    variables: {
      input:amazonListingsInput
    },
    fetchPolicy: 'no-cache'
  });

  return { loading, amazonListingData: data, refetch, error };
}

/* Added a custom Select component to enable required value for Select */
const RequiredSelect = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options}
  />
);


const MarketplaceInformationForm = ({  selectData, productData, refetch, update, marketplacePermission, parentProduct, updateFormData, errors, toggleOverflowScroll }) => {
  const { marketplacesInformation } = productData;

  marketplacesInformation.map(x => {
    let marketplaceTypes = [{ id: 0, name: 'ASIN' }, { id: 1, name: 'SKU' }]
    if(+x.marketplace_id == 22) marketplaceTypes = [{ id: 2, name: 'MLID' }, { id: 1, name: 'SKU' }]
    if(+x.marketplace_id == 23) marketplaceTypes = [{ id: 1, name: 'SKU' }]
    if(+x.marketplace_id == 24) marketplaceTypes = [{ id: 1, name: 'SKU' }]    
    x.marketplaceTypes = marketplaceTypes
    x.inventory_id = x.seller_id == 1 ? ((x.fulfillment_vendor_id == 1 || x.fulfillment_vendor_id == 15) ? x.marketplace_number_id : ((x.fulfillment_vendor_id > 1 && x.fulfillment_vendor_id != 15) ? productData.slug : null)) : null
  })
  const marketplaceTemp = ['ebay.de', 'kaufland.de', 'otto.de'];
  const marketplaceOptions = parentProduct ? selectData.marketplaces.filter(x => marketplaceTemp.indexOf(x.name.toLowerCase()) > -1 || +x.status == 1).map(item => ({
    id: item.id,
    name: item.name
  })) : selectData.marketplaces.filter(x => +x.status == 1).map(item => ({
    id: item.id,
    name: item.name
  }));

  const [ openMarketplaceIssuesModal, setOpenMarketplaceIssuesModal ] = useState(false);
  const [currentModalData, setCurrentModalData] = useState({ sku: '', marketplace_id: '', amazonStatus: '', errors: [] });
  function handleShow(sku, marketplace_id, amazonStatus, errors){
    setCurrentModalData({
      sku: sku,
      marketplace_id: marketplace_id,
      amazonStatus: amazonStatus,
      errors: errors
    });
    setOpenMarketplaceIssuesModal(true);
    toggleOverflowScroll()
  }
  const handleModalClose = () => {
    setOpenMarketplaceIssuesModal(false);
    toggleOverflowScroll()
  };

  const [errorsList, setErrorsList] = useState([]);
  const [errorsArray, setErrorsArray] = useState([]);

  const showError = (index, key) => {
    if(!errorsList[index] || !errorsList[index][key]) return null

    return (
      <div className="custom_error_container">
        <img src="/assets/images/info-button.png" alt="" />
        <p>{errorsList[index][key]}</p>
      </div>
    )
  }

  useEffect(() => {
    if(errors['marketplaces']){
      const err = {...errors['marketplaces']}
      const d = []

      Object.keys(err).map(k => {
        d[k] = err[k]
      });

      const r = []
      d.map((x, i) => {
        Object.keys(x).map(w => {
          r.push(x[w])
        })
      })

      setErrorsArray(r.join(';'))
      setErrorsList(d)
    }else{
      setErrorsList([])
    }
  }, [errors])

  const [amazonListingStatus, setAmazonListingStatus] = useState([]);
  const amazonListingsInput = [];
  const marketplaceErrors = [];
  productData.productMarketplaces.forEach(function(pm) {
    if(pm.error !== null) { 
      const mpErrors = pm.error.split(";"); /* Split errors in case multiple errors occured during validation */
      const marketpalceObj = {
        marketplace_id : pm.marketplace_id,
        sku: pm.marketplace_product_id,
        errors: []
      }
      mpErrors.forEach(function(mpError) {
        marketpalceObj.errors.push(mpError);
      });

      marketplaceErrors.push(marketpalceObj)
    }

    amazonListingsInput.push({
      sku: pm.marketplace_product_id,
      marketplace_id: pm.marketplace_id
    });
  });

  const { loadingAL, amazonListingData, refetchAL, errorAL } = useAmazonListings(amazonListingsInput);
  
  useEffect(() => {
      let azStatus = [];
      if(amazonListingData){
        amazonListingData.getAmazonMerchantListings.map(listing => {
          if(listing !== null){
            azStatus[listing.seller_sku + '-' + listing.marketplace] = listing.status;
          }
        });
      }
      setAmazonListingStatus(azStatus);
  }, [amazonListingData]);



  const [updateProductMarketplacesMutation, { loading, error: updateError }] = useMutation(
    updateProductMarketplaces
  );
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [validationErrror, setValidationError] = useState();
  const [formData, setFormData] = useState([ ...marketplacesInformation]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deleteMarketplaceMutation, {}] = useMutation(deleteProductMarketplace);
  const [load, setLoad] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [warning, setWarning] = useState(null)

  const { localStorage } = window;
  
  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true

  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  useEffect(() => {
    // const typesCheck = {};
    // let checkError = false;
    let asinData = formData.filter(item => {
      if ( item.marketplace_id_type === 0 && item.status === 1 ) return item;
    }).map(item => item.marketplace_id);

    // Allow only one active ASIN per marketplace
    if (asinData.length > 1 && (new Set(asinData)).size !== asinData.length ) {
      // setValidationError('One marketplace can not have many active ASIN!');
    }
  }, [formData]);

  useEffect(() => {
    marketplacesInformation.map(x => {
      let marketplaceTypes = [{ id: 0, name: 'ASIN' }, { id: 1, name: 'SKU' }]
      if(+x.marketplace_id == 22) marketplaceTypes = [{ id: 2, name: 'MLID' }, { id: 1, name: 'SKU' }]
      if(+x.marketplace_id == 23) marketplaceTypes = [{ id: 1, name: 'SKU' }]
      if(+x.marketplace_id == 24) marketplaceTypes = [{ id: 1, name: 'SKU' }]
  
      x.marketplaceTypes = marketplaceTypes
    })


    setFormData(marketplacesInformation)

  }, [marketplacesInformation])

  const submitHandler = async event => {
    event.preventDefault();

    let priceCheckError = false;

    if(!silentError){
      if((productData.product_category_hazmat == null || productData.product_category_hazmat.length == 0)  && +productData.product_type_id !== 8){
        if(!window.confirm('I confirm that product doesn’t fall under the hazmat category')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }

      if((productData.productPrices == null || productData.productPrices.length == 0)  && +productData.product_type_id !== 8){
        if(!window.confirm('I confirm that this product has no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }else{
        productData.productPrices.forEach((value, index) => {
          if((value.price_buy_net_actual_ct == null || value.price_buy_net_actual_ct == 0)  && +productData.product_type_id !== 8){
            priceCheckError = true
          }
        })
      }

      if(priceCheckError  && +productData.product_type_id !== 8) {
        if(!window.confirm('Some prices have no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }
    }


    const mrk = [];
    let sellerValidation = false;
    formData.map(x => {/* Add SKU to the warning? x.marketplace_number_id */
      if(+x.marketplace_id < 22 && (!productData.product_category_amazon || productData.product_category_amazon == 0)){
        mrk.push('Amazon category should be filled out')
      }else if(+x.marketplace_id == 22 && x.marketplace_id_type == 1 && (!productData.product_category_ebay || productData.product_category_ebay == 0)){
        mrk.push('Ebay category should be filled out')
      }else if(+x.marketplace_id == 23 &&  (!productData.product_category_kaufland || productData.product_category_kaufland == 0)){
        mrk.push('Kaufland category should be filled out')
      }else if(+x.marketplace_id == 24 &&  (!productData.product_category_otto || productData.product_category_otto == 0)){
        mrk.push('Otto category should be filled out')
      }
      if(x.seller_id == null && x.marketplace_id_type == 1){
        sellerValidation = true;
      }
    })

    if(mrk.length){
      setWarning(`${mrk.join('; ')}`)
    }

    if(sellerValidation){
      setValidationError('Seller should be filled out');
      return false;
    }

    const nfd = formData.filter(x => x.marketplace_id_type == 1 && x.status != 0)
    let fbmValidationError = false
    const fbmFulfillment = [2,8,9,10]; // CLD, S&S (Prime), BH Autostore
    nfd.map(x => {
      if(nfd.filter(w => w.marketplace_id == x.marketplace_id && fbmFulfillment.includes(x.fulfillment_vendor_id) && fbmFulfillment.includes(w.fulfillment_vendor_id)).length > 1){
        console.log(nfd.filter(w => w.marketplace_id == x.marketplace_id && x.fulfillment_vendor_id > 1 && w.fulfillment_vendor_id > 1))
        fbmValidationError = true
      }
    })

    if(fbmValidationError){
      setValidationError('It\'s not possible to assign FBM Fulfilment twice on the same marketplace')

      return false;
    }

    setChangesMade(false);
    let dataForm = []
    formData.map(x => {
      dataForm.push({
        product_child_id : x.product_child_id,
        fulfillment_vendor_id : x.fulfillment_vendor_id,
        fulfillment_vendor_number_id : x.fulfillment_vendor_number_id,
        inventory_id : x.inventory_id,
        marketplace_number_id : x.marketplace_number_id,
        marketplace_id : x.marketplace_id,
        marketplace_id_type : x.marketplace_id_type,
        product_parent_id : x.product_parent_id,
        seller_id : x.seller_id,
        status : x.status,
        marketplace_id_type : x.marketplace_id_type,
        fee_category_id: x.fee_category_id,
      })
    })

    try {
      const { data } = await updateProductMarketplacesMutation({
        variables: {
          input: dataForm,
          product_parent_id: productData.product_parent_id
        }
      });

      if (data.updateMarketplaceVendor[3]) {
        const valId = data.updateMarketplaceVendor[3].id;
        const valAsinId = valId.substr(valId.indexOf('--')).replace('--', '');
        setValidationError('The marketplace ID (ASIN: ' + valAsinId + ') you have entered is already assigned to another product: ' + data.updateMarketplaceVendor[0].id);
      }
      if(data.updateMarketplaceVendor[0].validation_error){
        setValidationError(data.updateMarketplaceVendor[0].validation_error)
        refetch();
        return false;
      }

      if (!data.updateMarketplaceVendor) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  const addRowHandler = () => {
    setChangesMade(true);

    setValidationError(null);
    const updatedFormData = [...formData];
    let marketplaceTypes = [{ id: 0, name: 'ASIN' }, { id: 1, name: 'SKU' }]
    updatedFormData.push({
      marketplace_id: selectData.marketplaces[0].id,
      marketplace_id_type: parentProduct ? marketplace_id_typeS[0].id : marketplace_id_typeS[1].id,
      marketplace_number_id: undefined,
      fulfillment_vendor_id: selectData.fulFillmentVendors[0].id,
      fulfillment_vendor_number_id: undefined,
      inventory_id: null,
      seller_id: parentProduct ? null : 1,
      status: MARKETPLACE_STATUS_OPTIONS[1].id,
      product_child_id: `${productData.slug}-C${formData.length + 1}`,
      marketplaceTypes: marketplaceTypes,
      error: null,
      fee_category_id: null,
    });
    setFormData(updatedFormData);
  };

  const checkboxClickHandler = (item, event) => {
    setChangesMade(true);

    let updatedSelectedItems = [...selectedItems];

    if (event.target.checked) {
      updatedSelectedItems.push(item.product_child_id);
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(i => i !== item.product_child_id);
    }

    updatedSelectedItems.sort((a, b) => b - a);
    setSelectedItems(updatedSelectedItems);
  };

  const changeFieldHandler = (rowNumber, event) => {
    setChangesMade(true);

    setValidationError(null);
    const updatedFormData = [...formData];

    if ((updatedFormData[rowNumber].fulfillment_vendor_id == 1 || updatedFormData[rowNumber].fulfillment_vendor_id == 15) && updatedFormData[rowNumber].seller_id == 1) {
      updatedFormData[rowNumber].inventory_id = event.target.value;
    }

    updatedFormData[rowNumber] = {
      ...updatedFormData[rowNumber],
      [event.target.id]: event.target.value
    };
    setFormData(updatedFormData);

    updateFormData({marketplaces: updatedFormData})

    setErrorsList([])
  };


  const changeSelectHandler = rowNumber => (option, data) => {
    setChangesMade(true);

    setValidationError(null);
    const updatedFormData = [...formData];
    updatedFormData[rowNumber] = {
      ...updatedFormData[rowNumber],
      [data.name]: option ? option.value : undefined
    };
    if (data.name === 'marketplace_id_type' && option.value === 0) {
      updatedFormData[rowNumber].fulfillment_vendor_id = null;
    }

    if ((+updatedFormData[rowNumber].fulfillment_vendor_id == 1 || updatedFormData[rowNumber].fulfillment_vendor_id == 15) && updatedFormData[rowNumber].seller_id == 1) {
      updatedFormData[rowNumber].inventory_id = updatedFormData[rowNumber].marketplace_number_id;
    }

    if(+updatedFormData[rowNumber].fulfillment_vendor_id > 1 && updatedFormData[rowNumber].fulfillment_vendor_id != 15 && updatedFormData[rowNumber].seller_id == 1){
      updatedFormData[rowNumber].inventory_id = productData.slug
    }
    
    if(data.name === 'seller_id' && +option.value != 1 ){
      updatedFormData[rowNumber].inventory_id = null
    }

    if(data.name === 'marketplace_id'){
      let marketplaceTypes = [{ id: 0, name: 'ASIN' }, { id: 1, name: 'SKU' }]
      if(+option.value == 22) marketplaceTypes = [{ id: 2, name: 'MLID' }, { id: 1, name: 'SKU' }]
      if(+option.value == 24 || +option.value == 23){
        marketplaceTypes = [{ id: 1, name: 'SKU' }]
        updatedFormData[rowNumber].marketplace_id_type = 1
      } 

      if(+option.value == 24 || +option.value == 22){ //+option.value == 23
        updatedFormData[rowNumber] = {
          ...updatedFormData[rowNumber],
          ['marketplace_number_id']: productData.slug
        };
      }

      if(+formData[rowNumber].marketplace_id <= 18 && +option.value > 18){
        updatedFormData[rowNumber].fee_category_id = null;
      }else if(+formData[rowNumber].marketplace_id > 18 && +option.value <= 18){
        updatedFormData[rowNumber].fee_category_id = null;
      }

      updatedFormData[rowNumber].marketplaceTypes = marketplaceTypes
    }

    setFormData(updatedFormData);

    updateFormData({marketplaces: updatedFormData})
    setErrorsList([])

  };

  if (error || updateError) {
    debugger
    throw new Error(error || updateError);
  }

  const deleteMarkeplace = async event => {
    event.preventDefault()
    setLoad(true)

    const { data } = await deleteMarketplaceMutation({
      variables: { 
        input: {cid: selectedItems}, 
        pid: productData.product_parent_id 
      }
    });
    
    if(data.deleteProductMarketplace[0].validation_error){
      setValidationError(data.deleteProductMarketplace[0].validation_error)
      setLoad(false)
      refetch();
      return false;
    }

    if (data) {
      setLoad(false)
      setFormData([])

      const cids = []
      data.deleteProductMarketplace.map(x => cids.push(x.product_child_id))
      const updatedFormData = [];

      cids.forEach(el => {
        formData.map(x => el === x.product_child_id ? updatedFormData.push(x) : null)
      });
      
      setSelectedItems([])

      setFormData([...updatedFormData]);

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    }
  }

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  if(productData.productLogistic && productData.productLogistic.article_packaging_type_id == 2){
    selectData.fulFillmentVendors = selectData.fulFillmentVendors.filter(x => x.id != 1)
  }

  const drawCategoryDropdown = data => {
    if(data.marketplace_id <= 18){
      return convertDataToOptions(selectData.amazonCategory)
    }else if(data.marketplace_id == 22){
      return convertDataToOptions(selectData.ebayCategory)
    }else if(data.marketplace_id == 23){
      return convertDataToOptions(selectData.kauflandCategory)
    }else if(data.marketplace_id == 24){
      return convertDataToOptions(selectData.ottoCategory)
    }

    return []
  }


  return ( // Get and show product marketplace errors
      <>
            { marketplaceErrors && marketplaceErrors.length !== 0 && (
          <div className="alert-danger alert-danger-custom p-3 mb-1" style={{borderRadius: '4px', padding: '4px 8px', minHeight: '130px'}}>
                <i className='fas fa-info-circle mr-1 red-info-icon'></i>
                <span className="error-message"> There are errors that require your attention:</span>
                { openMarketplaceIssuesModal && <MarketplaceIssuesModal 
                                                  sku={currentModalData.sku} 
                                                  marketplaceId={currentModalData.marketplace_id} 
                                                  amazonStatus={currentModalData.amazonStatus} 
                                                  marketplaceErrors={currentModalData.errors} 
                                                  setModalOpen={openMarketplaceIssuesModal}
                                                  handleModalClose={handleModalClose}
                                                />
                 }           
                <ul className="my-2" id="error-container">
                  { marketplaceErrors.map(obj => (
                    <>
                      {
                        obj.errors.map((mError, i) => (
                          !mError.includes('out of stock') &&
                        <>
                          <li className="error-message">{mError}
                            <span>   
                              <span className='mx-1'>→</span>
                              <a style={{cursor:'pointer',color:'#000',textDecoration:'underline'}} onMouseEnter={(e) => e.target.style.color = '#ed585f'} onMouseLeave={(e) => e.target.style.color = '#000'} onClick={() => handleShow(obj.sku, obj.marketplace_id,'Amazon errors and issues', obj.errors) }>  View error details</a>
                            </span>
                          </li>
                        </>
                        ))
                      }
                    </>
                  ))}
                </ul>        
          </div>
        )}
    <form > {/* .length !== 0 */}

        {/* Normal error & warning */}
        { validationErrror && (
          <Error setError={() => setValidationError('')} error={validationErrror}  close={true} />
        )}
        { warning && (
          <ModalError error={warning} className='warning' setError={setWarning} />
        )}
  
        
      <div className='overflow-auto'>
        <table className="table DIN2014 mt-0">
          <thead>
            <tr>
              <th style={parentProduct ? {width: "40px"} : null}></th>
              <th style={parentProduct ? {width: "200px"} : null}>
                Marketplace <span style={{ color: 'red' }}>*</span>
              </th>
              {!parentProduct && (
                <th>
                  Marketplace ID type <span style={{ color: 'red' }}>*</span>
                </th>
              )}
              <th style={parentProduct ? {width: "200px", display: 'flex'} : {display: 'flex'}}>
                Marketplace ID <span style={{ color: 'red' }}>*</span>
                { formData.filter(x => x.seller_id == 4).length > 0 && (
                  <div className="u_infoButton upsd_infoButton">
                      <img src="/assets/images/info-button.png" alt="" />
                      
                      <div className="infoBox">
                          <span>Agreed SKU formats with the client</span>
                          <span>PZN</span>
                          <span>PZN(fba)</span>
                          <span>PZN(SL)</span>
                          <span>PZN(-DE)</span>
                          <span>PZN(-)</span>
                      </div>
                  </div>  
                ) }

              </th>
              {!parentProduct && (
                <>
                  <th >
                    <div style={{minWidth: "200px", display: 'flex'}}>
                    Fulfillment vendor <span style={{ color: 'red' }}>*</span>
                    <div className="u_infoButton upsd_infoButton">
                      <img src="/assets/images/info-button.png" alt="" />
                      
                      <div className="infoBox" style={{ top: '34px', height: '40px' }}>
                          <span>Bundles cant be added to FBA</span>
                      </div>
                    </div>  
                    </div>
                  </th>
                  <th>
                    Fee category
                  </th>
                  <th>
                    Inventory id 
                  </th>
                  <th>
                    Seller <span style={{ color: 'red' }}>*</span>
                  </th>
                </>
              )}
              <th style={parentProduct ? {width: "200px"} : null}>
                Status <span style={{ color: 'red' }}>*</span>
              </th>
              {parentProduct && <th>Seller</th>}
              {<th>
                Amazon status
              </th>}
            </tr>
          </thead>
          <tbody>
            {formData.map((row, index) => (
              <tr key={index}>
                <td className="text-center align-middle">
                  <input
                    type="checkbox"
                    checked={selectedItems.some(i => i === row.product_child_id)}
                    onChange={checkboxClickHandler.bind(null, row)}
                  />
                </td>
                <td style={{ minWidth: '200px' }}>
                  <Select
                    name="marketplace_id"
                    menuPortalTarget={document.body}
                    options={convertDataToOptions(marketplaceOptions)}
                    defaultValue={createDefaultOption(
                      marketplaceOptions.find(i => i.id === row.marketplace_id)
                    )}
                    onChange={changeSelectHandler(index)}
                    isDisabled={
                      (marketplacesInformation[index] &&
                        marketplacesInformation[index].status !== 1 && marketplacesInformation[index].status !== 3) ||
                      !update || row.is_read_only ||
                      isArchived || (row.hasSale && row.hasSale.has_sales  == 1)
                    }
                  />
                </td>
                {!parentProduct && (
                  <td>
                    <Select
                      name="marketplace_id_type"
                      options={convertDataToOptions(row.marketplaceTypes)}
                      value={createDefaultOption(
                        row.marketplaceTypes.find(i => i.id === +row.marketplace_id_type)
                      )}
                      menuPortalTarget={document.body}
                      onChange={changeSelectHandler(index)}
                      instanceId={index}
                      
                      isDisabled={
                        (marketplacesInformation[index] &&
                          marketplacesInformation[index].status !== 1 && marketplacesInformation[index].status !== 3) ||
                        !update ||row.is_read_only ||
                        isArchived || (row.hasSale && row.hasSale.has_sales  == 1)
                      }
                    />
                  </td>
                )}
                <td className='marketplaceLinkWrapper rel_vis' style={{ minWidth: '200px' }}>
                  <input
                    title="SKU has sales and can't be modified"
                    type="text"
                    id="marketplace_number_id"
                    className={`form-control ${errorsList && errorsList[index] && errorsList[index]['marketplace_number_id'] ? 'invalid' : ''}`}
                    value={row.marketplace_number_id}
                    onChange={event => changeFieldHandler(index, event)}
                    required={true}
                    disabled={
                      (marketplacesInformation[index] &&
                        marketplacesInformation[index].status !== 1 && marketplacesInformation[index].status !== 3) ||
                      !update || row.is_read_only ||
                      isArchived || (row.hasSale && row.hasSale.has_sales  == 1) || (row.marketplace_id == 22 && +row.marketplace_id_type == 1) || row.marketplace_id == 24
                    }
                  />
                  {row.marketplace_id_type === 0 && marketplaceOptions.find(i => i.id === row.marketplace_id)?.name !== 'bol.com' && (
                    <div className="_marketplaceLink">
                      <a href={ `https://${marketplaceOptions.find(i => i.id === row.marketplace_id)?.name}/dp/${row.marketplace_number_id}` }
                        target="_blank"
                      >
                        <img src="/assets/images/ext-link.png" alt=""/>
                      </a>
                    </div>
                  )}
                  { showError(index, 'marketplace_number_id') }
                </td>
                {!parentProduct && (
                  <>
                    <td className='rel_vis'>
                      <RequiredSelect
                        name="fulfillment_vendor_id"
                        menuPortalTarget={document.body}
                        className={errorsList && errorsList[index] && errorsList[index]['fulfillment_vendor_id'] ? 'invalid' : ''}
                        options={convertDataToOptions(
                          row.marketplace_id_type === 0
                            ? [selectData.fulFillmentVendors[0]]
                            :  (row.fulfillment_vendor_id == 8 || row.fulfillment_vendor_id == 10) && row.hasSale && row.hasSale.has_sales  == 1 ? selectData.fulFillmentVendors.filter(x => [8, 10].includes(x.id)) : (row.marketplace_id < 20 ? selectData.fulFillmentVendors : selectData.fulFillmentVendors.filter(x => [8, 9, 4, 12, 14, 16].includes(x.id)))
                        )}
                        value={(row.marketplace_id_type == 0 || (row.marketplace_id_type == 2 && row.marketplace_id == 22)) ? null : createDefaultOption(
                          row.marketplace_id < 20 ? selectData.fulFillmentVendors.find(
                                  i => i.id === row.fulfillment_vendor_id
                            ) : selectData.fulFillmentVendors.filter(x => [8, 9, 4, 12, 14, 16].includes(x.id)).find(
                              i => i.id === row.fulfillment_vendor_id
                            )
                        )}
                        onChange={changeSelectHandler(index)}
                        isDisabled={
                          (row.fulfillment_vendor_id == 8 || row.fulfillment_vendor_id == 10) ? false : 
                          row.marketplace_id_type === 0 || row.marketplace_id_type === 2 || 
                          (marketplacesInformation[index] &&
                            marketplacesInformation[index].status !== 1 && marketplacesInformation[index].status !== 3) ||
                          !update || (row.hasSale && row.hasSale.has_sales  == 1) || row.is_read_only ||
                          isArchived 
                        }
                        required
                      />
                      { showError(index, 'fulfillment_vendor_id') }
                    </td>
                    <td className='rel_vis' style={{ minWidth: '250px' }}>
                      { showError(index, 'fee_category_id') }
                      <Select
                        name="fee_category_id"
                        className={errorsList && errorsList[index] && errorsList[index]['fee_category_id'] ? 'invalid' : ''}
                        options={drawCategoryDropdown(row)}
                        value={row.fee_category_id ? drawCategoryDropdown(row).find(x => +x.value == +row.fee_category_id) : null}
                        onChange={changeSelectHandler(index)}
                        isDisabled={!update || row.is_read_only || isArchived || row.marketplace_id_type !== 1 || (row.hasSale && row.hasSale.has_sales  == 1) || row.marketplace_id == 25}
                        isClearable={true}
                        menuPortalTarget={document.body}
                        />
                    </td>
                    <td style={{ minWidth: '200px' }}>
                      <input
                        type="text"
                        id="inventory_id"
                        className="form-control"
                        value={
                          (row.marketplace_id_type === 0 || row.marketplace_id_type === 2) ? '' : row.inventory_id === null ? '' : row.inventory_id
                        }
                        onChange={event => changeFieldHandler(index, event)}
                        disabled={true}
                      />
                    </td>

                    <td className='rel_vis' style={{ minWidth: '150px' }}>
                      <Select
                        name="seller_id"
                        className={errorsList && errorsList[index] && errorsList[index]['seller_id'] ? 'invalid' : ''}
                        options={convertDataToOptions(selectData.sellers)}
                        value={ (row.marketplace_id_type == 0 || (row.marketplace_id_type == 2 && row.marketplace_id == 22)) ? null : createDefaultOption(
                          selectData.sellers.find(i => i.id === row.seller_id)
                        )}
                        onChange={changeSelectHandler(index)}
                        menuPortalTarget={document.body}
                        isDisabled={
                          row.marketplace_id_type === 0 || row.marketplace_id_type === 2 || 
                          (marketplacesInformation[index] &&
                            marketplacesInformation[index].status !== 1 && marketplacesInformation[index].status !== 3) ||
                          !update || (row.hasSale && row.hasSale.has_sales  == 1) || row.is_read_only ||
                          isArchived
                        }
                        required
                      />
                      { showError(index, 'seller_id') }

                    </td>
                  </>
                )}
                <td style={{ minWidth: '150px' }}>
                  <Select
                    name="status"
                    options={convertDataToOptions(MARKETPLACE_STATUS_OPTIONS)}
                    value={
                      createDefaultOption(
                      MARKETPLACE_STATUS_OPTIONS.find(i => i.id === row.status)
                    )}
                    onChange={changeSelectHandler(index)}
                    menuPortalTarget={document.body}
                    isDisabled={!update || row.is_read_only || isArchived }
                    isOptionDisabled={(option) => option.label === 'Incomplete'}
                  />
                </td>
                {parentProduct && (
                  <td className="align-middle">Sira Trading</td>
                )}
                {<td className="tooltipHeading" style={{ marginTop: '11px'}}>
                  <a style={ row.error !== null ? {cursor: 'pointer'} : undefined } onClick={ row.error !== null ? () => handleShow(row.marketplace_number_id, row.marketplace_id, 'Amazon errors and issues', row.error ? row.error.split(';') : []) : undefined }>
                    { amazonListingData ? (
                      amazonListingStatus[row.marketplace_number_id + '-' + marketplaceOptions.find(i => i.id === row.marketplace_id)?.name] !== undefined ?
                        AmazonStatus(row, amazonListingStatus[row.marketplace_number_id + '-' + marketplaceOptions.find(i => i.id === row.marketplace_id)?.name]) : AmazonStatus(row)
                      ) : <div className="spinner" style={{ width: '20px', height: '20px', marginLeft: '35px' }} >Spinner</div> }
                  </a>
                </td>}
              </tr>
            ))}
          </tbody>
        </table>

        {update && !load && (
          <>
            {loading ? (
              <div className="spinner">Spinner</div>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-new mr-2"
                  onClick={addRowHandler}
                  disabled={isArchived}
                >
                  <i className="fas fa-plus-square mr-1" />
                  New
                </button>
                
              </>
            )}
          </>
        )}
        { marketplacePermission.delete && !loading && (
          <>
            {load ? (
              <div className="spinner">Spinner</div>
            ) : (
            <>
              <button
                type="button"
                className="btn btn-danger"
                onClick={deleteMarkeplace}
                disabled={!selectedItems.length} >
                Delete
              </button>
            </>
          )}
          </>
        )
        }
      </div>
    </form>
  </>

  );
};

export default memo(MarketplaceInformationForm);
