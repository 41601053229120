import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

/** Apollo */
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

import Routes from '../../lib/constants/routes';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';


import { STATUSES_ENUM, IMPORT_STATUSES } from '../../lib/constants/fields';

/** Components */
import { getCopyData as QUERY } from '../../services/api/queryService';
import ShippingCost from './shippingCost/shippingCost';
import Warehouse from './warehouseCost/WarehouseCost';
import MarketplaceCost from './marketplaceCost/MarketplaceCost';
import WarehouseCost from './warehouseCost/WarehouseCost';


const description = {
    module: 'copydata',
    description: 'CopyData',
    req: 'getCopyData',
    url: Routes.copyData.index,
};

const CostSettings = () => {
    const [ changedData, setChangedData ] = useState({});
    const [ loader, setLoader ] = useState(true);

    const history = useHistory();
    const location = useLocation();
    const [marketplaces, setMarketplaces] = useState(null)
    const [activeMarketplace, setActiveMarketplaces] = useState(null)
    const [selectedTab, setSelectedTab] = useState('shippingCost');

    return (
    <>

        <div className="row">
            <div className="col-12">
            <div className="page-title-box">
                <h4 className="page-title">Costs settings</h4>

                <div className="row">
                <div className="col-12">
                    <div className="page-title-box">

                    <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                            <Link to="/cost-settings">Settings</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="/cost-settings">Costs settings</Link>
                        </li>
                    </ol>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>

        <ul className="nav nav-tabs nav-bordered mb-3">
            <li className="nav-item" onClick={() => setSelectedTab('shippingCost')}>
                <a href="#shippingCost-tab" className="nav-link active" data-toggle="tab">
                    Fulfillmet/Shipping costs
                </a>
            </li>
            <li className="nav-item" onClick={() => setSelectedTab('warehouse')}>
                <a href="#warehouse-tab" className="nav-link" data-toggle="tab">
                    Warehouse costs
                </a>
            </li>
            <li className="nav-item" onClick={() => setSelectedTab('marketplace')}>
                <a href="#marketplace-tab" className="nav-link" data-toggle="tab">
                    Marketplace costs
                </a>
            </li>
        </ul>

        <div className="tab-content p-0">
            <div className="tab-pane show active" id="shippingCost-tab">
            {selectedTab === 'shippingCost' && (
                <ShippingCost
                    tabId="shippingCVost"
                />
            )}
            </div>
            <div className="tab-pane" id="warehouse-tab">
            {selectedTab === 'warehouse' && (
                <WarehouseCost
                tabId="warehouseCost"
                />
            )}
            </div>
            <div className="tab-pane" id="marketplace-tab">
            {selectedTab === 'marketplace' && (
                <MarketplaceCost
                tabId="markpetplaceCost"
                />
            )}
            </div>
        </div>

        
    </>
);

}

export default CostSettings;