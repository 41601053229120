import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import '../../components/table/Table.css';


import ModalError from '../../components/modal/ModalError';
import { recoverUserLogin } from '../../services/api/mutationService';

const AuthModal = ({setModalOpen}) => {
  const [formData, setFormData] = useState({
    email: ''
  });
  const [error, setError] = useState(false);
  const [changesMade, setChangesMade] = useState(false);

  const [recoverUserLoginMutation, {loading}] = useMutation(
    recoverUserLogin
  );

  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);




  const dispatch = useDispatch();

  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };


  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();

    try {
      const { data } = await recoverUserLoginMutation({
        variables: {
          email: formData.email,
        }
      });

      if (data.recoverUserLogin) {
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'User was successfully recovered'
          }
        });
        setModalOpen(false);
      }else{
        dispatch({
            type: 'UPDATE',
            payload: {
              type: 'error',
              message: 'No user found with this email'
            }
        });
      }

    } catch (err) {
      setError(err);
    }
  };


  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        tabIndex="-1"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={submitHandler}>
                <div className="modal-header">
                    <h4 className="modal-title" id="modalLabel">
                    Restore your login
                    </h4>
                    <button type="button" className="close" onClick={() => setModalOpen(false)}>
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body modal-form">
                    <div className="form-group col-6">
                        <label htmlFor="">E-mail</label>

                        <input className='form-control' type="text" style={{width: '100%'}} id='email' onChange={changeFieldHandler}  />
                    </div>
                </div>
              <div className="modal-footer justify-content-start">
                {loading ? (
                  <div className="spinner">Spinner</div>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                  >
                    Recover
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthModal;
