import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import { getProduct} from '../../services/api/queryService';
import { useQuery } from '@apollo/react-hooks';

/** Components */


import '../product/Product.scss';
import DetailsHeader from '../../components/details-header/DetailsHeader';
import UnitEconomicsTable from './UnitEconomicsTable';
import routes from '../../lib/constants/routes';

export const fieldsConvertToCents = {
    price_sell_gross_base_unit_ct: true,
    price_sell_gross_rrp_ct: true,
    price_sell_net_rrp_ct: true,
    price_sell_gross_min_ct: true,
    price_sell_net_min_ct: true,
    price_sell_gross_max_ct: true,
    price_sell_net_max_ct: true,
    price_buy_net_actual_ct: true
};

const UnitEconomics = ({ create, update, canDelete, getProductTitle }) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const slug = location.pathname.split('/')[2]

  const { data: productData, refetch } = useQuery(getProduct, {
    variables: { slug: slug }
  });

  const [parentProduct, setParentProduct] = useState(false); 


  let productPrices;

  if(productData){
    productPrices = productData.product.productPrices.map(item => {
        const updatedItem = { ...item };
        Object.keys(updatedItem).forEach(key => {
          if (fieldsConvertToCents[key]) {
            updatedItem[key] = +(updatedItem[key] / 100).toFixed(2);
          }
        });
        delete updatedItem.__typename;
        return updatedItem;
      });
  }

  useEffect(() => {
    if(productData && productData.product){

      setParentProduct(+productData.product.product_type_id === 8)

      let pckgName = ''
      if(+productData.product.product_type_id !== 8){
        pckgName = `| ${productData.product.packagingUnit?.name}`
      }

      getProductTitle(`${productData.product.product_name} ${pckgName}`)
    }
  }, [productData])

  const tabClickHandlerListing = () => {
    history.push(`/listings/${slug}/edit`);
  };

  const tabClickHandlerDocs = () => {
    history.push(`/listings/${slug}/edit?tab=docs`);
  };

  const tabClickHandler = () => {
    history.push(`/products/${slug}/edit`);
  };

  const tabClickHandlerLogs = () => {
    history.push(`/product-logs/${slug}`);
  };

  return (
      <>
      {!productData ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <>
            <div className="row">
                <div className="col-12">
                <div className="page-title-box">
                    <h4 className="page-title">
                      {
                        !parentProduct ? (
                        <>
                          {productData.product.product_name} | {productData.product.packagingUnit?.name}
                        </>
                      ) : (
                        productData.product.product_name
                      )}
                    </h4>

                    <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                        
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item">
                                <Link to="/">PIM</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to={`${routes.products.index}?tab=search`}>Products</Link>
                            </li>
                            <li className="breadcrumb-item active">{productData.product.product_name}</li>
                        </ol>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* end page title */}


            <DetailsHeader renderFor="products" data={productData.product} />

            <div className="product-tabs product-tabs-custom col-10">
                    <div
                    className={`tab`}
                    onClick={tabClickHandler}
                    >
                    Information
                    </div>
                
                    <div
                    className={`tab `}
                    onClick={tabClickHandlerListing}
                    >
                    Content
                    </div>
                    <div
                    className={`tab active`}
                    onClick={null}
                    >
                    Unit economics
                    </div>
                    
                    <div
                      className={`tab `}
                      onClick={tabClickHandlerLogs}
                      >
                      Update log
                    </div>

                    <div className="tab-bar" />
            </div>

            <UnitEconomicsTable 
                data={productData.product}  />
        </>

      )}
    </>
  );
};

export default UnitEconomics;
