import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';


import ModalError from '../../components/modal/ModalError';
import { createLinkedItem, updateLinkedItem } from '../../services/api/mutationService';

const LinkedItemModal = ({setModalOpen, itemId, product_parent_id, selectedItems}) => {
  const [formData, setFormData] = useState({
    product_parent_id: ''
  });
  const [error, setError] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);



  const dispatch = useDispatch();
  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const [createLinkedItemMutation, { loading, error: updateError }] = useMutation(
    createLinkedItem
  );

  const [updateLinkedItemMutation, { }] = useMutation(
    updateLinkedItem
  );

  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();
    let dataInputs = {
      product_parent_id : formData.product_parent_id, 
      variation_product_parent_id: selectedItems.join(','),
      multiple: true
    }

    try {
      const { data } = await createLinkedItemMutation({
        variables: {
          input: dataInputs,
          product_parent_id: product_parent_id,
        }
      });

      if (data.createLinkedItem && data.createLinkedItem.error) {
        setError(data.createLinkedItem.error)
      } else {
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully added'
          }
        });
        setModalOpen(false);
      }
    } catch (err) {
      setError(err);
    }

  };

  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          Add items to parent
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <div className="form-group">
          <ModalError error={error} setError={setError} />
          <label htmlFor="name">
            PID <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            id="product_parent_id"
            className="form-control"
            required
            name="product_parent_id"
            defaultValue={formData.product_parent_id}
            onChange={changeFieldHandler}
          />
        </div>
      </div>
      <div className="modal-footer justify-content-start">
        <button type="submit" className="btn btn-primary">
            Save
        </button>
      </div>
    </form>
  );
};

export default LinkedItemModal;
