import React from 'react';

import './Footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="d-flex">
              <p className="m-0 ml-2">{(new Date().getFullYear())} &copy; Ebazaaris.io</p>             
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
