import React, { useState } from 'react';

import { PRODUCT_LISTING_FORMS } from '../../lib/constants/fields';

import './ProductFormCard.scss';

const ListingFormCard = ({ id, listing, settings, formPreOpen = false, hasTable = false, disabled = false, activeMarketplace, children }) => {
  const [formOpen, setFormOpen] = useState(formPreOpen);

  let listingClassName = ''
  let listingCharactersClassName = ''
  let listingWordCount = 0
  let listingCharacters = 0

  if(id === 'productTitle'){
    listingWordCount = listing.title? listing.title.split(' ').filter(x => x.length).length : 0
    listingClassName = settings.title_words_max < listingWordCount || listingWordCount < settings.title_words_min ? 'text-danger' : 'text-green'
    listingCharacters = listing.title? listing.title.length : 0
    listingCharactersClassName = listingCharacters > 0 && (listingCharacters < settings.title_chars_length??150) ? 'text-green' : 'text-danger'
    
    // Ebay
    // if(listing.marketplace_id == 22 || listing.marketplace_id == 23){
    //   listingCharactersClassName = listingCharacters > 0 && (listingCharacters < settings.title_chars_length??0) ? 'text-green' : 'text-danger'
    // }
    
  }

  if(id === 'productSubtitle'){
    listingWordCount = listing.subtitle? listing.subtitle.split(' ').filter(x => x.length).length : 0
    listingClassName = settings.title_words_max < listingWordCount || listingWordCount < settings.title_words_min ? 'text-danger' : 'text-green'
    listingCharacters = listing.subtitle? listing.subtitle.length : 0
    
    listingCharactersClassName = listingCharacters < 55 ? 'text-green' : 'text-danger'
  }

  if(id === 'productCharacteristics'){
    listingCharacters =  0


    if(listing.characteristics){
      const d = JSON.parse(listing.characteristics)
      console.log(d, Object.keys(d).length)
      let i = 0
      Object.keys(d).map(x => {
        if(d[x])
          i++
      })

      listingCharacters = i
    }
    
    listingCharactersClassName = listingCharacters < 6 ? 'text-danger' : 'text-green'
  }

  if(id === 'productBullets'){
    let i = 0;
    if(listing.bullets){
      listing.bullets.split(';').forEach(el => {
        if(el.length){
          i++
          if(el.split(' ').filter(x => x.length).length > 1){
            i += el.split(' ').filter(x => x.length).length-1
          }
        }
      });
    }
    listingWordCount = i
    listingClassName = settings.word_per_bullet_max < listingWordCount || listingWordCount < settings.word_per_bullet_min ? 'text-danger' : 'text-green'
    listingCharacters = listing.bullets ? listing.bullets.replace(/;/g, '').length : 0
    listingCharactersClassName = listingCharacters > 0 && (listingCharacters < settings.max_bullet_characters??100) ? 'text-green' : 'text-danger'
  }

  if(id === 'productKeywords'){
    listingWordCount = listing.backend_keywords ? listing.backend_keywords.split(',').length : 0
    listingCharacters = listing.backend_keywords? listing.backend_keywords.length : 0
    const umlautsCount = listing.backend_keywords? listing.backend_keywords.split('').filter(w => w == 'ä' || w == 'ö' || w == 'ü').length : 0
    listingCharacters += umlautsCount
    listingClassName = settings.backend_keyword_count_max < listingCharacters || listingCharacters < settings.backend_keyword_count_min ? 'text-danger' : 'text-green'
    listingCharactersClassName = settings.backend_keyword_count_max < listingCharacters || listingCharacters < settings.backend_keyword_count_min ? 'text-danger' : 'text-green'
  }

  if(id === 'productDescription'){
    let temp = listing.description ? listing.description.replaceAll(/<[^>]*>/g," ") : "";
    temp = temp.replace(/\s+/g, ' ');
    temp = temp.trim();
    listingWordCount = temp.split(" ").filter(x => x.length).length
    listingCharacters = listing.description ? listing.description.replaceAll(/<\/?[^>]+(>|$)/g, "").length : 0
    listingCharactersClassName = listingCharacters <= settings.characters_max && settings.characters_min <= listingCharacters ? 'text-green' : 'text-danger'
    listingClassName = temp.split(' ').filter(x => x.length).length >= 1 ? 'text-green' : 'text-danger'
  }


  return (
    <div className="product-form container col-12 px-0" style={{ background: disabled ? '#ddd' : '#fff', opacity: disabled ? '0.75' : '1'}}>
      <div
        className="product-form__header d-flex justify-content-between align-items-center"
        onClick={() => disabled ? setFormOpen(false) : setFormOpen(!formOpen)}
      >
        <h4 className={`m-0 ${ id == 'productBullets' ? 'd-flex' : '' }`}>
          {id == 'productImages' && activeMarketplace ? activeMarketplace.name : null }
          {id != 'productImages' && PRODUCT_LISTING_FORMS[id].title}
          {id == 'productBullets' && (
            <div className="u_infoButton">
                <img src="/assets/images/info-button.png" alt="" />
                
                <div className="infoBox">
                    <span>if text contaitns ';' it will be used as delimiter</span>
                </div>
            </div> 
          )}

        </h4>
          <div className="d-flex">
            { drawSectionTitle(id, listingClassName, listingWordCount, listingCharactersClassName, listingCharacters, listing) }
              <i className="mdi mdi-chevron-down" />
          </div>

      </div>
      <div style={{ display: disabled ? 'none' : (formOpen ? 'block' : 'none') }}>
        {!hasTable && <hr />}
        <div className={`product-form__body ${hasTable ? 'pt-0' : ''}`}>{children}</div>
      </div>
    </div>
  );


  
};

const drawSectionTitle = (id, listingClassName, listingWordCount, listingCharactersClassName, listingCharacters, listing) => {
  return( 
    <>
      {listing.marketplace_id != 24 && id !== 'listingCateogry' && id !== 'productImages' && id !== 'productDocuments' && id != 'productCharacteristics' && id !== 'productDescription' && id !== 'productRichContent' && id !== 'productBullets' &&(
      <>
        <div className="mr-2">
          <p className="mb-0 font-weight-bold">
            { listing.marketplace_id != 22 && listing.marketplace_id != 23  && (
              <>
                Words: <span className={listingClassName}>{listingWordCount}</span> | 
              </>
            )}
            Characters: <span className={listingCharactersClassName}> {listingCharacters}</span> | 
            Bytes: <span className={listingCharactersClassName}> {listingCharacters} kb</span>
          </p>
        </div>
      </>
      )}
      {id == 'productDescription' &&(
        <>
          <div className="mr-2">
            <p className="mb-0 font-weight-bold">
              Characters: <span className={listingCharactersClassName}> {listingCharacters}</span> | 
              Bytes: <span className={listingCharactersClassName}> {listingCharacters} kb</span>
            </p>
          </div>
        </>
      )}
      {id == 'productCharacteristics' &&(
        <>
          <div className="mr-2">
            <p className="mb-0 font-weight-bold">
              Number of characteristics: <span className={listingCharactersClassName}> {listingCharacters}</span>
            </p>
          </div>
        </>
      )}
      {id == 'productImages' &&(
        <>
          <div className="mr-2">
            <p className="mb-0 font-weight-bold">
              Images: <span className='text-green'> {listing.images.length}</span>
            </p>
          </div>
        </>
      )}
      {id == 'productDocuments' &&(
        <>
          <div className="mr-2">
            <p className="mb-0 font-weight-bold">
              Documents: <span className='text-green'> {listing.files.length}</span>
            </p>
          </div>
        </>
      )}
    </> 
  )
}




export default ListingFormCard;
