import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ModalError from '../../components/modal/ModalError';
import { STATUS_OPTIONS } from '../../lib/constants/fields';

import {
  convertDataToOptions,
  createDefaultOption
} from '../../services/util/dataHandlerService';
import { createPowerBISection, updatePowerBISection, deleteSection } from '../../services/api/mutationService';

const AddSectionFields = ({ setModalOpen, itemId, sections }) => {
  const [formData, setFormData] = useState({
    section: itemId && itemId.section ? itemId.section : '',
    subsection: itemId && itemId.subsection ? itemId.subsection : '',
    status: itemId && itemId.status ? itemId.status : 1
  });

  const [error, setError] = useState(null);
  const [hardDelete, setHardDelete] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [checked, setChecked] = useState(false)
  const [subsections, setSubsections] = useState([])
  const [newSubsection, setNewSubsection] = useState(false)
  const [newSubsectionText, setNewSubsectionText] = useState('')
  
  const [createRoleMutation, { }] = useMutation(
    createPowerBISection
  );

  const [updateRoleMutation, {}] = useMutation(
    updatePowerBISection
  );

  const [deleteMutation, {}] = useMutation(
    deleteSection
  );

  useEffect(() => {
    if (itemId) {
      setFormData(itemId)
    }
    if(sections){
      const tempSubsection = [];
      sections.forEach(el => {
        tempSubsection.push({
          value: el.subsection,
          label: el.subsection
        })
      });

      const unique = [...new Set(tempSubsection.map(x => x.value))]
      const subs = unique.map((x) => {
        return { label: x, value: x }
      })
      setSubsections(subs)
    }
  }, [itemId, sections])

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {
    if(data.name === 'subsection'){

    }
    setFormData({
      ...formData,
      [data.name]: option && option.value
    });
  };


  const submitHandler = async event => {
    event.preventDefault();

    try {
      if (itemId) {
        delete formData.__typename
        delete formData.id
        delete formData.roleId
        delete formData.showExplanation
        delete formData.explanation_url
        const { data } = await updateRoleMutation({
          variables: {
            input: formData,
            id: itemId.id,
            updateSection: newSubsectionText
          },
          id: itemId.id
        });

        if (!data.updateRole) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data.updateRole.error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createRoleMutation({
          variables: {
            input: formData
          }
        });
        if (!data.updateRole) {
          throw new Error('Server responded with no data');
        }
      }      
      // refetch();
    } catch (err) {
      // console.log(err)
      // setError('Internal server error');
    }

    dispatch({
      type: 'UPDATE',
      payload: {
        type: 'success',
        message: `Recored was successfully ${itemId ? 'updated' : 'created'}`
      }
    });
    setModalOpen(false);
  };
  
  const showNewSubsectionInput = e => {
    e.preventDefault()
    formData.subsection = null
    setNewSubsectionText('')
    setNewSubsection(!newSubsection)
  }

  const showEditSubsectionInput = e =>{
    e.preventDefault()
    setNewSubsectionText(formData.subsection)
    setNewSubsection(!newSubsection)
  }
  
  const checkboxChangeHandler = event => {
    const { checked } = event.target;
    setChecked(checked)
  };

  const deleteSec = async (id) => {
    setLoader(true)

    const { data } = await deleteMutation({
      variables: {
        id: Number(id),
        hardDelete: checked
      },
    });
    if (data) {
      setLoader(false)
      if(data.deleteSection.error){
        setError(data.deleteSection.error)
      }else{
        setModalOpen(false);
      }
    }
  }


  const btnText = [
    {
      value: false,
      text: "Add New"
    },{
      value: true,
      text: "Show List"
    }
  ]
  
  const categories = [
    {
      value: 'Retail',
      label: 'Retail'
    },
    {
      value: 'Media',
      label: 'Media'
    },
    {
      value: 'Inventory',
      label: 'Inventory'
    },
    {
      value: 'Pricing',
      label: 'Pricing'
    },
    {
      value: 'Operations',
      label: 'Operations'
    },
    {
      value: 'Controlling',
      label: 'Controlling'
    },
    {
      value: 'Digital Shelf',
      label: 'Digital Shelf'
    },
    {
      value: 'Billing',
      label: 'Billing'
    },
    {
      value: 'Accounting',
      label: 'Accounting'
    },
    {
      value: 'PnL',
      label: 'PnL'
    },
  ]

  return (
    <form onSubmit={submitHandler} className="roles-form">
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {itemId ? 'Edit' : 'Create'} subsection
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError className="modal-thin" error={error} setError={setError} />
        
        <div className="form-group">
          <label htmlFor="section">
            Section <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            id="section"
            name="section"
            required
            defaultValue={categories.find(option => option.label == formData.section)}
            options={categories}
            onChange={changeSelectHandler}
          />
        </div>
        <div className="form-group">
          <label htmlFor="subsection">
            Subsection name
          </label>
          <div className="d-flex">
          { !newSubsection && subsections.length && (
          <Select
            className="w-100"
            id="subsection"
            name="subsection"
            required
            defaultValue={subsections.find(option => option.label == formData.subsection)}
            options={subsections}
            onChange={changeSelectHandler}
          />          
          )}
          { newSubsection && (
            <input
              type="text"
              id="subsection"
              name="subsection"
              className="form-control"
              required
              defaultValue={newSubsectionText}
              onChange={changeFieldHandler}
            />
            ) }
          { !newSubsection && formData.subsection ? (
            <button type="button" className="btn btn-primary list-toggle-btn" onClick={showEditSubsectionInput}>Edit</button>
          ) : (
            <button className="btn btn-primary list-toggle-btn" onClick={showNewSubsectionInput}>{ btnText.find(x => x.value == newSubsection).text }</button>
          )}
          
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="tab">
            Tab name
          </label>
          <input
            type="text"
            id="tab"
            name="tab"
            className="form-control"
            required
            defaultValue={formData.tab}
            onChange={changeFieldHandler}
          />
        </div>  


          <label htmlFor="status">
            Status <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="status"
            // id="status"
            options={convertDataToOptions(STATUS_OPTIONS)}
            defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id == formData.status))}
            onChange={changeSelectHandler}
          />
        </div>
      
      <div className="modal-footer justify-content-start">
        {/* {createLoading || updateLoading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        )} */}
        { loader? (
          <div className="spinner absolute">Spinner</div>
        ) : '' }

        <div className="d-flex flex-column">
          { error ? (
            <div>
              <input id="deletion" type="checkbox" className="mr-1" onChange={checkboxChangeHandler} />
              <label htmlFor="deletion">Confirm deletion</label>
            </div>
          ) : "" }

          <div>
          <button type="submit" className="btn btn-primary mr-1">
            Save
          </button>
          {itemId && itemId.id && (
            <button onClick={(e) => {e.preventDefault(); deleteSec(itemId.id)}} className="btn btn-danger">
              Delete
            </button>
          )}
          </div>
        </div>
      </div>
    </form>
  )
};

export default AddSectionFields;
