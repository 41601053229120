import React, {useEffect, useState} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../components/table/Table';
import AddSectionFields from './AddSectionFields';
import { getSections as QUERY } from '../../services/api/queryService';
import { arrayMove, fetchData } from '../../services/util/dataHandlerService';
import Routes from '../../lib/constants/routes';
import ModalForm from '../../components/modal/ModalForm';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { updateTabsOrdering } from '../../services/api/mutationService';

const description = {
  module: 'analyticsSections',
  description: 'analyticsSections',
  req: 'getSections',
  url: Routes.products.index,
  tab: 'search'
};

const TabSort = ({ location, reloadData }) => {

  const [ changedData, setChangedData ] = useState([]);
  const [ loader, setLoader ] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState(null)
  const [ sections, setSections ] = useState({});
  const [ subsections, setSubsections ] = useState([]);
  const [ tabs, setTabs ] = useState([]);
  const [ activeSubsection, setActiveSubsection ] = useState(null)
  const dispatch = useDispatch();
  const [tabsOrderingMutation, { error: updateError }] = useMutation(updateTabsOrdering);

  const user = useSelector(state => state.auth.user)

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      filter: user.id
    },
    fetchPolicy: 'no-cache'
  });


  useEffect(() => {
    refetch()
    if (data) {
      const d = data.getSections.map(x => {
        x.subsection = x.subsection + '*' + x.section 
        delete x.showFilter;
        return x
      })
      const newData = [...d]
      const subs = [...new Set(newData.map(x => x.subsection))]
      const tbs = [];
      subs.map((item, i) => {
        const f = [...newData.filter(x => x.subsection == item)]
        f.sort(function(a, b) {
            return a.user_tab_position - b.user_tab_position;
        })

        tbs.push({
            id: f.id,
            section: f.find(x => x.subsection == item).section,
            subsection: item,
            tabs: [...f.map(k => k.tab)],
            position: ++i
        })
      })

      setChangedData(newData)
      setSubsections(subs)
      setTabs(tbs)
      setLoader(false)
    }
  }, [data, reloadData])

  if (error) {
    throw new Error(error);
  }

  const renderTableHeading = key => {
    if (key === '__typename') {
      return;
    }
    if (key === 'id') {
      return (
        <th key={key} className="text-capitalize text-center">
          ID
        </th>
      );
    }

    return (
      <th key={key} className="text-capitalize">
        {key.replace('_', ' ')}
      </th>
    );
  };

  const renderTableField = (array, item) => {

    const f = [...array.filter(x => x.subsection == item)]

    return f;
  };

  const changeSort = (subsection) =>{
    setActiveSubsection(subsection)
  }

  const SortableItem = SortableElement(({value, subsection}) => (
    <span className="draggableSpan" onMouseEnter={() => changeSort(subsection)}>
      <img src="/assets/images/drag-d.svg" alt="" />
        {value}
    </span>
  ));

  const SortableList = SortableContainer(({items}) => {
    return (
      <div className="d-flex flex-direction-column">
        {items.tabs.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} subsection={items.subsection} value={value} />
        ))}
      </div>
    );
  });
  
  const onSortEnd = ({oldIndex, newIndex}) => {
    // Detect the subsection
    if(activeSubsection){

        const fd = tabs.filter(x => x.subsection === activeSubsection)[0]
        const nd = tabs.filter(x => x.subsection !== activeSubsection)

        fd.tabs = [...arrayMove(fd.tabs, oldIndex, newIndex)]
        
        nd.splice(fd.position-1, 0, fd);
        
        setTabs([...nd])
    }else{
        alert('Something went wrong')
    }
  };

  const submitHandler = async () => {
    try {
        tabs.map(x => {
          x.subsection = x.subsection.split('*')[0]
        })

        const dataInput = {
            data : JSON.stringify(tabs),
            user_id: user.id
        } 

        const data = await tabsOrderingMutation({
            variables: {
                input: dataInput
            }
        });

        if (data.error) {
            alert('Server responded with no data');
            return;
        }

        dispatch({
            type: 'UPDATE',
            payload: {
            type: 'success',
            message: `Recored was successfully updated`
            }
        });
      
    } catch (err) {
        console.log(err)
    }
  }

  return (
    <>
      {loader || !changedData.length || !tabs ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="sections-page-table custom-table card-body">
          <table className="table mt-3 mb-0">
            <thead>
                <tr>
                    {subsections.map(key => renderTableHeading(data.getSections.find(x => x.subsection == key).section))}
                </tr>
            </thead>
            <thead>
                <tr>
                    {subsections.map(key => renderTableHeading(key.split('*')[0]))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {tabs && tabs.map(item => (
                        <td key={item.subsection}>
                            <SortableList items={item} data-subsection={item.subsection} onSortEnd={onSortEnd} />
                        </td>
                    ))}
                </tr>
                <tr>
                    
                </tr>
            </tbody>
            </table>
            <hr style={{ borderColor : "#dee2e6" }} />
            <div>
                <button className="btn btn-primary" onClick={() => submitHandler()}>
                    Save
                </button>
            </div>
        </div>
      )}
    </>
  );
};

export default TabSort;
