import React from 'react';

/** UI-components */
import Td from '../ui-components/td/Td';

/** Redux */
import { useSelector, useDispatch } from 'react-redux';

const TableBody = ({
  openNewTab,
  keys,
  content,
  moduleName,
  refetch,
  history,
  selectedItems,
  hasAction,
  onAction,
  notClickable,
  setSelectedItems,
  isListing
}) => {
  const updated = useSelector(state => state.ui.draw);
  const dispatch = useDispatch();
  const isProduct = moduleName === 'products';
  const isManufacturer = moduleName === 'manufacturers';

  if (updated) {
    refetch();

    dispatch({
      type: 'DRAW',
      payload: false
    });
  }


  const checkboxClickHandler = (item, event) => {
    let updatedSelectedItems = [...selectedItems];
    if (event.target.checked) {
      updatedSelectedItems.push(+item.product_parent_id);
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(i => i !== +item.product_parent_id);
    }

    updatedSelectedItems.sort((a, b) => b - a);
    setSelectedItems(updatedSelectedItems);
  };

  const clickHandler = id => {
    if (openNewTab) {
      if (moduleName !== 'importJobs') {
        const win = window.open(`/${moduleName}/${id}/edit`, '_blank');
        win.focus();
      } else {
        const win = window.open(`/products/import/${id}`, '_blank');
        win.focus();
      }
    } else {
      if (moduleName !== 'importJobs') {
        history.push(`/${moduleName}/${id}/edit`);
      } else {
        history.push(`/products/import/${id}`);
      }
    }
  };


  return (
    <tbody>
      {content.map((item, index) => {
        
        return (
          <tr key={index}>
            {(isProduct || isListing) && (
              <td className="text-center" id={item.product_parent_id}>
                <input
                  type="checkbox"
                  checked={selectedItems.some(i => +i === +item.product_parent_id)}
                  onChange={checkboxClickHandler.bind(null, item)}
                />
              </td>
            )}
            {keys.map((fieldKey, i) => {
              if(item[fieldKey] === null) item[fieldKey] = '-'
              if(fieldKey === 'product_details' && item['product_type_id'] === 8) item[fieldKey] = 'Parent product'
              if (fieldKey !== 'embeddCopyedLocation' && fieldKey !== 'slug' && fieldKey !== 'showFilter' && fieldKey !== 'alt_name' 
              && fieldKey !== 'showFlag' && fieldKey !== 'user_tab_position' && fieldKey !== 'product_type_id' && fieldKey !== 'roleId' && fieldKey !== 'explanation_url' && fieldKey !== 'showExplanation') {
                return (
                  <Td
                    key={i}
                    field={fieldKey}
                    value={fieldKey === 'product_parent_id' || ( isManufacturer && fieldKey === 'id') ? item['slug'] : item[fieldKey]}
                    item={item}
                    notClickable={notClickable}
                    moduleName={moduleName}
                    isListing={isListing}
                  />
                );
              }
            })}
            {hasAction && (
              <td className="text-center">
                <i onClick={() => onAction(item)} className="fas fa-edit" style={{cursor: 'pointer' }}></i>
              </td>
            )}
          </tr>
        );
      })}
    </tbody>
  );
};

export default TableBody;
