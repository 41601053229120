const initialState = {
    user: {
        firstName: '',
        lastName: '',
        email: '',
        id: 1
    },
    isAuthenticated: false,
    access_token: null
}

const auth = (state = initialState, action) => {

    switch (action.type) {

        case 'SIGN_IN':
            return {
                ...state,
                isAuthenticated: true,
            }

        case 'LOG_OUT':
            return {
                ...state,
                isAuthenticated: false,
                user: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    id: 1
                },

            }

        case 'PROFILE':
            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                access_token: action.payload.api_token
            }

        default:
            return state
    }
}

export default auth;