import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import PimSettingsTabContent from './PimSettingsTabContent';
import {
  getMarketplacesSettings,
  getFulfillmentVendorsSettings,
  getProductTypesSettings,
  getPackagingUnitsSettings,
  getProductCategoriesSettings,
  getBaseUnitsSettings,
  getArticlePackagingTypes,
  getSellers,
  getShippingUnits,
  getShippingCarriers,
  getAttributes
} from '../../services/api/queryService';
import {
  createMarketplace,
  updateMarketplace,
  createFulfillmentVendor,
  updateFulfillmentVendor,
  createProductType,
  updateProductType,
  createPackagingUnit,
  updatePackagingUnit,
  createProductCategoryTable,
  updateProductCategoryTable,
  createBaseUnit,
  updateBaseUnit,
  createArticlePackagingType,
  updateArticlePackagingType,
  createSeller,
  updateSeller,
  createShippingUnit,
  updateShippingUnit,
  createShippingCarrier,
  updateShippingCarrier,
  createAttribute,
  updateAttribute
} from '../../services/api/mutationService';

import './pimSettings.scss'

const PimSettings = ({ create, update }) => {
  const [selectedTab, setSelectedTab] = useState('marketplaces');
  return (
    <>
      {/* Header */}
      <div className="page-title-box">
        <h4 className="page-title">PIM Settings</h4>
        <ol className="breadcrumb m-0">
          <li className="breadcrumb-item">
            <Link to="/">Settings</Link>
          </li>
          <li className="breadcrumb-item active">PIM Settings</li>
        </ol>
      </div>

      {/* Tabs */}
      <div className="relative">
        <div className="slide_indicator">
          <img src="/assets/images/right-chevron.png" alt="" />
        </div>
      </div>
      <ul className="nav nav-tabs nav-bordered mb-3 tabs_blocks scroll-x">
        
        <li className="nav-item" onClick={() => setSelectedTab('marketplaces')}>
          <a href="#marketplaces-tab" className="nav-link active" data-toggle="tab">
            Marketplaces
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('fulfillmentVendors')}>
          <a href="#fulfillment-vendors-tab" className="nav-link" data-toggle="tab">
            Fulfillment vendors
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('productTypes')}>
          <a href="#product-types-tab" className="nav-link" data-toggle="tab">
            Product types
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('packagingUnits')}>
          <a href="#packaging-units-tab" className="nav-link" data-toggle="tab">
            Packaging units
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('productCategories')}>
          <a href="#product-categories-tab" className="nav-link" data-toggle="tab">
            Product categories
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('baseUnits')}>
          <a href="#base-units-tab" className="nav-link" data-toggle="tab">
            Base units
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('articlePackagingTypes')}>
          <a href="#article-packaging-types-tab" className="nav-link" data-toggle="tab">
            Article packaging types
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('sellers')}>
          <a href="#sellers-tab" className="nav-link" data-toggle="tab">
            Sellers
          </a>
        </li>
        <li className="nav-item" onClick={() => setSelectedTab('attributes')}>
          <a href="#attributes-tab" className="nav-link" data-toggle="tab">
            Attributes
          </a>
        </li>
      </ul>

      {/* Content */}
      <div className="tab-content p-0">
        <div className="tab-pane show active" id="marketplaces-tab">
          {selectedTab === 'marketplaces' && (
            <PimSettingsTabContent
              tabId="marketplaces"
              name="Marketplace"
              query={getMarketplacesSettings}
              createMutation={createMarketplace}
              updateMutation={updateMarketplace}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="fulfillment-vendors-tab">
          {selectedTab === 'fulfillmentVendors' && (
            <PimSettingsTabContent
              tabId="fulfillmentVendors"
              name="Fulfillment vendor"
              query={getFulfillmentVendorsSettings}
              createMutation={createFulfillmentVendor}
              updateMutation={updateFulfillmentVendor}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="product-types-tab">
          {selectedTab === 'productTypes' && (
            <PimSettingsTabContent
              tabId="productsTypes"
              name="Product type"
              query={getProductTypesSettings}
              createMutation={createProductType}
              updateMutation={updateProductType}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="packaging-units-tab">
          {selectedTab === 'packagingUnits' && (
            <PimSettingsTabContent
              tabId="packagingUnits"
              name="Packaging unit"
              query={getPackagingUnitsSettings}
              createMutation={createPackagingUnit}
              updateMutation={updatePackagingUnit}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="product-categories-tab">
          {selectedTab === 'productCategories' && (
            <PimSettingsTabContent
              tabId="productCategories"
              name="Product category"
              query={getProductCategoriesSettings}
              createMutation={createProductCategoryTable}
              updateMutation={updateProductCategoryTable}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="base-units-tab">
          {selectedTab === 'baseUnits' && (
            <PimSettingsTabContent
              tabId="baseUnits"
              name="Base Unit"
              query={getBaseUnitsSettings}
              createMutation={createBaseUnit}
              updateMutation={updateBaseUnit}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="article-packaging-types-tab">
          {selectedTab === 'articlePackagingTypes' && (
            <PimSettingsTabContent
              tabId="articlePackagingTypes"
              name="Article packaging types"
              query={getArticlePackagingTypes}
              createMutation={createArticlePackagingType}
              updateMutation={updateArticlePackagingType}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="sellers-tab">
          {selectedTab === 'sellers' && (
            <PimSettingsTabContent
              tabId="sellers"
              name="Sellers"
              query={getSellers}
              createMutation={createSeller}
              updateMutation={updateSeller}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="shipping-tab">
          {selectedTab === 'shipping' && (
            <PimSettingsTabContent
              tabId="shippingUnits"
              name="shipping"
              query={getShippingUnits}
              createMutation={createShippingUnit}
              updateMutation={updateShippingUnit}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="carriers-tab">
          {selectedTab === 'carriers' && (
            <PimSettingsTabContent
              tabId="shippingCarriers"
              name="carriers"
              query={getShippingCarriers}
              createMutation={createShippingCarrier}
              updateMutation={updateShippingCarrier}
              create={create}
              update={update}
            />
          )}
        </div>
        <div className="tab-pane" id="attributes-tab">
          {selectedTab === 'attributes' && (
            <PimSettingsTabContent
              tabId="attributes"
              name="attributes"
              query={getAttributes}
              createMutation={createAttribute}
              updateMutation={updateAttribute}
              create={create}
              update={update}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PimSettings;
