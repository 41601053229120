import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { CURRENCIES_OPTIONS, STATUS_OPTIONS } from '../../../lib/constants/fields';
import ModalError from '../../../components/modal/ModalError';

const MarketplacesFormFields = ({
  itemId,
  createMutationId,
  updateMutationId,
  data,
  setModalOpen,
  name,
  createMutation,
  updateMutation,
  refetch
}) => {
  const [formData, setFormData] = useState({
    name: data && data.name,
    tax_rate: data ? data.tax_rate.map(i => (i * 100).toFixed(2)) : [],
    currency_id: data ? data.currency_id : CURRENCIES_OPTIONS[0].id,
    status: data ? data.status : STATUS_OPTIONS[1].id
  });
  const dispatch = useDispatch();
  const [createItem, { loading: createLoading, error: createError }] = useMutation(createMutation);
  const [updateItem, { loading: updateLoading, error: updateError }] = useMutation(updateMutation);
  const [error, setError] = useState(null);
  const [mutationError, setMutationError] = useState();

  const [changesMade, setChangesMade] = useState(false);
  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();
    setError(null);

    const preparedFormData = { ...formData, tax_rate: [...formData.tax_rate] };
    preparedFormData.tax_rate = preparedFormData.tax_rate.map(i => (i / 100).toFixed(4));

    try {
      if (itemId) {
        const { data } = await updateItem({
          variables: {
            input: { ...preparedFormData, id: itemId }
          }
        });

        if (!data[updateMutationId]) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data[updateMutationId].error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createItem({
          variables: {
            input: preparedFormData
          }
        });
        if (!data[createMutationId]) {
          throw new Error('Server responded with no data');
        }
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setModalOpen(false);
  };

  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {
    setChangesMade(true);
    let newValue;
    if (option) {
      newValue = option.value !== undefined ? option.value : option.map(i => i.value);
    } else {
      newValue = [];
    }
    setFormData({
      ...formData,
      [data.name]: newValue
    });
  };

  const isValidNewOption = input => {
    if (!input) {
      return false;
    }
    if (!isFinite(input)) {
      return false;
    }
    if (+input > 100 || +input < 0) {
      return false;
    }
    return true;
  };

  if (createError || updateError || mutationError) {
    throw new Error(createError || updateError || mutationError);
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {`${itemId ? 'Edit' : 'Add'} "${name}" dimension`}
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError error={error} setError={setError} />
        <div className="form-group">
          <label htmlFor="name">
            Name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            required
            defaultValue={formData.name}
            onChange={changeFieldHandler}
            disabled={data && data.status === 0}
          />
        </div>
        <div>
          <label htmlFor="tax_rate">
            Tax rate <span style={{ color: 'red' }}>*</span>
          </label>
          <CreatableSelect
            name="tax_rate"
            defaultValue={formData.tax_rate.map(i => ({ label: i, value: i }))}
            onChange={changeSelectHandler}
            isValidNewOption={isValidNewOption}
            isDisabled={data && data.status === 0}
            isMulti
          />
          <input
            tabIndex={-1}
            style={{ opacity: 0, height: 0 }}
            required={!formData.tax_rate.length}
          />
        </div>
        <div className="form-group">
          <label htmlFor="currency_id">
            Currency <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="currency_id"
            options={convertDataToOptions(CURRENCIES_OPTIONS)}
            defaultValue={createDefaultOption(
              CURRENCIES_OPTIONS.find(i => i.id === formData.currency_id)
            )}
            onChange={changeSelectHandler}
            isDisabled={data && data.status === 0}
          />
        </div>
        <div className="form-group">
          <label htmlFor="status">
            Status <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="status"
            options={convertDataToOptions(STATUS_OPTIONS)}
            defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id === formData.status))}
            onChange={changeSelectHandler}
          />
        </div>
      </div>
      <div className="modal-footer justify-content-start">
        {createLoading || updateLoading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default MarketplacesFormFields;
