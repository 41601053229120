import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import ModalError from '../../components/modal/ModalError';
import { deleteManufacturerPortfolio, deleteProduct } from '../../services/api/mutationService';
import { useDispatch } from 'react-redux';

const ManufacturerProductsTabDeleteModal = ({ selectedItems, setModalOpen }) => {
    const [loader, setLoader] = useState(false)
    const [acceptDelete, setAcceptDelete] = useState(false)
    const dispatch = useDispatch()

    const [
        deleteManufacturerPortfolioMutation, { loading, error }
      ] = useMutation(deleteManufacturerPortfolio);
    

    const submitDelete = async () => {
        const response = await deleteManufacturerPortfolioMutation({
            variables: { id: selectedItems}
        })
    
        dispatch({
            type: 'UPDATE',
            payload: {
                type: 'success',
                message: `Selected items are deleted successfully`
            }
        }); 

        setModalOpen(false)
    }



  const backdropClickHandler = event => {
        if (event.target.id === 'exportModal') {
        setModalOpen(false);
        }
    };

  const checkboxChangeHandler = event => {
        const { checked } = event.target;
        setAcceptDelete(checked)
    };

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="exportModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete portfolios</h4>
              <button type="button" className="close" onClick={() => setModalOpen(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body">
              {loader ? (
                <div className="spinner absolute">Spinner</div>
              ) : (
                <div>
                  <div className="table-responsive mb-4">
                    <p>By performing the selected action all the selected portfolios will be deleted and all the products under these portfolios will loose their relation</p>
                  </div>

                  <div>
                    <input id="deletion" type="checkbox" className="mr-1" onChange={checkboxChangeHandler} />
                    <label htmlFor="deletion">Please confirm the action</label>
                  </div>
                </div>
              )}
            </div>
            
            <div className="modal-footer justify-content-start">
                <button onClick={() => submitDelete()} disabled={!acceptDelete} className="btn btn-danger">
                  Delete
                </button>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ManufacturerProductsTabDeleteModal;
