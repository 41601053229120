const  UPDATE = 'UPDATE';
const  CLEAN  = 'CLEAN';

const initialState = { 
    type : '',
    message : ''
};

const notification = (state = initialState , action) => {
  
    switch(action.type) {

        case UPDATE:
            return  {
                type: action.payload.type , 
                message: action.payload.message,
            }
        case CLEAN:  
            return {
                type:'',
                message:''
            }
        default : 
            return state
    }

}

export default notification;