import React, { useState } from 'react';

import ModalForm from '../../components/modal/ModalForm';
import BrandFormFields from '../../view/pimSettings/formFields/DefaultDimensionFormFields';

import { createBrand, updateBrand } from '../../services/api/mutationService';
import { STATUSES } from '../../lib/constants/fields';

const ManufacturerBrandsTab = ({ data, manufacturerId, refetch, create, update }) => {
  const [itemId, setItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const renderTableHeading = key => {
    if (key === '__typename' || key === 'slug') {
      return;
    }
    if (key === 'id') {
      return (
        <th key={key} className="text-capitalize text-center">
          ID
        </th>
      );
    }
    return (
      <th key={key} className="text-capitalize">
        {key.replace('_', ' ')}
      </th>
    );
  };

  const renderTableField = (key, item) => {
    if (key === '__typename' || key === 'slug') {
      return;
    }
    if (key === 'id') {
      return (
        <td key={key} className="text-center">
          {item['slug']}
        </td>
      );
    }
    if (key === 'status') {
      return <td key={key}>{STATUSES[item[key]]}</td>;
    }
    return <td key={key}>{item[key]}</td>;
  };

  return (
    <>
      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="card">
          <div className="card-body">
            <div>
              {create && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setItemId(null);
                    setModalOpen(true);
                  }}
                >
                  Create New
                </button>
              )}
            </div>

            {!!data.length && (
              <div className="table-responsive">
                <table className="table mt-3 mb-0">
                  <thead>
                    <tr>
                      {Object.keys(data[0]).map(key => renderTableHeading(key))}
                      {update && <th className="text-center">Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map(item => (
                      <tr key={item.id}>
                        {Object.keys(item).map(key => renderTableField(key, item))}
                        {update && (
                          <td className="text-center">
                            <i
                              id={item.id}
                              className="fas fa-edit"
                              style={{ cursor: 'pointer' }}
                              data-toggle="modal"
                              data-target="#modal"
                              onClick={e => {
                                setItemId(+e.target.id);
                                setModalOpen(true);
                              }}
                            />
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>

          {modalOpen && (
            <ModalForm setModalOpen={setModalOpen}>
              <BrandFormFields
                itemId={itemId}
                createMutationId={createBrand.definitions[0].name.value}
                updateMutationId={updateBrand.definitions[0].name.value}
                data={data.find(item => +item.id === +itemId)}
                setModalOpen={setModalOpen}
                name="Brand"
                manufacturerId={manufacturerId}
                createMutation={createBrand}
                updateMutation={updateBrand}
                refetch={refetch}
              />
            </ModalForm>
          )}
        </div>
      )}
    </>
  );
};

export default ManufacturerBrandsTab;
