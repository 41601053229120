import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateListing } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

const getDefaultOptionFromValue = (value, options) => {
  if (value) {
    const option = options.find(option => value === option.id);
    if (option) {
      return createDefaultOption(option);
    }
  }
};

const ProductBullets = ({ selectData, productData, refetch, update, onLisitingChange, activeMarketplace }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [marketplaceID, setMarketplaceID] = useState(
    productData.marketplace_id
  );
  let bullets = []
  
  if(productData.bullets){
    productData.bullets.split(';').map(item => {
      bullets.push(item)
    })
  }
  
  const settings = JSON.parse(productData.setting.settings)
  
  for(let i = 0; i < settings.bulltets_count_max; i++){
    bullets[i] = bullets[i] ? bullets[i] : ''
  }

  const [formData, setFormData] = useState({
    bullets: [...bullets],
    altersempfehlung: productData.altersempfehlung??'',
    warnhinweis: productData.warnhinweis??'',
  });

  useEffect(() => {
    bullets = []
    if(productData.bullets){
      productData.bullets.split(';').splice(0, 5).map(item => {
        bullets.push(item)
      })
    }

    for(let i = 0; i < settings.bulltets_count_max; i++){
      bullets[i] = bullets[i] ? bullets[i] : ''
    }


    setFormData({
      bullets: [...bullets],
      altersempfehlung: productData.altersempfehlung??'',
      warnhinweis: productData.warnhinweis??'',
    })

  }, [productData])


  const submitHandler = async event => {
    event.preventDefault();

    const payload = {
      bullets : formData.bullets.join(';'),
      warnhinweis: formData.warnhinweis,
      altersempfehlung: formData.altersempfehlung,
    }

    try {
      const { data } = await updateListingMutation({
        variables: {
          input: {...payload},
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  const changeFieldHandler = event => {
    const d = {...formData}
    if(event.target.id == 'altersempfehlung' || event.target.id == 'warnhinweis'){
      d[event.target.id] = event.target.value
      productData[event.target.id] = event.target.value
    }else{
      d.bullets[event.target.id] = event.target.value
    }
    
    
    setFormData({
      ...d
    });  

    const newData = {
      ['bullets'] : formData.bullets.join(';'),
      'altersempfehlung' : formData.altersempfehlung,
      'warnhinweis' : formData.warnhinweis,
    }
    productData.bullets = formData.bullets.join(';')

    onLisitingChange(newData)
  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  return (
    <form onSubmit={submitHandler}>
      <fieldset disabled={!update}>
        <div className="row row-cols-4">
          {formData && formData.bullets.map((item, index) => (
            <div className="col-12 mb-2" key={index}>
              <div className='d-flex justify-content-between align-items-center'>
                <label htmlFor="title">Bullet {index+1}</label>
                <p className="mb-0 ">
                  Characters: <span className={item.length < settings.bytes_min || settings.bytes_max < item.length  ? 'text-danger' : 'text-green'}> {item.length}</span> | 
                  Bytes: <span className={item.length < settings.bytes_min || settings.bytes_max < item.length  ? 'text-danger' : 'text-green'}> {item.length} kb</span>
                </p>
              </div>
              <input
                type="text"
                id={index}
                className="form-control"
                value={item}
                onChange={changeFieldHandler}
                maxLength={settings.restrict_bytes_max??500}
              />
            </div>
          ))}
          {formData  && activeMarketplace == 24 && (
            <>
              <div className="col-12 mb-2" key={6}>
                <div className='d-flex justify-content-between align-items-center'>
                  <label htmlFor="title">Warnhinweis</label>
                  <p className="mb-0 ">
                    Characters: <span className={formData.warnhinweis.length < settings.bytes_min || settings.bytes_max < formData.warnhinweis.length  ? 'text-danger' : 'text-green'}> {formData.warnhinweis.length}</span> | 
                    Bytes: <span className={formData.warnhinweis.length < settings.bytes_min || settings.bytes_max < formData.warnhinweis.length  ? 'text-danger' : 'text-green'}> {formData.warnhinweis.length} kb</span>
                  </p>
                </div>
                <input
                  type="text"
                  id="warnhinweis"
                  className="form-control"
                  value={formData.warnhinweis}
                  onChange={changeFieldHandler}
                  maxLength={settings.restrict_bytes_max??500}
                />
              </div>
              <div className="col-12 mb-2" key={7}>
                <div className='d-flex justify-content-between align-items-center'>
                  <label htmlFor="title">Altersempfehlung</label>
                  <p className="mb-0 ">
                    Characters: <span className={formData.altersempfehlung.length < settings.bytes_min || settings.bytes_max < formData.altersempfehlung.length  ? 'text-danger' : 'text-green'}> {formData.altersempfehlung.length}</span> | 
                    Bytes: <span className={formData.altersempfehlung.length < settings.bytes_min || settings.bytes_max < formData.altersempfehlung.length  ? 'text-danger' : 'text-green'}> {formData.altersempfehlung.length} kb</span>
                  </p>
                </div>
                <input
                  type="text"
                  id="altersempfehlung"
                  className="form-control"
                  value={formData.altersempfehlung}
                  onChange={changeFieldHandler}
                  maxLength={settings.restrict_bytes_max??500}
                />
              </div>
            </>
          )}
        </div>
        {update && (
          <>
            {loading ? (
              <div className="spinner">Spinner</div>
            ) : (
              <button type="submit" className="btn btn-primary px-3 mt-2">
                Save
              </button>
            )}
          </>
        )}
      </fieldset>
    </form>
  );
};

export default ProductBullets;
