import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import Error from '../../../components/modal/ModalError';

import { updateCustoms } from '../../../services/api/mutationService';

const CustomsInformationForm = ({ productData, selectData, refetch, update, updateFormData, errors }) => {
  const [updateCustomsMutation, { loading, error: updateError }] = useMutation(updateCustoms);
  const [error, setError] = useState();
  const dispatch = useDispatch();
  const [validationErrror, setValidationError] = useState();

  const [formData, setFormData] = useState(productData.customs || []);
  const [changesMade, setChangesMade] = useState(false);
  const [errorsList, setErrorsList] = useState([]);

  useEffect(() => {
    setErrorsList(errors)
  }, [errors])

  const { localStorage } = window;
  
  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true

  const activeMarkeplaces = productData.marketplacesInformation.filter(x => x.status == 1)
  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);
  
  const submitHandler = async event => {
    event.preventDefault();

    let priceCheckError = false;

    if(!silentError){
      if(productData.product_category_hazmat == null || productData.product_category_hazmat.length == 0){
        if(!window.confirm('I confirm that product doesn’t fall under the hazmat category')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }

      if(productData.productPrices == null || productData.productPrices.length == 0){
        if(!window.confirm('I confirm that this product has no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }else{
        productData.productPrices.forEach((value, index) => {
          if(value.price_buy_net_actual_ct == null || value.price_buy_net_actual_ct == 0){
            priceCheckError = true
          }
        })
      }

      if(priceCheckError) {
        if(!window.confirm('Some prices have no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }
    }


    setChangesMade(false);
    try {
      const { data } = await updateCustomsMutation({
        variables: {
          input: formData,
          product_parent_id: productData.product_parent_id
        }
      });

      if (!data.updateCustom) {
        throw new Error('Server responded with no data');
      }

      if(data.updateCustom.error){
        setValidationError(data.updateCustom.error)
        return false
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  const changeFieldHandler = (rowNumber, event) => {
    setChangesMade(true);
    
    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == event.target.id)

    if(d){
      delete err[event.target.id]

      setErrorsList({...err})
    }
    
    const updatedFormData = [...formData];
    updatedFormData[rowNumber] = {
      ...updatedFormData[rowNumber],
      [event.target.id]: event.target.value
    };
    setFormData(updatedFormData);
  };

  const changeSelectHandler = rowNumber => (option, data) => {
    setChangesMade(true);
    const updatedFormData = [...formData];
    updatedFormData[rowNumber] = {
      ...updatedFormData[rowNumber],
      [data.name]: option ? option.value : undefined
    };

    const err = {...errorsList}
    const d = Object.keys(err).find(x => x == 'product_country_of_origin')

    if(d){
      delete err['product_country_of_origin']
      setErrorsList({...err})
    }


    setFormData(updatedFormData);
  };

  useEffect(() => {
    updateFormData({customsInformation: formData[0]})
  }, [formData])

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  if (!formData.length) {
    return null;
  }

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  const showError = key => {
    if(!errorsList[key]) return null

    return (
      <div className="custom_error_container">
        <img src="/assets/images/info-button.png" alt="" />
        <p>{errorsList[key]}</p>
      </div>
    )
  }

  return (
    <form onSubmit={submitHandler}>
      <fieldset disabled={!update}>
      <Error setError={() => setValidationError('')} error={validationErrror}  close={true} />

        <div style={{overflow: 'unset'}}>
          <table className="table mt-0">
            <thead>
              <tr>
                <th>Product country of origin</th>
                <th style={{ display: 'flex'}}>
                  Customs tariff number
                  <div className="u_infoButton">
                      <img src="/assets/images/info-button.png" alt="" />

                      <div className="infoBox">
                          <span>The TARIC code (TARif Intégré Communautaire; Integrated Tariff of the European Communities) is designed to show the various rules applying to specific products when imported into the EU, see more: <a style={{color: "#fff", textDecoration: "underline"}} href="https://ec.europa.eu/taxation_customs/business/calculation-customs-duties/customs-tariff/eu-customs-tariff-taric_en" target="_blank" >EU Customs Tariff (TARIC)</a></span>
                      </div>
                  </div>
                </th>
                <th>Product tax rate (in %) </th>
              </tr>
            </thead>
            <tbody>
              {formData.map((row, index) => (
                <tr key={row.id}>
                  <td>
                    <Select
                      className={`${errorsList && errorsList['product_country_of_origin'] ? 'invalid' : ''}`}
                      name="product_country_of_origin"
                      options={selectData.countries.map(country => ({
                        label: country.name,
                        value: country.name
                      }))}
                      defaultValue={{
                        label: row.product_country_of_origin || '',
                        value: row.product_country_of_origin || ''
                      }}
                      onChange={changeSelectHandler(index)}
                      menuPortalTarget={document.body}
                      instanceId={index}
                      isClearable
                      isDisabled={!update || isArchived}
                    />
                    { showError('product_country_of_origin') }


                  </td>
                  <td style={{ position: 'relative', overflow: 'visible', paddingBottom: '1rem' }}>
                    <input
                      id="product_tax_number"
                      className={`form-control ${errorsList && errorsList['product_tax_number'] ? 'invalid' : ''}`}
                      type="text"
                      defaultValue={row.product_tax_number}
                      onChange={event => changeFieldHandler(index, event)}
                      disabled={isArchived}
                    />
                    { showError('product_tax_number') }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </fieldset>
    </form>
  );
};

export default CustomsInformationForm;
