import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { PRODUCT_FORMS } from '../../lib/constants/fields';
import { getProduct } from '../../services/api/queryService';
import { useQuery } from '@apollo/react-hooks';



import './ProductFormCard.scss';

const ProductFormCard = ({ id, formPreOpen = false, hasTable = false, children, disabled = false, isAttribute, match }) => {
  const [formOpen, setFormOpen] = useState(formPreOpen);
  const location = useLocation();
  const slug = location.pathname.split('/')[2]

  const { data: productData, refetch } = useQuery(getProduct, {
    variables: { slug: slug }
  });

  const marketplaceErrors = [];
  if(productData?.product){
    productData.product.productMarketplaces.forEach(function(pm) {
      if(pm.error !== null) { 
        const mpErrors = pm.error.split(";"); /* Split errors in case multiple errors occured during validation */
        mpErrors.forEach(function(mpError) {
          if(!mpError.includes('out of stock')){
            marketplaceErrors.push(mpError);
          }
        });
      }
    });
  }

  const priceSDesabled = () => {
    return (
      <>
        Prices | <span>Product is sold by 3P seller, no prices are managed by eBazaaris</span>
      </>
    )
  }

  return (
    <div className="product-form container col-12 px-0" style={{ background: disabled ? '#ddd' : '#fff', opacity: disabled ? '0.75' : '1',  marginBottom: isAttribute ? '150px' : '1rem' }}>
      <div
        className="product-form__header d-flex justify-content-between align-items-center"
        onClick={() => disabled ? null : setFormOpen(!formOpen)}
      >
        <h4 className="m-0" style={{ display: id == 'marketplaceInformation' ? 'flex' : 'block', alignItems: 'center' }}>
          {disabled ?
          "Prices | Product is sold by 3P seller, no prices are managed by eBazaaris" : 
          PRODUCT_FORMS[id].title}
         {id == 'marketplaceInformation' && marketplaceErrors.length != 0 && !formOpen && !disabled && (
            <div className="alert-danger alert-danger-custom ml-2" style={{borderRadius: '4px', padding: '4px 8px'}}>
              <div className="tooltipHeading">
                <div className="">
                  <i className='fas fa-info-circle mr-1 red-info-icon'></i>
                  <span className="u_infoButton" style={{fontWeight: '500', color: '#343a40'}}>
                    { marketplaceErrors.length } errors require attention
                    {
                      <ul className="infoBox" style={{bottom: '10px', width: '400px', paddingLeft: '20px' }}>
                        { 
                          marketplaceErrors.map(mError => {
                            return (<li>{mError}</li>);
                          })
                        }
                      </ul>
                    
                    }
                  </span>
                </div>
              </div>
            </div>
          ) } 
        </h4>
        <i className="mdi mdi-chevron-down" />
      </div>
      <div style={{ display: formOpen && !disabled ? 'block' : 'none' }}>
        {!hasTable && <hr />}
        <div className={`product-form__body ${hasTable ? 'pt-0' : ''}`}>{children}</div>
      </div>
    </div>
  );
};

export default ProductFormCard;
