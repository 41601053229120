import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ModalError from '../../../components/modal/ModalError';
import { STATUS_OPTIONS } from '../../../lib/constants/fields';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import "../costSettings.scss"

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { createMarketplaceCost, updateMarketplaceCost } from '../../../services/api/mutationService';
import { 
    getMarketplacesSettings, 
    getProductCategoriesSettings
} from '../../../services/api/queryService';

const MarketplaceCostModal = ({ setModalOpen, itemId, sections, refetch }) => {


  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [fulfilments, setFulfilments] = useState([]);
  const [marketplaces, setMarkpetplaces] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [formData, setFormData] = useState(itemId ? {...itemId} :{
    marketplace_id: 1,
    category_id: null,
    period: null,
    category_fee: 0,
    price_from: 0,
    price_to: ''
  });

  let { data : getMarketplaces } = useQuery(getMarketplacesSettings, {
    variables: {
        order: 'id',
        filter: 'status'
    }
  });

  let { data : getCategories } = useQuery(getProductCategoriesSettings, {
    variables: {
        order: 'id',
        filter: 'status'
    }
  });

  useEffect(() => {
    if(getMarketplaces){
        setMarkpetplaces([...getMarketplaces.marketplaces])
    }

    if(getCategories){
        setCategories([...getCategories.productCategories])
        setFilteredCategories([...getCategories.productCategories])
    }
  }, [getMarketplaces, getCategories])

  useEffect(() => {
    if(itemId){
      let r = itemId.period.split('-')[0].split('/')
      let k = itemId.period.split('-')[1].split('/')
      let s = new Date(`${r[2]}-${r[1]}-${r[0]}`)
      let e = new Date(`${k[2]}-${k[1]}-${k[0]}`)
      setDateRange([s, e])
    }
  }, [itemId])

  useEffect(() => {
    setFormData({
      ...formData,
      ['period']: `${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`
    });
  }, [dateRange])

  useEffect(() => {
    let t = marketplaces.filter(x => x.id == formData.marketplace_id)
    if(t.length){
        t = t[0]
        let c = 'Amazon'
        if(+t.id < 17 || +t.id == 18) c = 'Amazon'
        if(+t.id == 22) c = 'Ebay'
        if(+t.id == 23) c = 'Kaufland'
        if(+t.id == 24) c = 'Otto'
        
        const f = categories.filter(x => x.category == c)
        
        setFilteredCategories(f)
    }

  }, [formData.marketplace_id, categories])


  const [createFulfilmetnMutation, { }] = useMutation(
    createMarketplaceCost
  );

  const [updateFulfilmentMutation, {}] = useMutation(
    updateMarketplaceCost
  );

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {

    setFormData({
      ...formData,
      [data.name]: option && option.value
    });
  };

  const submitHandler = async event => {
    event.preventDefault();

    try {
      if (itemId) {
        delete formData.__typename
        delete formData.error
        delete formData.marketplace
        delete formData.category
        const { data } = await updateFulfilmentMutation({
          variables: {
            input: formData,
            id: itemId.id,
          },
          id: itemId.id
        });

        if (!data.id) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data.updateRole.error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createFulfilmetnMutation({
          variables: {
            input: formData
          }
        });
        if (!data.id) {
          throw new Error('Server responded with no data');
        }
      }    

    } catch (err) {
      console.log(err)
      // setError('Internal server error');
    }

    dispatch({
      type: 'UPDATE',
      payload: {
        type: 'success',
        message: `Recored was successfully ${itemId ? 'updated' : 'created'}`
      }
    });
    refetch();

    setModalOpen(false);
  };
  

  return (
    <form onSubmit={submitHandler} className="roles-form">
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {itemId ? 'Edit' : 'Add'} Warehouse Costs
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError className="modal-thin" error={error} setError={setError} />

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Marketplace</label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="marketplace_id"
                    name="marketplace_id"
                    value={createDefaultOption(marketplaces.filter(x => x.id == formData.marketplace_id)[0])}
                    onChange={changeSelectHandler}
                    options={convertDataToOptions(marketplaces)}
                    isDisabled={true}
                />
                                <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0 }}
                        required={!formData.marketplace_id}
                      />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Validity period</label>
            <div className="calendarInput" style={{ width: '300px' }}>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  isClearable={false}
                  selected={startDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                  className="form-control"
                  required={true}
                  disabled={true}
                />
                <img className="calendarIcon" src="/assets/images/calendar-2.png" alt="" />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Category name</label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="category_id"
                    name="category_id"
                    value={createDefaultOption(categories.filter(x => x.id == formData.category_id)[0])}
                    onChange={changeSelectHandler}
                    options={convertDataToOptions(filteredCategories)}
                    isDisabled={true}
                />
                <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0 }}
                        required={!formData.category_id}
                      />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab">Category fee</label>
              <div style={{ width: '300px' }}>
                  <input
                      className="form-control"
                      type="number"
                      step="0.01"
                      id="category_fee"
                      name="category_fee"
                      value={formData.category_fee}
                      onChange={changeFieldHandler}
                      required={!formData.category_fee}
                      disabled={true}
                  />
                   <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0 }}
                        required={+formData.category_fee == 0}
                      />
              </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab">Item price range</label>
              <div style={{ width: '300px' }} className="d-flex align-items-center justify-content-between">
                  <div style={{width: '150px'}} className="d-flex align-items-center justify-content-between">
                    <p className='m-0'>From: </p>
                    <div style={{width: '100px'}}>
                      <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        id="price_from"
                        name="price_from"
                        value={formData.price_from}
                        onChange={changeFieldHandler}
                        required
                        disabled={true}
                        />
                                           
                    </div>
                  </div>
                  <div style={{width: '130px'}} className="d-flex align-items-center justify-content-between">
                    <p className='m-0'>To: </p>
                    <div style={{width: '100px'}}>
                      <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        id="price_to"
                        name="price_to"
                        value={formData.price_to}
                        onChange={changeFieldHandler}
                        disabled={true}
                        />
                    </div>
                  </div>
              </div>
        </div>  

  
      </div>
      
      <div className="modal-footer justify-content-start">
        { loader? (
          <div className="spinner absolute">Spinner</div>
        ) : (
          //   <button  type="submit" className="btn btn-primary">
          //   Save
          // </button>
          <></>
        ) }        
      </div>
    </form>
  )
};

export default MarketplaceCostModal;
