import React, {useState, useEffect, useRef, memo, useMemo, useCallback} from 'react';
import { useSelector } from 'react-redux';
import { Report } from 'react-powerbi-client';
import { useHistory, useLocation, Redirect } from 'react-router-dom';

const { localStorage } = window;

const SummaryTab = ({reports, manufacturers, accessToken}) => {
    const location = useLocation();
    const reportId = +new URLSearchParams(location.search).get('id');

    const [activeManufacturer, selectActiveManufacturer] = useState(null)
    const [activeSellers, selectActiveSellers] = useState(null)
    const [loader, setLoader] = useState(true)
    const [ratio, setRatio] = useState(0)
    const [blurDate, setBlurDate] = useState(null)
    const ref = useRef(null)
    const userData = useSelector(state => state.auth.user);

    const handleResize = () => {
        setRatio(ref.current?.clientWidth / (1350/750).toFixed(1))
    }

    useEffect(() => {

        if(document.visibilityState == 'hidden'){
            if(blurDate){
                const diff = Math.abs(new Date() - blurDate)
                const minutes = Math.floor((diff/1000)/60);

                if(minutes >= 60){
                    window.location.reload()
                }
            }
            setBlurDate(new Date())
        }else{
            setBlurDate(null)
        }

    }, [document.visibilityState])

    useEffect(() => {
        if(manufacturers.length == 0){
            selectActiveManufacturer(null)
        }else{
            selectActiveManufacturer(manufacturers)
        }

    }, [])

    useEffect(() => {
        const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
        if(storageActiveManufacturers && userData.user_type == 2){
          selectActiveManufacturer(storageActiveManufacturers);
        }

        const storageActiveSellers = JSON.parse(localStorage.getItem('active_sellers'));
        if(storageActiveSellers){
            selectActiveSellers(storageActiveSellers);
        }
    }, []);

    window.addEventListener('activeManufacturerChange', (e) => {
        let aManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
        selectActiveManufacturer(aManufacturers);
    });


    window.addEventListener('activeSellerChange', (e) => {
        let aSellers = JSON.parse(localStorage.getItem('active_sellers'));
        selectActiveSellers(aSellers);
    });


    const calcReportHeight = (w) => {
        return w / (1350/750).toFixed(1)
    }

    useEffect(() => {
        console.log(ref.current?.clientWidth , ratio)
        if(ratio == 0 || !ratio){
            setRatio(calcReportHeight(ref.current?.clientWidth))
        }

        if(ref.current?.clientWidth > 0 && (!localStorage.getItem('report_width') || localStorage.getItem('report_width') != ref.current?.clientWidth) ){
            localStorage.setItem('report_width', ref.current?.clientWidth )
        }
    }, [loader, reports])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        }
    }, [])

    const onEmbedded = (embed) => {

        embed.on("loaded", function(){
            setLoader(false)
        })

        embed.on("error", function(){
            window.location.reload()
            console.log('ERROR REPORT')
        })

    }



    if(Number.isInteger(reportId)){

        const report = reports.find(x => x?.report?.id == reportId)?.report
        console.log(report.reportId);
        if(report){

            const extraSettings = {
                filterPaneEnabled: false, //true
                navContentPaneEnabled: false, //true
            };

            let embededUrl = '';
            if(report.embedUrl && accessToken){
                embededUrl = report.embedUrl

                if(userData.user_type == 3){
                    let fieldName = 'elmo_dim_manufacturer'
                    let manufacturerField = 'demo_user_filter'


                    let mq = [1].map(x => `%27u${x}%27`)

                    embededUrl = `${report.embedUrl}&filter=${fieldName}/${manufacturerField}%20in%20%28${mq.join(',')}%29`
                }else if(activeManufacturer && activeManufacturer.length > 0){
                    let fieldName = 'fltr_client_user_manufacturer'
                    let manufacturerField = 'client_user_manufacturer_filter'

                    let mq = [];

                    if(manufacturers.length > 0 && userData.user_type == 1) {
                        mq = activeManufacturer.map(x => `%27m${x.manufacturer.id}u${userData.id}%27`)
                        embededUrl = `${report.embedUrl}&filter=${fieldName}/${manufacturerField}%20in%20%28${mq.join(',')}%29`

                        fieldName = 'fltr_internal_user_elmo_dim_sellers'
                        manufacturerField = 'fltr_internal_seller_id'

                        mq = [...userData.sellers.map(x => x.seller)].map(x => `%27s${x.id}s%27`)

                        embededUrl += `%20and%20${fieldName}/${manufacturerField}%20in%20%28${mq.join(',')}%29`

                    } else if (activeManufacturer.length > 0 && userData.user_type == 2) {
                        mq = activeManufacturer.map(x => `%27m${x.id}m%27`)
                        embededUrl = `${report.embedUrl}&filter=fltr_internal_user_manufacturer/internal_user_manufacturer_filter%20in%20%28${mq.join(',')}%29`
                    }

                    if(activeSellers && activeSellers.length > 0){
                        fieldName = 'fltr_internal_user_elmo_dim_sellers'
                        manufacturerField = 'fltr_internal_seller_id'

                        mq = activeSellers.map(x => `%27s${x.id}s%27`)
                        if(activeManufacturer.length > 0){
                            embededUrl += `%20and%20${fieldName}/${manufacturerField}%20in%20%28${mq.join(',')}%29`
                        }else{
                            embededUrl += `${report.embedUrl}&filter=${fieldName}/${manufacturerField}%20in%20%28${mq.join(',')}%29`
                        }

                    }
                }else if(activeSellers && activeSellers.length > 0){
                    let fieldName = 'fltr_internal_user_elmo_dim_sellers'
                    let manufacturerField = 'fltr_internal_seller_id'

                    let mq = activeSellers.map(x => `%27s${x.id}s%27`)
                    embededUrl += `${report.embedUrl}&filter=${fieldName}/${manufacturerField}%20in%20%28${mq.join(',')}%29`

                }

            }


            return (
                <div>
                    { loader && !accessToken && ( 
                        <div className='init_loader'>
                            <div className="spinner">Spinner</div>
                            <p>It might take a few moments to load the page</p>
                        </div>
                    )}
                    {accessToken ? (
                        <>
                            <div style={{ height: `${ratio}px`}} ref={ref} className={`app-embed ${loader == false ? '' : 'hidden'}`}>
                                <Report
                                    style={{ height: `100%`}}
                                    className="powerBiEmbed"
                                    id={report.reportId}
                                    embedUrl={embededUrl}
                                    accessToken={accessToken}
                                    filterPaneEnabled={false}
                                    navContentPaneEnabled={false}
                                    onEmbedded={onEmbedded}
                                    extraSettings={extraSettings}
                                    type='report'
                                />
                            </div>
                            <div className={`spinner absolute ${loader ? '' : 'hidden'}`}>Spinner</div>
                            
                            {/* <div className={`white-background-preloader ${loader == true ? '' : 'fadeout'}`} style={{ height: `${calcReportHeight(localStorage.getItem('report_width'))}px`}}></div> */}
                        </>
                    ) : (
                        <div className="spinner absolute">Spinner</div>
                    )}
                </div>
            )
        }else{
            return (
                <div className="d-flex align-items-center justify-content-center" style={style.div}>
                    <img src="/assets/images/logoOP50.png" className="reposnive-img" style={style.img} alt=""></img>
                </div>
            )
        }

    }else{
        return (
            <div className="d-flex align-items-center justify-content-center" style={style.div}>
                <img src="/assets/images/logoOP50.png" className="reposnive-img" style={style.img} alt=""></img>
            </div>
        )
    }

};



const style = {
    div: {
        height: '350px'
    },
    img : {
        height: '100%'
    }
}

export default memo(SummaryTab);
