import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import ModalError from '../../components/modal/ModalError';
import { bulkEditStatus } from '../../services/api/mutationService';
import { PRODUCT_STATUS_OPTIONS_SHOWN } from '../../lib/constants/fields';
import { convertDataToOptions } from '../../services/util/dataHandlerService';

const BulkEditModal = ({ selectedItems, setModalOpen }) => {
  const [bulkEditProductMutation, {}] = useMutation(bulkEditStatus);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    status: PRODUCT_STATUS_OPTIONS_SHOWN[0].value
  });
  
  const submitHandler = async () => {
    setLoading(true)
    const { data } = await bulkEditProductMutation({
      variables: { 
        pids: selectedItems, 
        status: formData.status 
      }
    });
    if (data) {
        setLoading(false)

        setModalOpen(false)
    }
    // setModalOpen(false);
  };


  const backdropClickHandler = event => {
    if (event.target.id === 'exportModal') {
      setModalOpen(false);
    }
  };

  const changeSelectHandler = (option, data) => {
    if (option) {
      setFormData({
        ...formData,
        [data.name]: option.value
      });
    }
  };

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="exportModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Product bulk edit</h4>
              <button type="button" className="close" onClick={() => setModalOpen(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div className="modal-body pb-4">
                <label htmlFor="status" style={{ fontWeight: 600, marginBottom: '0.3rem' }}>
                  Type
                </label>
                <Select
                  name="status"
                  options={convertDataToOptions(PRODUCT_STATUS_OPTIONS_SHOWN)}
                  defaultValue={convertDataToOptions(PRODUCT_STATUS_OPTIONS_SHOWN)[0]}
                  onChange={changeSelectHandler}
                />
              </div>
              <div className="modal-footer justify-content-start">
                {loading ? (
                  <div className="spinner">Spinner</div>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!selectedItems.length}
                    onClick={submitHandler}
                  >
                    Save
                  </button>
                )}
              </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default BulkEditModal;
