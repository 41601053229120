import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { getSection, getAllSectionWithReports } from '../../../services/api/queryService'
import { useQuery } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';

/** Components */
import SummaryTab from './SummaryTab'

const { localStorage } = window;

const SalesPage = ({manufacturers}) => {
  const location = useLocation();
  const [activeSection, setActiveSection] = useState(null)
  const [prevTab, setPrevTab] = useState(null)

  const selectedSection = new URLSearchParams(location.search).get('section');
  const selectedSubsection = new URLSearchParams(location.search).get('subsection');
  const user = useSelector(state => state.auth.user)

  const { data } = useQuery(getAllSectionWithReports, {
    variables: {
      section: selectedSection,
      subsection: selectedSubsection.replaceAll('-', ' '),
      filter: user.id
    }
  });

  const powerbiToken = localStorage.getItem('powerbi_token')

  const powerBiTokenGenerator = (exp_datetime) => {
    if(exp_datetime < +(new Date().getTime()).toString().substring(0, exp_datetime.length)){
      const appUri = 'https://azure-api.ebazaaris.io/api/power-bi'
      fetch(appUri)
      .then(response => response.json())
      .then(data => {
          localStorage.setItem('powerbi_token', JSON.stringify({
            token: data.access_token,
            expires_on:  data.expires_on
          }))

          setAccessToken(data.access_token)
      });
    }
  }


  const [accessToken, setAccessToken] = useState('')
  const [reports, setReports] = useState([])
  const history = useHistory();

  const selectedTab = new URLSearchParams(location.search).get('tab');

  useEffect(() => {
    if(powerbiToken){
      setAccessToken(JSON.parse(powerbiToken).token)
    }

  }, [])

  const tabStandartize = (str) => {
    if(str){
      str = str.replaceAll(/\s+/g, '-');
      str = str.toLowerCase()
    }
    return str;
  }

  useEffect(() => {
    if(data != undefined && reports.length == 0){
      data.getAllSectionWithReports.sort(function(a, b) {
        return a.user_tab_position - b.user_tab_position;
      })
      
      setReports([...data.getAllSectionWithReports])

      // setReports([...data.getAllSectionWithReports.filter(x => x.report)])

      let activeSectionData = null
      if(selectedTab){
        activeSectionData = {...data.getAllSectionWithReports.filter(x => x.report && tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection && tabStandartize(x.tab) === selectedTab)[0]}
        setActiveSection(activeSectionData)
      }else{
        activeSectionData = {...data.getAllSectionWithReports.filter(x => x.report && tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection)[0]}
        setActiveSection(activeSectionData)
      }
      
      history.push(`/analytics?section=${tabStandartize(selectedSection)}&subsection=${tabStandartize(selectedSubsection)}&tab=${selectedTab ? selectedTab : tabStandartize(data.getAllSectionWithReports.filter(x => x.report && tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection)[0].tab)}&id=${activeSectionData?.report?.id}`);
    }

  }, [data, manufacturers])


  // useEffect(() => {
  //   if(reports && reports.length > 0){
  //     if(selectedTab){
  //       tabClickHandler(selectedTab)
  //     }
      
  //     history.push(`/analytics?section=${tabStandartize(selectedSection)}&subsection=${tabStandartize(selectedSubsection)}&tab=${selectedTab ? selectedTab : tabStandartize(reports.filter(x => tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection)[0].tab)}`);
  //   }

  // }, [selectedTab])

  const tabClickHandler = (tabId, indx) => {

    let f = reports.find(x => x.report && x.report.id == tabId)

    if(!f){
      f = reports.filter(x => tabStandartize(x.section) == tabStandartize(selectedSection) && tabStandartize(x.subsection) == tabStandartize(selectedSubsection))[indx]
    }

    history.push(`/analytics?section=${tabStandartize(selectedSection)}&subsection=${tabStandartize(selectedSubsection)}&tab=${tabStandartize(f.tab)}&id=${tabId}`);
    
    setActiveSection({...f})
      
    if(powerbiToken){
      powerBiTokenGenerator(JSON.parse(powerbiToken).expires_on)
    }else{
      powerBiTokenGenerator(1087178)
    }
  };

  return (
    <>
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box reportPage">
            <div className="row">
              <div className={activeSection && activeSection.showExplanation === 1 ? 'col-10 x-scroll-custom' : 'col-12 x-scroll-custom' }>
                <div className="product-tabs">
                  {reports && reports.filter(x => tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`tab ${selectedTab === tabStandartize(item.tab) ? 'active' : ''}`}
                        onClick={tabClickHandler.bind(null, tabStandartize(item.report?.id), index )}
                      >
                        {item.tab}
                      </div>
                    )
                  })}
                  {reports && reports.length > 0 && (
                    <div className="tab-bar" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}

      {/* Tabs */}

      <div className='reportBody'>
        {reports.filter(x => tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection && tabStandartize(x.tab) === selectedTab)[0] &&  reports.filter(x => tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection && tabStandartize(x.tab) === selectedTab)[0].report && !!reports.filter(x => tabStandartize(x.section) == selectedSection && tabStandartize(x.subsection) == selectedSubsection && tabStandartize(x.tab) === selectedTab)[0].report.showFlag && 
          <div className="alert alert-warning  w-100">
                <i className='fas fa-info-circle mr-2'></i>
                <strong > Beta version</strong>
                <span> | This is a beta version that might still include some issues e.g. in visualization, data refresh or metric calculation. We are busy working on improving them and are happy to receive your feedback!</span>
          </div>
        }

        {reports && reports.length > 0 && (
            <SummaryTab reports={reports} accessToken={accessToken} manufacturers={manufacturers} />    
        )}
        { reports && reports.length == 0 && history.action == 'POP' && (
          <div className='init_loader'>
            <div className="spinner">Spinner</div>
            <p>It might take a few moments to load the page</p>
          </div>
        )}

      </div>
    </>
  );
};





const style = {
  div: {
      height: '350px'
  },
  img : {
      height: '100%'
  }
}


const customStyles = {
  control: base => ({
    ...base,
    height: 30,
    minHeight: 30,
    fontSize: '12px'
  })
};


export default SalesPage;
