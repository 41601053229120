import React, { createRef, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
/** Redux */
import { useSelector, useDispatch } from 'react-redux';
/** Apollo */
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
/** Services */
import {
  me as PROFILE,
  getTranslations as GET_TRANSLATION_LIST,
  searchProducts as QUERY,
  getManufacturersList as MANUFACTURERS,
  getSellers
} from '../../../../services/api/queryService';
import { useDebounce } from 'use-debounce';
import {
  convertManufacturerDataToOptions,
  convertSellerDataToOptions,
} from '../../../../services/util/dataHandlerService';

const setCookie = (cookieName, cookieValue, hourToExpire) => {
  let date = new Date();
  date.setTime(date.getTime()+(hourToExpire*60*60*1000));
  document.cookie = cookieName + " = " + cookieValue + "; expires = " +date.toGMTString();
}

const HeaderProfile = () => {
  /** Initial Redux Data / Functions */
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState('')
  const [products, setProducts] = useState([])
  const { user_type, manufacturers } = useSelector(state => state.auth.user);
  
  const [searchingKeyword] = useDebounce(keyword, 400);
  const { loading: profileLoading, data, error } = useQuery(PROFILE);
  const [ loadSearchedProducts, { loading, data: productsData, refetch }] = useLazyQuery(QUERY, {
    variables: {
      keyword: searchingKeyword,
    }
  });
  const multiselectRef = createRef();
  const sellerMultiselectRef = createRef()
  const [dropdownManufacturers, setDropdownManufacturers] = useState([]);
  const [dropdownSellers, setDropdownSellers] = useState([]);
  const [activeManufacturers, setActiveManufacturers] = useState([]);
  const [activeSellers, setActiveSellers] = useState([]);
  const { loading: loadingManufacturers, data: manufacturersData, error: errorManufacturers } = useQuery(MANUFACTURERS, {
    variables: {
      order: 'type',
    }
  });

  const { loading: loadingSellers, data: sellersData, error: sellersError } = useQuery(getSellers, {
    variables: { order: 'id' }
  });

  useEffect(() => {
    if(searchingKeyword.length > 1){
      loadSearchedProducts()
    }
  }, [searchingKeyword])
  useEffect(() => {
    if(productsData && productsData.searchProductsList && searchingKeyword.length > 0){
      setProducts([...productsData.searchProductsList])
    }else{
      setProducts([])
    }
  }, [loading, productsData])



  useEffect(() => {
    const storageActiveManufacturers = JSON.parse(localStorage.getItem('active_manufacturers'));
    const storageActiveSellers = JSON.parse(localStorage.getItem('active_sellers'));
    if(storageActiveManufacturers){
      setActiveManufacturers(convertManufacturerDataToOptions(storageActiveManufacturers));
    }

    if(storageActiveSellers){
      setActiveSellers(convertSellerDataToOptions(storageActiveSellers));
    }
    
  }, []);
  const service_types = [
    { label: 'Acceleration', value: 'Acceleration' },
    { label: 'Service', value: 'Service' },
    { label: 'Service Plus', value: 'Service Plus' }
]

  useEffect(() => {
    if(manufacturersData && !loadingManufacturers){
      setDropdownManufacturers(manufacturersData.manufacturers)
      const acceleration = [{
        name: 'Acceleration',
        id: 'Acceleration',
        service_type: 'Acceleration'
      }, ...manufacturersData.manufacturers.filter(x => x.service_type == 'Acceleration')]
      const service = [{
        name: 'Service',
        id: 'Service',
        service_type: 'Service'
      }, ...manufacturersData.manufacturers.filter(x => x.service_type == 'Service')]
      const service_plus = [{
        name: 'Service Plus',
        id: 'Service_Plus',
        service_type: 'Service Plus'
      }, ...manufacturersData.manufacturers.filter(x => x.service_type == 'Service Plus')]
      setDropdownManufacturers([...acceleration, ...service, ...service_plus])
    } else {
      setDropdownManufacturers([])
    }
  }, [loadingManufacturers, manufacturersData])

  useEffect(() => {
    if(sellersData && !loadingSellers && data){
      let sellerData = []
      let vendorData = []
      const sellerIds =  data.me.sellers.map(x => x.seller.id)

      if(data.me.user_type === 1 || data.me.user_type === 4){
        sellerData = [...sellersData.sellers.filter(x => x.account_type == 'Seller' && sellerIds.includes(x.id))]
        vendorData = [...sellersData.sellers.filter(x => x.account_type == 'Vendor' && sellerIds.includes(x.id))]
      }else{
        sellerData = [...sellersData.sellers.filter(x => x.account_type == 'Seller')]
        vendorData = [...sellersData.sellers.filter(x => x.account_type == 'Vendor')]
      }

      const seller = [{
        name: 'Seller',
        id: 'seller',
        account_type: 'Seller'
      },  ...sellerData]

      const vendor = [{
        name: 'Vendor',
        id: 'vendor',
        account_type: 'Vendor'
      }, ...vendorData]

      console.log(seller, vendor)

      setDropdownSellers([...seller, ...vendor])
    } else {
      setDropdownSellers([])
    }
  }, [loadingSellers, sellersData, data])

  const changeMultiSelectHandler = (options, data) => {
    //Save selected to localstorage
    let filteredManufacturers = options ? dropdownManufacturers.filter(manu => options.map(option => option.value).includes(manu.id)) : [];
    if(data.value === 'Acceleration'){
      filteredManufacturers = options ? dropdownManufacturers.filter(manu => manu.service_type == 'Acceleration') : [];
    }
    if(data.value === 'Service'){
      filteredManufacturers = options ? dropdownManufacturers.filter(manu => manu.service_type == 'Service') : [];
    }
    if(data.value === 'Service_Plus'){
      filteredManufacturers = options ? dropdownManufacturers.filter(manu => manu.service_type == 'Service Plus') : [];
    }
    setActiveManufacturers(convertManufacturerDataToOptions(filteredManufacturers));
    localStorage.setItem('active_manufacturers', JSON.stringify(filteredManufacturers));
    window.dispatchEvent(new Event("activeManufacturerChange"));
  };

  const changeSellerMultiSelectHandler = (options, data) => {
    //Save selected to localstorage
    let filteredSellers = options ? dropdownSellers.filter(manu => options.map(option => option.value).includes(manu.id)) : [];
    if(data.value === 'seller'){
      filteredSellers = options ? dropdownSellers.filter(manu => manu.account_type == 'Seller') : [];
    }
    if(data.value === 'vendor'){
      filteredSellers = options ? dropdownSellers.filter(manu => manu.account_type == 'Vendor') : [];
    }

    setActiveSellers(convertSellerDataToOptions(filteredSellers))
    localStorage.setItem('active_sellers', JSON.stringify(filteredSellers));
    window.dispatchEvent(new Event("activeSellerChange"));
  };

  const removeMultiSelectSellerHandler = (options, data) => {
    //Save selected to localstorage
    let filteredSellers = options ? dropdownSellers.filter(manu => options.map(option => option.value).includes(manu.id)) : [];
    if(data.value === 'seller'){
      filteredSellers = options ? filteredSellers.filter(manu => manu.account_type != 'Seller') : [];
    }
    if(data.value === 'vendor'){
      filteredSellers = options ? filteredSellers.filter(manu => manu.account_type != 'Vendor') : [];
    }

    setActiveSellers(convertSellerDataToOptions(filteredSellers))
    localStorage.setItem('active_sellers', JSON.stringify(filteredSellers));
    window.dispatchEvent(new Event("activeSellerChange"));
  };

  const removeMultiSelectHandler = (options, data) => {
    //Save selected to localstorage
    let filteredManufacturers = options ? dropdownManufacturers.filter(manu => options.map(option => option.value).includes(manu.id)) : [];
    if(data.value === 'Acceleration'){
      filteredManufacturers = options ? filteredManufacturers.filter(manu => manu.service_type != 'Acceleration') : [];
    }
    if(data.value === 'Service'){
      filteredManufacturers = options ? filteredManufacturers.filter(manu => manu.service_type != 'Service') : [];
    }
    console.log(data.value)
    if(data.value === 'Service_Plus'){
      filteredManufacturers = options ? filteredManufacturers.filter(manu => manu.service_type != 'Service Plus') : [];
    }
    
    setActiveManufacturers(convertManufacturerDataToOptions(filteredManufacturers));
    localStorage.setItem('active_manufacturers', JSON.stringify(filteredManufacturers));
    window.dispatchEvent(new Event("activeManufacturerChange"));
  };


  const searchHandler = e => {
    const k = e.target.value
    setKeyword(k);
    if(k.length == 0){
      setProducts([])
    }
  }
  useEffect(() => {
    const handleEsc = (event) => {
       if (event.keyCode === 27) {
         setKeyword('')
        setProducts([])
      }
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
  useEffect(() => {
    if (data) {
      if (data.me) {
        const userData = { ...data.me };
        userData.manufacturers = data.me.manufacturers.map(manu => manu.manufacturer.id);
        userData.manufacturersArray = data.me.manufacturers
        if(userData.manufacturers.length > 0){
          setDropdownManufacturers(data.me.manufacturers);
        }

        setCookie('_user_token', userData.api_token, 24*7)

        
        userData.roles = data.me.roles.map(role => {
          const updatedRole = {
            ...role,
            permissions: role.permissions.map(permission => ({
              ...permission,
              id: +permission.id.slice(0, permission.id.lastIndexOf(role.id))
            }))
          };
          updatedRole.permissions.forEach(permission => delete permission.__typename);
          return updatedRole;
        });
        delete userData.__typename;
        dispatch({
          type: 'PROFILE',
          payload: userData
        });
        const checkIfLogged = localStorage.getItem('loggedIn');
        let payload = {}
        if(checkIfLogged === null){
          payload = {
            type: 'success',
            message: ` Welcome, ${data.me.first_name}`
          }
          localStorage.setItem("loggedIn", 1);
        }
        dispatch({
          type: 'UPDATE',
          payload: payload
        });
      } else {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('loggedIn')

        window.location.href = '/login';
      }
    }
  }, [data, profileLoading, error, dispatch]);



  return (
    <div className="d-flex align-items-center ">
      {/* { !refreshBtnClicked && (
        <button type="button" className="refreshReports" onClick={refreshReports} title="Refresh all reports">
          <img src="/assets/images/refresh.png" />
        </button>
      )} */}
      
      {
        data && data.me && data.me.user_type === 2 && (
        <div id="manufacturer-dropdown" className="card flex justify-content-center dropdown"> {/* color: '#707070' */}
          <Multiselect
            options={convertSellerDataToOptions(dropdownSellers) }
            ref={sellerMultiselectRef}
            selectedValues={activeSellers}
            groupBy='type'
            displayValue="label"
            onSelect={changeSellerMultiSelectHandler}
            onRemove={removeMultiSelectSellerHandler} 
            placeholder='Filter by channel...'
            hidePlaceholder
            showCheckbox
            showArrow
            selectedValueDecorator={ v => v.length > 10 ?  sellerMultiselectRef && sellerMultiselectRef.current !== null ? `(` + sellerMultiselectRef.current.getSelectedItemsCount() + `)` + ` ${v.substring(0,10)}...` : `${v.substring(0,10)}...` : (`(` + activeSellers.length + `) ` + v) }
            /> 
        </div>
      )}
      {
        data && data.me && data.me.user_type === 2 && (
        <div id="manufacturer-dropdown" className="card flex justify-content-center dropdown"> {/* color: '#707070' */}
          <Multiselect
            options={convertManufacturerDataToOptions(dropdownManufacturers) }
            ref={multiselectRef}
            selectedValues={activeManufacturers}
            groupBy='service_type'
            displayValue="label"
            onSelect={changeMultiSelectHandler}
            onRemove={removeMultiSelectHandler} 
            placeholder='Filter by manufacturers...'
            hidePlaceholder
            showCheckbox
            showArrow
            selectedValueDecorator={ v => v.length > 10 ?  multiselectRef && multiselectRef.current !== null ? `(` + multiselectRef.current.getSelectedItemsCount() + `)` + ` ${v.substring(0,10)}...` : `${v.substring(0,10)}...` : (`(` + activeManufacturers.length + `) ` + v) }
            /> 
        </div>
      )}       
      { user_type != 3  && ( 
        <li className="dropdown notification-list">
          <input
              type="text"
              id="product_name"
              className="form-control"
              required
              placeholder='Search PID, SKU, ASIN...'
              value={keyword}
              onChange={searchHandler}
              style={{paddingRight: '40px'}}
            />
            <div className="search_icon">
              <img src='/assets/images/search.png'  />
            </div>
            { products && products.length > 0 && keyword.length > 0 && !loading && (
              <div className='search_response'>
                {products.map(x => ( 
                  <a href={`/products/${x.slug}/edit`}>{ x.slug }</a>
                ))}
              </div>
            ) }
        </li>
      )}
    </div>
  );
};
export default withRouter(HeaderProfile);