import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { PRODUCT_STATUSES, STATUSES } from '../../lib/constants/fields';

import './DetailsHeader.scss';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { getPortfolios } from '../../services/api/queryService';
import { assignTags } from '../../services/api/mutationService';

const DetailsHeader = ({ data, renderFor, parentProduct }) => {

  const [toggleAsin, setToggleAsin] = useState(false)
  const [toggleSku, setToggleSku] = useState(false)
  const { loading, data: portfolios, refetch } = useQuery(getPortfolios, {
    variables: {
      manufacturer: data?.manufacturer?.name
    }
  });

  const [selectedTags, setSelectedTags] = useState([]);
  const [tags, setTags] = useState([])
  const [keyword, setKeyword] = useState(null)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const [assignTagsMutation, {loading: tagLoading}] = useMutation(
    assignTags
);


  const checkboxChangeHandler = async k => {
    const d = [...selectedTags]
    if(k == 'all'){
      if(d.length === tags.length){
        d.splice(0, tags.length)
      }else{
        tags.map(x => {
          if(d.indexOf(x.id) == -1){
            d.push(x.id)
          }
        })
      }
    }else{
      if(d.indexOf(k) > -1){
        d.splice(d.indexOf(k), 1)
      }else{
        d.push(k)
      }
    }

    const tagsInput = tags.filter(w => d.some(x => x == w.id)).map(x => x.name)


    try {
      const { response } = await assignTagsMutation({
        variables: {
          tags: tagsInput,
          pid: [data.product_parent_id],
        }
      });
    } catch (err) {
      console.error(err)
    }

    setSelectedTags([...d])
  }


  let asin, sku = []

  if(data.productMarketplaces){
    asin = data.productMarketplaces.filter(i => i.marketplace_id_type == 'ASIN')
    sku = data.productMarketplaces.filter(i => i.marketplace_id < 18 && i.marketplace_id_type == 'SKU')
  }

  useEffect(() => {
    if(portfolios){
        setTags([...portfolios.getPortfolios])

        const d = []

        data.tags.map(x => {
          if(x.portfolio)
            d.push(x.portfolio.id)
        })

        setSelectedTags([...d])
    }
  }, [portfolios])



  switch (renderFor) {
    
    case 'products':
      const listing = data.listing

      listing.sort((a, b) => a.marketplace_id - b.marketplace_id)
    
      const images = listing.filter(x => x.images.length > 0).map(x => x.images)
    
      const image = images.length !== 0 ? images[0][0].filename : null
      
      return (
        <>
          <div className="container col-12 details-header product_details_header">
            <div className="row col-10 p-0">
              <div className="col-md-3 p-0 mt-1">
                <strong>Manufacturer: </strong>
                {data.manufacturer ? (
                <NavLink to={`/manufacturers/${data.manufacturer.slug}/edit`}>
                  {data.manufacturer.name}
                </NavLink>
                ) : ( <span>-</span> )}
              </div>
              <div className="col-md-3 p-0 mt-1">
                <strong>PID:</strong> {data.slug}
              </div>
              <div className="col-md-3 p-0 mt-1 d-flex flex-wrap">
                { !parentProduct && (
                  <>
                      <div className={ toggleAsin ? 'header_cid_dropdown open_dropdown' : 'header_cid_dropdown' }>

                        { asin && asin.length > 1 && (
                          <div className="toggle_dropdown" onClick={() => setToggleAsin(!toggleAsin)}>
                            <i class="fas fa-caret-down"></i>
                          </div>
                        )}

                        { asin.map(x => (
                            <div className="cid_item">
                              <strong className="mr-1">ASINs:</strong> 
                              <p className='m-0'>{ x.marketplace.name }</p>
                              <a className="mLinkA" href={ `https://${ x.marketplace.name }/dp/${x.marketplace_product_id}` }
                                  target="_blank"
                                >
                                  <img className="mlinkImage ml-1" src="/assets/images/ext-link.png" alt=""/>
                              </a>
                            </div>
                        )) } 

                      </div>
                  </>
                )}
              </div>
              <div className="col-md-3 p-0 mt-1">
                <div style={{ float: 'left', position: 'relative', display: 'flex', width: '100%'}}>
                  <strong style={{ marginRight: '20px' }}>Portfolio:</strong> 

                  <div className="custom__select header__dropdown__select" style={{minWidth: 'calc(100% - 70px)', width: 'calc(100% - 70px)'}}>
                        <div className="custom__select_inner" style={{ overflow: 'hidden' }} onClick={() => setDropdownOpen(true)}>
                            
                            { loading && (
                                <div className="spinner" style={{ width: '28px', height: '28px' }} > Spinner</div>
                            ) }

                            { !loading && selectedTags.length === 0 && (
                                <p className="custom__select__placeholder">Select/Input ...</p>
                            ) }

                            { selectedTags.length > 0 && (
                                <div className="custom__select__tags">
                                    <>
                                        { selectedTags.map(x => (
                                            <div className="custom__select__tag">
                                                <span className='custom__select__title'>{ tags.find(w => w.id == x).name }</span>
                                                <span className='custom__select__tag_close'>x</span>
                                            </div>
                                        )) }
                                    </>
                                </div>
                            ) }

                        </div>
                        <div className="custom__select_icon" style={{ backgroundColor: "#f2f5f7" }} onClick={() => setDropdownOpen(true)}>
                            <i class="mdi mdi-chevron-down"></i>
                        </div>

                        { dropdownOpen && (
                            <div className="custom__select__dropdown">
                                <div className="custom__select__dropdown__header">
                                    <div className="custom__select__dropdown__header__tr__checkbox">
                                        <input type="checkbox" checked={ tags.length === selectedTags.length && selectedTags.length > 0 } onChange={() => checkboxChangeHandler('all')} />
                                    </div>
                                    <div className="custom__select__dropdown__header__tr__search" style={{width: 'calc(100% - 10px)'}}>
                                        <input type="text" style={{width: '100%'}} onChange={(e) => setKeyword(e.target.value)} />
                                        
                                        <i class="mdi mdi-magnify"></i>
                                    </div>
                                    <div className="custom__select__dropdown__header__tr__close" onClick={() => setDropdownOpen(false)}>
                                        <i class="mdi mdi-close"></i>
                                    </div>
                                </div>
                                <div className="custom__select__dropdown__body">
                                    { !loading && tags && tags.map(x => (
                                        <div onClick={() => checkboxChangeHandler(x.id)} className={`custom__select__dropdown__body__item ${ selectedTags.includes(x.id) ? 'checked' : '' }`} style={{ display: (keyword && x.name.toLowerCase().includes(keyword.toLowerCase())) || (!keyword || keyword?.length == 0)  ? 'flex' : 'none' }}>
                                            <div className={`custom__select__dropdown__body__item__checkbox`}>
                                                <input type="checkbox" checked={ selectedTags.includes(x.id) }  />
                                            </div>
                                            <div className="custom__select__dropdown__body__item__tag">
                                                <span>{x.name}</span>
                                            </div>
                                        </div>
                                    )) }

                                    { loading && (
                                        <div className="spinner">Spinner</div>
                                    ) }
                                </div>
                                <div className="custom__select__dropdown__footer">
                                    <div className="custom__select__dropdown__footer__divider"></div>
                                </div>
                            </div>
                        ) }

                    </div>
                </div>
              </div>
              <div className="col-md-3 p-0 mt-2">
                <strong>Brand:</strong> {data.brand ? data.brand.name : ''}
              </div>
              <div className="col-md-3 p-0 mt-2">
                {!parentProduct && (
                  <>
                    <strong>Commission type:</strong> {data.manufacturer.commission_type}
                  </>
                )}
              </div>
              <div className="col-md-3 p-0 mt-2">
                {!parentProduct && (
                  <>
                    {sku && sku.length > 0 && ( 
                      <div className={ toggleSku ? 'header_cid_dropdown open_dropdown' : 'header_cid_dropdown' }>

                      { sku && sku.length > 1 && (
                        <div className="toggle_dropdown" onClick={() => setToggleSku(!toggleSku)}>
                          <i class="fas fa-caret-down"></i>
                        </div>
                      )}

                        { sku.map(x => (
                            <div className="cid_item">
                              <strong className="mr-1">SKUs:</strong> 
                              <img className='flag' src={`/assets/images/flags/${x?.marketplace.name.split('.')[1]}.svg`} alt='' />
                              <p className='m-0'>{ x.marketplace_product_id }</p>
                              <a className="mLinkA" href={ `https://sellercentral.amazon.de/inventory/ref=xx_invmgr_favb_xx?tbla_myitable=sort:%7B%22sortOrder%22%3A%22DESCENDING%22%2C%22sortedColumnId%22%3A%22date%22%7D;search:${x.marketplace_product_id};pagination:1;` }
                                  target="_blank"
                                >
                                  <img className="mlinkImage ml-1" src="/assets/images/ext-link.png" alt=""/>
                              </a>
                            </div>
                        )) } 

                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="col-md-3 p-0 mt-2 d-flex justify-content-between">
                <div>
                  <strong>Product type:</strong> {data.type ? data.type.name : ''}
                </div>
                <div>
                  <strong style={{ marginRight: '8px' }}>Status:</strong> {PRODUCT_STATUSES[data.status]}
                </div>
                {/* <div className="ml-1 tooltipHeading" style={{float: 'left', marginTop: '3px'}}>
                  { 
                    data.status === 4 ? <div className="u_infoButton"><i className='fas fa-info-circle red-info-icon'></i><div className="infoBox"><span>The product is active but changes will not be synced due to existing errors.</span></div></div>
                    : '' 
                  }
                </div> */}

              </div>
              <hr className="mb-3" style={{ background: '#dedede', height: '1px' }} />
            </div>
            <div className="row col-2 p-0">
                { image ? (
                  <div className="previewImage">
                    <div className="expand-image" onClick={() => window.open(`https://documentsupload.blob.core.windows.net/pictures/${image}`)}>
                      <img src="/assets/images/expand.svg" alt=""/>
                    </div>
                    <img src={`https://documentsupload.blob.core.windows.net/pictures/${image}`} alt="" />
                  </div>
                ) : (

                  <div className="noImage">
                    <p>{'No image added'} <br /> {'to content -> images'}</p>
                  </div>
                )}
                  
            </div>
          </div>
        </>
      );
    case 'manufacturers':
      return (
        <div className="mb-2">
          <div className="row">
            <div className="col-12 d-flex px-0">
              <div className="col-md-4">
                <strong>Brands: </strong>
                {data.brands.join(', ')}
              </div>
              <div className="col-md-4">
                <strong>Commission type: </strong>
                {data.commission_type}
              </div>
              <div className="col-md-4 text-right">
                <div className="d-flex justify-content-between">
                  <div className='d-flex'>
                    <strong style={{ marginRight: '20px', display: 'inline-block' }}>Sales status: </strong>
                    {STATUSES[data.status]}
                  </div>
                  <div className='d-flex'>
                    <strong style={{ marginRight: '20px', display: 'inline-block' }}>Advertising status: </strong>
                    {STATUSES[data.advertising_status]}
                  </div>
                </div>
              </div>
            </div>
          </div>

          { data.commission_type === 'Goods consignment' && (
            <div className="row">
              <div className="col-12 d-flex px-0">
                <div className="col-md-4"></div>
                <div className="col-md-5">
                  <strong>Commission: </strong>
                  {data.commission_percentage}% of net revenues
                </div>
                <div className="col-md-3"> </div>
              </div>
            </div>
          ) }
        </div>
        );
    default:
      return null;
  }
};

export default DetailsHeader;
