import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

/** Style */
import './Pagination.css';

const Pagination = ({ data }) => {
  const [routes, setRoutes] = useState([1, 2, 3, 4, 5]);

  const shown = () => {
    return (data.currentPage - 1) * data.perPage;
  };

  const generateUrl = () => {
    let url = '';

    if (data.perPage) {
      url += `&count=${data.perPage}`;
    }

    if (data.queryStrFilter) {
      url += `&${data.queryStrFilter}`;
    }

    if (data.dateFrom) {
      url += data.dateFrom;
    }

    if (data.dateTo) {
      url += data.dateTo;
    }

    if (data.search) {
      url += `&keyword=${data.search}`;
    }

    if (data.tab) {
      url += `&tab=${data.tab}`;
    }

    return url;
  };

  const prevPage = () => {
    return data.currentPage > 1 ? data.currentPage - 1 : data.currentPage;
  };

  const nextPage = () => {
    return data.currentPage < data.lastPage ? data.currentPage + 1 : data.currentPage;
  };

  useEffect(() => {
    const routesArray = [];
    const current = data.currentPage;

    let first = 1;
    let last = 1;

    if ((current > 0 && current < 3) || (current >= 3 && current < 5)) {
      last = data.lastPage < 5 ? data.lastPage : 5;
    } else if (current >= 5) {
      first = current - 2;
      last = current + 2;

      if (data.lastPage < current + 2) {
        last = data.lastPage;
        first = last - 4;
      }
    }

    for (let i = first; i <= last; i++) {
      routesArray.push(i);
    }

    setRoutes(routesArray);
  }, [data]);

  return (
    <div className="row pagerComponent">
      <div className="col-sm-12 col-md-5">
        <div className="dataTables_info" id="key-datatable_info" role="status" aria-live="polite">
          Showing {shown()} to {data.hasMorePages ? shown() + data.perPage : data.total} from{' '}
          {data.total}
        </div>
      </div>

      <div className="col-sm-12 col-md-7">
        <div className="dataTable_paginate paging_simple_numbers" id="key-datatable_paginate">
          <ul className="pagination float-right pagination-rounded">
            {/** First Page */}
            <li className="paginate_button page-item ">
              <Link to={`?page=1${generateUrl()}`} className="page-link">
                <strong>{'|<'} </strong>
              </Link>
            </li>

            {/** Previes Page */}
            <li className="paginate_button page-item previous" id="key-datatable_previous">
              <Link
                to={`?page=${prevPage()}${generateUrl()}`}
                aria-controls="key-datatable"
                data-dt-idx="0"
                tabIndex="0"
                className="page-link"
              >
                <i className="mdi mdi-chevron-left"></i>
              </Link>
            </li>

            {/** Displayed Pages */}
            {routes.map((item, index) => {
              return (
                <li
                  className={`paginate_button page-item ${
                    item === data.currentPage ? 'active' : ''
                  }`}
                  key={index}
                >
                  <Link
                    to={`?page=${item}${generateUrl()}`}
                    aria-controls="key-datatable"
                    data-dt-idx="1"
                    tabIndex="0"
                    className="page-link"
                  >
                    {item}
                  </Link>
                </li>
              );
            })}

            {/** Next Page */}
            <li className="paginate_button page-item next" id="key-datatable-next">
              <Link
                to={`?page=${nextPage()}${generateUrl()}`}
                aria-controls="key-datatable"
                data-dt-idx="7"
                tabIndex="0"
                className="page-link"
              >
                <i className="mdi mdi-chevron-right"></i>
              </Link>
            </li>

            {/** Last Page */}
            <li className="paginate_button page-item ">
              <Link to={`?page=${data.lastPage}${generateUrl()}`} className="page-link">
                <strong>{'>|'} </strong>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
