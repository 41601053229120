import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ModalError from '../../../components/modal/ModalError';
import { updateProduct } from '../../../services/api/mutationService';


import { getAttributes, getProductCategoriesSettings } from '../../../services/api/queryService';
import { number } from 'prop-types';

const ProductAttributes = ({ selectData, parentProduct, productData, refetch, update }) => {
  const [updateProductCategoryMutation, { loading, error: updateError }] = useMutation(
    updateProduct
  );
  
  let { data : getAttributesData } = useQuery(getAttributes);

  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true


  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [formData, setFormData] = useState({
    manufacturer_id: productData.manufacturer && productData.manufacturer.id,
    manufacturer_slug: productData.manufacturer && productData.manufacturer.slug,
    brand_id: productData.brand && productData.brand.id,
    status: productData.status,
    product_type_id: productData.type && productData.type.id,
    product_name: productData.product_name,
    product_details: productData.product_details,
    product_contents: productData.product_contents,
    packaging_unit_id: productData.packagingUnit && productData.packagingUnit.id,
    gtin: productData.gtin,
    manufacturer_product_id: productData.manufacturer_product_id,
    base_content_volume: productData.base_content_volume,
    base_unit: productData.base_unit,
    pzn: productData.pzn,
    attribute_values:  productData.attribute_values ? productData.attribute_values.split(',') : [],
  });

  const [changesMade, setChangesMade] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorArray, setErrorArray] = useState([]);
  const [attributes, setAttributes] = useState([]);


  useEffect(() => {
    if(attributes.titles && attributes.titles.length && attributes.dropdowns){
      let newData = []

      attributes.titles.map(x => {    
        let v = attributes.dropdowns[x].find(w => formData.attribute_values.indexOf(w.value) > -1)
        if(v){
          newData[x] = v.value
        }

        setFormData({
          ...newData,
          ...formData
        });
      })
    }
  }, [attributes])


  useEffect(() => {
    if(getAttributesData){
      const _arr = []

      getAttributesData.attributes.filter(x => +x.manufacturer_id === +formData.manufacturer_id).map(x => {
        if(_arr.indexOf(x.attribute) < 0){
          _arr.push(x.attribute)
        }
      })


      const attributeDropdown = getAttributesData.attributes.filter(x => +x.manufacturer_id === +formData.manufacturer_id && _arr.indexOf(x.attribute) > -1)
      

      const array = []
      _arr.filter(x => productData.parentProduct.parentProduct.attributes.split(',').indexOf(x) != -1).map(w => {
        array[w] = attributeDropdown.filter(x => x.attribute === w).map(x => ( {label: x.attribute_name, value: x.id }))
      })


      setIsLoading(false)
      setAttributes({titles: [..._arr.filter(x => productData.parentProduct.parentProduct.attributes.split(',').indexOf(x) != -1)], dropdowns: array})

    }
  }, [getAttributesData])



  const submitHandler = async event => {
    
    const arr = [];

    event.preventDefault();
    
    setChangesMade(false);
    const preparedFormData = {};

    if(arr.length === 0){
      try {

        let attributeValues = []
        attributes.titles.map(x => {
          if(formData[x]){
            attributeValues.push(formData[x])
          }
        })

        const { data } = await updateProductCategoryMutation({
          variables: { ...formData, attribute_values: attributeValues.join(','), product_parent_id: productData.product_parent_id }
        });

        if (data.updateProduct.warning) {
          let warningText = JSON.parse(data.updateProduct.warning);
          setErrorArray([...warningText])
        }


        if (!data.updateProduct) {
          throw new Error('Server responded with no data');
        }

        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully updated'
          }
        });
        refetch();
      } catch (err) {
        setError(err);
      }
    }
  };


  const changeSelectHandler = (option, data) => {
    setChangesMade(true);

    setFormData({
      ...formData,
      [data.name]: option ? option.value : null
    });


  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);



  return (
    <>
      <form onSubmit={submitHandler}>
        { errorArray && errorArray.length ? errorArray.map((item, index) => (
              <ModalError key={index} error={item} setError={setErrorArray} />
          )) : null }
        <div className="row row-cols-4">
          { attributes && attributes.titles && attributes.titles.map((item, index) => (
            <div className="col-md-3">
              <label htmlFor="product_category_food">{ item }</label>
                <Select
                  name={item}
                  options={attributes.dropdowns[item]}
                  value={formData[item] ? attributes.dropdowns[item].find(w => w.value == formData[item]) : null}
                  onChange={changeSelectHandler}
                  isClearable={true}
                  />
            </div>
          ))}
        </div>
      </form>
    </>
  );
};

export default ProductAttributes;
