import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { updateListing } from '../../../services/api/mutationService';
import { PRODUCT_STATUS_OPTIONS } from '../../../lib/constants/fields';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import ReactQuill, { Quill } from 'react-quill';

import 'react-quill/dist/quill.snow.css';

const ProductRichContent = ({ selectData, productData, product, activeMarketplace, refetch, update, onLisitingChange, onPreview }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );
  const dispatch = useDispatch();
  const [error, setError] = useState();



  const [formData, setFormData] = useState({
    rich_content: productData ? product.listing.find(x => x.marketplace_id == activeMarketplace).rich_content??"" : ""
  });
  
  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      ['rich_content']: event.target.value
    });


    productData.rich_content = event.target.value
  };

  useEffect(() => {
    setFormData({
      rich_content: productData.rich_content
    })
  }, [productData])

  const submitHandler = async event => {
    event.preventDefault();

    try {
      const { data } = await updateListingMutation({
        variables: {
          input: formData,
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }


  return (
    <form onSubmit={submitHandler}>
      <div className="row row-cols-4" id="listing-description">
        <div className="col-12">
          <div className='d-flex justify-content-between align-items-center mb-3'>
            
            <p className="mb-0">
              HTML code input
              {/* Characters: <span className={formData.description ? formData.description.replace(/<\/?[^>]+(>|$)/g, "").length : 0 < settings.characters_min || settings.characters_max < formData.description ? formData.description.replace(/<\/?[^>]+(>|$)/g, "").length : 0 ? 'text-danger' : 'text-green'}> {formData.description ? formData.description.replace(/<\/?[^>]+(>|$)/g, "").length : 0}</span>  ({settings.characters_min} - {settings.characters_max}) */}
            </p>
          </div>
          { productData && (
            <textarea id="custom_textarea" name='rich_content' value={ formData.rich_content } onChange={changeFieldHandler} />
            ) }
        </div>
      </div>
      {update && (
        <>
          {loading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <>
              <button type="submit" className="btn btn-primary px-3 mt-3">
                Save
              </button>

              <button type="button" className="btn btn-secondary px-3 mt-3 ml-2" style={{border: 0}} onClick={() => onPreview(formData.rich_content)}>
                Preview
              </button>
            </>
          )}
        </>
      )}
    </form>
  );
};

export default ProductRichContent;
