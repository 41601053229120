import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import ModalForm from '../../components/modal/ModalForm';
import FormFieldsComponents from './formFields/FormFieldsComponents';

import { STATUSES, CURRENCIES } from '../../lib/constants/fields';

const PimSettingsTabContent = ({
  tabId,
  name,
  query,
  createMutation,
  updateMutation,
  create,
  update
}) => {
  const [itemId, setItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { data, refetch, error } = useQuery(query, {
    variables: {
      order: 'id',
      filter: 'status'
    }
  });

  const FormFieldsComponent = FormFieldsComponents[tabId];

  if (data && tabId === 'marketplaces') {
    data[tabId] = data[tabId].map(item => ({
      ...item,
      tax_rate: typeof item.tax_rate === 'string' ? JSON.parse(item.tax_rate) : item.tax_rate
    }));
  }

  const renderTableHeading = key => {
    if (key === '__typename') {
      return;
    }
    if (key === 'id') {
      return (
        <th key={key} className="text-capitalize text-center">
          ID
        </th>
      );
    }
    if (key === 'currency_id') {
      return (
        <th key={key} className="text-capitalize">
          Currency
        </th>
      );
    }

    if (key === 'manufacturer') {
      return;
    }

    if (key === 'manufacturer_id') {
      return (
        <th key={key} className="text-capitalize">
          Manufacturer
        </th>
      );
    }
    
    return (
      <th key={key} className="text-capitalize">
        {key.replace('_', ' ')}
      </th>
    );
  };

  const renderTableField = (key, item) => {
    if (key === '__typename') {
      return;
    }
    if (key === 'manufacturer') {
      return;
    }
    if (key === 'id') {
      return (
        <td key={key} className="text-center">
          {item[key]}
        </td>
      );
    }
    if (key === 'tax_rate') {
      const converted = item[key].map(i => `${(i * 100).toFixed(2)}%`);
      return <td key={key}>{converted.join(', ')}</td>;
    }
    if (key === 'currency_id') {
      return <td key={key}>{CURRENCIES[item[key]]}</td>;
    }
    if (key === 'status') {
      return <td key={key}>{STATUSES[item[key]]}</td>;
    }
    if (key === 'manufacturer_id') {
      return <td key={key}>{item.manufacturer.name}</td>;
    }
    return <td key={key}>{item[key]}</td>;
  };

  if (error) {
    throw new Error(error);
  }

  if(data){
    console.log(data[tabId])
  }

  return (
    <>
      {!data ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="card">
          <div className="card-body">
            <div>
              {create && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setItemId(null);
                    setModalOpen(true);
                  }}
                >
                  Create New
                </button>
              )}
            </div>

            <div className="table-responsive">
              <table className="table mt-3 mb-0">
                <thead>
                  <tr>
                    {Object.keys(data[tabId][0]).map(key => renderTableHeading(key))}
                    {update && <th className="text-center">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {data[tabId].map(item => (
                    <tr key={item.id}>
                      {Object.keys(item).map(key => renderTableField(key, item))}
                      {update && (
                        <td className="text-center">
                          <i
                            id={item.id}
                            className="fas fa-edit"
                            style={{ cursor: 'pointer' }}
                            data-toggle="modal"
                            data-target="#modal"
                            onClick={e => {
                              setItemId(+e.target.id);
                              setModalOpen(true);
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {modalOpen && (
            <ModalForm setModalOpen={setModalOpen}>
              <FormFieldsComponent
                itemId={itemId}
                createMutationId={createMutation.definitions[0].name.value}
                updateMutationId={updateMutation.definitions[0].name.value}
                data={data[tabId].find(item => +item.id === +itemId)}
                setModalOpen={setModalOpen}
                name={name}
                createMutation={createMutation}
                updateMutation={updateMutation}
                refetch={refetch}
              />
            </ModalForm>
          )}
        </div>
      )}
    </>
  );
};

export default PimSettingsTabContent;
