import React, { useState } from 'react';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';

/** Components */
import ListingSearchTab from './ListingSearchTab';
import ImportJobList from './import/ImportJobList';
import ImportModal from './import/ImportModal';

import './Product.scss';

const Listing = ({ create, update, canDelete }) => {
  const [importModalOpen, setImportModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const selectedTab = new URLSearchParams(location.search).get('tab');

  if (!selectedTab) {
    return <Redirect to="/listings?tab=search" />;
  }

  const tabClickHandler = tabId => {
    history.push(`/listings?tab=${tabId}`);
  };

  return (
    <>
      {/* Start Content */}
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Listings</h4>

            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item active">Listings</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}


      {selectedTab === 'search' && <ListingSearchTab canDelete={false} location={location} />}

      {/* Import Modal */}
      {importModalOpen && <ImportModal setModalOpen={setImportModalOpen} />}
    </>
  );
};

export default Listing;
