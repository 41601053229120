import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updatePharmacyInformation } from '../../../services/api/mutationService';

const DocumentLinkForm = ({ productData, selectData, updateFormData, update }) => {
  const [updatePharmacyInformationMutation, { loading, error: updateError }] = useMutation(updatePharmacyInformation);
  const [error, setError] = useState();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    safety_data_sheet: productData.safety_data_sheet??null,
    gs1_certificate: productData.gs1_certificate??null,
    live_images: productData.live_images??null,
    warnings_and_instructions: productData.warnings_and_instructions??null,
    video_material: productData.video_material??null,
  });
  const [changesMade, setChangesMade] = useState(false);

  const { localStorage } = window;
  
  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);
  

  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });

    const fd = {...formData}
    fd[event.target.id] = event.target.value

    updateFormData({documents: fd})

  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  const options = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 2 }
  ]

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  return (
    <form >
        <fieldset disabled={!update}>
            <div className="row row-cols-4">
                <div className="col-md-3">
                    <div className="form-group  ">
                        <label htmlFor="product_name">
                            Safety Data Sheet 
                        </label>
                        <div className="marketplaceLinkWrapper">
                            <input
                                type="text"
                                id="safety_data_sheet"
                                className={`form-control`}
                                required
                                defaultValue={formData.safety_data_sheet}
                                onChange={changeFieldHandler}
                                disabled={isArchived}
                                style={{ paddingRight: '48px', textOverflow: 'ellipsis' }}
                            />
                            <div className="_marketplaceLink" style={{ top: '8px', right: 0 }}>
                                <a href={ formData.safety_data_sheet }
                                    target="_blank"
                                >
                                    <img src="/assets/images/ext-link.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="product_name">
                            GS1 Certificate
                        </label>
                        <div className="marketplaceLinkWrapper">
                            <input
                                type="text"
                                id="gs1_certificate"
                                className={`form-control`}
                                required
                                defaultValue={formData.gs1_certificate}
                                onChange={changeFieldHandler}
                                disabled={isArchived}
                                style={{ paddingRight: '48px', textOverflow: 'ellipsis' }}
                            />
                            <div className="_marketplaceLink" style={{ top: '8px', right: 0 }}>
                                <a href={ formData.gs1_certificate }
                                    target="_blank"
                                >
                                    <img src="/assets/images/ext-link.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="product_name">
                            Live Images 
                        </label>
                        <div className="marketplaceLinkWrapper">
                            <input
                                type="text"
                                id="live_images"
                                className={`form-control`}
                                required
                                defaultValue={formData.live_images}
                                onChange={changeFieldHandler}
                                disabled={isArchived}
                                style={{ paddingRight: '48px', textOverflow: 'ellipsis' }}
                            />
                            <div className="_marketplaceLink" style={{ top: '8px', right: 0 }}>
                                <a href={ formData.live_images }
                                    target="_blank"
                                >
                                    <img src="/assets/images/ext-link.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="product_name">
                            Warnings and Instructions
                        </label>
                        <div className="marketplaceLinkWrapper">
                            <input
                                type="text"
                                id="warnings_and_instructions"
                                className={`form-control`}
                                required
                                defaultValue={formData.warnings_and_instructions}
                                onChange={changeFieldHandler}
                                disabled={isArchived}
                                style={{ paddingRight: '48px', textOverflow: 'ellipsis' }}
                            />
                            <div className="_marketplaceLink" style={{ top: '8px', right: 0 }}>
                                <a href={ formData.warnings_and_instructions }
                                    target="_blank"
                                >
                                    <img src="/assets/images/ext-link.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="form-group">
                        <label htmlFor="product_name">
                            Video Material
                        </label>
                        <div className="marketplaceLinkWrapper">
                            <input
                                type="text"
                                id="video_material"
                                className={`form-control`}
                                required
                                defaultValue={formData.video_material}
                                onChange={changeFieldHandler}
                                disabled={isArchived}
                                style={{ paddingRight: '48px', textOverflow: 'ellipsis' }}
                            />
                            <div className="_marketplaceLink" style={{ top: '8px', right: 0 }}>
                                <a href={ formData.video_material }
                                    target="_blank"
                                >
                                    <img src="/assets/images/ext-link.png" alt=""/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </fieldset>
    </form>
  );
};

export default DocumentLinkForm;
