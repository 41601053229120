import React, { useEffect, memo } from 'react';

/** Style */
import './TableFilter.css';

/** UI-Components */
import Input from '../ui-components/input/Input';

/** Fields Structure */
import { tableFilterFields as fields } from '../../lib/constants/fields';

const TableFilter = ({ perPage = 10, searchValue = '', filter, expandedHeader }) => {
  useEffect(() => {
    fields.count.value = [{ value: perPage, label: perPage }];
    fields.search.value = searchValue;

    return () => {
      fields.count.value = [{ value: 10, label: '10' }];
      fields.search.value = '';
    };
  }, [searchValue, perPage]);

  const filterHandler = (e, field) => {
    switch (field) {
      case 'search':
        fields.search.value = e.target.value.length >= 3 ? e.target.value : '';
        break;

      case 'count':
        fields.count.value = [{ value: e.value, label: e.value }];
        break;

      default:
        break;
    }

    return filter(fields);
  };
 
  return (
    <div className="d-flex justify-content-between w-100">
      <Input
        data={fields.count}
        inputChanged={e => filterHandler(e, 'count')}
        inlineLabel={true}
        expandedHeader={expandedHeader}
      />
      <Input
        data={fields.search}
        inputChanged={e => filterHandler(e, 'search')}
        inlineLabel={true}
      />
    </div>
  );
};

export default memo(TableFilter);
