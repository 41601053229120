import React, {useEffect, useState} from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../components/table/Table';
import AddSectionFields from './AddSectionFields';
import { getSections as QUERY } from '../../services/api/queryService';
import { arrayMove, fetchData } from '../../services/util/dataHandlerService';
import Routes from '../../lib/constants/routes';
import ModalForm from '../../components/modal/ModalForm';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { updateSubsectionOrdering } from '../../services/api/mutationService';

const description = {
  module: 'analyticsSections',
  description: 'analyticsSections',
  req: 'getSections',
  url: Routes.products.index,
  tab: 'search'
};

const SubsectionSort = ({ location, reloadData }) => {

  const [ changedData, setChangedData ] = useState([]);
  const [ loader, setLoader ] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [item, setItem] = useState(null)
  const [ sections, setSections ] = useState({});
  const [ subsections, setSubsections ] = useState([]);
  const [ tabs, setTabs ] = useState([]);
  const [ activeSection, setActiveSection ] = useState(null)
  const dispatch = useDispatch();
  const [SubsectionOrderingMutation, { error: updateError }] = useMutation(updateSubsectionOrdering);

  const user = useSelector(state => state.auth.user)

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      filter: user.id
    },
    fetchPolicy: 'no-cache'
  });


  useEffect(() => {
    refetch()
    if (data) {
      const d = data.getSections.map(x => {
        delete x.showFilter;
        return x
      })
      const newData = [...d]
      const secs = [...new Set(newData.map(x => x.section))]
      console.log(newData)

      const subsArr = [];
      secs.map((item, i) => {
        const f = [...newData.filter(x => x.section == item).sort(function(a, b) {
          return a.position - b.position;
        })]

        const subs = [...new Set(f.map(k => k.subsection))]
        // f.sort(function(a, b) {
        //     return a.user_tab_position - b.user_tab_position;
        // })
        console.log(subs)

        subsArr.push({
            id: f.id,
            section: item,
            subsections: subs,
            position: ++i
        })

      })


      setChangedData(newData)
      setSections(secs)
      setSubsections(subsArr)
      setLoader(false)
    }
  }, [data, reloadData])

  if (error) {
    throw new Error(error);
  }

  const renderTableHeading = key => {
    if (key === '__typename') {
      return;
    }
    if (key === 'id') {
      return (
        <th key={key} className="text-capitalize text-center">
          ID
        </th>
      );
    }

    return (
      <th key={key} className="text-capitalize">
        {key.replace('_', ' ')}
      </th>
    );
  };

  const renderTableField = (array, item) => {

    const f = [...array.filter(x => x.section == item)]

    return f;
  };

  const changeSort = (section) =>{
    setActiveSection(section)
  }

  const SortableItem = SortableElement(({value, section}) => (
    <span className="draggableSpan" onMouseEnter={() => changeSort(section)}>
      <img src="/assets/images/drag-d.svg" alt="" />
        {value}
    </span>
  ));

  const SortableList = SortableContainer(({items}) => {
    return (
      <div className="d-flex flex-direction-column">
        {items.subsections.map((value, index) => (
          <SortableItem key={`item-${value}`} index={index} section={items.section} value={value} />
        ))}
      </div>
    );
  });
  
  const onSortEnd = ({oldIndex, newIndex}) => {
    // Detect the section
    if(activeSection){

        const fd = subsections.filter(x => x.section === activeSection)[0]
        const nd = subsections.filter(x => x.section !== activeSection)

        fd.subsections = [...arrayMove(fd.subsections, oldIndex, newIndex)]
        
        nd.splice(fd.position-1, 0, fd);
        
        setSubsections([...nd])
    }else{
        alert('Something went wrong')
    }
  };

  const submitHandler = async () => {
    try {
        const dataInput = {
            data : JSON.stringify(subsections),
            user_id: user.id
        } 

        console.log(dataInput)

        const data = await SubsectionOrderingMutation({
            variables: {
                input: dataInput
            }
        });

        if (data.error) {
            alert('Server responded with no data');
            return;
        }

        dispatch({
            type: 'UPDATE',
            payload: {
            type: 'success',
            message: `Recored was successfully updated`
            }
        });

        refetch()

      
    } catch (err) {
        console.log(err)
    }
  }



  return (
    <>
      {loader || !changedData.length || !tabs ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="sections-page-table custom-table card-body">
          <table className="table mt-3 mb-0">
            <thead>
                <tr>
                    {sections.map(key => renderTableHeading(key))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {subsections && subsections.map(item => (
                        <td key={item.position}>
                            <SortableList items={item} data-section={item.section} onSortEnd={onSortEnd} />
                        </td>
                    ))}
                </tr>
                <tr>
                    
                </tr>
            </tbody>
            </table>
            <hr style={{ borderColor : "#dee2e6" }} />
            <div>
                <button className="btn btn-primary" onClick={() => submitHandler()}>
                    Save
                </button>
            </div>
        </div>
      )}
    </>
  );
};

export default SubsectionSort;
