import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useHistory, useLocation, Link } from 'react-router-dom';

import Pagination from '../../../components/ui-components/pager/Pagination';
import { tableFilterFields, MARKETPLACE_STATUS_OPTIONS } from '../../../lib/constants/fields';
import { importProductCancel, importProductConfirm } from '../../../services/api/mutationService';

import './ImportJobTable.scss';
import { badValueMessage } from 'graphql/validation/rules/ValuesOfCorrectType';
import { getContentSettings, getImportProductsCurrentData as GET_CURRENT_PRODUCT_DATA } from '../../../services/api/queryService';
import Axios from 'axios';

let _uri = 'https://staging-api.ebazaaris.io'
if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod_azure') {
  _uri = 'https://azure-api.ebazaaris.io'
} else if (process.env.REACT_APP_ENV === 'dev') {
  _uri = 'http://localhost:8000'
}

const ImportJobTable = ({
  importJobId,
  importType,
  tableData,
  params,
  first,
  input,
  paginatorInfo,
  isPreview,
  isImporting,
  isCanceled,
  refetch,
  hasImportError,
  marketplace,
}) => {
  const [extendedId, setExtendedId] = useState(null);
  const [importProductCancelMutation] = useMutation(importProductCancel);
  const [importProductConfirmMutation, { loading, error: importError }] = useMutation(
    importProductConfirm
  );
  const [error, setError] = useState();
  const history = useHistory();
  const location = useLocation();
  const tableHeadings = [];
  const tableCells = [];
  const extendedValues = [];
  const [currentProductData, setCurrentProductData] = useState(null);
  const currentProductsInput = [];

  if(importType == 'cid_update'){ //Only fetch data if the import type is cid_update
    tableData.forEach(item => {
      currentProductsInput.push({
        marketplace_name: item.marketplace_name, 
        slug: item.product_parent_id,
        marketplace_product_id: item.marketplace_id,
        marketplace_id_type: item.marketplace_id_type,
        product_child_id: item.product_child_id
      });
    });
  }

  const { loading: currentDataLoading, data: currentData, refetch: refetchCurrentData, error: currentDataError } = useQuery(GET_CURRENT_PRODUCT_DATA, {
    variables: {
      input:currentProductsInput
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    let cData = [];
    if(currentData){
      currentData.currentProducts.map(cProduct => {
        if(cProduct !== null){
          cData[cProduct.product_child_id] = cProduct;
        }
      });    
      setCurrentProductData(cData);
    }
  }, [currentData])


  tableData.forEach(item => {
    let isAdditional = false;

    if (!tableHeadings.length) {
      if(importType == 'cid_update'){ //Headings for ASIN/SKU update
        tableHeadings.push('PID');
        tableHeadings.push('Manufacturer');
        tableHeadings.push('Brand');
        tableHeadings.push('Product name');
        tableHeadings.push('Product details');
        tableHeadings.push('Product contents');
        tableHeadings.push('Packaging unit');
        tableHeadings.push('Product type');
        tableHeadings.push('Marketplace');
        tableHeadings.push('status');
      }
      Object.keys(item).forEach(key => {
        if (!isAdditional && importType != 'cid_update') {
          if(importType == 'bundle'){
            if (key === 'error') {
              tableHeadings.push('status');
              isAdditional = true;
              return;
            }
            if (key === 'product_parent_id') {
              tableHeadings.push('PID');
              tableHeadings.push('Manufacturer');
              tableHeadings.push('Brand');
              tableHeadings.push('Product name');
              tableHeadings.push('Product type');
              tableHeadings.push('Product contents');
              tableHeadings.push('Packaging unit');
              return;
            }else{
              return
            }
          }else if(importType == 'content_import'){
            if (key === 'error') {
              tableHeadings.push('status');
              isAdditional = true;
              hasImportError = true;
              return;
            }
            if (key === 'product_parent_id') {
              tableHeadings.push('PID');
              tableHeadings.push('Product name');
              tableHeadings.push('Marketplace');
              return;
            }else{
              return
            }
          }else{
            if (key === 'error') {
              tableHeadings.push('status');
              isAdditional = true;
              return;
            }
            if (key === 'product_parent_id') {
              tableHeadings.push('PID');
              return;
            }
            if (key === 'marketplace_product_id') {
              tableHeadings.push('Marketplace ID');
              return;
            }
            if (key === 'slug') {
              return;
            }
          }
          tableHeadings.push(key.replaceAll('_', ' '));
        }
      });
      isAdditional = false;
    }

    const itemCells = [];
    const itemExtendedValues = {};
    let index = 1;

    Object.keys(item).forEach(key => {
      if(importType === 'bundle' && tableCells.length && item.product){
        if(tableCells.find(x => x[0]?.props.children == item.product.slug) !== undefined){
          if(item.error){
            const d = tableCells.find(x => x[0]?.props.children == item.product.slug)
            d[7] = renderStatus(item.error, isPreview, isCanceled, importType);
          }
          return

        }
      }

      if (!isAdditional) {
        if(importType === 'bundle'){

          if (key === 'error') {
            itemCells.push(renderStatus(item[key], isPreview, isCanceled, importType));
            isAdditional = true;
            return;
          }
          if (key === 'product_parent_id') {
            if(item.product){
              itemCells.push(<Link to={`/products/${item.product.slug}/edit`}>{item.product.slug}</Link>);
              itemCells.push(item.product.manufacturer.name);
              itemCells.push(item.product.brand.name);
              itemCells.push(item.product.product_name);
              itemCells.push(item.product.type.name);
              itemCells.push(item.product.product_contents);
              itemCells.push(item.product.packagingUnit.name);
            }else{
              itemCells.push(item.product_parent_id);
              itemCells.push(null);
              itemCells.push(null);
              itemCells.push(null);
              itemCells.push(null);
              itemCells.push(null);
              itemCells.push(null);
            }
            return;
          }else{
            return
          }
        }else if(importType === 'content_import'){

          if (key === 'error') {
            itemCells.push(renderStatus(item[key], isPreview, isCanceled, importType));
            isAdditional = true;
            return;
          }
          if (key === 'product_parent_id') {
            if(item.product){
              itemCells.push(<Link to={`/products/${item.product.slug}/edit`}>{item.product.slug}</Link>);
              itemCells.push(item.product.product_name);
              itemCells.push(item.marketplace_name);
            }else{
              itemCells.push(item.product_parent_id);
              itemCells.push(item.product_name);
              itemCells.push(item.marketplace_name);
            }
            return;
          }else{
            return
          }
        }else{
          if (key === 'error') {
            if(importType === 'cid_update'){
              itemCells.push(item.marketplace_name);
            }
            itemCells.push(renderStatus(item[key], isPreview, isCanceled, importType));
            isAdditional = true;
            return;
          }
          if (key === 'slug') {
            return;
          }
          if (key === 'product_parent_id') {
            if(importType === 'product_price_update' || importType === 'cid' || importType === 'cid_update'){
              itemCells.push(item['product_parent_id']);
            }else{
              itemCells.push(item['slug']);
            }
            return;
          }
          if (key === 'product_name' && item.slug) {
            itemCells.push(<Link to={`/products/${item.slug}/edit`}>{item[key]}</Link>);
            return;
          }
        }
        item.id = item.product_parent_id
        itemCells.push(item[key]);
      } else {
        if(importType == 'bundle'){
          itemExtendedValues['qty'] = item.qty
          itemExtendedValues['pid'] = item.product_parent_id
          itemExtendedValues['bundled_item_pid'] = item.bundled_item_pid
        }
        itemExtendedValues[key] = item[key] || '';
      }
    });
    
    if(itemCells.length){
      tableCells.push(itemCells);
      extendedValues.push(itemExtendedValues);
    }
  });


  const renderExtendedValues = values => importType == 'bundle' ? (
    <div className="container col-12 extended-values">
      <div className="row row-cols-3">
        {values.map((item, i) => (
          Object.keys(item).map(key => (
            <div className="col-md-3 mb-1" key={`${key}${i}`}>
              <strong>{key}: </strong>
              {item[key]}
            </div>
          ))))
        }
      </div>
    </div>
  ): importType == 'cid_update' ? (
    <div className="container col-12 extended-values">
      <div className="row row-cols-5 cid_update" >
        {Object.keys(values).map((key, i) => (
          <div className="col-md-2 mb-1" key={`${key}${i}`}>
              <>
                <strong>{ key.charAt(0) == '_' ? key.replace(/_/g,'') : key + ':' } </strong>
                {values[key]}
              </>
          </div>
        ))}
      </div>
    </div>
  ) : (
    <div className="container col-12 extended-values">
      <div className="row row-cols-3" >
        {Object.keys(values).map(key => (
          <div className="col-md-4 mb-1" key={key}>
            {key !== 'blank' ? (
              <>
                <strong>{key}: </strong>
                {values[key]}
              </>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );

  const rowClickHanler = id => {
    setExtendedId(id === extendedId ? null : id);
  };

  const entriesChangeHandler = option => {
    params.set('count', option.value);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const changeSearchInputHandler = event => {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      params.set('keyword', inputValue);
    } else {
      params.delete('keyword');
    }
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const cancelClickHandler = async () => {
    try {
      const { data } = await importProductCancelMutation({
        variables: { id: importJobId }
      });

      if (!data.importProductCancel) {
        throw new Error('Server responded with no data');
      }

      history.push('/products?tab=import');
    } catch (err) {
      setError(err);
    }
  };

  const createClickHandler = async () => {
    try {
      const { data } = await importProductConfirmMutation({
        variables: { id: importJobId, type: importType }
      });

      if (!data.importProductConfirm) {
        throw new Error('Server responded with no data');
      }

      refetch();
    } catch (err) {
      setError(err);
    }
  };

  if (loading || currentDataLoading) {
    return <div className="spinner absolute" />;
  }

  if (error || importError) {
    throw new Error(error || importError);
  }

  const errorExportClickHandler = async () => {
    try {
      Axios.get(`${_uri}/api/export/products/${importJobId}/error/${importType}`,  {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        let ext = 'xlsx'
        let fileName = 'product_asin_sku_error_export_' + `${importJobId}`;
        if(importType == 'cid_update'){
          fileName = 'product_asin_sku_update_error_export_' + `${importJobId}`;
        }

        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${fileName}.${ext}`;
        link.click()
      })
        
      .catch(error => console.error(`Error: ${error}`));
    } catch (err) {
      setError(err);
    }
  };


  return (
    <div className="card mt-3">
      <div className="card-body import-job-table">
        <div className="import-job-table__header">
          <div className="import-job-table__header--item">
            <button
              className="btn btn-danger mr-2"
              onClick={cancelClickHandler}
              disabled={!isPreview && !isImporting}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary mr-3"
              onClick={createClickHandler}
              disabled={!isPreview}
            >
              {importType === 'product' ? 'Create' : 'Update'}
            </button>
            <button
              className={(importType === 'cid' || importType === 'cid_update') ? "btn btn-primary mr-2" : "btn btn-primary mr-2 hidden"}
              onClick={errorExportClickHandler}
              disabled={!isPreview && !hasImportError}
            >
              Error export
            </button>
            <p>Show entries:</p>
            <div style={{ width: '80px' }}>
              <Select
                options={tableFilterFields.count.list}
                defaultValue={
                  first ? { label: first, value: +first } : tableFilterFields.count.value
                }
                onChange={entriesChangeHandler}
              />
            </div>
          </div>

          <div className="import-job-table__header--item">
            <p>Search:</p>
            <input
              type="text"
              className="form-control"
              onChange={changeSearchInputHandler}
              defaultValue={input}
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table mt-3">
            <thead>
              <tr>
                <th></th>
                {tableHeadings.map(item => (
                  <th key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              { tableCells.map((row, rowIndex) =>  (
                
                <React.Fragment key={rowIndex}>
                  <tr
                    className={rowIndex % 2 === 0 ? 'gray' : 'white'}
                    style={{ cursor: 'pointer' }}
                    onClick={rowClickHanler.bind(null, rowIndex)}
                  >
                    <td style={{ maxWidth: '10px', overflow: 'visible' }}>
                      <i className="fas fa-plus-square" />
                    </td>
                    {row.map((cell, cellIndex) => (
                      <td
                        key={`${rowIndex}-${cellIndex}`}
                        style={{ maxWidth: cell && cell.type === 'span' ? 'unset' : '175px' }}
                      >
                        {cell}
                      </td>
                    ))}
                  </tr>
                  {extendedId === rowIndex && !currentDataLoading && currentProductData &&  ( // 
                    <tr className={rowIndex % 2 === 0 ? 'gray' : 'white'}>
                      <td colSpan={row.length + 1} className="p-0">
                        { 
                          renderExtendedValues( processExtendedValues(tableData, extendedValues[rowIndex], importType, rowIndex, paginatorInfo, marketplace, currentProductData))
                        }
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination data={paginatorInfo} />
      </div>
    </div>
  );
};

export default ImportJobTable;


const processExtendedValues = (tableData, values, type, index, paginatorInfo, marketplace, currentProductData) => {
  let processedValues = {};
  if (type === 'product' || type === 'product_update' ) {
    processedValues = {
      'Manufacturer Part Number': values.manufacturer_product_id,
      'PZN': values.pzn,
      'Logistical Unit': values.packaging_logistical,
      'GTIN/EAN': values.gtin,
      'WxDxH': [values.article_width_mm, values.article_depth_mm, values.article_height_mm]
        .filter(i => !!i)
        .join('x'),
      'Logistical Unit/EPAL':
        values.packaging_unit_per_eur_pallet && `${values.packaging_unit_per_eur_pallet}/EPAL`,
      'Weight': values.article_weight_g && `${values.article_weight_g}g`,
      'Product Categories': [
        values.product_category_pharmacy,
        values.product_category_hazmat,
        values.product_category_electronics,
        values.product_category_food
      ]
        .filter(i => !!i)
        .join(', ')
    };
  } else if (type === 'cid') {
    processedValues = {
      'Product Child ID': values.id + '-C' + (paginatorInfo.lastItem - paginatorInfo.count + index+1),
      'Marketplace ID type': values.marketplace_id_type,
      'Fulfillment vendor': values.fulfillment_vendor,
      'Marketplace Name': values.marketplace_name,
      'Marketplace ID': values.marketplace_id,
      'Seller': values.seller,
      'Fee category': values?.fee_category?.name,
    };
  } else if (type === 'cid_update') {
    const currentKey = values.product_child_id;
    if(values.marketplace_id_type == 'ASIN' || values.marketplace_id_type == 'MLID' ){
          processedValues = { // Underlines are tricks to align the values in the table as wanted by dev-3461
            'Marketplace ID type': values.marketplace_id_type,
            '_Marketplace name': '',
            '_Status':'',
            '__':'',
            '___':'',
            '____':'',
            'Old marketplace ID': currentProductData[currentKey]?.marketplace_product_id !== undefined ? currentProductData[currentKey].marketplace_product_id : '',
            'Old marketplace': currentProductData[currentKey]?.marketplace?.name !== undefined ? currentProductData[currentKey].marketplace?.name : '',
            'Old status': currentProductData[currentKey]?.status !== undefined ? MARKETPLACE_STATUS_OPTIONS[currentProductData[currentKey].status].name : '',
            '______':'',
            '_______':'',
            '________':'',
            'New marketplace ID': values.marketplace_id,
            'New marketplace': values.marketplace_name,
            'New status': values.status_marketplace
          };
        } else {
          processedValues = {
            //'Product child ID': values.id + '-C' + (paginatorInfo.lastItem - paginatorInfo.count + index+1),
            'Marketplace ID type': values.marketplace_id_type,
            '_Marketplace name': '',
            '_Status': '',
            '_Seller': '',
            '_Fulfillment vendor': '',
            '_':'',
            'Old marketplace ID': currentProductData[currentKey]?.marketplace_product_id !== undefined ? currentProductData[currentKey].marketplace_product_id : '',
            'Old marketplace': currentProductData[currentKey]?.marketplace?.name !== undefined ? currentProductData[currentKey].marketplace?.name : '',
            'Old status': currentProductData[currentKey]?.status !== undefined ? MARKETPLACE_STATUS_OPTIONS[currentProductData[currentKey].status].name : '',
            'Old seller': currentProductData[currentKey]?.seller?.name !== undefined ? currentProductData[currentKey].seller.name : '',
            'Old fulfillment vendor': currentProductData[currentKey]?.skuFullFilmentVendors?.fulfillment?.name !== undefined ? currentProductData[currentKey].skuFullFilmentVendors.fulfillment.name : '',
            '__':'',
            'New marketplace ID': values.marketplace_id,
            'New marketplace': values.marketplace_name,
            'New status': values.status_marketplace,
            'New seller': values.seller,
            'New fulfillment vendor': values.fulfillment_vendor
          };
       }

  } else if (type === 'price' || type === 'product_price_update') {
    processedValues = {
      'Buy price': `${values.price_buy_net_actual_ct}€ cents`,
      'Manufacturer margin': `${values.manufacturer_margin}%`,
      'Reference price': `${+values.rrp_value*100}€ cents`,
      'Tax rate' : `${(values.tax_rate*100).toFixed(2)}%`,
      'Additional costs': `${+values.additional_costs*100}€ cents`,
      'Amazon tax code': `${values.tax_code}`
    };
  }else if (type === 'bundle') {
    let val = tableData.filter(x => x.product_parent_id == values.product.product_parent_id)
    const arr = []
    if(!val.length){
      val = tableData.filter(x => x.product_parent_id == values.pid)
    }
    val.forEach(item => {
      if(item.bundledProduct){
        arr.push({
          'Bundled item ID': item.bundledProduct.slug,
          'Product name': item.bundledProduct.product_name,
          'Packaging unit': item.bundledProduct.packagingUnit.name,
          'Quantity': item.qty
        });
      }else{
        arr.push({
          'Bundled item ID': item.bundled_item_pid,
          'Product name': null,
          'Packaging unit': null,
          'Quantity': item.qty,
        });
      }
    })
    return arr
  }else if (type === 'content_import') {
    const data = tableData[index]

    const setting = data.contentSetting ? JSON.parse(data.contentSetting.settings) : null
    const bullets = [
      data.listing_bullet_1,
      data.listing_bullet_2,
      data.listing_bullet_3,
      data.listing_bullet_4,
      data.listing_bullet_5,
      data.listing_bullet_6,
      data.listing_bullet_7,
      data.listing_bullet_8,
      data.listing_bullet_9,
      data.listing_bullet_10,
    ].filter(x => x != null && x.length !== 0);

    if(marketplace == 'ebay'){
      let c = 0;
      if(data.main_category) c++
      if(data.subcategory) c++

      const chars = Object.fromEntries(Object.entries(JSON.parse(data.characteristics)).filter(([_, v]) => v != null))

      processedValues = {
        "Product Title" : `${data.listing_title ? data.listing_title.length : 0}/${setting ? setting.title_chars_length : 0}`,
        "Subtitle" : `${data.subtitle ? data.subtitle.length : 0}/${setting ? setting.title_chars_length : 0}`,
        "Characterics" : `${data.characteristics ? Object.keys(chars).length : 0}/${Object.keys(JSON.parse(data.characteristics)).length}`,
        "Description" : `${data.listing_desc ? data.listing_desc.length : 0}/${setting ? setting.characters_max : 0}`,
        "Rich content" : `${data.rich_content ? data.rich_content.length : 0}/${setting ? setting.characters_max : 0}`,
        "Listing categories" : `${c}/2`,
      };
    }else if(marketplace == 'kaufland'){
      let c = 0;
      if(data.main_category) c++
      if(data.subcategory) c++


      processedValues = {
        "Product Title" : `${data.listing_title ? data.listing_title.length : 0}/${setting ? setting.title_chars_length : 0}`,
        "Backend Keywords" : data.backend_keywords ? data.backend_keywords.split(',').length : 0,
        "Description" : `${data.listing_desc ? data.listing_desc.length : 0}/${setting ? setting.characters_max : 0}`,
        "Listing categories" : `${c}/2`,
      };
    }else if(marketplace == 'otto'){
      let c = 0;
      if(data.main_category) c++
      if(data.subcategory) c++


      processedValues = {
        "Product Title" : `${data.listing_title ? data.listing_title.length : 0}/${setting ? setting.title_chars_length : 0}`,
        "Product Bullets" : `${bullets.length}/${setting ? setting.bulltets_count_max : 0}`,
        "Description" : `${data.listing_desc ? data.listing_desc.length : 0}/${setting ? setting.characters_max : 0}`,
        "Listing categories" : `${c}/2`,
      };
    }else{
      processedValues = {
        "Product Title" : `${data.listing_title ? data.listing_title.split(' ').length : 0}/${setting ? setting.title_words_max : 0}`,
        "Backend Keywords" : data.backend_keywords ? data.backend_keywords.split(',').length : 0,
        "Product Bullets" : `${bullets.length}/${setting ? setting.bulltets_count_max : 0}`,
        "Description" : `${data.listing_desc ? data.listing_desc.length : 0}/${setting ? setting.characters_max : 0}`,
      };
    }

  }
  return processedValues;
};

const renderStatus = (value, isPreview, isCanceled, importType) => {
  if (isPreview) {
    return value ? (
      <span>
        <span className="status-badge status-badge-canceled mr-2" style={{ width: '40px' }}>
          Error
        </span>
        {value}
      </span>
    ) : (
      <span>
        <span className="status-badge status-badge-active mr-2" style={{ width: '40px' }}>
          OK
        </span>
        {importType === 'product' ? 'Create product' : 'Update existing values'}
      </span>
    );
  } else {
    return value || isCanceled ? (
      <span className="status-badge status-badge-canceled">Failed</span>
    ) : (
      <span className="status-badge status-badge-active">
        {importType === 'product' ? 'Created' : 'Updated'}
      </span>
    );
  }
};
