import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

/** Redux */
import { useDispatch } from 'react-redux';

/** Apollo */
import { useMutation } from '@apollo/react-hooks';

/** Ui Components */
import Input from '../ui-components/input/Input';
import Button from '../ui-components/button/Button';
import Error from '../modal/ModalError';

/** Services */
import { formatData } from '../../services/util/dataHandlerService';

const DefaultForm = ({
  service,
  deleteService,
  fields,
  moduleName,
  history,
  action,
  id,
  req,
  refetch = () => {}
}) => {
  const dispatch = useDispatch();
  const [addItem, { loading: addLoading, data: addData, error: addError }] = useMutation(service);
  const [{ loading: deleteLoading, data: deleteData }] = useMutation(deleteService);
  const [error, setError] = useState();
  const pageArr = ['manufacturers', 'products'];
  const [validationErrror, setValidationError] = useState();
  const [changesMade, setChangesMade] = useState(false);
  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  useEffect(() => {
    if (addLoading || deleteLoading) {
    } else if (addData || deleteData) {
      dispatch({
        type: 'DRAW',
        payload: true

      });
      if (!action) {
        if(pageArr.includes(moduleName)){
          history.push(`/${moduleName}/${addData[req].slug}/edit`);
         }else{
          history.push(`/${moduleName}/${addData[req].id}/edit`);
         }
        return () => {
          Object.keys(fields).forEach(item => {
            if (item === 'status' || fields[item].inputConf.type === 'hidden') {
              return;
            }

            switch (fields[item].inputType) {
              case 'select':
                fields[item].value = [];
                break;
              case 'selectSingleTarget':
                fields[item].value = [];
                break;
              default:
                fields[item].value = '';
                break;
            }
          });
        };
      }
    }
  });

  /** Submit Handler */
  const submitHandler = async e => {
    setChangesMade(false);
    e.preventDefault();

    const reqData = formatData(fields);
    let ready = true;

    Object.keys(fields).forEach(key => {
      if (!fields[key].isValid) {
        let checkValid = validate(fields[key].value, fields[key].validation);
        if (!checkValid) {
          ready = false;

          dispatch({
            type: 'UPDATE',
            payload: {
              type: 'error',
              message: `Invalid Field : ${key}`
            }
          });
        }
      }
    });

    if (moduleName === 'manufacturers') {
      reqData.brands = [];
    }

    if (ready) {
      const { data } = await addItem({ variables: { id, ...reqData } });

      if (!data[req]) {
        setError('Server responded with no data');
        return;
      }
      refetch();

      if(data.updateManufacturer && data.updateManufacturer.error){
        setValidationError(data.updateManufacturer.error)
      }else{

        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: `Record was successfully ${action === 'edit' ? 'Updated' : 'Created'}`
          }
        });
      }
    }
  };

  /** Validate new value of updated input*/
  const validate = (value, rules) => {
    let isValid = true;

    if (rules.required) {
      if (typeof value !== 'object') {
        isValid = value.trim() !== '' && isValid;
      } else {
        isValid = value && value.length > 0 && isValid;
      }
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.isEmail) {
      const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  };

  /** input on change function */
  const inputChangeHandler = (e, name) => {
    setChangesMade(true);
    /** if nothing was chosen from select field */
    if (!e) {
      fields[name].value = [];
      fields[name].isValid = validate(e, fields[name].validation);
      return;
    }

    /** if we got some values  */
    if (e.length > 0) {
      fields[name].value = e;
      fields[name].isValid = validate(e, fields[name].validation);
    } else if (fields[name].inputType === 'selectSingleTarget') {
      
      fields[name].value = [e];
      fields[name].isValid = validate([e], fields[name].validation);
    } else {
      fields[name].value = e.target.value;
      fields[name].isValid = validate(e.target.value, fields[name].validation);
    }
  };

  const editableInputChangeHandler = (name, value) => {
    fields[name].value = JSON.stringify([value]);
  };

  if (error || addError) {
    throw new Error(error || addError);
  }

  return (
    <div className="row">
      <div className="col-md-12 ">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <Error setError={() => setValidationError('')} error={validationErrror}  close={true} />
                <h4 className="pl-2 mt-0">General Information</h4>
                <hr />
                <form onSubmit={submitHandler} >
                  { moduleName === 'manufacturers' ? (
                      <div style={{'display': 'flex', 'flexWrap': 'wrap'}}>
                        {Object.keys(fields).map((key, index) => {
                          return (
                            <Input
                              key={index}
                              data={fields[key]}
                              inputChanged={e => inputChangeHandler(e, key)}
                              childInputChanged={editableInputChangeHandler}
                              required={fields[key].validation.required}
                              col={true}
                            />
                          );
                          })}
                      </div>
                  ) : (
                    Object.keys(fields).map((key, index) => {
                      return (
                        <Input
                          key={index}
                          data={fields[key]}
                          inputChanged={e => inputChangeHandler(e, key)}
                          childInputChanged={editableInputChangeHandler}
                          required={fields[key].validation.required}
                          col={false}
                        />
                      );
                      })
                  ) }
                  
                  {/** Button Input */}

                  <div className="form-group col-12 d-flex pt-1 mb-0">
                    <Button type="submit" text="Save" display="primary" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(withRouter(DefaultForm));
