import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';

import { importProduct } from '../../../services/api/mutationService';
import { PRODUCT_IMPORT_OPTIONS, PRODUCT_IMPORT_TEMPLATES, PROD_PRODUCT_IMPORT_TEMPLATES, IMPORT_SELECT_OPTIONS } from '../../../lib/constants/fields';
import ModalError from '../../../components/modal/ModalError';

import './ImportModal.scss';

const ImportModal = ({ setModalOpen }) => {
  const [fileName, setFileName] = useState('No file selected');
  const [file, setFile] = useState();
  const [error, setError] = useState();
  const filePickerRef = useRef();
  const history = useHistory();
  const [importProductMutation, { loading, error: importError }] = useMutation(importProduct);
  const [formData, setFormData] = useState({
    type: IMPORT_SELECT_OPTIONS[0].value,
    file: null
  });

  const IMPORT_OPTIONS = process.env.REACT_APP_ENV === 'prod_azure' ? PROD_PRODUCT_IMPORT_TEMPLATES : PRODUCT_IMPORT_TEMPLATES


  useEffect(() => {
    if (!file || !file.name.includes('xlsx')) {
      return;
    }
    setFileName(file.name);
    let fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      setFormData({
        ...formData,
        file: fileReader.result
      });
    };
    return () => {
      fileReader = null;
    };
    // eslint-disable-next-line
  }, [file]);

  const backdropClickHandler = event => {
    if (event.target.id === 'importModal') {
      setModalOpen(false);
    }
  };

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {
    setFormData({
      ...formData,
      [data.name]: option.value
    });
    setError(null);
  };

  const selectClickHandler = () => {
    filePickerRef.current.click();
  };

  const selectFileHandler = event => {
    const { files } = event.target;
    let selectedFile;
    if (files && files.length === 1) {
      selectedFile = files[0];
      setFile(selectedFile);
    }
    setError(null);
  };

  const downloadClickHandler = () => {
    window.location = IMPORT_OPTIONS[formData.type];
  };

  const submitHandler = async event => {
    event.preventDefault();

    const { data } = await importProductMutation({
      variables: { input: formData }
    });
    const { id, error } = data.importProduct;

    if (error) {
      setError(error);
      return;
    }

    if (!id) {
      setError('Import file column structure did not match, please try again');
      return;
    }

    history.push(`/products/import/${data.importProduct.id}`);
  };

  if (importError) {
    throw new Error(importError);
  }

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="importModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog import-modal">
          <div className="modal-content">
            <form onSubmit={submitHandler}>
              <div className="modal-header">
                <h4 className="modal-title">Import products</h4>
                <button type="button" className="close" onClick={() => setModalOpen(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ModalError error={error} setError={setError} />
                <div className="form-group">
                  <label htmlFor="name">
                    Name of import <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    required
                    defaultValue={formData.name}
                    onChange={changeFieldHandler}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="type">
                    Import type <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    name="type"
                    options={IMPORT_SELECT_OPTIONS}
                    defaultValue={IMPORT_SELECT_OPTIONS[0]}
                    onChange={changeSelectHandler}
                    styles={customStyles}
                  />
                </div>

                <label htmlFor="file">
                  Select import file <span style={{ color: 'red' }}>*</span>
                </label>
                <div className="select-file-wrapper">
                  <p>{fileName}</p>
                  <input
                    ref={filePickerRef}
                    style={{ display: 'none' }}
                    type="file"
                    accept=".xlsx"
                    onChange={selectFileHandler}
                  />
                  <div className="btn btn-secondary px-4" onClick={selectClickHandler}>
                    Select file
                  </div>
                </div>

                {formData.type !== 'product_update'&& formData.type !== 'product_price_update' && (
                  <div className="download-wrapper" onClick={downloadClickHandler} >
                    <i className="fas fa-download mr-2" />
                    <span>Download xlsx template</span>
                  </div>
                  ) }
              </div>
              <div className="modal-footer justify-content-start">
                {loading ? (
                  <div className="spinner">Spinner</div>
                ) : (
                  <button type="submit" className="btn btn-primary" disabled={!formData.file}>
                    Import
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

const customStyles = {
  // For the options
  option: (styles, { isDisabled}) => {
    if(isDisabled){
      return {
        ...styles,
        borderBottom: '1px solid #ccc',
        innerHeight: '1px',
        padding: '0px',
        margin: '0px'
      };
    }

    return { ...styles }
  },
};

export default ImportModal;
