import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateListing } from '../../../services/api/mutationService';
import { STATUS_OPTIONS, marketplace_id_typeS } from '../../../lib/constants/fields';
import Error from '../../../components/modal/ModalError';

const ProductKeywords = ({ selectData, productData, refetch, update, onLisitingChange, activeMarketplace }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [marketplaceID, setMarketplaceID] = useState(
    productData.marketplace_id
  );
  const [targetedKeywords, setTargetedKeywords] = useState([]);

  let targeted_keywords = []
  let monitor_keywords = []
  let backend_keywords = []

  const convertDataToOptions = data =>
  data.map(item => ({ label: item, value: item }));

  const createDefaultOption = item => {
    if (item) {
      return { label: item.label, value: item.value };
    }
  };

  const [formData, setFormData] = useState({
    targeted_keywords : [...targeted_keywords],
    monitor_keywords : [...monitor_keywords],
    backend_keywords : [...backend_keywords],
  });

  const settings = JSON.parse(productData.setting.settings)

  useEffect(() => {
    
    
    if(productData.targeted_keywords){
      productData.targeted_keywords.split(',').map((item, index) => {
        targeted_keywords[index] = item
      })

      setTargetedKeywords([...convertDataToOptions(targeted_keywords)])
    }
  
    if(productData.monitor_keywords){
      monitor_keywords = convertDataToOptions(productData.monitor_keywords.split(','))
    }else if(productData.targeted_keywords){
      monitor_keywords = [...convertDataToOptions(targeted_keywords.slice(0, 5))]
    }
  
    if(productData.backend_keywords){
      productData.backend_keywords.split(',').map((item, index) => {
        backend_keywords[index] = item
      })
    }

    

    setFormData({
      targeted_keywords : [...targeted_keywords],
      monitor_keywords : [...monitor_keywords],
      backend_keywords : [...backend_keywords],
    })
  }, [productData])


  const submitHandler = async event => {
    event.preventDefault();
    try {
      const { data } = await updateListingMutation({
        variables: {
          input: {
            targeted_keywords: formData.targeted_keywords.join(','),
            monitor_keywords: [...formData.monitor_keywords.map(x => x.value)].join(','),
            backend_keywords: formData.backend_keywords.join(','),
          },
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value.split(',')
    });

    if(event.target.id === 'targeted_keywords'){
      setTargetedKeywords([...convertDataToOptions(event.target.value.split(','))])
    }else{
      const newData = {
        ['backend_keywords'] : event.target.value
      }
      onLisitingChange(newData)

    }

  };

  const changeMultiSelectHandler = (options, data) => {
    if(options && options.length > 5){
      alert('Max select count is 5');
      return false;
    }

    setFormData({
      ...formData,
      [data.name]: options??[]
    });
  };

  if (error || updateError) {
    debugger
    throw new Error(error || updateError);
  }


  return (
    <form onSubmit={submitHandler}>
      <div className="row row-cols-4">
        {activeMarketplace !== 23 && (
          <>
            <div className="col-12 mb-2">
              <div className='d-flex justify-content-between align-items-center'>
                  <label htmlFor="title">Targeted Keyword</label>
              </div>
              <input
                type="text"
                id="targeted_keywords"
                className="form-control"
                value={formData.targeted_keywords}
                onChange={changeFieldHandler}
                maxLength={253}
              />
            </div>
            {/* <div className="col-12 mb-2">
              <div className='d-flex justify-content-between align-items-center'>
                <label htmlFor="title">Monitor Keyword</label>
              </div>
              <Select
                name="monitor_keywords"
                options={targetedKeywords}
                value={formData.monitor_keywords}
                onChange={changeMultiSelectHandler}
                isMulti
              />
            </div> */}
          </>
        )}

          <div className="col-12">
            <div className='d-flex justify-content-between align-items-center'>
              <label htmlFor="title">Backend Keyword</label>
            </div>
            <input
              type="text"
              id="backend_keywords"
              className="form-control"
              value={formData.backend_keywords}
              onChange={changeFieldHandler}
              maxLength={settings.backend_keyword_count_max}
            />
          </div>
      </div>
      {update && (
        <>
          {loading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <button type="submit" className="btn btn-primary px-3 mt-3">
              Save
            </button>
          )}
        </>
      )}
    </form>
  );
};

export default ProductKeywords;
