import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import ModalError from '../../../components/modal/ModalError';
import { STATUS_OPTIONS } from '../../../lib/constants/fields';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";
import "../costSettings.scss"

import {
  convertDataToOptions,
  createDefaultOption,
  convertTitleToOptions
} from '../../../services/util/dataHandlerService';
import { createFulFillmentCost, updateFulFillmentCost } from '../../../services/api/mutationService';
import { 
  getFulfillmentVendorsSettings, 
  getShippingCarriers ,
  getMarketplacesSettings,
  getFBMSizes,
} from '../../../services/api/queryService';

const ShippingCostModal = ({ setModalOpen, itemId,shippingUnits, sections, refetch }) => {


  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false)
  const [fulfilments, setFulfilments] = useState([]);
  const [shippingCarriers, setShippingCarriers] = useState([]);
  const [filteredShippingCarriers, setFilteredShippingCarriers] = useState([]);
  const [totalArticleShipped, setTotalArticleShipped] = useState(0.00);
  const [totalArticleReturned, setTotalArticleReturned] = useState(0.00);
  const [marketplaces, setMarkpetplaces] = useState([]);
  const [FBMSizes, setFBMSizes] = useState([]);
  const [FBMCode, setFBMCode] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const currencies = ['EUR','GBP','SEK','PLN'];


  useEffect(() => {
    if(itemId){
      let r = itemId.date_range.split('-')[0].split('/')
      let k = itemId.date_range.split('-')[1].split('/')
      let s = new Date(`${r[2]}-${r[1]}-${r[0]}`)
      let e = new Date(`${k[2]}-${k[1]}-${k[0]}`)
      setDateRange([s, e])

      Object.keys(itemId).map(x => {
        if(typeof(itemId[x]) == 'number'){
          itemId[x] = Number(itemId[x]).toFixed(2)
        } 
      })

      setTotalArticleShipped(Number(+itemId.cost_order_received + +itemId.single_article_pick + +itemId.cost_order_shipping).toFixed(2))

      if(itemId.unit_data.fulfillment_vendor != 'FBA' && itemId.unit_data.fulfillment_vendor != 'FBM Small & Light'){
        setTotalArticleReturned(Number(+itemId.cost_order_received + +itemId.single_article_pick + +itemId.cost_return_article).toFixed(2))
      }else{
        setTotalArticleReturned(Number(+itemId.cost_return_article).toFixed(2))
      }
    }
  }, [itemId])


  const [formData, setFormData] = useState(itemId ? {...itemId} :{
    cost_order_received : 0.00,
    bundle_article_pick : 0.00,
    cost_order_shipping : 0.00,
    cost_return_article : 0.00,
    cost_return_article: 0.00,
    unit_data: {
      unit_size : null,
      unit_weight : null,
      sfp_uid : null,
      dimension_width_mm : null,
      dimension_height_mm : null,
      dimension_depth_mm : null,
      dimension_operator : null,
      sfp_dimensions_min : null,
      sfp_dimensions_max : null,
      fulfillment_vendor : null,
      shipping_carrier : null,
      marketplace : null,
      currency : null,
      status : null,
      fbm_size: null,
      weight_g: 0,
    },
    single_article_pick: 0,
    cost_article_returned_percentage: 15,
    cost_article_shipped_percentage: 15,
    status: 0
  });

  const percentages = [
    { label: '0%', value: 0 },
    { label: '5%', value: 5 },
    { label: '10%', value: 10 },
    { label: '15%', value: 15 },
    { label: '20%', value: 20 },
    { label: '25%', value: 25 },
  ]

  let { data : getFullfilmetns } = useQuery(getFulfillmentVendorsSettings, {
    variables: {
        order: 'id',
        filter: 'status'
    }
  });

  let { data : getMarketplaces } = useQuery(getMarketplacesSettings, {
    variables: {
        order: 'id',
        filter: 'status'
    }
  });
  
  let { data : getShippingCarriesData } = useQuery(getShippingCarriers);
  let { data : getFBMSizesData } = useQuery(getFBMSizes);
  // let { data : getShippingUnitsData } = useQuery(getShippingUnits);
  
  useEffect(() => {
    if(getShippingCarriesData){
      setShippingCarriers([...getShippingCarriesData.shippingCarriers])
      setFilteredShippingCarriers([...getShippingCarriesData.shippingCarriers])
    }

    if(getFullfilmetns){
      setFulfilments([...getFullfilmetns.fulfillmentVendors.filter(x => [1,2,8,9,10,15,16].indexOf(+x.id) > -1)])
    }

    if(getMarketplaces){
      setMarkpetplaces([...getMarketplaces.marketplaces])
    }

    if(getFBMSizesData){
      const arr = []
      getFBMSizesData.getFBMSizes.map(x => arr.push(
          {
            id : x.id,
            name : `${x.size} = ${x.min} - ${x.max}cm`,
            code: x.uid
          }
        )
      )

      setFBMSizes([...arr])
    }
  }, [getShippingCarriesData, getFullfilmetns, getMarketplaces, getFBMSizesData])

  useEffect(() => {
    setFormData({
      ...formData,
      ['date_range']: `${moment(startDate).format('DD/MM/YYYY')}-${moment(endDate).format('DD/MM/YYYY')}`
    });
  }, [dateRange])


  const [createFulfilmetnMutation, { }] = useMutation(
    createFulFillmentCost
  );

  const [updateFulfilmentMutation, {}] = useMutation(
    updateFulFillmentCost
  );

  const blurFieldHandler = event => {
    let d = {...formData}
    d[event.target.id] = Number(event.target.value).toFixed(2)
    

    setFormData({...d});
  }

  const changeFieldHandler = event => {
    let d = {...formData}

    if(event.target.id == 'weight_g'){
      d.unit_data.weight_g = event.target.value
    }else{
      d[event.target.id] = event.target.value
    }

    setFormData({...d});
  };

  const changeSelectHandler = (option, data) => {
    let d = {...formData}
    if(data.name === 'fbm_size'){
      const t = FBMSizes.filter(x => x.id == option.value)
      if(t.length){
          setFBMCode(t[0].code)
      }

      d.unit_data.fbm_size = t[0].code
    }

    if(data.name === 'fulfillment_vendor'){
      d.unit_data.fulfillment_vendor = fulfilments.find(x => x.id == option.value).name
    }

    if(data.name === 'shipping_carrier'){
      d.unit_data.shipping_carrier = option.value
    }
    
    if(data.name === 'marketplace'){
      d.unit_data.marketplace = marketplaces.find(x => x.id == option.value).name
    }

    if(data.name === 'unit_weight'){
      d.unit_data.unit_weight = option.value
    }

    if(data.name === 'unit_size'){
      d.unit_data.unit_size = option.value
    }

    if(data.name === 'currency'){
      d.unit_data.currency = option.value
    }

    if(data.name === 'cost_article_returned_percentage'){
      d.cost_article_returned_percentage = option.value
    }

    if(data.name === 'cost_article_shipped_percentage'){
      d.cost_article_shipped_percentage = option.value
    }

    setFormData({...d});
  };


  const submitHandler = async event => {
    event.preventDefault();

    formData.unit_data = JSON.stringify(formData.unit_data)

    try {
      if (itemId) {
        delete formData.__typename
        delete formData.error


        const { data } = await updateFulfilmentMutation({
          variables: {
            input: formData,
            id: itemId.id,
          },
          id: itemId.id
        });

        if (data.updateFulFillmentCost.error) {
          throw new Error('Server responded with no data');
        }

        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: `Recored was successfully ${itemId ? 'updated' : 'created'}`
          }
        });
        refetch();
    
        setModalOpen(false);
      } else {
        console.log('------------')

        const { data } = await createFulfilmetnMutation({
          variables: {
            input: formData
          }
        });

        formData.unit_data = JSON.parse(formData.unit_data)

        console.log(data, formData)

        if(data.createFulFillmentCost.error){
          setError(data.createFulFillmentCost.error)
          return
        }else if (!data.createFulFillmentCost.id) {
          throw new Error('Server responded with no data');
        }

        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: `Recored was successfully ${itemId ? 'updated' : 'created'}`
          }
        });
        refetch();
    
        setModalOpen(false);
      }    

    } catch (err) {
      console.log(err)
      // setError('Internal server error');
    }


  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const currencyIcon = {
    eur: '€',
    gbp: '£',
    sek: 'kr',
    pln: 'zł'
}
  
  return (
    <form onSubmit={submitHandler} className="roles-form">
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {itemId ? 'Edit' : 'Add'} Fulfillment & Shipping cost
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError className="modal-thin" error={error} setError={setError} />

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Fulfillment vendor</label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="fulfillment_vendor"
                    name="fulfillment_vendor"
                    value={createDefaultOption(fulfilments.filter(x => x.name == formData.unit_data.fulfillment_vendor)[0])}
                    onChange={changeSelectHandler}
                    options={convertDataToOptions(fulfilments)}
                    isDisabled={true}
                />
                            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data.fulfillment_vendor}
            />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Shipping carrier</label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="shipping_carrier"
                    name="shipping_carrier"
                    isDisabled={true}
                    value={createDefaultOption(filteredShippingCarriers.filter(x => x.name == formData.unit_data.shipping_carrier)[0])}
                    onChange={changeSelectHandler}
                    options={convertTitleToOptions(shippingUnits.filter(x => x.fulfillment_vendor == formData.unit_data.fulfillment_vendor).map(x => x.shipping_carrier).filter(onlyUnique))}
                />
                            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data.shipping_carrier}
            />
            </div>
        </div>  

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab">Marketplace</label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="marketplace"
                    name="marketplace"
                    value={createDefaultOption(marketplaces.filter(x => x.name.toLowerCase() == formData.unit_data.marketplace?.toLowerCase())[0])}
                    onChange={changeSelectHandler}
                    options={convertDataToOptions(marketplaces)}
                    isDisabled={true}
                />
                            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data.marketplace}
            />
            </div>
        </div>  


        { formData && formData.unit_data && formData.unit_data.fulfillment_vendor !== 'FBA' && formData.unit_data.fulfillment_vendor !== 'FBA Small & Light' && formData.unit_data.fulfillment_vendor !== 'S&S Prime' && (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab"> Unit</label>
              <div style={{ width: '300px' }}>
                    <Select
                        type="text"
                        id="unit_weight"
                        name="unit_weight"
                        isDisabled={true}
                        value={convertTitleToOptions([formData.unit_data?.unit_weight])}
                        onChange={changeSelectHandler}
                        options={convertTitleToOptions(shippingUnits.filter(x => x.fulfillment_vendor == formData.unit_data.fulfillment_vendor && x.shipping_carrier == formData.unit_data.shipping_carrier).map(x => x.unit_weight))}
                    />
                                <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data?.unit_weight}
            />
              </div>
          </div>  
        )}
        
        { formData && formData.unit_data.fulfillment_vendor === 'S&S Prime' && formData.unit_data.shipping_carrier === 'DPD' && (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab"> Unit</label>
              <div style={{ width: '300px' }}>
                  <Select
                      type="text"
                      id="fbm_size"
                      name="fbm_size"
                      isDisabled={true}
                      value={createDefaultOption(FBMSizes.filter(x => x.code == formData.unit_data.fbm_size)[0])}
                      onChange={changeSelectHandler}
                      options={convertDataToOptions(FBMSizes)}
                  />
                              <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data.fbm_size}
            />
              </div>
          </div>  
        )}

        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
            <label htmlFor="tab"> Currency </label>
            <div style={{ width: '300px' }}>
                <Select
                    type="text"
                    id="currency"
                    name="currency"
                    isDisabled={true}
                    value={convertTitleToOptions([formData.unit_data.currency])}
                    onChange={changeSelectHandler}
                    options={convertTitleToOptions(currencies)}
                />
                            <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data.currency}
            />
            </div>
        </div>  

        {formData && formData.unit_data && (formData.unit_data.fulfillment_vendor === 'FBA' || formData.unit_data.fulfillment_vendor === 'FBA Small & Light') && formData.unit_data.shipping_carrier === 'FBA' && (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab" className="d-flex">
                  Height x Width x Depth (mm)
                  {/* <div className="infoButton">
                    <img src="/assets/images/info-button.png" alt="" />
                  </div>  */}
                </label>
                <div style={{ width: '300px' }} >
                  <Select
                      type="text"
                      id="unit_size"
                      name="unit_size"
                      isDisabled={true}
                      value={convertTitleToOptions([formData.unit_data.unit_size])}
                      onChange={changeSelectHandler}
                      options={convertTitleToOptions(shippingUnits.filter(x => x.fulfillment_vendor == formData.unit_data.fulfillment_vendor && x.shipping_carrier == formData.unit_data.shipping_carrier).map(x => x.unit_size).filter(onlyUnique))}
                  />
                              <input
              tabIndex={-1}
              style={{ opacity: 0, height: 0, position: 'absolute' }}
              required={!formData.unit_data.unit_size}
            />
                </div>
          </div>  
        )}


        { formData && formData.unit_data && (formData.unit_data.fulfillment_vendor === 'FBA' || formData.unit_data.fulfillment_vendor === 'FBA Small & Light') && formData.unit_data.shipping_carrier === 'FBA'  && (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab"> Max weight </label>
              <div style={{ width: '300px' }}>
                  <Select
                        type="text"
                        id="unit_weight"
                        name="unit_weight"
                        isDisabled={true}
                        value={convertTitleToOptions([formData.unit_data.unit_weight])}
                        onChange={changeSelectHandler}
                        options={convertTitleToOptions(shippingUnits.filter(x => x.fulfillment_vendor == formData.unit_data.fulfillment_vendor && x.shipping_carrier == formData.unit_data.shipping_carrier).map(x => x.unit_weight))}
                    />
                 <input
                  tabIndex={-1}
                  style={{ opacity: 0, height: 0, position: 'absolute' }}
                  required={!formData.unit_data.unit_weight}
                />
              </div>
          </div>  
        )}

        { formData && formData.unit_data && formData.unit_data.fulfillment_vendor !== 'S&S Prime' && formData.unit_data.fulfillment_vendor !== 'FBA' && formData.unit_data.fulfillment_vendor !== 'FBA Small & Light'  && (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab"> Max weight (g)</label>
              <div style={{ width: '300px' }}>
                  <p className="mb-0">{ +shippingUnits.find(x => x.fulfillment_vendor == formData.unit_data.fulfillment_vendor && x.shipping_carrier == formData.unit_data.shipping_carrier && x.unit_weight == formData.unit_data?.unit_weight)?.weight_g }</p>
              </div>
          </div> 
        )}
        
        { formData && formData.unit_data.fulfillment_vendor === 'S&S Prime' && formData.unit_data.shipping_carrier === 'DPD' && (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab"> SFP Code</label>
              <div style={{ width: '300px' }}>
                  <p className="mb-0">{ formData.unit_data.fbm_size }</p>
              </div>
          </div>  

        )}

                
        <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px', borderBottom: '1px solid #b2b2b2'}}>
            <label htmlFor="tab"></label>
            <div style={{ width: '300px' }} className="dividerDiv">
              <div style={{ width: '100px' }}>
                  <p>Buy price</p>
              </div>
              <div style={{ width: '100px' }}>
                  <p>Margin</p>
              </div>
              <div style={{ width: '100px' }}>
                  <p>Sell price</p>
              </div>
            </div>
        </div>

        { formData && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier !== 'FBA' && (
          <>
            
            <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab"> Order received</label>
                <div style={{ width: '300px' }}>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                          className="form-control"
                          type="number"
                          step="0.01"
                          min={0}
                          id="cost_order_received"
                          name="cost_order_received"
                          value={formData.cost_order_received}
                          onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={!formData.cost_order_received}
                        disabled={true}
                          />
                      <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                    </div>
                    <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={+formData.cost_order_received == 0}
                      />
                    </div>
                </div>
            </div>  

            <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab"> Article pick</label>
                <div style={{ width: '300px' }}>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                          className="form-control"
                          type="number"
                          min={0}
                          step="0.01"
                          id="single_article_pick"
                          name="single_article_pick"
                          value={formData.single_article_pick}
                          onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={!formData.single_article_pick}
                        disabled={true}
                          />
                          <div className="currencyIcon">
                              <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                          </div>
                          <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={+formData.single_article_pick == 0}
                      />
                    </div>
                </div>
            </div>  

            <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab"> Bundle article pick</label>
                <div style={{ width: '300px' }}>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                          className="form-control"
                          type="number"
                          step="0.01"
                          min={0}
                          id="bundle_article_pick"
                          name="bundle_article_pick"
                          value={formData.bundle_article_pick}
                          onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={!formData.bundle_article_pick}
                        disabled={true}
                          />
                          <div className="currencyIcon">
                            <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                        </div>
                        <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={+formData.bundle_article_pick == 0}
                      />
                    </div>
                </div>
            </div>  

            <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab" className='d-flex'> Order shipping</label>
                
                
                <div style={{ width: '300px' }}>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                          className="form-control"
                          type="number"
                          id="cost_order_shipping"
                          min={0}
                          step="0.01"
                          name="cost_order_shipping"
                          value={formData.cost_order_shipping}
                          onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={!formData.cost_order_shipping}
                        disabled={true}
                          />
                        <div className="currencyIcon">
                          <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                      </div>
                      <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={+formData.cost_order_shipping == 0}
                      />
                    </div>
                </div>
            </div>  

            <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab" className='d-flex'> Order returning</label>
                <div style={{ width: '300px' }}>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                          className="form-control"
                          type="number"
                          id="cost_return_article"
                          min={0}
                          step="0.01"
                          name="cost_return_article"
                          value={formData.cost_return_article}
                          onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={!formData.cost_return_article}
                        disabled={true}
                          />
                        <div className="currencyIcon">
                          <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                      </div>
                      <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={+formData.cost_order_shipping == 0}
                      />
                    </div>
                </div>
            </div> 
            { (formData.unit_data && formData.unit_data.shipping_carrier) && (formData.unit_data.shipping_carrier == 'DHL' || formData.unit_data.shipping_carrier == 'DPD') && (
                <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                  <label htmlFor="tab" className='d-flex'> Surcharge CO2/Energy</label>
                  <div style={{ width: '300px' }}>
                    <div style={{ width: '80px', position: 'relative' }}>
                        <input
                            className="form-control"
                            type="number"
                            id="surcharge"
                            min={0}
                            step="0.01"
                            name="surcharge"
                            value={formData.surcharge}
                            onChange={changeFieldHandler}
                            onBlur={blurFieldHandler}
                            required={!formData.surcharge}
                            disabled={true}
                            />
                          <div className="currencyIcon">
                            <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                        </div>
                        <input
                          tabIndex={-1}
                          style={{ opacity: 0, height: 0, position: 'absolute' }}
                          required={+formData.cost_order_shipping == 0}
                        />
                      </div>
                  </div>
                </div> 
            )}
          </>
        )}


        { formData && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier !== 'FBA' ? (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px', borderTop: '1px solid #b2b2b2'}}>
              <label htmlFor="tab" className='d-flex'> 
               Total article shipped
               <div className="u_infoButton">
                    <img src="/assets/images/info-button.png" alt=""  />

                    <div className="infoBox">
                        <span>Total cost assuming only 1 article shipped (no bundle picks) includes Order received + Article pick + Order shipping plus the shown margin</span>
                    </div>
                </div>
              </label>

              <div style={{ width: '300px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80px', display: 'flex', alignItems: 'center', position: 'relative'  }}>
                  {/* <p className="mb-0" style={{paddingLeft: '16px'}}>{ Number(totalArticleShipped).toFixed(2) }</p> */}
                  <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        value={ Number(totalArticleShipped).toFixed(2) }
                        disabled={true}
                    />
                  <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                    </div>
                </div>
                <div style={{ width: '80px' }}>
                  <Select
                      type="text"
                      id="cost_article_shipped_percentage"
                      name="cost_article_shipped_percentage"
                      value={percentages.find(w => w.value == formData.cost_article_shipped_percentage)}
                      options={percentages}
                      onChange={changeSelectHandler}
                      isDisabled={true}
                  />
                </div>
                <div style={{ width: '80px', position: 'relative' }}>
                    <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        value={Number(totalArticleShipped * (1 + +Number(+formData.cost_article_shipped_percentage/100).toFixed(2))).toFixed(2)}
                        disabled={true}
                    />
                    <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                    </div>
                </div>
                {formData.unit_data && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier == 'DHL' && (
                  <div className="u_infoButton" style={{ marginTop: '7px' }}>
                    <img src="/assets/images/info-button.png" alt="" />
                    
                    <div className="infoBox">
                        <span>Plus DHL CO<small>2</small> costs</span>
                    </div>
                  </div>
                )}
                {formData.unit_data && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier == 'DPD' && (
                  <div className="u_infoButton" style={{ marginTop: '7px' }}>
                    <img src="/assets/images/info-button.png" alt="" />
                    
                    <div className="infoBox">
                        <span>Plus DPD Energy costs</span>
                    </div>
                  </div>
                )}
              </div>
          </div>  
        ) : (
          <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
              <label htmlFor="tab">
                Total article shipped
              </label>
              <div style={{ width: '300px', display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ width: '80px', position: 'relative' }}>
                    <input
                          className="form-control"
                          type="number"
                          id="total_cost_article_shipped"
                          min={0}
                          step="0.01"
                          name="total_cost_article_shipped"
                          value={totalArticleShipped}
                          onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={!totalArticleShipped}
                        disabled={true}
                          />
                    <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                    </div>
                    <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={+totalArticleShipped == 0}
                      />
                </div>
                <div style={{ width: '80px' }}>
                  <Select
                      type="text"
                      id="cost_article_shipped_percentage"
                      name="cost_article_shipped_percentage"
                      value={percentages.find(w => w.value == formData.cost_article_shipped_percentage)}
                      options={percentages}
                      onChange={changeSelectHandler}
                      isDisabled={true}
                  />
                </div>
                <div style={{ width: '80px', position: 'relative' }}>
                    <input
                        className="form-control"
                        type="number"
                        step="0.01"
                        value={Number(totalArticleShipped * (1 + +Number(+formData.cost_article_shipped_percentage/100).toFixed(2))).toFixed(2)}
                        disabled={true}
                    />
                    <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                    </div>
                </div>
                {formData.unit_data && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier == 'DHL' && (
                    <div className="u_infoButton" style={{ marginTop: '7px' }}>
                      <img src="/assets/images/info-button.png" alt="" />
                      
                      <div className="infoBox">
                          <span>Plus DHL CO<small>2</small> costs</span>
                      </div>
                    </div>
                  )}
                  {formData.unit_data && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier == 'DPD' && (
                    <div className="u_infoButton" style={{ marginTop: '7px' }}>
                      <img src="/assets/images/info-button.png" alt="" />
                      
                      <div className="infoBox">
                          <span>Plus DPD Energy costs</span>
                      </div>
                    </div>
                  )}
              </div>
          </div>  
        )}



            <div className="form-group mb-0  d-flex align-items-center justify-content-between" style={{ padding: '9px 7px'}}>
                <label htmlFor="tab" className='d-flex'>
                  Total article returned
                  { formData && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier !== 'FBA' && (
                    <div className="u_infoButton">
                      <img src="/assets/images/info-button.png" alt=""  />

                      <div className="infoBox">
                          <span>Total cost assuming only 1 article returned includes Order received + Article pick + Order returning plus the shown margin.</span>
                      </div>
                    </div>
                  )}
                </label>
                <div style={{ width: '300px', display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                            className="form-control"
                            type="number"
                            id="total_cost_article_returned"
                            min={0}
                            step="0.01"
                            name="total_cost_article_returned"
                            value={totalArticleReturned}
                            onChange={changeFieldHandler}
                        onBlur={blurFieldHandler}
                        required={totalArticleReturned}
                        disabled={true}
                        />
                      <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                      </div>
                      <input
                        tabIndex={-1}
                        style={{ opacity: 0, height: 0, position: 'absolute' }}
                        required={totalArticleReturned == 0}
                      />
                  </div>
                  <div style={{ width: '80px' }}>
                    <Select
                        type="text"
                        id="cost_article_returned_percentage"
                        name="cost_article_returned_percentage"
                        value={percentages.find(w => w.value == formData.cost_article_returned_percentage)}
                        options={percentages}
                        onChange={changeSelectHandler}
                        isDisabled={true}
                        />
                  </div>
                  <div style={{ width: '80px', position: 'relative' }}>
                      <input
                          className="form-control"
                          type="number"
                          step="0.01"
                          value={Number(totalArticleReturned * (1 + +Number(+formData.cost_article_returned_percentage/100).toFixed(2))).toFixed(2)}
                          disabled={true}
                      />
                      <div className="currencyIcon">
                        <span>{ currencyIcon[formData.unit_data.currency?.toLowerCase()] }</span>
                      </div>
                  </div>
                  {formData.unit_data && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier == 'DHL' && (
                    <div className="u_infoButton" style={{ marginTop: '7px' }}>
                      <img src="/assets/images/info-button.png" alt="" />
                      
                      <div className="infoBox">
                          <span>Plus DHL CO<small>2</small> costs</span>
                      </div>
                    </div>
                  )}
                  {formData.unit_data && formData.unit_data.shipping_carrier && formData.unit_data.shipping_carrier == 'DPD' && (
                    <div className="u_infoButton" style={{ marginTop: '7px' }}>
                      <img src="/assets/images/info-button.png" alt="" />
                      
                      <div className="infoBox">
                          <span>Plus DPD Energy costs</span>
                      </div>
                    </div>
                  )}
                </div>
            </div>  

  
        
      </div>
      
      <div className="modal-footer justify-content-start">
        { loader? (
          <div className="spinner absolute">Spinner</div>
        ) : (
          //   <button  type="submit" className="btn btn-primary">
          //   Save
          // </button>
          <></>
        ) }        
      </div>
    </form>
  )
};

export default ShippingCostModal;
