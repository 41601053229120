import React from 'react';

import './ModalError.scss';

const ModalError = ({ error, setError, close, className = '', isArray = false }) => {
  if (!error) {
    return null;
  }

  const modelErrors = isArray ? null : error.split(";");
  
  let priceErrors = []

  if(isArray){
    Object.keys(error).map(x => {
      if(x == 'prices' || x == 'marketplaces'){
        Object.keys(error[x]).map((w, y) => {
          priceErrors.push(error[x][Object.keys(error[x])[y]])
        })

        delete error[x]
      }
    })
  }

  console.log(priceErrors)
 
  const cleanError = () => {
    setError(null);
  };

  return (
    <div className={`modal-error-field ${className} ${close? 'w-100' : ''}`}>
      {
        className == 'warning' ? 
        <div className='error-message'><i className='fas fa-info-circle mr-1 yellow-info-icon'></i> Warning: <ul className="my-2">
          { isArray ? (
            Object.keys(error).map(mError => (
              <li className="error-message">{error[mError]}</li>
            ))
          ) : (
            modelErrors.map(mError => (
              <li className="error-message">{mError}</li>
            ))
          )
          }</ul></div> 
        : 
        <div className='error-message'><i className='fas fa-info-circle mr-1 red-info-icon'></i> Error:<ul className="my-2">
          { isArray ? (
            Object.keys(error).map(mError => (
              <li className="error-message">{error[mError]}</li>
            ))
          ) : (
            modelErrors.map(mError => (
              <li className="error-message">{mError}</li>
            ))
          )}
          { priceErrors && priceErrors.map(priceError => (
            <>
              {Object.keys(priceError).map(x => (
                <li className="error-message">{priceError[x]}</li>
              ))}
            </>
          )) }
          </ul></div> 
      }
      {setError && (
        <div className={`modal-error-field__close-btn  ${close? 'form-error-close-button' : ''}`} onClick={cleanError}>
          ×
        </div>
      )}
    </div>
  );
};

export default ModalError;