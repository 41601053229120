import React from 'react';

/** Style */
import './Button.css';

const Button = ({ type = 'button', text = 'Submit', display = 'primary', clicked = () => {} }) => {
  return (
    <button className={`btn btn-${display} custom-btn  mr-1 mb-1`} type={type} onClick={clicked}>
      {text}
    </button>
  );
};

export default Button;
