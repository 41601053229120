import React, { useEffect, useState } from 'react';
import ModalForm from '../../../components/modal/ModalForm'
import { deleteLinkedItem } from '../../../services/api/mutationService';
import VariationFormModal from './VariationModalForm';
import { useMutation , useQuery} from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import { getAttributes } from '../../../services/api/queryService';

const VariationsForm = ({ productData, productId, refetch, update }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [itemId, setItem] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])
  const [deleteLinkedItemMutation, {}] = useMutation(deleteLinkedItem);
  const dispatch = useDispatch();
  const [attributes, setAttributes] = useState([]);

  let { data : getAttributesData } = useQuery(getAttributes);

  useEffect(() => {
    if(getAttributesData){
      const _arr = []
      getAttributesData.attributes.filter(x => +x.manufacturer_id === +productData.manufacturer.id).map(x => {
        _arr.push(x)
      })

      setAttributes([..._arr])
    }
  }, [getAttributesData])
  

  const addRowHandler = () => {
    setItem(null)
    setModalOpen(true)
  };

  const edit = (id) => {
    setItem(id)
    setModalOpen(true)
  }

  useEffect(() => {
    [...productData.linkedItems].forEach((item, index) => {
        if(item.product === null) productData.linkedItems.splice(index, 1);
    });

    setFormData(productData.linkedItems)
  }, [productData])

  
  const checkboxClickHandler = (item, event) => {
    let updatedSelectedItems = [...selectedItems];
    if (event.target.checked) {
      updatedSelectedItems.push(+item.id);
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(i => i !== +item.id);
    }

    updatedSelectedItems.sort((a, b) => b - a);
    setSelectedItems(updatedSelectedItems);
  };

  const deleteLinkedItems = async event => {
    event.preventDefault()

    if(!window.confirm("Are you sure to delete linked item(s)?")) return false;

    const { data } = await deleteLinkedItemMutation({
      variables: { 
        input: {id: selectedItems}, 
      }
    });

    if (data) {
      
      setSelectedItems([])

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully deleted'
        }
      });
      refetch();
    }
  }

  const attributesTitles = !productData.attributes ? [] : productData.attributes.split(',')


  return (
    <form>
      <div className="overflow-auto">
        <table className="table mt-0">
          <thead>
            <tr>
              <th></th>
              <th>PID</th>
              <th>Product name</th>
              <th>Product Details</th>
              <th>Product Contents</th>
              <th>Packaging unit</th>
              { attributesTitles.map(x => (
                  <th>{ x }</th>
              )) }
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((row, index) => row.product != null && (
              <tr key={index} >
                <td className="text-center" id={row.id}>
                    <input
                    type="checkbox"
                    checked={selectedItems.some(i => +i === +row.id)}
                    onChange={checkboxClickHandler.bind(null, row)}
                    />
                </td>
                <td>
                  {row.product.slug}
                </td>

                <td>
                  <a  style={{color: '#00488c'}} target="_blank" href={`/products/${row.product.slug}/edit`}>
                    {row.product.product_name}
                  </a>
                </td>

                <td>
                  {row.product.product_details}
                </td>

                <td>
                  {row.product.product_contents}
                </td>

                <td>
                  {row.product.packagingUnit.name}
                </td>

                {attributes && attributes.length > 0 && attributesTitles.map((x, i) => {
                    const attrs = row.product.attribute_values.split(',')

                    return (
                      <td>{ attributes.find(a => +a.id === +attrs[i])?.attribute_name }</td>
                    )
                    
                }) }

                <td style={{ minWidth: '150px' }}>
                  {row.product.status ? (
                    <span className="status-badge status-badge-active">Active</span>
                  ) : (
                    <span className="status-badge status-badge-archived">Archived</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {modalOpen && (
          <ModalForm setModalOpen={setModalOpen}>
            <VariationFormModal
              itemId={itemId}
              product_parent_id={productData.product_parent_id} 
              setModalOpen={() => {setModalOpen(false); refetch();}}
            />
          </ModalForm>
        )}

          <div className='d-flex'>
            <button
              type="button"
              className="btn btn-new"
              onClick={addRowHandler}
            >
              <i className="fas fa-plus-square mr-1" />
              New
            </button>
            <button
                type="button"
                className="btn btn-danger ml-3"
                onClick={deleteLinkedItems}
                disabled={!selectedItems.length} >
                Delete
            </button>
        </div>
      </div>
    </form>
  );
};

export default VariationsForm;
