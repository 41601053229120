import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';

import Routes from '../../lib/constants/routes';
import DetailsHeader from '../../components/details-header/DetailsHeader';
import ListingFormCard from './ListingFormCard';
import ProductDescription from './forms/ProductDescription';
import ProductTitle from './forms/ProductTitle';
import ProductBullets from './forms/ProductBullets';
import ProductKeywords from './forms/ProductKeywords';
import ProductImages from './forms/ProductImages';
import ProductFiles from './forms/ProductFiles';

import { getProduct, getProductSettings, me } from '../../services/api/queryService';

import './Product.scss';
import ProductCharacteristics from './forms/ProductCharacteristics';
import ProductSubtitle from './forms/ProductSubtitle';
import ListingCategory from './forms/ListingCategory';
import ProductRichContent from './forms/ProductRichContent';

const processSkusAsins = (productMarketplaces) => {
  const processedSkusAsins = productMarketplaces;

  processedSkusAsins.sort((a, b) => {
    const anum = +a.id.split('-C')[1];
    const bnum = +b.id.split('-C')[1];
    if (anum < bnum) {
      return -1;
    }
    if (anum > bnum) {
      return 1;
    }
    return 0;
  });

  return processedSkusAsins.map(item => {
    item = { ...item, ...item.skuFullFilmentVendors };
    item.marketplace_id_type = item.marketplace_id_type === 'SKU' ? 1 : 0;
    item.marketplace_number_id = item.marketplace_product_id;
    item.cid = item.product_child_id;
    delete item.marketplace_product_id;
    delete item.skuFullFilmentVendors;
    delete item.__typename;
    delete item.id;
    delete item.cid;
    return item;
  });
};

const EditListing = ({ match, update, getProductTitle, defaultActiveTab = 'content' }) => {
  const { data: user } = useQuery(me);

  const { data: productData, refetch } = useQuery(getProduct, {
    variables: { slug: match.params.id }
  });
  const { data: productSettingsData } = useQuery(getProductSettings);
  const history = useHistory();
  const location = useLocation();

  let selectedTab = new URLSearchParams(location.search).get('tab');

  if (!selectedTab) {
    selectedTab = defaultActiveTab;
  }

  let selectData;
  let productPrices;
  let marketplaceOptions;
  let productMarketplaces = [];
  let listing = []
  let currMarketplace = null
  const [currentListing, setCurrentListing] = useState(null)
  const [activeMarketplace, setActiveMarketplace] = useState(1)
  const [activeTab, setActiveTab] = useState(selectedTab)
  const [parentProduct, setParentProduct] = useState(false); 
  const [modelOpen, setModelOpen] = useState(false); 
  const [previewHTML, setPreviewHTML] = useState(null); 
  

  if (productSettingsData) {
    selectData = JSON.parse(productSettingsData.productSettings);
    selectData.marketplaces.forEach(item => {
      if (typeof item.tax_rate === 'string') {
        item.tax_rate = JSON.parse(item.tax_rate);
      }
    });

    marketplaceOptions = selectData.marketplaces.map(item => ({
      id: item.id,
      name: item.name
    }));
  }
  if (productData && productData.product) {
    productData.product.marketplacesInformation = processSkusAsins(
      productData.product.productMarketplaces
    );
    

    productData.product.productMarketplaces.forEach(x => productMarketplaces.push(x.marketplace_id))
    productMarketplaces = [...new Set(productMarketplaces)]

    if(productData.product.listing.length) {
      listing = productData.product.listing
      currMarketplace = listing[0].marketplace_id
    }
  }


  const tabClickHandler = () => {
    history.push(`/products/${match.params.id}/edit`);
  };

  const changeActiveMarketplace = id => {
    setActiveMarketplace(id)

    setCurrentListing({
      ...productData.product.listing.find(x => +x.marketplace_id === +id)
    })
  }

  useEffect(() => {
    if(productData && productData.product && productData.product.listing.length){
        const am = productData.product.listing.sort((a, b) => a.marketplace_id - b.marketplace_id)[0].marketplace_id 
        let amv = activeMarketplace
        if(am !== 1){
          setActiveMarketplace(am)
          amv = am
        }

        setCurrentListing({
          ...productData.product.listing.find(x => x.marketplace_id == amv)
        })
          
      setParentProduct(+productData.product.product_type_id === 8)
      
      let pckgName = ''
      if(+productData.product.product_type_id !== 8){
        pckgName = `| ${productData.product.packagingUnit?.name}`
      }

      getProductTitle(`${productData.product.product_name} ${pckgName}`)
    }
  }, [productData])


  const handleListingChange = e => {
    const k = Object.keys(e)[0]
    currentListing[k] = e[k]
    const newData = {...currentListing}

    setCurrentListing({...newData})
  }

  const handlePreview = value => {
    setPreviewHTML(value)
    setModelOpen(true)
  }

  const changeTab = tab => {
    setActiveTab(tab)
  }



  const tabClickHandlerListing = () => {
    history.push(`/listings/${match.params.id}/edit`);
  };

  const tabClickHandlerLogs = () => {
    history.push(`/product-logs/${match.params.id}`);
  };

  const tabClickHandlerImages = () => {
    history.push(`/listings/${match.params.id}/images`);
  };
  
  return (
    <>
      {!productData || !productSettingsData ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <>
          {/* start page title */}
          <div className="row">
            <div className="col-12">
              <div className="page-title-box">
                <h4 className="page-title">
                  {
                    !parentProduct ? (
                    <>
                      {productData.product.product_name} | {productData.product.packagingUnit?.name}
                    </>
                  ) : (
                    productData.product.product_name
                  )}
                </h4>

                <div className="page-title-left">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">PIM</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={`${Routes.products.index}?tab=listings`}>Products</Link>
                    </li>
                    <li className="breadcrumb-item active">{productData.product.product_name}</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          {/* end page title  */} 

          <DetailsHeader renderFor="products" data={productData.product} />

        {/* Tabs */}
        <div className="product-tabs product-tabs-custom col-10">
          <div
            className={`tab`}
            onClick={tabClickHandler}
          >
            Information
          </div>
        
          <div
            className={`tab ${activeTab === 'content' ? 'active' : '' }`}
            onClick={tabClickHandlerListing}
          >
            Content
          </div>
          { user.me.user_type == 2 &&
            <div
              className={`tab `}
              onClick={tabClickHandlerLogs}
              >
              Update log
            </div>
          }


            <div
              className={`tab ${activeTab === 'images' ? 'active' : '' }`}
              onClick={tabClickHandlerImages}
            >
              Images
            </div>

          <div className="tab-bar" />
        </div>

          <div className="row">
            {(!currentListing || !currentListing.setting) && (
              <div className="col-12">
                <div className="spinner">Spinner</div>
              </div>
            )}
            {currentListing && currentListing.setting && (
              <>
                { activeTab === 'content' && (
                <>
                <div className="col-2">
                  <div id='listing-nav' className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                    {productData.product.listing.map((item, index) => (                
                      <p key={index} onClick={() => changeActiveMarketplace(item.marketplace_id)} className={`nav-link ${item.marketplace_id === activeMarketplace ? 'active' : ''} `}>{ marketplaceOptions.find(x => x.id == item.marketplace_id)?.name }</p>
                    ))}
                  </div>
                </div>

                {modelOpen && (
                  <>
                    <div className="modal-backdrop show" />
                    <div
                      className="modal"
                      id="exportModal"
                      tabIndex="-1"
                      style={{ display: modelOpen ? 'block' : 'none' }}
                      onMouseDown={null}
                    >
                      <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h4 className="modal-title">HTML Preview</h4>
                            <button type="button" className="close" onClick={() => setModelOpen(false)}>
                              <span aria-hidden="true">&times;</span>
                            </button>
                          </div>
                          <div className="modal-body modal-scroll">
                            <div className="body_preview" dangerouslySetInnerHTML={{ __html: previewHTML }} ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-10" >
                  <ListingFormCard id="productTitle" listing={currentListing} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductTitle
                      selectData={marketplaceOptions}
                      productData={currentListing}
                      product={productData.product}
                      refetch={refetch}
                      update={update}
                      onLisitingChange={handleListingChange}
                    />
                  </ListingFormCard>

                  <ListingFormCard id="productSubtitle" disabled={activeMarketplace != 22} listing={currentListing} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductSubtitle
                      selectData={marketplaceOptions}
                      productData={currentListing}
                      refetch={refetch}
                      update={update}
                      onLisitingChange={handleListingChange}
                    />
                  </ListingFormCard>

                  <ListingFormCard id="productCharacteristics" disabled={activeMarketplace != 22} listing={currentListing} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductCharacteristics
                    selectData={marketplaceOptions}
                    productData={currentListing}
                    product={productData.product}
                    refetch={refetch}
                    update={update}
                    onLisitingChange={handleListingChange}
                    />
                  </ListingFormCard>

                  <ListingFormCard id="productBullets" listing={currentListing} disabled={activeMarketplace == 22 || activeMarketplace == 23} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductBullets
                    selectData={marketplaceOptions}
                    productData={currentListing}
                    refetch={refetch}
                    update={update}
                    activeMarketplace={activeMarketplace}
                    onLisitingChange={handleListingChange}
                    />
                  </ListingFormCard>

                  <ListingFormCard id="productDescription" listing={currentListing} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductDescription
                      selectData={marketplaceOptions}
                      productData={currentListing}
                      refetch={refetch}
                      product={productData.product}
                      update={update}
                      activeMarketplace={activeMarketplace}
                      onLisitingChange={handleListingChange}
                    />
                  </ListingFormCard>
                  <ListingFormCard id="productRichContent" listing={currentListing} disabled={activeMarketplace != 22}  settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductRichContent
                      selectData={marketplaceOptions}
                      productData={currentListing}
                      refetch={refetch}
                      product={productData.product}
                      update={update}
                      activeMarketplace={activeMarketplace}
                      onLisitingChange={handleListingChange}
                      onPreview={handlePreview}
                    />
                  </ListingFormCard>
  
                  <ListingFormCard id="productKeywords" listing={currentListing} disabled={activeMarketplace == 22} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductKeywords
                      selectData={marketplaceOptions}
                      productData={currentListing}
                      refetch={refetch}
                      update={update}
                      onLisitingChange={handleListingChange}
                      activeMarketplace={activeMarketplace}
                    />
                  </ListingFormCard>

                    <ListingFormCard id="listingCateogry" listing={currentListing} disabled={activeMarketplace < 22} settings={JSON.parse(currentListing.setting.settings)}>
                      <ListingCategory
                        selectData={marketplaceOptions}
                        productData={currentListing}
                        refetch={refetch}
                        update={update}
                        onLisitingChange={handleListingChange}
                        activeMarketplace={activeMarketplace}
                      />
                    </ListingFormCard>

                  {/* <ListingFormCard id="productDocuments" listing={currentListing} settings={JSON.parse(currentListing.setting.settings)}>
                    <ProductFiles 
                      selectData={marketplaceOptions}
                      productData={currentListing}
                      refetch={refetch}
                      update={update}
                      activeMarketplace={activeMarketplace}
                    />
                  </ListingFormCard> */}
                </div>
                </>
                ) }




                { activeTab === 'images' && (
                  <>
                    <div className="col-12" >
                      { marketplaceOptions && marketplaceOptions?.length > 0 && marketplaceOptions.map(x => (
                        <ListingFormCard id="productImages" activeMarketplace={x} formPreOpen={true} listing={productData.product.listing.find(w => +w.marketplace_id === +x.id)} settings={JSON.parse(productData.product.listing.find(w => +w.marketplace_id === +x.id).setting.settings)}>
                          <ProductImages
                            selectData={marketplaceOptions}
                            productData={productData.product.listing.find(w => +w.marketplace_id === +x.id)}
                            refetch={refetch}
                            update={update}
                            activeMarketplace={+x.id}
                          />
                        </ListingFormCard>
                      )) }

                    </div>
                  </>
                ) }



              </>
            )}
          </div>
          
        </>
      )}
    </>
  );
};

export default EditListing;
