import React, { useState, useEffect } from 'react';

const ChildInput = ({ data , updatedFields }) => {
  const [fields, setFields] = useState({});
  const [update, setUpdate] = useState(false);
  const [number, setNumber] = useState(0);

  useEffect(() => {
    if (data.value) {
      const valueObject = JSON.parse(data.value)[0];

      Object.keys(valueObject).forEach(item => {
        valueObject[item] = item;
      });

      setFields(valueObject);
    }
  }, [data]);

  const childInputHandler = () => {
    const childInputsValue = {};

    Object.keys(fields).forEach(item => {
      if (fields[item]) {
        childInputsValue[fields[item]] = fields[item];
      }
    });

    return updatedFields(data.inputConf.name, childInputsValue);
  };
  const deleteFieldHandler = str => {
    let fieldsArray = {};
    fieldsArray = fields;

    delete fieldsArray[str];
    setFields(fieldsArray);
    setUpdate(!update);
    childInputHandler();
  };

  const addInput = () => {
    setFields({ ...fields, [`empty${number + 1}`]: '' });
    setNumber(number + 1);
  };

  const inputChangeHandler = (e, item) => {
    const fieldsObject = fields;
    fieldsObject[item] = e.target.value;
    setFields(fieldsObject);
    setUpdate(!update);

    childInputHandler();
  };

  return (
    <>
        <button type='button' onClick={addInput} className="form-control col-md-1 offset-md-11">+</button> 
            {
                Object.keys(fields).map( (item , index) => {

                    return (<div className="row pl-2 pr-2 pt-1" key={index}>
                            <input 
                                type={data.inputConf.type}
                                // id={data.inputConf.name}
                                className="form-control col-md-10"
                                placeholder={data.inputConf.placeholder}
                                defaultValue={item}
                                onChange = {(e) => inputChangeHandler(e,item)}
                                required={true}
                            />
                            <button type='button' onClick={ () => deleteFieldHandler(item) } className="form-control col-md-1 offset-md-1">x</button> 
                        </div>)
                })
            }
        </>
    )
};

export default ChildInput;
