import React, {useEffect, useState} from 'react';
import { useQuery } from '@apollo/react-hooks';
import Table from '../../components/table/Table';
import ModalForm from '../../components/modal/ModalForm'
import { getAllReport as QUERY } from '../../services/api/queryService';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';
import Routes from '../../lib/constants/routes';
import ReportsModal from './ReportsModal'

const description = {
  module: 'analyticsReports',
  description: 'analyticsReports',
  req: 'getAllReport',
  url: Routes.analyticsSettings,
  tab: 'search'
};

const ReportsTab = ({ location }) => {
  const [ changedData, setChangedData ] = useState({});
  const [ loader, setLoader ] = useState(true);
  const { first, page, keyword, filter } = queryStringHandler(location.search);
  const [modalOpen, setModalOpen] = useState(false);
  const [item, setItem] = useState({})

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (data) {
      data.getAllReport.forEach((item) => {
        if (item.embeddedLocation) {
          const tempUrl = item.embeddedLocation.split('/')
          if(tempUrl.length == 3){
            item.embeddCopyedLocation = `analytics?section=${tempUrl[0]}&subsection=${tempUrl[1]}&tab=${tempUrl[2]}`
            item.embeddedLocation =  `analytics?section=${tempUrl[0]}&subsection=${tempUrl[1]}&tab=${tempUrl[2]}`
          }
        }

        if (item.userType === 1) {
          item.userType = 'Internal';
        }
        if (item.userType === 2) {
          item.userType = 'Manufacturer'
        }
        if (item.userType === 0) {
          item.userType = ''
        }
        
        delete item.sectionStatus
      })
      const newData = {
        getAllReport: {
          data: data.getAllReport,
        }
      }
      setChangedData(newData)
      setLoader(false)
    }
  }, [data])

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      {loader ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="reports-page-table">
          <Table
            loading={loading}
            refetch={refetch}
            description={description}
            pagerData={{
              ...fetchData(description.req, changedData).pagerData
            }}
            tableData={fetchData(description.req, changedData).data}
            expandedHeader={false}
            hasAction={true}
            onAction={(item) => {setItem(item); setModalOpen(true)}}
            hasExport={false}
            filter={false}
            notClickable={true}
          />

          {modalOpen && (
            <ModalForm setModalOpen={setModalOpen}>
              <ReportsModal
                itemId={item}
                setModalOpen={() => {setModalOpen(false); refetch()}}
              />
            </ModalForm>
          )}
        </div>
      )}
    </>
  );
};

export default ReportsTab;
