import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateProductPrices, deleteProductPrice } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { CURRENCY_SYMBOLS } from '../../../lib/constants/fields';
import { getExchangeRates } from '../../../services/api/queryService';

const pricesFields = {
  price_sell_gross_rrp_ct: 'price_sell_net_rrp_ct',
  price_sell_gross_min_ct: 'price_sell_net_min_ct',
  price_sell_net_max_ct: 'price_sell_gross_max_ct',
  provision: 'provision'
};

export const fieldsConvertToCents = {
  price_sell_gross_base_unit_ct: true,
  price_sell_gross_rrp_ct: true,
  price_sell_net_rrp_ct: true,
  price_sell_gross_min_ct: true,
  price_sell_net_min_ct: true,
  price_sell_gross_max_ct: true,
  price_sell_net_max_ct: true,
  price_buy_net_actual_ct: true
};

const taxCodesData = [
  "A_BABY_BIBCLOTH", 
  "A_BABY_CARSEAT", 
  "A_BABY_NAPPIES", 
  "A_BOOK_ATLAS", 
  "A_BOOK_AUDIOBOOK", 
  "A_BOOK_GLOBE", 
  "A_BOOK_MAGAZINE", 
  "A_BOOK_MAP", 
  "A_BOOKS_GEN", 
  "A_CLTH_BABY", 
  "A_CLTH_CHILD", 
  "A_CLTH_PROTECTIVE", 
  "A_FOOD_ANIMALFOOD", 
  "A_FOOD_ANIMALMED", 
  "A_FOOD_ANIMALVITAMINS", 
  "A_FOOD_CAKEDECOR", 
  "A_FOOD_CANFRUIT", 
  "A_FOOD_CEREALBARS", 
  "A_FOOD_CEREALCHOCBARS", 
  "A_FOOD_CHOCEREAL", 
  "A_FOOD_CNDY", 
  "A_FOOD_COFFEE", 
  "A_FOOD_DAIRY", 
  "A_FOOD_DESSERT", 
  "A_FOOD_DRIEDFRUIT", 
  "A_FOOD_FLOUR", 
  "A_FOOD_GEN", 
  "A_FOOD_MEATCHICKEN", 
  "A_FOOD_MISCBEVERAGE", 
  "A_FOOD_NAAN", 
  "A_FOOD_NCARBWTR", 
  "A_FOOD_OIL", 
  "A_FOOD_PASTANOODLE", 
  "A_FOOD_PASTRYCASE", 
  "A_FOOD_PETFOOD34", 
  "A_FOOD_PETFOOD", 
  "A_FOOD_PLAINBISCUIT", 
  "A_FOOD_PLAINCRACKER", 
  "A_FOOD_PLAINNUT", 
  "A_FOOD_RICE", 
  "A_FOOD_SEASONINGS", 
  "A_FOOD_SNACK",
  'A_FOOD_TEA',
  "A_GEN_STANDARD",
  "A_HLTH_PILLCAPSULETABLET",
  "A_HPC_DIETARYSUPPL",
  "A_HLTH_VITAMINS",
  "A BOOK MAGAZINE",
  "A BOOK ADULT",
  "A FOOD CEREALBARS",
  "A FOOD PLAINCRACKER",
  "A FOOD SODAJUICE",
  "A FOOD SODAJUICEUNSWEET",
  "A FOOD SPREAD",
  "A FOOD SWEETNER",
  "A FOOD TEA",
  "A FOOD VEGETABLE",
  "A FOOD ANIMALFOOD",
  "A HLTH NUTRITIONBAR",
  "A HLTH NUTRITIONDRINK",
  "A HLTH SMOKINGCESSATION",
  "A HLTH SMOKINGGUM",
  "A HPC CONTACTLENSES",
  "A HPC CONTRACEPTIVE",
  "A HPC INCONTINENCE",
  "A HPC MEDICINE",
  "A HPC PPECLOTHING",
  "A HPC PPEMASKS",
  "A HPC PPESANITISER",
  "A HPC CORRECTIVEGLASSES",
  "A HPC SANITARYPRODUCTS",
  "A HPC SANITARYPRODUCTSWASHABLE",
  "A HPC THERMOMETER",
  "A HPC WALKINGSTICK",
  "A HPC WHEELCHAIR",
  "A OUTDOOR FUEL",
  "A OUTDOOR FERTILIZER",
  "A OUTDOOR LAWNCONTROL",
  "A OUTDOOR PLANTFOOD",
  "A OUTDOOR PLANTS",
  "A OUTDOOR SEEDS",
  "A BABY BIBCLOTH",
  "A GEN NOTAX"
];

const taxCodes = taxCodesData.map((item) => {
  return {
    value: item,
    label: item.replaceAll('_', ' ')
  }
})

const PricesForm = ({ selectData, productData, productId, productPrices, refetch, update, pricePermission, updateFormData, errors }) => {
const marketplaceOptions = selectData.marketplaces.map(item => ({
  id: item.id,
  name: item.name,
  currency: item.currency_id
}));




  const [updateProductPricesMutation, { loading, error: updateError }] = useMutation(
    updateProductPrices
  );
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [formData, setFormData] = useState(productPrices);
  const [availableMarketplaces, setAvailableMarketplaces] = useState(marketplaceOptions);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deletePriceMutation, {}] = useMutation(deleteProductPrice);
  const [load, setLoad] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [commissionType, setCommissionType] = useState(true)
  const [exchangeRates, setExchangeRates] = useState([])
  const [errorsList, setErrorsList] = useState([]);
  const [errorsArray, setErrorsArray] = useState([]);

  const { localStorage } = window;
  
  let _arr = localStorage.getItem('idarray')
  if(_arr){
    _arr = _arr.split(',')
  }else{
    _arr = []
  }

  useEffect(() => {
    if(errors['prices']){
      const err = {...errors['prices']}
      const d = []

      Object.keys(err).map(k => {
        d[k] = err[k]
      });

      const r = []
      d.map((x, i) => {
        Object.keys(x).map(w => {
          r.push(x[w])
        })
      })

      console.log(r)
      setErrorsArray(r.join(';'))
      setErrorsList(d)
    }
  }, [errors])

  const showError = (index, key) => {
    if(!errorsList[index] || !errorsList[index][key]) return null

    return (
      <div className="custom_error_container">
        <img src="/assets/images/info-button.png" alt="" />
        <p>{errorsList[index][key]}</p>
      </div>
    )
  }

  const silentError = _arr.indexOf(productData.product_parent_id) < 0 ? false : true

  const { data: getExchangeRatesData } = useQuery(getExchangeRates);
  
  useEffect(() => {

    if(getExchangeRatesData && getExchangeRatesData.exchange_rates){
      setExchangeRates(getExchangeRatesData.exchange_rates)
    }
  }, [getExchangeRatesData])

  useEffect(() => {
    setAvailableMarketplaces(
      marketplaceOptions.filter(
        item => !formData.some(formValue => formValue.marketplace_id === item.id)
      )
    );

    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    productPrices = productData.productPrices.map(item => {
      const updatedItem = { ...item };
      Object.keys(updatedItem).forEach(key => {
        if (fieldsConvertToCents[key]) {
          updatedItem[key] = +(updatedItem[key] / 100).toFixed(2);
        }
      });
      delete updatedItem.__typename;
      return updatedItem;
    });

    setFormData(productPrices)

  }, [productData])



  const submitHandler = async event => {
    event.preventDefault();

    let priceCheckError = false;

    if(!silentError){
      if(productData.product_category_hazmat == null || productData.product_category_hazmat.length == 0){
        if(!window.confirm('I confirm that product doesn’t fall under the hazmat category')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }

      if(productData.productPrices == null || productData.productPrices.length == 0){
        if(!window.confirm('I confirm that this product has no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }else{
        formData.forEach((value, index) => {
          if(value.price_buy_net_actual_ct == null || value.price_buy_net_actual_ct == 0){
            priceCheckError = true
          }
        })
      }

      if(priceCheckError) {
        if(!window.confirm('Some prices have no buy (net) price')){
          return false;
        }else{
          if(_arr.indexOf(productData.product_parent_id) < 0){
            _arr.push(productData.product_parent_id)
          }
        }
      }
    }

    setChangesMade(false);

    const updatedFormData = formData.map(item => {
      const updatedItem = { ...item };
      Object.keys(updatedItem).forEach(key => {
        if (fieldsConvertToCents[key]) {
          updatedItem[key] = Math.round(+updatedItem[key] * 100);
        }
      });
      updatedItem.tax_region = 1;
      delete updatedItem.marketplace
      return updatedItem;
    });

    try {
      const { data } = await updateProductPricesMutation({
        variables: {
          input: updatedFormData,
          product_parent_id: productId
        }
      });

      if (!data.updateMarketplacePrices) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  const addRowHandler = () => {
    const updatedFormData = [...formData];
    const marketplaceInfo = selectData.marketplaces.find(
      marketplace => marketplace.id === availableMarketplaces[0].id
    );
    updatedFormData.push({
      marketplace_id: availableMarketplaces[0].id,
      tax_rate: marketplaceInfo.tax_rate[0],
      currency_id: marketplaceInfo.currency_id,
      // price_base_unit: selectData.baseUnits[0].id
    });
    setFormData(updatedFormData);
  };

  const deletePrice = async event => {
    event.preventDefault()
    setLoad(true)

    const { data } = await deletePriceMutation({
      variables: { 
        input: {id: selectedItems}, 
        pid: productData.product_parent_id 
      }
    });


    if (data) {
      setLoad(false)
      setFormData([])

      const ids = []
      data.deleteProductPrice.map(x => ids.push(x.id))
      const updatedFormData = [];

      ids.forEach(el => {
        formData.map(x => el === x.id ? updatedFormData.push(x) : null)
      });

      setSelectedItems([])
      
      setFormData([...updatedFormData]);

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    }
  }

  const checkboxClickHandler = (item, event) => {
    setChangesMade(true);

    let updatedSelectedItems = [...selectedItems];

    if (event.target.checked) {
      updatedSelectedItems.push(+item.id);
    } else {
      updatedSelectedItems = updatedSelectedItems.filter(i => i !== +item.id);
    }

    updatedSelectedItems.sort((a, b) => b - a);
    setSelectedItems(updatedSelectedItems);
  };

  const changeFieldHandler = (rowNumber, event, taxRate) => {
    setChangesMade(true);

    setErrorsList([])

    const updatedFormData = [...formData];
    const calculatedField = pricesFields[event.target.id];

    updatedFormData[rowNumber] = {
      ...updatedFormData[rowNumber],
      [event.target.id]: event.target.value
    };

    if (taxRate) {
      if(event.target.id === 'price_sell_gross_rrp_ct'){
        updatedFormData[rowNumber] = {
          ...updatedFormData[rowNumber],
          [calculatedField]: (+event.target.value * (1 - +taxRate)).toFixed(2)
        };
      }else{
        updatedFormData[rowNumber] = {
          ...updatedFormData[rowNumber],
          [calculatedField]: (+event.target.value * (1 - +taxRate)).toFixed(2)
        };
      }
    }

    if(event.target.id === 'price_sell_gross_rrp_ct'){
      updatedFormData[rowNumber] = {
        ...updatedFormData[rowNumber],
        ['provision']: (+event.target.value * +productData.manufacturer.margin_percentage / 100).toFixed(2)
      };
    }

    // if(event.target.id === 'price_buy_net_actual_ct'){
    //   updatedFormData[rowNumber] = {
    //     ...updatedFormData[rowNumber],
    //     ['price_buy_net_actual_ct']: +event.target.value - 0.01
    //   };
    // }

    setFormData(updatedFormData);
    
  };

  const changeSelectHandler = rowNumber => (option, data) => {
    setChangesMade(true);

    setErrorsList([])

    const updatedFormData = [...formData];

    updatedFormData[rowNumber] = {
      ...updatedFormData[rowNumber],
      [data.name]: option.value
    };

    if (data.name === 'marketplace_id') {
      const marketplaceInfo = selectData.marketplaces.find(
        marketplace => marketplace.id === option.value
      );

      updatedFormData[rowNumber] = {
        ...updatedFormData[rowNumber],
        tax_rate: marketplaceInfo.tax_rate[0],
        currency_id: marketplaceInfo.currency_id,
        price_sell_gross_rrp_ct: (
          updatedFormData[rowNumber].price_sell_net_rrp_ct *
          (1 + +marketplaceInfo.tax_rate[0])
        ).toFixed(2),
        price_sell_gross_min_ct: (
          updatedFormData[rowNumber].price_sell_net_min_ct *
          (1 + +marketplaceInfo.tax_rate[0])
        ).toFixed(2),
        price_sell_gross_max_ct: (
          updatedFormData[rowNumber].price_sell_net_max_ct *
          (1 + +marketplaceInfo.tax_rate[0])
        ).toFixed(2)
      };
    }

    if (data.name === 'tax_rate') {
      updatedFormData[rowNumber] = {
        ...updatedFormData[rowNumber],
        price_sell_net_rrp_ct:
          updatedFormData[rowNumber].price_sell_gross_rrp_ct &&
          (updatedFormData[rowNumber].price_sell_gross_rrp_ct * (1 - +option.value)).toFixed(2),
          price_sell_net_min_ct:
          updatedFormData[rowNumber].price_sell_gross_min_ct &&
          (updatedFormData[rowNumber].price_sell_gross_min_ct * (1 - +option.value)).toFixed(2),
        price_sell_gross_max_ct:
          updatedFormData[rowNumber].price_sell_net_max_ct &&
          (updatedFormData[rowNumber].price_sell_net_max_ct * (1 + +option.value)).toFixed(2)
      };
    }

    setFormData(updatedFormData);
  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  const isArchived = productData.status === 0 || productData.manufacturer.status === 0;

  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);


  useEffect(() => {
    updateFormData({prices: formData})
  }, [formData])

  return (
    <form onSubmit={submitHandler}>
      <fieldset disabled={!update}>
        <div className="overflow-auto mb-3">
          <table className="table prices-table mt-0">
            <thead>
              <tr>
                <th>
                  Marketplace 
                  { commissionType && (<span style={{ color: 'red' }}>*</span> ) }
                </th>
                <th>Marketplace ID</th>
                <th>
                  Buy (net) { commissionType && (<span style={{ color: 'red' }}>*</span> ) }
                </th>
                <th>Currency FX</th>

                <th>Recomended retail price (gross)</th>
                <th>Currency FX</th>

                <th>Tax { commissionType && (<span style={{ color: 'red' }}>*</span> ) }</th>

                <th className="text-center">
                  Amazon Tax Code <span style={{ color: 'red' }}>*</span> 
                </th>
              </tr>
            </thead>
            <tbody className='priceList'>
              {formData && formData.map((row, index) => {
                const marketplace = selectData.marketplaces.find(i => i.id === row.marketplace_id);
                const currency = selectData.currencies.find(cur => cur.id === row.currency_id);
                const taxRateOptions = marketplace
                  ? marketplace.tax_rate.map(i => ({
                      label: `${(i * 100).toFixed(2)}%`,
                      value: i
                    }))
                  : [];
                return (
                  <tr key={index}>
                    
                    <td className="align-middle" style={{ minWidth: '80px' }}>
                      { marketplace.name }
                    </td>
                    <td className="align-middle" style={{ maxWidth: 'max-content' }}>
                      {row.marketplace_product_id}
                    </td>
                    <td className="align-middle" style={{ minWidth: '80px' }}>
                      <div style={{width: formData.filter(x => x.price_buy_net_actual_ct?.toString().length > 3).length > 0 ? '100px' : '80px', position: "relative"}}>
                        <input
                          type="number"
                          step="0.01"
                          id="price_buy_net_actual_ct"
                          className={`form-control ${errorsList && errorsList[index] && errorsList[index]['buy_net'] ? 'invalid' : ''} `}
                          defaultValue={parseFloat(row.price_buy_net_actual_ct).toFixed(2)}
                          onChange={event => changeFieldHandler(index, event)}
                          disabled={isArchived || !row.status}
                        />
                        <div className="symbol">
                          { CURRENCY_SYMBOLS[0] }
                        </div>
                        { showError(index, 'buy_net') }
                        {row.status == 1 && (

                          <input
                            tabIndex={-1}
                            type="number"
                            min={0}
                            step="0.01"
                            value={parseFloat(row.price_buy_net_actual_ct-0.01).toFixed(2)}
                            style={{ opacity: 0, height: 0, position: 'absolute', width: 0 }}
                            required={row.status == 1}
                            
                            />
                        )}
                      </div>
                    </td>

                    <td className="align-middle" style={{ minWidth: '100px' }}>
                      <div style={{width: formData.filter(x => x.price_buy_net_actual_ct?.toString().length > 3).length > 0 ? '100px' : '80px', position: "relative"}}>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          value={parseFloat(row.price_buy_net_actual_ct * exchangeRates.find(w => w.currency_id == marketplace.currency_id)?.value).toFixed(2) }
                          disabled={true}
                        />
                        <div className="symbol">
                          { CURRENCY_SYMBOLS[marketplace.currency_id] }
                        </div>
                        { marketplace.currency_id != 0 && (
                          <div className="u_infoButton">
                              <img src="/assets/images/info-button.png" alt=""  />

                              <div className="infoBox">
                                  <span>The weekly avarage exchange rate for the currency ~ 1Euro = {exchangeRates.find(w => w.currency_id == marketplace.currency_id)?.value}{ CURRENCY_SYMBOLS[marketplace.currency_id] }</span>
                              </div>
                          </div>
                        ) }

                      </div>
                    </td>

                    <td className="align-middle" style={{ minWidth: '80px' }}>
                      <div style={{width: formData.filter(x => x.rrp_value?.toString().length > 3).length > 0 ? '100px' : '80px', position: "relative"}}>
                        <input
                          type="number"
                          min={0}
                          step="0.01"
                          id="rrp_value"
                          className="form-control"
                          defaultValue={parseFloat(row.rrp_value).toFixed(2)}
                          onChange={event => changeFieldHandler(index, event)}
                          disabled={isArchived || !row.status}
                        />
                        <div className="symbol">
                        { CURRENCY_SYMBOLS[0] }
                        </div>
                      </div>
                    </td>

                    <td className="align-middle" style={{ minWidth: '100px' }}>
                      <div style={{width: '100px', position: "relative"}}>
                        <input
                          type="number"
                          step="0.01"
                          className="form-control"
                          value={parseFloat(row.rrp_value * exchangeRates.find(w => w.currency_id == marketplace.currency_id)?.value).toFixed(2) }
                          disabled={true}
                        />
                        <div className="symbol">
                          { CURRENCY_SYMBOLS[marketplace.currency_id] }
                        </div>
                        { marketplace.currency_id != 0 && (
                          <div className="u_infoButton">
                              <img src="/assets/images/info-button.png" alt="" />

                              <div className="infoBox">
                                  <span>The weekly avarage exchange rate for the currency ~ 1Euro = {exchangeRates.find(w => w.currency_id == marketplace.currency_id)?.value}{ CURRENCY_SYMBOLS[marketplace.currency_id] }</span>
                              </div>
                          </div>
                        ) }
                      </div>
                    </td>


                    <td className="align-middle" style={{ minWidth: '120px', position: "relative" }}>
                      <div style={{width: '120px', position: "relative"}}>
                        <Select
                          className={`${errorsList && errorsList[index] && errorsList[index]['tax_rate'] ? 'invalid' : ''} `}
                          name="tax_rate"
                          options={taxRateOptions}
                          defaultValue={{
                            label: `${(row.tax_rate * 100).toFixed(2)}%`,
                            value: row.tax_rate
                          }}
                          value={taxRateOptions.find(i => i.value === row.tax_rate)}
                          onChange={changeSelectHandler(index)}
                          menuPortalTarget={document.body}
                          required={row.status == 1}
                          isDisabled={!update || !pricePermission.update || isArchived || !row.status}
                        />
                        { showError(index, 'tax_rate') }
                        <input type="text" style={{ position: "absolute", bottom: "0", opacity: "0.0009", zIndex: "-1" }} required={commissionType && row.status == 1} name="tax_rate" value={row.tax_rate} id="" />
                      </div>
                    </td>
                    {/* <td style={{ maxWidth: '50px' }} className="align-middle">
                      { row.provision }
                    </td> */}
                    {row.marketplace_id && row.marketplace_id < 22 ? (
                      <td className="align-middle" style={{ minWidth: '220px', position: "relative" }}>
                        <Select
                          className={`${errorsList && errorsList[index] && errorsList[index]['tax_code'] ? 'invalid' : ''} `}
                          name="tax_code"
                          options={taxCodes}
                          defaultValue={taxCodes.find((tcode) => tcode.value === row.tax_code)}
                          onChange={changeSelectHandler(index)}
                          menuPortalTarget={document.body}
                          isDisabled={!update || !pricePermission.update || isArchived || !row.status}
                        />
                        <input
                          tabIndex={-1}
                          style={{ opacity: 0, height: 0, position: 'absolute' }}
                          required={row.status == 1 && !row.tax_code}
                        />
                        { showError(index, 'tax_code') }
                      </td>
                    ) : <td></td>}
                    </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        
      </fieldset>
    </form>
  );
};

export default PricesForm;
