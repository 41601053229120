import React, { memo, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

/** Styles */
import './index.css';

/** Components */
import HeaderProfile from './headerProfile';

const TopBar = ({ title }) => {

  const sideBarTrigger = () => {
    document.body.classList.toggle('enlarged');
  };
  const [activeMenu, setActiveMenu] = useState('');
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const location = useLocation();
  

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    setActiveSubmenu(null)

    let am = ''
    if(location.pathname == '/analytics'){
      am = capitalizeFirstLetter(location.search.split('section=')[1].split('&')[0].replaceAll('-', ' '))
      let sm = capitalizeFirstLetter(location.search.split('subsection=')[1].split('&')[0].replaceAll('-', ' '))
      setActiveSubmenu(sm)
    }else{
      if(title && (location.pathname.includes('/manufacturers') || location.pathname.includes('/products') || location.pathname.includes('/listings') || location.pathname.includes('/unit-economics') || location.pathname.includes('/product-logs'))){
        am = title
      }else{
        am = capitalizeFirstLetter(location.pathname.replace('/', '').replace('-', ' ').split('/')[0])
      }
    }

    setActiveMenu(am)

  }, [location, title])

  document.title = `${activeMenu} ${activeSubmenu ? '>' : ''} ${activeSubmenu??''} | eBazaaris Platform`

  return (
    <>
      {/* Topbar Start */}
      <div className="navbar-custom navbar-custom-light">
        <ul className="list-unstyled topnav-menu float-right mb-0" style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <HeaderProfile />
        </ul>

        {/* LOGO */}
        <div className="logo-box">
          <Link to="/" className="logo text-center">
            <span className="logo-lg">
              <img src="/assets/images/Logo-ebz.svg" alt="" />
            </span>
            <span className="logo-sm">
              <img src="/assets/images/Logo-ebz.svg" alt="" />
            </span>
          </Link>
          <button
              className="button-menu-mobile waves-effect waves-light"
              onClick={sideBarTrigger}
            >
              <i className="fe-menu text-white" />
            </button>
        </div>

        <div className='pageName'>
          <h3>{activeMenu} {activeSubmenu ? '>' : ''} {activeSubmenu}</h3>
        </div>
      </div>
      {/* end Topbar */}
    </>
  );
};

export default memo(TopBar);
