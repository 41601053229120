import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateListing } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

import { getProductCategoriesSettings } from '../../../services/api/queryService';
import { number } from 'prop-types';

const ProductTitle = ({ selectData, productData, product, refetch, update, onLisitingChange }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );

  const dispatch = useDispatch();
  const [error, setError] = useState();

  const [formData, setFormData] = useState({
    title: productData ? productData.title : '',
    besondere_merkmale: productData ? productData.besondere_merkmale??'' : '',
    model_name: productData ? productData.model_name??'' : '',
  });

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });

    const newData = {
      [event.target.id] : event.target.value
    }

    productData[event.target.id] = event.target.value

    onLisitingChange(newData)
  };

  useEffect(() => {
    setFormData({
      title: productData.title??'',
      besondere_merkmale: productData.besondere_merkmale??'',
      model_name: productData.model_name??'',
    })
  }, [productData])


  const submitHandler = async event => {
    event.preventDefault();

    try {
      const { data } = await updateListingMutation({
        variables: {
          input: formData,
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }
  
  return (
    <form onSubmit={submitHandler}>
      <div className="row row-cols-4">
        
        {productData && productData.marketplace_id == 24 ? (
          <>
            <div className="col-12 mb-2">
              <label htmlFor="title">Name</label>
              <input
              type="text"
              id="name"
              className="form-control"
              value={`${product.brand.name} ${productData.getSubcategory ? productData.getSubcategory.title.trim() : ''} ${formData.model_name??''}, ${formData.besondere_merkmale??''}`}
              onChange={changeFieldHandler}
              readOnly={true}
              />
            </div>
            <div className="col-12 mb-2">
              <label htmlFor="title">Brand</label>
              <input
              type="text"
              id="brand"
              className="form-control"
              value={product.brand.name}
              onChange={changeFieldHandler}
              readOnly={true}
              />
            </div>
            <div className="col-12 mb-2">
              <div className='d-flex justify-content-between align-items-center'>
                <label htmlFor="title">OTTO Modellname</label>
                <p className="mb-0 ">
                  Characters: <span className={formData.model_name.length < 1 || 180 < formData.model_name.length  ? 'text-danger' : 'text-green'}> {formData.model_name.length}</span> | 
                  Bytes: <span className={formData.model_name.length < 1 || 180 < formData.model_name.length  ? 'text-danger' : 'text-green'}> {formData.model_name.length} kb</span>
                </p>
              </div>
              <input
              type="text"
              id="model_name"
              className="form-control"
              value={formData.model_name}
              onChange={changeFieldHandler}
              maxLength={180}
            />
            </div>
            <div className="col-12 mb-2">
                <div className='d-flex justify-content-between align-items-center'>
                  <label htmlFor="title">OTTO Besondere Merkmale</label>
                  <p className="mb-0 ">
                    Characters: <span className={formData.besondere_merkmale.length < 1 || 180 < formData.besondere_merkmale.length  ? 'text-danger' : 'text-green'}> {formData.besondere_merkmale.length}</span> | 
                    Bytes: <span className={formData.besondere_merkmale.length < 1 || 180 < formData.besondere_merkmale.length  ? 'text-danger' : 'text-green'}> {formData.besondere_merkmale.length} kb</span>
                  </p>
                </div>
                <input
                type="text"
                id="besondere_merkmale"
                className="form-control"
                value={formData.besondere_merkmale}
                onChange={changeFieldHandler}
                maxLength={180}
            />
            </div>
          </>
        ) : (
          <div className="col-12">
            <label htmlFor="title">Name</label>
              <input
              type="text"
              id="title"
              className="form-control"
              value={formData.title}
              onChange={changeFieldHandler}
              maxLength={200}
          />
          </div>
        )}
        
      </div>
      {update && (
        <>
          {loading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <button type="submit" className="btn btn-primary px-3 mt-3">
              Save
            </button>
          )}
        </>
      )}
    </form>
  );
};

export default ProductTitle;
