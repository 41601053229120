import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const RequiredDataModal = ({ requiredData, setModalOpen, handleModalClose }) => {
  const requiredDataErrors = requiredData && requiredData.length > 0 ? requiredData.split(';') : [];
  const [ openRequiredDataModal, setOpenModal ] = useState(setModalOpen);
  const handleClose = () => {
    setOpenModal(false);
    handleModalClose()
  } 

  return (
    <>
      <div className="modal-backdrop show" style={ openRequiredDataModal ? {width: '100vw'} : {width: '0vw'} } />
      <div
        className="modal"
        id="requiredDataModal"
        tabIndex="-1"
        style={ openRequiredDataModal ? {display: 'block'} : {display: 'none'} }
      >
        <div className="modal-dialog" style={{color:'#525254'}}>
          <div className="modal-content DIN2014" >
            <form>
              <div className="modal-header">
                <h4 style={{color:'#525254'}} className="modal-title DIN2014">Missing data for synchronisation to JTL</h4>
                <button type="button" className="close" onClick={handleClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div style={{ overflow: 'hidden',  whiteSpace: 'break-spaces', paddingLeft: '2rem'}} className="modal-body pb-4 ">
                <h5 style={{color:'#525254', fontWeight: 700}} className='DIN2014'>Missing data</h5>
                <ul>
                  { requiredDataErrors && requiredDataErrors.length > 0 ?
                    ( requiredDataErrors.map(rError => {
                        return (<li>{rError}</li>);
                      })
                    ) : (<li className="spinner">No errors found</li>)
                  }
                </ul>
              </div>
              <div className="modal-footer justify-content-start">
                {/* Do we want anything in the footer?  */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequiredDataModal;
