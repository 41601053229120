import React, { Component, useState, useEffect }  from 'react';
import { AMAZON_MARKETPLACES } from '../../lib/constants/fields';
import Axios from 'axios';
import { useQuery } from '@apollo/react-hooks';
import { getAmazonListingStatus as GET_AMAZON_LISTING_STATUS } from '../../services/api/queryService';

/**
 * External amazonStatus function that returns HTML data response depending on product marketplace seller, marketplace id/type and error
 * @param {*} marketplaceData 
 * @returns 
 */
export const AmazonStatus = function(marketplaceData, AmazonListingStatus = null) {
  const marketplaceError = marketplaceData.error ? marketplaceData.error.split(';') : [];
 
  if (marketplaceData.seller_id === 1 && marketplaceData.marketplace_id_type === 0 && marketplaceData.error !== null && AMAZON_MARKETPLACES.includes(marketplaceData.marketplace_id)){
    return (<span><i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  ASIN missing SKU</span>);
  } else if (marketplaceData.seller_id === 1 && marketplaceData.marketplace_id_type === 1 && marketplaceData.error === null && AMAZON_MARKETPLACES.includes(marketplaceData.marketplace_id)) {
    if(AmazonListingStatus === 'Active') {
      return (<span><i style={{marginLeft: '5px'}} className='fas fa-circle green-icon'></i> Active</span>);
    }
    return (<span><i style={{marginLeft: '5px'}} className='fas fa-circle gray-icon'></i> { AmazonListingStatus === null ? '' : AmazonListingStatus }</span>);
  } else if (marketplaceData.seller_id === 1 && marketplaceData.marketplace_id_type === 1 && marketplaceData.error !== null && AMAZON_MARKETPLACES.includes(marketplaceData.marketplace_id)) {
    if(marketplaceData.error.includes('out of stock')) {
      return (<span>
        <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  Possible out of stock {(marketplaceError.length > 1) ? <span class='red-info-icon' style={{fontWeight:'bold'}}>+{marketplaceError.length-1}</span> : ''}
      </span>);
    }
    if(marketplaceData.error.includes('fulfillment vendor')) {
      return (<span>
          <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  Wrong fulfillment vendor {(marketplaceError.length > 1) ? <span class='red-info-icon' style={{fontWeight:'bold'}}>+{marketplaceError.length-1}</span> : ''}
        </span>);
    }
    if(marketplaceData.error.includes('Incomplete')) {
      return (<span>
          <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  Incomplete {(marketplaceError.length > 1) ? <span class='red-info-icon' style={{fontWeight:'bold'}}>+{marketplaceError.length-1} </span> : ''}
        </span>);
    }
    if(marketplaceData.error.includes('Inactive')) {
      return (<span>
          <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  Inactive/Closed {(marketplaceError.length > 1) ? <span class='red-info-icon' style={{fontWeight:'bold'}}>+{marketplaceError.length-1} </span> : ''}
        </span>);
    }
    if(marketplaceData.error.includes('does not exist')) {
      return (<span>
          <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  Doesn't exist {(marketplaceError.length > 1) ? <span class='red-info-icon' style={{fontWeight:'bold'}}>+{marketplaceError.length-1} </span> : ''}
        </span>);
    }
    if(marketplaceData.error.includes('Active')) {
      return (<span>
          <i style={{marginLeft: '5px'}} className='fas fa-info-circle red-info-icon'></i>  Active {(marketplaceError.length > 1) ? <span class='red-info-icon' style={{fontWeight:'bold'}}>+{marketplaceError.length-1} </span> : ''}
        </span>);
    }
  } else {
    return '';
  }
}

/**
 * Function returns Marketplace Issue Modal error title depending on error
 * @param {*} error 
 * @returns string
 */
export const AmazonStatusError = function(error) {
  if(error.includes('out of stock')) {
    return 'Possible out of stock';
  }
  if(error.includes('fulfillment vendor')) {
    return 'Wrong fulfillment vendor';
  }
  if(error.includes('Incomplete')) {
    return 'Incomplete';
  }
  if(error.includes('Inactive')) {
    return 'Inactive/Closed';
  }
  if(error.includes('does not exist')) {
    return "Doesn't exist";
  }
  if(error.includes('Active')) {
    return "Active";
  }
}


/**
 * Function calls backend API to get the status of sku on Amazon
 * @param {*} skus 
 * @returns array
 */
export const AmazonListingStatus = function(skus) {
  console.log(JSON.stringify(skus));
  let _uri = 'https://staging-api.ebazaaris.io'
  if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod_azure') {
    _uri = 'https://azure-api.ebazaaris.io'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    _uri = 'http://localhost:8000'
  }

  const listingStatuses = {};
  skus.forEach(function(pm) {
    Axios.get(`${_uri}/api/amazon/listing/sku/${pm.marketplace_product_id}/${pm.marketplace_id}/status`)
    .then((response) => {
      listingStatuses[pm.marketplace_product_id] = {
        [pm.marketplace_id]: response.data
      };
      console.log('GetListingData: ' + pm.marketplace_product_id + ' ' + pm.marketplace_id + ' ' + JSON.stringify(response.data));
    })
    
    .catch(error => console.error(`Error: ${error}`));
  });

  return listingStatuses;
}