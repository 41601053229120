import React, { useState } from 'react';
import Select from 'react-select';
import { useMutation } from '@apollo/react-hooks';

import { exportProduct } from '../../services/api/mutationService';
import { PRODUCT_EXPORT_OPTIONS } from '../../lib/constants/fields';
import ModalError from '../../components/modal/ModalError';

const ExportModal = ({ selectedItems, setModalOpen, dataList }) => {
  const [formData, setFormData] = useState({
    type: PRODUCT_EXPORT_OPTIONS[0].value
  });
  const [exportProductMutation, { loading, error }] = useMutation(exportProduct);
  const [errorMsg, setError] = useState(false);

  const submitHandler = async event => {
    event.preventDefault();

    let valid = true;
    let err = "";
    if(formData.type === "product_parent"){
      dataList.filter(x => selectedItems.indexOf(+x.product_parent_id) > -1).map(x => {
        if(+x.product_type_id !== 8){
          err = "Selected items should be of type parent"
          valid = false
        }
      })
    }else{
      dataList.filter(x => selectedItems.indexOf(+x.product_parent_id) > -1).map(x => {
        if(+x.product_type_id === 8){
          err = "Selected items should not be of type parent"
          valid = false
        }
      })
    }

    console.log(valid, err)

    if(!valid){
      setError(err)
      return false;
    }

    const { data } = await exportProductMutation({
      variables: { input: { ...formData, productIds: selectedItems } }
    });

    let ext = 'xlsx'

    const a = document.createElement('a');
    a.href = `data:image/png;base64,${data.exportProduct}`;
    let fileName = ''
    switch(formData.type){
      case 'cid': 
        fileName = 'ASIN_SKU'; break
      case 'cid_update':
        fileName = 'ASIN_SKU_update'; break
      case 'listing': 
        fileName = 'Listing'; break
      case 'product_update': 
        fileName = 'Product_update'; break
      case 'product_price_update': 
        fileName = 'Product_price_update_v2'; break
      case 'product_parent':
        ext = 'csv';
        fileName = 'Product_parent'; break
      default: 
        fileName = 'Product'; break
    }
    a.download = `${fileName}_export_${new Date()
      .toISOString()
      .slice(0, 10)
      .replaceAll('-', '')}_${+new Date()}.${ext}`;
    a.click();

    setModalOpen(false);
  };

  const changeSelectHandler = (option, data) => {
    if (option) {
      setFormData({
        ...formData,
        [data.name]: option.value
      });
    }
  };

  const backdropClickHandler = event => {
    if (event.target.id === 'exportModal') {
      setModalOpen(false);
    }
  };

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      <div className="modal-backdrop show" />
      <div
        className="modal"
        id="exportModal"
        tabIndex="-1"
        style={{ display: 'block' }}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <form onSubmit={submitHandler}>
              <div className="modal-header">
                <h4 className="modal-title">Export products</h4>
                <button type="button" className="close" onClick={() => setModalOpen(false)}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pb-4">
                <ModalError error={errorMsg} setError={setError} />

                <label htmlFor="status" style={{ fontWeight: 600, marginBottom: '0.3rem' }}>
                  Type
                </label>
                <Select
                  name="type"
                  options={PRODUCT_EXPORT_OPTIONS}
                  defaultValue={PRODUCT_EXPORT_OPTIONS[0]}
                  onChange={changeSelectHandler}
                />
              </div>
              <div className="modal-footer justify-content-start">
                {loading ? (
                  <div className="spinner">Spinner</div>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={!selectedItems.length}
                  >
                    Export
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExportModal;
