import React, { useState } from 'react';
import Select from 'react-select';
import { useQuery } from '@apollo/react-hooks';

import ModalForm from '../../../components/modal/ModalForm';
import UserFormFields from './formFields/UsersFormFields';
import EmailFormFields from './formFields/EmailFormFields';
import PasswordFormFields from './formFields/PasswordFormFields';
import Pagination from '../../../components/ui-components/pager/Pagination';
import { tableFilterFields } from '../../../lib/constants/fields';
import { STATUSES, USER_TYPE_OPTIONS } from '../../../lib/constants/fields';

import { getUsers, getManufacturersList, getRolesList, getSellers } from '../../../services/api/queryService';

import './UsersList.scss';

const UsersList = ({ location, history, create, update }) => {
  const params = new URLSearchParams(location.search);
  const page = params.get('page') || 1;
  const first = params.get('count') || 10;
  const input = params.get('keyword') || '';

  const [openModalId, setOpenModalId] = useState();
  const [openDropdownId, setOpenDropdownId] = useState();
  const [itemId, setItemId] = useState(null);
  const { data: usersData, refetch, error: usersError } = useQuery(getUsers, {
    variables: {
      order: 'id',
      first,
      page,
      input
    }
  });
  const { data: manufacturersData, error: manufacturersError } = useQuery(getManufacturersList, {
    variables: { order: 'id' }
  });
  const { data: rolesData, error: rolesError } = useQuery(getRolesList, {
    variables: { order: 'id' }
  });
  const { data: sellersData, error: sellersError } = useQuery(getSellers, {
    variables: { order: 'id' }
  });
  

  let users;
  let paginatorInfo;
  let manufacturers;
  let roles;
  let sellers;

  if (usersData) {
    users = usersData.searchUsers.data;
    paginatorInfo = usersData.searchUsers.paginatorInfo;
  }
  if (manufacturersData) {
    manufacturers = manufacturersData.manufacturers;
  }

  if (rolesData) {
    roles = rolesData.roles;
  }

  if (rolesData) {
    sellers = sellersData.sellers;
  }

  const renderTableHeading = key => {
    if (key === '__typename' || key === 'last_name') {
      return;
    }

    let value = '';
    if (key === 'id') {
      value = 'ID';
    } else if (key === 'first_name') {
      value = 'Name';
    } else if (key === 'user_type') {
      value = 'Type';
    } else {
      value = key.replaceAll('_', ' ');
    }

    return (
      <th key={key} className={key === 'id' ? 'text-center' : ''}>
        {value}
      </th>
    );
  };

  const renderTableCell = (key, row) => {
    if (key === '__typename' || key === 'last_name') {
      return;
    }
    if (key === 'id') {
      return (
        <td key={key} className="text-center">
          {row[key]}
        </td>
      );
    }
    if (key === 'first_name') {
      return <td key={key}>{`${row[key]} ${row.last_name}`}</td>;
    }
    if (key === 'user_type') {
      const typeOption = USER_TYPE_OPTIONS.find(i => i.value === row[key]);
      return <td key={key}>{typeOption && typeOption.label}</td>;
    }
    if (key === 'roles' || key === 'manufacturers' || key === 'sellers') {
      const source = key === 'roles' ? roles : manufacturers;

      if(key == 'sellers'){
        const d = [];
        row[key].map(x => {
          d.push({
            id: x['seller'] ? x['seller'].id : x.id
          })
        })

        row[key] = [...d]

        return (
          <td key={key}>
            {sellers
              .filter(i => row[key].some(j => i.id === j.id))
              .map(i => i.name)
              .join(', ')}
          </td>
        );
      }

      if(key == 'manufacturers'){
        const d = [];
        row[key].map(x => {
          d.push({
            id: x['manufacturer'] ? x['manufacturer'].id : x.id
          })
        })

        row[key] = [...d]
      }
      return (
        <td key={key}>
          {source
            .filter(i => row[key].some(j => i.id === j.id))
            .map(i => i.name)
            .join(', ')}
        </td>
      );
    }
    if (key === 'status') {
      return <td key={key}>{STATUSES[row[key]]}</td>;
    }
    return <td key={key}>{row[key]}</td>;
  };

  const entriesChangeHandler = option => {
    params.set('count', option.value);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const searchInputChangeHandler = event => {
    const inputValue = event.target.value;
    if (inputValue.length > 2) {
      params.set('keyword', inputValue);
    } else {
      params.delete('keyword');
    }
    history.push(`${location.pathname}?${params.toString()}`);
  };

  const actionsClickHandler = rowId => {
    setOpenDropdownId(!openDropdownId ? rowId : null);
  };

  const actionClickHandler = (itemId, modalId) => {
    setItemId(itemId);
    setOpenModalId(modalId);
    setOpenDropdownId(null);
  };

  if (usersError || manufacturersError || rolesError) {
    throw new Error(usersError || manufacturersError || rolesError);
  }

  console.log(users)

  return (
    <>
      {!users || !manufacturers || !roles ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="card mt-3">
          <div className="card-body import-job-table">
            <div className="import-job-table__header mb-3">
              <div className="import-job-table__header--item">
                {create && (
                  <button
                    className="btn btn-primary mr-3"
                    onClick={actionClickHandler.bind(null, null, 'edit')}
                  >
                    Create New
                  </button>
                )}
                <p>Show entries:</p>
                <div style={{ width: '80px' }}>
                  <Select
                    options={tableFilterFields.count.list}
                    defaultValue={
                      first ? { label: first, value: +first } : tableFilterFields.count.value
                    }
                    onChange={entriesChangeHandler}
                  />
                </div>
              </div>

              <div className="import-job-table__header--item">
                <p>Search:</p>
                <input
                  type="text"
                  className="form-control"
                  onChange={searchInputChangeHandler}
                  defaultValue={input}
                />
              </div>
            </div>

            {!!users.length && (
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      {Object.keys(users[0]).map(key => renderTableHeading(key))}
                      {update && <th className="text-center">Actions</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(row => (
                      <tr key={row.id}>
                        {Object.keys(row).map(key => renderTableCell(key, row))}
                        {update && (
                          <td className="text-center actions-cell">
                            <i
                              className="fas fa-edit"
                              onClick={actionsClickHandler.bind(null, row.id)}
                            />
                            {openDropdownId === row.id && (
                              <ul className="actions-dropdown">
                                <li onClick={actionClickHandler.bind(null, row.id, 'edit')}>
                                  Edit
                                </li>
                                <li onClick={actionClickHandler.bind(null, row.id, 'email')}>
                                  Change email
                                </li>
                                <li onClick={actionClickHandler.bind(null, row.id, 'password')}>
                                  Change password
                                </li>
                              </ul>
                            )}
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <Pagination data={{ ...paginatorInfo, search: input, tab: 'users' }} />

            {openModalId === 'edit' && (
              <ModalForm setModalOpen={setOpenModalId}>
                <UserFormFields
                  itemId={itemId}
                  data={users.find(item => item.id === itemId)}
                  roles={roles}
                  manufacturers={manufacturers}
                  setOpenModalId={setOpenModalId}
                  sellers={sellers}
                  refetch={refetch}
                />
              </ModalForm>
            )}

            {openModalId === 'email' && (
              <ModalForm setModalOpen={setOpenModalId}>
                <EmailFormFields
                  userId={itemId}
                  setOpenModalId={setOpenModalId}
                  refetch={refetch}
                />
              </ModalForm>
            )}

            {openModalId === 'password' && (
              <ModalForm setModalOpen={setOpenModalId}>
                <PasswordFormFields
                  userId={itemId}
                  setOpenModalId={setOpenModalId}
                  refetch={refetch}
                />
              </ModalForm>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UsersList;
