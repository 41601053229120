import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { CURRENCIES_OPTIONS, STATUS_OPTIONS } from '../../../lib/constants/fields';
import ModalError from '../../../components/modal/ModalError';
import { getManufacturersList } from '../../../services/api/queryService';

const AttributesFormFields = ({
  itemId,
  createMutationId,
  updateMutationId,
  data,
  setModalOpen,
  name,
  createMutation,
  updateMutation,
  refetch
}) => {
  const [formData, setFormData] = useState({
    attribute: data && data.attribute,
    attribute_name: data && data.attribute_name,
    manufacturer_id: data && data.manufacturer_id,
    status: data ? data.status : STATUS_OPTIONS[1].id
  });
  const dispatch = useDispatch();
  const [createItem, { loading: createLoading, error: createError }] = useMutation(createMutation);
  const [updateItem, { loading: updateLoading, error: updateError }] = useMutation(updateMutation);
  const [error, setError] = useState(null);
  const [manufacturers, setManufacturers] = useState(null);
  const [mutationError, setMutationError] = useState();

  const [changesMade, setChangesMade] = useState(false);

  let { data : getManufacturersData } = useQuery(getManufacturersList, {
    variables: {
        order: 'id',
        filter: 'status'
    }
  });
  
  useEffect(() => {
    if(getManufacturersData){
      setManufacturers(getManufacturersData.manufacturers)
    }
  }, [getManufacturersData])

  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);


  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();
    setError(null);

    const preparedFormData = { ...formData };

    try {
      if (itemId) {
        const { data } = await updateItem({
          variables: {
            input: { ...preparedFormData, id: itemId }
          }
        });

        if (!data[updateMutationId]) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data.updateAttribute.error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createItem({
          variables: {
            input: preparedFormData
          }
        });
        if (!data[createMutationId]) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data.createAttribute.error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setModalOpen(false);
  };

  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {
    setChangesMade(true);
    let newValue;
    if (option) {
      newValue = option.value !== undefined ? option.value : option.map(i => i.value);
    } else {
      newValue = [];
    }
    setFormData({
      ...formData,
      [data.name]: newValue
    });
  };

  const isValidNewOption = input => {
    if (!input) {
      return false;
    }
    if (!isFinite(input)) {
      return false;
    }
    if (+input > 100 || +input < 0) {
      return false;
    }
    return true;
  };

  if (createError || updateError || mutationError) {
    throw new Error(createError || updateError || mutationError);
  }

  return (
    <>
      {manufacturers && (
        <form onSubmit={submitHandler}>
          <div className="modal-header">
            <h4 className="modal-title" id="modalLabel">
              {`${itemId ? 'Edit' : 'Add'} "${name}" Attributes`}
            </h4>
            <button type="button" className="close" onClick={() => setModalOpen(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body modal-form">
            <ModalError error={error} setError={setError} />
            <div className="form-group">
              <label htmlFor="attribute">
                Attribute <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                id="attribute"
                className="form-control"
                required
                defaultValue={formData.attribute}
                onChange={changeFieldHandler}
                disabled={data && data.status === 0}
              />
            </div>
            <div className="form-group">
              <label htmlFor="attribute_name">
                Attribute name <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type="text"
                id="attribute_name"
                className="form-control"
                required
                defaultValue={formData.attribute_name}
                onChange={changeFieldHandler}
                disabled={data && data.status === 0}
              />
            </div>
            <div className="form-group">
              <label htmlFor="currency_id">
                Manufacturer <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                name="manufacturer_id"
                options={convertDataToOptions(manufacturers)}
                value={createDefaultOption(
                  manufacturers.find(i => +i.id === formData.manufacturer_id)
                )}
                onChange={changeSelectHandler}
                isDisabled={data && data.status === 0}
              />
            </div>
            <div className="form-group">
              <label htmlFor="status">
                Status <span style={{ color: 'red' }}>*</span>
              </label>
              <Select
                name="status"
                options={convertDataToOptions(STATUS_OPTIONS)}
                defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id === formData.status))}
                onChange={changeSelectHandler}
              />
            </div>
          </div>
          <div className="modal-footer justify-content-start">
            {createLoading || updateLoading ? (
              <div className="spinner">Spinner</div>
            ) : (
              <button type="submit" className="btn btn-primary">
                Save
              </button>
            )}
          </div>
        </form>
      )}
      </>

  );
};

export default AttributesFormFields;
