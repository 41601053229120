import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';
import Nestable from 'react-nestable';

import { updateListing } from '../../../services/api/mutationService';
import Error from '../../../components/modal/ModalError';
import { getEbayCategories, getListingCategories, getOttoCategories } from '../../../services/api/queryService';

import 'react-nestable/dist/styles/index.css';

const ListingCategory = ({ selectData, productData, refetch, update, onLisitingChange, activeMarketplace }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );
  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [marketplaceID, setMarketplaceID] = useState(
    productData.marketplace_id
  );
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [expandedList, setExpandedList] = useState([]);
  const focusRef = useRef(null);

  let categoryQuery = getListingCategories
  if(activeMarketplace == 24){
    categoryQuery = getOttoCategories
  }else if(activeMarketplace == 23){
    categoryQuery = getListingCategories
  }else if(activeMarketplace == 22){
    categoryQuery = getEbayCategories
  }

  
  const { data, loading: isLoading } = useQuery(categoryQuery);
  
  useEffect(() => {
      if (data) {
          let formatedData = []
          if(activeMarketplace == 24){
            formatedData = data[Object.keys(data)[0]].map(x => {
              return {
                id: x.id,
                title : x.title,
                level: x.level,
                parent_id: x.parent_category_id
              }
            })
          }else if(activeMarketplace == 22){
            formatedData = data[Object.keys(data)[0]].map(x => {
              return {
                id: x.id,
                title : x.title,
                level: x.level,
                parent_id: x.parent_category_id
              }
            })
          }else{
            formatedData = data[Object.keys(data)[0]].map(x => {
              return {
                id: x.id_category,
                id_record: x.id,
                title : x.title_singular,
                level: x.level,
                parent_id: x.id_parent_category
              }
            })
          }

          if(productData.subcategory){
            if(activeMarketplace == 23){
              let g = true
              let p = formatedData.find(x => x.id_record == productData.subcategory).parent_id
              let q = []
              q.push(p)

              while(g){
                let w = formatedData.find(x => x.id == p)
                if(w){

                  q.push(w.parent_id)
                  p = w.parent_id
                  if(w.level == 3){
                    g = false
                  }
                }else{
                  g = false
                }
              }

              setExpandedList([...q])
            }else if(activeMarketplace == 22){
              let g = true
              let p = formatedData.find(x => x.id == productData.subcategory).parent_id
              let q = []
              q.push(p)

              while(g){
                let w = formatedData.find(x => x.id == p)
                if(w){
                  q.push(w.parent_id)
                  p = w.parent_id
                  if(w.level == 3){
                    g = false
                  }
                }else{
                  g = false
                }
              }

              setExpandedList([...q])
            }
          }



          setCategories([...formatedData])
        }
  }, [data, activeMarketplace])


  const convertDataToOptions = data =>
    data.map(item => ({ label: item.title, value: item.id }));

  const createDefaultOption = item => {
    if (item) {
      return { label: item.title, value: item.id };
    }
  };

  const [formData, setFormData] = useState({
    main_category : productData ? productData.main_category : null,
    subcategory : productData ? productData.subcategory : null,
  });

  useEffect(() => {   
    setFormData({
        main_category : productData.main_category,
        subcategory : productData.subcategory,
    })


    
  }, [productData])


  const submitHandler = async event => {
    event.preventDefault();
    try {
      const { data } = await updateListingMutation({
        variables: {
          input: {
            main_category: formData.main_category,
            subcategory: !formData.main_category ? null : formData.subcategory,
          },
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  const getChildren = id => {
    return categories.filter(x => +x.parent_id == +id)
  }

  useEffect(() => {   
    const d = []

    setTimeout(() => {
      if(formData && formData.main_category){
        categories.filter(x => +x.parent_id == +formData.main_category).map(x => {

          //level 3
          x.children = [...getChildren(x.id)]
          
          x.children.map(w => {
            //level 4
            w.children = getChildren(w.id)
            
            w.children.map(a => {
              //level 5
              a.children = getChildren(a.id)
              
              a.children.map(b => {
                //level 6
                b.children = getChildren(b.id)
                
                b.children.map(z => {
                  //level 7
                  z.children = getChildren(z.id)
                  
                  z.children.map(r => {
                    //level 8
                    r.children = getChildren(r.id)
                  })
                })

                b.children.sort((m,n) => {
                  return  n.count - m.count
                })
      
                b.count = w.children.length
              })
              a.children.sort((m,n) => {
                return  n.count - m.count
              })
    
              a.count = a.children.length
            })
            w.children.sort((m,n) => {
              return  n.count - m.count
            })
  
            w.count = w.children.length
          })

          x.children.sort((m,n) => {
            return  n.count - m.count
          })

          x.count = x.children.length

          d.push({...x})
        })

        d.sort((m,n) => {
          return  n.count - m.count
        })


        setSubcategories([...d])


        if(refNestable.current){
          setTimeout(() => {
            const nd = getCollapseArray()
            refNestable.current.collapse(nd)

            // setTimeout(() => {
            //   console.log(focusRef)
            //   if(focusRef.current){
            //     console.log(focusRef.current)
            //     focusRef.current.scrollIntoView()   
            //   }
            // }, 1500)
            
          }, 500)
        }
      }
    }, 1000);

  }, [categories])

  const changeSelectHandler = (option, data) => {
    if(option && data.name == "main_category" && activeMarketplace != 24){
      const d = []

      categories.filter(x => +x.parent_id == +option.value).map(x => {

        //level 3
        x.children = getChildren(x.id)
        x.children.map(w => {
          
          //level 4
          w.children = getChildren(w.id)

          w.children.map(a => {
            
            //level 5
            a.children = getChildren(a.id)

            a.children.map(b => {
              
              //level 6
              b.children = getChildren(b.id)

              b.children.map(z => {
                
                //level 7
                z.children = getChildren(z.id)

                z.children.map(r => {
                  
                  //level 8
                  r.children = getChildren(r.id)
                })
              })
            })
          })
        })
        x.count = x.children.length

        d.push({...x})
      })

      d.sort((m,n) => {
        return  n.count - m.count
      })

      setSubcategories([...d])


      if(refNestable.current){
        setTimeout(() => {
          const nd = getCollapseArray()
          refNestable.current.collapse(nd)

          // setTimeout(() => {
          //   console.log(focusRef)
          //   if(focusRef.current){
          //     focusRef.current.scrollIntoView()   
          //   }
          // }, 1000)
          
        }, 500)
      }
    }

    setFormData({
      ...formData,
      [data.name]: option && option.value
    });
  };

  if (error || updateError) {
    debugger
    throw new Error(error || updateError);
  }

  const refNestable = useRef(null)

  const getCollapseArray = () => {
    if(!expandedList.length) return []

    let v = categories.find(x => expandedList[0] == x.id)
    let q = categories.filter(x => v.level == x.level)
    q = q.filter(x => x.id != v.id)
    const d = q.map(x => x.id)
    return d
  }

  const clickHandle = (item, canBeSelected) => {

    if(canBeSelected){
      setFormData({
        ...formData,
        ['subcategory']: item.id_record??item.id
      })
    }
  }



  const renderItem = ({ item, collapseIcon, handler }) => {
    if(activeMarketplace == 22){
      return (
        <div ref={item.id == formData.subcategory ? focusRef : null} 
          style={{...styles, backgroundColor: item.id == formData.subcategory ? '#002f5c' : ( collapseIcon ? '#e2e3e4' : '#b5b5b5' ), color: collapseIcon ? '#72747b' : '#fff', fontWeight: collapseIcon ? 'bolder' : 'normal' }} onClick={() => clickHandle(item, !collapseIcon)}>
          {handler}
          {collapseIcon}
          {item.title}
        </div>
      );
    }else{
      return (
        <div ref={item.id == formData.subcategory ? focusRef : null} 
          style={{...styles, backgroundColor: item.id_record == formData.subcategory ? '#002f5c' : ( collapseIcon ? '#e2e3e4' : '#b5b5b5' ), color: collapseIcon ? '#72747b' : '#fff', fontWeight: collapseIcon ? 'bolder' : 'normal' }} onClick={() => clickHandle(item, !collapseIcon)}>
          {handler}
          {collapseIcon}
          {item.title}
        </div>
      );
    }

  };


  return (
    <form onSubmit={submitHandler}>
      { isLoading && <div className="spinner">Spinner</div> }
      { !isLoading && categories && (
        <>
          <div className="row row-cols-4">
            <div className="col-12 mb-2">
              <div className='d-flex justify-content-between align-items-center'>
                <label htmlFor="title">Main category</label>
              </div>
              <Select
                name="main_category"
                options={convertDataToOptions(categories.filter(x => +x.level === 1))}
                value={createDefaultOption(categories.find(x => x.id == formData.main_category))}
                onChange={changeSelectHandler}
                isClearable
              />
            </div>
            <div className="col-12 mb-2">
              <div className='d-flex justify-content-between align-items-center'>
                <label htmlFor="title">Subcategory</label>
              </div>
              {activeMarketplace == 24 && formData.main_category && (
                <Select
                  name="subcategory"
                  options={convertDataToOptions(categories.filter(x => +x.parent_id === +formData.main_category))}
                  value={createDefaultOption(categories.find(x => x.id == formData.subcategory))}
                  onChange={changeSelectHandler}
                  isClearable
                  
                />
              )}
              { activeMarketplace != 24 && formData.main_category && categories.length && subcategories.length > 0 && (
                  <Nestable
                    collapsed={true}
                    items={subcategories}
                    ref={refNestable}
                    renderItem={renderItem}
                  />
                )
              }

              { !formData || !formData.main_category && <p>You need to choose main category</p> }

            </div>
          </div>
          {update && (
            <>
              {loading ? (
                <div className="spinner">Spinner</div>
              ) : (
                <button type="submit" className="btn btn-primary px-3 mt-3">
                  Save
                </button>
              )}
            </>
          )}
        </>
      )}
    </form>
  );
};


const styles = {
  position: "relative",
  padding: "10px 15px",
  fontSize: "16px",
  border: "1px solid #f9fafa",
  background: "#e2e3e4",
  cursor: "pointer"
};


export default ListingCategory;
