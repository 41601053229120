import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';


import Table from '../../components/table/Table';
import { queryStringHandler, fetchData } from '../../services/util/dataHandlerService';
import routes from '../../lib/constants/routes';
import { PRODUCT_IMPORT_OPTIONS } from '../../lib/constants/fields';

import { searchDocumentUploads } from '../../services/api/queryService';
import DocumentUploadModal from './documentUploadModal';

const description = {
  module: 'DocumentUploads',
  description: 'DocumentUploads',
  req: 'searchDocumentUploads',
  tab: 'DocumentUploads',
  url: routes.documentUploads.index,
};


const DocumentUploadList = ({ canImport, location }) => {
  const { first, page, keyword, filter } = queryStringHandler(location.search);
  let convertedData = null;
  const [importModalOpen, setImportModalOpen] = useState(false);

  let { loading, data, refetch, error } = useQuery(searchDocumentUploads, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'id',
      filter
    },
    fetchPolicy: 'no-cache'
  });

  if (data) {
      convertedData = data[description.req].data.map(item => {
      const convertedItem = {
        ...item,
        user: { first_name : item.user.first_name+' '+item.user.last_name },
      };
      return convertedItem;
    });
  }

  useEffect(() => {
    refetch();
  }, [importModalOpen])

  if (error) {
    throw new Error(error);
  }


  return (
    <>
      {!convertedData ? (
        <div className="spinner absolute">Spinner</div>
      ) : (

        <>
            {/* Start Content */}
            {/* start page title */}
            <div className="row">
                <div className="col-12">
                <div className="page-title-box">
                    <h4 className="page-title">Products</h4>

                    <div className="row">
                    <div className="col-12">
                        <div className="page-title-box">
                            <button
                            type="button"
                            className="page-title-right btn btn-primary mr-1 mb-1 mt-0 px-3"
                            style={{ borderRadius: '20px' }}
                            onClick={() => setImportModalOpen(true)}
                            >
                            Upload
                            </button>
                        
                            {/* <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    <Link to="/">U</Link>
                                </li>
                                <li className="breadcrumb-item active">Products</li>
                            </ol> */}
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            {/* end page title */}

            {/* Tabs */}
            <div className="product-tabs">
                <div
                className={`tab active`}
                >
                    Documents upload
                </div>
                <div className="tab-bar" />
            </div>
            <Table
                loading={loading}
                refetch={refetch}
                description={description}
                pagerData={{
                    ...fetchData(description.req, data).pagerData,
                    search: keyword,
                    queryStrFilter: filter,
                    tab: 'DocumentUploads'
                }}
                tableData={convertedData}
                filter={filter}
                notClickable={true}
            />
            { console.log('setImportModalOpen', importModalOpen) }
            { importModalOpen && <DocumentUploadModal setModalOpen={setImportModalOpen} /> }
        </>
      )}
    </>
  );
};

export default DocumentUploadList;
