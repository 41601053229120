import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import RolesFormFields from './RolesFormFields';
import ModalForm from '../../../components/modal/ModalForm';
import { STATUSES, USER_TYPE_OPTIONS } from '../../../lib/constants/fields';

import { getRolesList } from '../../../services/api/queryService';

const RolesList = ({ create, update }) => {
  const [itemId, setItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { data, refetch, error } = useQuery(getRolesList, {
    variables: { order: 'name' }
  });

  let roles;
  if (data) {
    roles = data.roles;
  }

  const renderTableHeading = key => {
    if (key === '__typename' || key === 'permissions') {
      return;
    }
    if (key === 'id') {
      return (
        <th key={key} className="text-capitalize text-center">
          ID
        </th>
      );
    }
    return (
      <th key={key} className="text-capitalize">
        {key.replace('_', ' ')}
      </th>
    );
  };

  const renderTableField = (key, item) => {
    if (key === '__typename' || key === 'permissions') {
      return;
    }
    if (key === 'id') {
      return (
        <td key={key} className="text-center">
          {item[key]}
        </td>
      );
    }
    if (key === 'status') {
      return <td key={key}>{STATUSES[item[key]]}</td>;
    }
    if (key === 'sections') {
      return;
    }
    if (key === 'user_type') {
      return <td key={key}>{ USER_TYPE_OPTIONS.find(x => x.value == item[key]).label }</td>;
    }
    return <td key={key}>{item[key]}</td>;
  };

  if (error) {
    throw new Error(error);
  }

  return (
    <>
      {!roles ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <div className="card">
          <div className="card-body">
            <div>
              {create && (
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    setItemId(null);
                    setModalOpen(true);
                  }}
                >
                  Create New
                </button>
              )}
            </div>

            {!!roles.length && (
              <table className="table mt-3 mb-0">
                <thead>
                  <tr>
                    {Object.keys(roles[0]).map(key => renderTableHeading(key))}
                    {update && <th className="text-center">Actions</th>}
                  </tr>
                </thead>
                <tbody>
                  {roles.map(item => (
                    <tr key={item.id}>
                      {Object.keys(item).map(key => renderTableField(key, item))}
                      {update && (
                        <td className="text-center">
                          <i
                            id={item.id}
                            className="fas fa-edit"
                            style={{ cursor: 'pointer' }}
                            onClick={e => {
                              setItemId(+e.target.id);
                              setModalOpen(true);
                            }}
                          />
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>

          {modalOpen && (
            <ModalForm setModalOpen={setModalOpen}>
              <RolesFormFields
                itemId={itemId}
                data={roles.find(item => +item.id === itemId)}
                setModalOpen={setModalOpen}
                refetch={refetch}
              />
            </ModalForm>
          )}
        </div>
      )}
    </>
  );
};

export default RolesList;
