import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';

/** style */
import './Table.css';

/** Components */
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import TableFilter from './TableFilter';
import ExpandedTableFilter from './ExpandedTableFilter';
import ExportModal from '../../view/product/ExportModal';
import DeleteModal from '../../view/product/DeleteModal';

/** ui-components */
import Pagination from '../ui-components/pager/Pagination';
import LinkedItemModal from '../../view/product/LinkedItemModal';
import ModalForm from '../modal/ModalForm';
import ContentExportModal from '../../view/listings/ContentExportModal';
import PortfolioModal from '../../view/product/PortfolioModal';
import BulkEditModal from '../../view/product/BulkEditModal';
import ImportModal from '../../view/product/import/ImportModal';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ContentImportModal from '../../view/listings/import/ContentImportModal';
import { CURRENCY_SYMBOLS } from '../../lib/constants/fields';

const Table = ({
  openNewTab,
  description,
  pagerData,
  tableData,
  history,
  refetch,
  hasAction,
  filterObject = { modal: false },
  expandedHeader,
  hasExport,
  onAction,
  notClickable,
  canDelete,
  filter,
  isListing
}) => {
  /** Initial Values */
  const [{ header, body }, setTableItems] = useState({
    header: [],
    body: []
  });
  const [expandedFilterOpen, setExpandedFilterOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [deletetModalOpen, setdeletetModalOpen] = useState(false);
  const [bulkEdittModalOpen, setBulkEdittModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
   
  const [linkedItemsModalOpen, setLinkedItemsModalOpen] = useState(false);
  const [portfolioModalOpen, setPortfolioModalOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isAsc, setIsAsc] = useState(null);
  const [selectedProductTab, setSelectedProductTab] = useState('product');
  const location = useLocation();
  const isProduct = description.is_product
  
  const selectedTab = new URLSearchParams(location.search).get('tab');

  
  /** Fill Header Array */
  useEffect(() => {
    const headerData = [];
    const bodyData = [];
    const marketplaces = []

    if(isProduct){
      tableData = tableData.map(x => {
        const obj = {
          slug: x.slug,
          product_parent_id: x.product_parent_id,
          name: `${x.brand?.name} | ${x.product_name} | ${x.product_details} | ${x.packagingUnit?.name} | ${x.gtin} | ${x.manufacturer_product_id}`,
        }
        if(selectedProductTab == 'product'){
          obj.manufacturer = x.manufacturer.name
          obj.brand = x.brand.name
          obj.product_name = x.product_name
          obj.product_details = x.product_details
          obj.gtin = x.gtin
          obj.packaging_unit = x.packagingUnit?.name
          obj.type = x.type.name
          obj.status = x.status
        }else if(selectedProductTab == 'categorization'){
          obj.product_category_electronics = x.electronicsCategory?.name
          obj.product_category_food = x.foodCategory?.name
          obj.product_category_hazmat = x.hazmatCategory?.name
          obj.product_category_pharmacy = x.pharmacyCategory?.name
        }else if(selectedProductTab == 'logistics'){
          obj.article_width =  x.productLogistic?.article_width ? x.productLogistic?.article_width + 'mm' : '-'
          obj.article_height =  x.productLogistic?.article_height ? x.productLogistic?.article_height + 'mm' : '-'
          obj.article_depth =  x.productLogistic?.article_depth ? x.productLogistic?.article_depth + 'mm' : '-'
          obj.article_weight =  x.productLogistic?.article_weight ? x.productLogistic?.article_weight + 'g' : '-'
          obj.article_packaging_type =  x.productLogistic?.articlePackagingType ? x.productLogistic?.articlePackagingType?.name : '-'
          // obj.logistical_packaging_unit =  x.productLogistic?.packaging_unit_logistical ? x.productLogistic?.packaging_unit_logistical : '-'
        }else if(selectedProductTab == 'marketplace'){
          if(x.productMarketplaces){
            x.productMarketplaces.map(m => {
              const objMarketpalce = {...obj}
              objMarketpalce.marketplace = m.marketplace?.name
              objMarketpalce.marketplace_id_type = m.marketplace_id_type
              objMarketpalce.marketplace_id = m.marketplace_product_id
              objMarketpalce.fulfillment_vendor = m.skuFullFilmentVendors?.fulfillment?.name
              objMarketpalce.fee_category = m.feeCategory?.name
              objMarketpalce.inventory_id = m.seller_id == 1 ? ((m.skuFullFilmentVendors?.fulfillment_vendor_id == 1 || m.skuFullFilmentVendors?.fulfillment_vendor_id == 15) ? m.marketplace_number_id : ((m.skuFullFilmentVendors?.fulfillment_vendor_id > 1 && m.skuFullFilmentVendors?.fulfillment_vendor_id != 15) ? x.slug : null)) : null
              objMarketpalce.seller = m.seller?.name
              objMarketpalce.status = m.status

              marketplaces.push(objMarketpalce)
            })
            return null
          }else{
            obj.marketplace = '-'
            obj.marketplace_id_type = '-'
            obj.marketplace_id = '-'
            obj.fulfillment_vendor = '-'
            obj.fee_category = '-'
            obj.inventory_id = '-'
            obj.seller = '-'
            obj.status = '-'
          }
        }else if(selectedProductTab == 'prices'){
          if(x.productPrices){
            x.productPrices.map(m => {
              obj.marketplace = m.marketplace?.name
              obj.marketplace_id = m.marketplace_product_id
              obj.buy_net = (m.price_buy_net_actual_ct ? (m.price_buy_net_actual_ct/100).toFixed(2) : (0).toFixed(2)) + CURRENCY_SYMBOLS[0]
              obj.recomended_retail_price = (+m.rrp_value).toFixed(2) + CURRENCY_SYMBOLS[0]
              obj.tax = (m.tax_rate*100) + '%'
              obj.amazon_tax_code = m.tax_code
            })
          }else{
            obj.marketplace = '-'
            obj.marketplace_id = '-'
            obj.buy_net = '-'
            obj.recomended_retail_price = '-'
            obj.tax = '-'
            obj.amazon_tax_code = '-'
          }

        }else if(selectedProductTab == 'documents'){
          obj.safety_data_sheet = x.safety_data_sheet
          obj.gs1_certificate = x.gs1_certificate
          obj.live_images = x.live_images
          obj.warnings_and_instructions = x.warnings_and_instructions
          obj.video_material = x.video_material
        }else if(selectedProductTab == 'customs'){
          if(x.customs){
            x.customs.map(m => {
              obj.product_country_of_origin = m.product_country_of_origin
              obj.customs_tariff_number = m.product_tax_number
            })
          }else{
            obj.product_country_of_origin = '-'
            obj.customs_tariff_number = '-'
          }

        }
        
        x = {...obj}
        return x
      })

      if(marketplaces.length > 0){
        tableData = [...marketplaces]
      }
    }



    /** Loop on response */
    tableData.filter(x => x).forEach(item => {
      /** loop on Each row from response */
      Object.keys(item).forEach(key => {
        /** check if record is type OBject ( that means that its relation data  ) */
        if (typeof item[key] === 'object' && item[key]) {
          let newObject = {};

          /** loop on each record of relation  */
          Object.keys(item[key]).forEach(childObjKey => {
            newObject = {
              ...newObject,
              [childObjKey]: item[key][childObjKey]
            };

            if (headerData.indexOf(childObjKey) === -1 && childObjKey !== '__typename') {
              headerData.push(childObjKey);
            }
          });

          /** merge child objects records to parent object */
          item = {
            ...item,
            ...newObject
          };
        } else {

          if(key === 'slug'){
            item['_id'] = item[key]
          }
          if (headerData.indexOf(key) === -1 && key !== '__typename' && key !== 'slug') {
            headerData.push(key);
          }
        }
      });

      bodyData.push(item);
    });

    setTableItems({
      header: headerData,
      body: bodyData
    });
  }, [tableData, pagerData.perPage, pagerData.search, selectedProductTab]);

  const filterHandler = obj => {
    let url = `${description.url}?page=1`;
    Object.keys(obj).forEach(key => {
      if (obj[key].value) {
        switch (key) {
          case 'search':
            url += '&keyword=' + obj[key].value;
            if (filter && filter !== '' && filter !== '=undefined') {
              url += '&' + filter;
            }
            break;

          case 'count':
            url += '&count=' + obj[key].value[0].value;
            if (filter && filter !== '' && filter !== '=undefined') {
              url += '&' + filter;
            }
            break;

          default:
            if (obj[key].value[0]) {
              obj[key].value.forEach(item => {
                url += '&' + obj[key].label + '[]=' + item.value;
              });
            }
            break;
        }
      }
    });

    url = pagerData.dateFrom ? `${url}${pagerData.dateFrom}` : url;
    url = pagerData.dateTo ? `${url}${pagerData.dateTo}` : url;

    if (description.tab) {
      url += `&tab=${description.tab}`;
    }

    history.push(url);
  };

  useEffect(() => {
    setMenuOpen(false)
  }, [portfolioModalOpen, linkedItemsModalOpen, deletetModalOpen, bulkEdittModalOpen])

  const sortByHandler = s => {
    const b = [...body]
    const opt = s.replaceAll(' ', '_')

    b.sort((a, b) => {
      if(isAsc != opt){
        return a[opt]?.localeCompare(b[opt])
      }

      return b[opt]?.localeCompare(a[opt])
    })

    if(isAsc == opt){
      setIsAsc(null)
    }else{
      setIsAsc(opt)
    }


    setTableItems({
      header,
      body: b
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <div className="d-flex mb-2">
                {hasExport && (
                  <>
                  
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      style={{ height: '36px', width: '70px' }}
                      onClick={() => setExportModalOpen(true)}
                    >
                      Export
                    </button>
                    <button
                      type="button"
                      className="btn btn-primary mr-2"
                      style={{ height: '36px', width: '70px' }}
                      onClick={() => setImportModalOpen(true)}
                    >
                      Import
                    </button>

                    {description.module === 'products' && tableData && (
                      <div style={{ position: 'relative' }}>
                        <button
                          type="button"
                          className="btn btn-secondary mr-2"
                          style={{ height: '36px', width: '70px' }}
                          onClick={() => setMenuOpen(!menuOpen)}
                        >
                          Edit
                        </button>
                        { menuOpen && (
                          <div className="dropdown__menu">
                            <button className="dropdown__menu__item">
                              <Link
                                to={`/products/create`}
                                style={{ width: '100px' }}
                              >
                                Create New
                              </Link>
                            </button>
                            <button className="dropdown__menu__item" disabled={ selectedItems.length === 0 } onClick={() => setPortfolioModalOpen(true)}>
                              <p>Add to portfolio</p>
                            </button>

                            <button className="dropdown__menu__item" disabled={ selectedItems.length === 0 } onClick={() => setLinkedItemsModalOpen(true)}>
                              <p>Link items</p>
                            </button>

                            <button className="dropdown__menu__item" disabled={ selectedItems.length === 0 } onClick={() => setdeletetModalOpen(true)}>
                              <p>Delete</p>
                            </button>

                            <button className="dropdown__menu__item" disabled={ selectedItems.length === 0 } onClick={() => setBulkEdittModalOpen(true)}>
                              <p>Bulk edit</p>
                            </button>

                          </div>
                        ) }
                      </div>
                    )}
                    {/* {description.module === 'products' && tableData && canDelete.delete && !selectedItems.length && (
                      <button
                        type="button"
                        className="btn btn-danger mr-2"
                        style={{ height: 'fit-content' }}
                        disabled={true}
                        onClick={() => setdeletetModalOpen(true)}
                      >
                        Delete
                      </button>
                    )}
                    {description.module === 'products' && tableData && canDelete.delete && selectedItems.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger mr-2"
                        style={{ height: 'fit-content' }}
                        onClick={() => setdeletetModalOpen(true)}
                      >
                        Delete
                      </button>
                    )}
                    {description.module === 'products' && tableData && selectedItems.length > 0 && (
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        style={{ height: 'fit-content', width: '120px', border: "0px" }}
                        onClick={() => setLinkedItemsModalOpen(true)}
                      >
                        Link items
                      </button>
                    )} */}
                  </>
                )}

                {exportModalOpen && description.tab === 'listings' && (
                  <ContentExportModal selectedItems={selectedItems} dataList={body} setModalOpen={setExportModalOpen} />
                )}

                {exportModalOpen && description.tab !== 'listings' && (
                  <ExportModal selectedItems={selectedItems} dataList={body} setModalOpen={setExportModalOpen} />
                )}

                {deletetModalOpen && (
                  <DeleteModal selectedItems={selectedItems} setModalOpen={() => {setdeletetModalOpen(false); refetch();} } />
                )}

                {bulkEdittModalOpen && (
                  <BulkEditModal selectedItems={selectedItems} setModalOpen={() => {setBulkEdittModalOpen(false); refetch();} } />
                )}


                {linkedItemsModalOpen && (
                  <ModalForm setModalOpen={setLinkedItemsModalOpen}>
                    <LinkedItemModal selectedItems={selectedItems} setModalOpen={() => {setLinkedItemsModalOpen(false); refetch();} } />
                  </ModalForm>
                )}

                {portfolioModalOpen && (
                  <ModalForm setModalOpen={setPortfolioModalOpen}>
                    <PortfolioModal selectedItems={selectedItems} products={tableData} setModalOpen={() => {setPortfolioModalOpen(false); } } />
                  </ModalForm>
                )}

                {importModalOpen && selectedTab != 'listings' && <ImportModal setModalOpen={setImportModalOpen} />}
                {importModalOpen && selectedTab == 'listings' && <ContentImportModal setModalOpen={setImportModalOpen} />}




                {pagerData.perPage && (
                  <TableFilter
                    filter={filterHandler}
                    perPage={pagerData.perPage}
                    searchValue={pagerData.search}
                    queryStr={pagerData.queryStrFilter}
                    filterObject={filterObject}
                    download={true}
                    exportModule={description.module}
                    expandedHeader={expandedHeader}
                  />
                )}
                {expandedHeader && (
                  <div className="d-flex">
                    <div className="d-flex mx-3 align-items-center">Filter: </div>
                    <div
                      className={`table-filter-button ${expandedFilterOpen ? 'active' : ''}`}
                      onClick={() => setExpandedFilterOpen(!expandedFilterOpen)}
                    >
                      <i className="fas fa-filter" />
                    </div>
                  </div>
                )}
              </div>

              {expandedFilterOpen && (
                <ExpandedTableFilter
                  pagerData={pagerData}
                  history={history}
                  queryStr={filter}
                  module={description.module}
                  tab={description.tab}
                  filterType={isListing ? 'content' : 'product'}
                />
              )}

              { isProduct && (
                <div className="d-flex py-2">
                  <div className="product-tabs">
                    <div onClick={() => setSelectedProductTab('product')} className={`tab ${selectedProductTab === 'product' ? 'active' : ''}`}>Product information</div>
                    <div onClick={() => setSelectedProductTab('categorization')} className={`tab ${selectedProductTab === 'categorization' ? 'active' : ''}`}>Product categorization</div>
                    <div onClick={() => setSelectedProductTab('logistics')} className={`tab ${selectedProductTab === 'logistics' ? 'active' : ''}`}>Logistics information</div>
                    <div onClick={() => setSelectedProductTab('marketplace')} className={`tab ${selectedProductTab === 'marketplace' ? 'active' : ''}`}>Marketplace information</div>
                    <div onClick={() => setSelectedProductTab('prices')} className={`tab ${selectedProductTab === 'prices' ? 'active' : ''}`}>Prices</div>
                    <div onClick={() => setSelectedProductTab('customs')} className={`tab ${selectedProductTab === 'customs' ? 'active' : ''}`}>Customs information</div>
                    <div onClick={() => setSelectedProductTab('documents')} className={`tab ${selectedProductTab === 'documents' ? 'active' : ''}`}>Documents</div>
                    <div className="tab-bar"></div>
                  </div>
                </div>
              )}

              <div className="table-responsive">
                <table id="basic-datatable" className="table table-hover dt-responsive nowrap">
                  <TableHeader
                    titles={header}
                    page={description.module}
                    content={body}
                    hasAction={hasAction}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    isListing={isListing}
                    sortByHandler={sortByHandler}
                  />
                  <TableBody
                    moduleName={description.module}
                    content={body}
                    openNewTab={openNewTab}
                    keys={header}
                    hasAction={hasAction}
                    refetch={refetch}
                    notClickable={notClickable}
                    history={history}
                    onAction={onAction}
                    selectedItems={selectedItems}
                    setSelectedItems={setSelectedItems}
                    isListing={isListing}
                  />
                </table>
              </div>
              {pagerData.perPage && (
                <Pagination data={pagerData} />
              )}
            </div>{' '}
            {/* end card body*/}
          </div>{' '}
          {/* end card */}
        </div>
        {/* end col*/}
      </div>
    </>
  );
};

export default withRouter(Table);
