import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import ModalError from '../../../../components/modal/ModalError';
import { updateUserPassword } from '../../../../services/api/mutationService';

const PasswordFormFields = ({ userId, setOpenModalId, refetch }) => {
  const [formData, setFormData] = useState({
    password: null,
    password_repeat: null
  });
  const [mutationError, setMutationError] = useState();
  const [errors, setErrors] = useState({
    password: null,
    password_repeat: null,
    update: null
  });
  const dispatch = useDispatch();
  const [updateUserPasswordMutation, { loading, error: updateError }] = useMutation(
    updateUserPassword
  );

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.id]: null
    });
  };

  const submitHandler = async event => {
    event.preventDefault();

    const errors = {
      password: formData.password.length < 8 && 'Password should be at least 8 characters',
      password_repeat: formData.password !== formData.password_repeat && 'Passwords must match'
    };
    if (Object.keys(errors).some(key => errors[key])) {
      setErrors(errors);
      return;
    }

    try {
      const { data } = await updateUserPasswordMutation({
        variables: {
          id: userId,
          password: formData.password
        }
      });
      if (!data.updateUserPassword) {
        throw new Error('Server responded with no data');
      }
      const errorMessage = data.updateUserPassword.error;
      if (errorMessage) {
        setErrors({
          ...errors,
          update: errorMessage
        });
        return;
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setOpenModalId(null);
  };

  if (mutationError || updateError) {
    throw new Error(mutationError || updateError);
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          Update password
        </h4>
        <button type="button" className="close" onClick={() => setOpenModalId(null)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError error={errors.password} />
        <ModalError error={errors.password_repeat} />
        <ModalError error={errors.update} />
        <div className="form-group">
          <label htmlFor="password">
            Password <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="password"
            id="password"
            className="form-control"
            required
            defaultValue={formData.email}
            onChange={changeFieldHandler}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password_repeat">
            Password repeat <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="password"
            id="password_repeat"
            className="form-control"
            required
            defaultValue={formData.email_repeat}
            onChange={changeFieldHandler}
          />
        </div>
      </div>
      <div className="modal-footer justify-content-start">
        {loading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default PasswordFormFields;
