import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Link, useHistory, useLocation, Redirect } from 'react-router-dom';
import axios from 'axios';

import { getSections as QUERY } from '../../services/api/queryService';

/** Components */
import ReportsTab from './ReportsTab';
import SectionsTab from './SectionsTab';
import AddSectionFields from './AddSectionFields';
import ModalForm from '../../components/modal/ModalForm';

import './Analytics.scss';
import TabSort from './TabSort';
import SubsectionSort from './SubsectionSort';

const AnalyticsSettings = ({ create, update }) => {
  const [openModal, setOpenModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [reloadData, seReloadData] = useState(0)
  const [loader, setLoader] = useState(0)
  const [refreshBtnClicked, setRefreshBtnClicked] = useState(false)
  const [ changedData, setChangedData ] = useState({});

  let { loading, data, refetch, error } = useQuery(QUERY, {
    variables: {
      filter: false
    }
  });

  useEffect(() => {
    refetch()
    if (data) {
      const newData = {
        getSections: {
          data: data.getSections,
        }
      }
      setChangedData(newData)
      setLoader(false)
    }
  }, [data])

  const selectedTab = new URLSearchParams(location.search).get('tab');

  if (!selectedTab) {
    return <Redirect to="/analytics-settings?tab=reports" />;
  }

  const tabClickHandler = tabId => {
    history.push(`/analytics-settings?tab=${tabId}`);
  };


  const refreshReports = () => {
    setRefreshBtnClicked(true)
    axios.get(`https://azure-api.ebazaaris.io/api/getAllReports`)
      .then(res => {
        setRefreshBtnClicked(false)

        alert('Report are successfully refreshed')
      })
  }

  const baseUri = 'https://live.ebazaaris.io/';

  refetch()


  return (
    <>
      {/* start page title */}
      <div className="row">
        <div className="col-12">
          <div className="page-title-box">
            <h4 className="page-title">Analytics settings</h4>

            <div className="row">
              <div className="col-12">
                <div className="page-title-box">
                  {selectedTab === 'sections' && (
                    <button
                      type="button"
                      className="page-title-right btn btn-primary mr-1 mb-1 mt-0 px-3"
                      style={{ borderRadius: '20px' }}
                    >
                      <span
                        onClick={() => setOpenModal(true)}
                        style={{ width: '100px' }}
                        className="text-white"
                      >
                        Create New
                      </span>
                    </button>
                  )}
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item">
                      <Link to="/">Settings</Link>
                    </li>
                    <li className="breadcrumb-item active">Analytics settings</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end page title */}

      {/* Tabs */}
      <div className="row reportPage">
        <div className="col-10">
          <div className="product-tabs  analytics-tabs">
            <div
              className={`tab ${selectedTab === 'reports' ? 'active' : ''}`}
              onClick={tabClickHandler.bind(null, 'reports')}
            >
              PowerBI reports
            </div>
            <div
              className={`tab ${selectedTab === 'sections' ? 'active' : ''}`}
              onClick={tabClickHandler.bind(null, 'sections')}
            >
              Sections
            </div>
            <div
              className={`tab ${selectedTab === 'subsections-order' ? 'active' : ''}`}
              onClick={tabClickHandler.bind(null, 'subsections-order')}
            >
              Subsections order
            </div>
            <div
              className={`tab ${selectedTab === 'tabs-order' ? 'active' : ''}`}
              onClick={tabClickHandler.bind(null, 'tabs-order')}
            >
              Tabs order
            </div>
            
            <div className="tab-bar" />
          </div>
        </div>
        <div className="col-2">
          {!refreshBtnClicked && selectedTab === 'reports' && (
              <a onClick={refreshReports} target="_blank" style={{ borderRadius: '20px', width: '200px', position: 'absolute', right: '0', top: '-5px' }} className="btn btn-primary  btn-block cursor-pointer rfrBtn text-white relative">
                Refresh all reports <img src="/assets/images/refresh.png" width="20px" alt="" />
              </a>
          )}
          {refreshBtnClicked && (
            <p>Fetching...</p>
          )}
        </div>
      </div>
      {selectedTab === 'sections' &&
        <div className="alert alert-warning  w-100">
            <i className='fas fa-info-circle mr-2 '></i>
            <span >New analytics sections need to be assigned to the required user role before being visible. You can assign roles here: <a  style={{ textDecoration: 'underline' }} href={`${baseUri}user-management?tab=roles`}>{'Usermanagement > Roles'}</a></span>
        </div>
      }
      {selectedTab === 'reports' && <ReportsTab location={location} />}

      {selectedTab === 'sections' && <SectionsTab reloadData={reloadData} location={location} />}

      {selectedTab === 'tabs-order' && <TabSort reloadData={reloadData} location={location} />}

      {selectedTab === 'subsections-order' && <SubsectionSort reloadData={reloadData} location={location} />}


      
      {openModal && (
        <ModalForm setModalOpen={setOpenModal}>
          <AddSectionFields
            sections={changedData.getSections.data}
            setModalOpen={() => {setOpenModal(false); seReloadData(reloadData + 1) } }
          />
        </ModalForm>
      )}
    </>
  );
};

export default AnalyticsSettings;
