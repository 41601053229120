import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import { updateListing } from '../../../services/api/mutationService';
import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';

import { getProductCategoriesSettings } from '../../../services/api/queryService';
import { number } from 'prop-types';

const ProductCharacteristics = ({ selectData, productData, refetch, update, onLisitingChange, product }) => {
  const [updateListingMutation, { loading, error: updateError }] = useMutation(
    updateListing
  );

  const dispatch = useDispatch();
  const [error, setError] = useState();
  const [marketplaceID, setMarketplaceID] = useState(
    productData.marketplace_id
  );

  const parsedData = productData.characteristics ? JSON.parse(productData.characteristics) : {}


  const packagingUnits = [
    '',
    'Single article',
    'Soft bundle article',
    'Hard bundle article'
  ]

  let vol = ''
  if(product.productLogistic){
    if(product.productLogistic.article_height && product.productLogistic.article_width && product.productLogistic.article_depth){
      vol = `${product.productLogistic.article_height}/${product.productLogistic.article_depth}/${product.productLogistic.article_width} mm`
    }

  } 


  const [formData, setFormData] = useState({
    marke: product.brand.name,
    produktart: parsedData.produktart,
    material: parsedData.material,
    farbe: parsedData.farbe,
    form: parsedData.form,
    anlass: parsedData.anlass,
    thema: parsedData.thema,
    besonderheiten: parsedData.besonderheiten,
    character_famillie: parsedData.character_famillie,
    charakter: parsedData.charakter,
    herstellungsjahr: parsedData.herstellungsjahr,
    herstellernummer: parsedData.herstellernummer,
    muster: parsedData.muster,
    land_region: parsedData.land_region,
    abteilung: parsedData.abteilung,
    personalisieren: parsedData.personalisieren,
    angebotspaket: parsedData.angebotspaket,
    anzahl: product.productLogistic?packagingUnits[product.productLogistic.article_packaging_type_id]:'',
    beschreibung: product.product_contents,
    breite: product.productLogistic?product.productLogistic.article_depth:'',
    gewicht: product.productLogistic?product.productLogistic.article_weight:'',
    grosse: vol,
    herstellungszeitraum: parsedData.herstellungszeitraum,
    hohe: product.productLogistic?product.productLogistic.article_height:'',
    lange: product.productLogistic?product.productLogistic.article_width:'',
    masseinheit: 'mm',
    enhalt: product.product_contents,
    stuckzahl: parsedData.stuckzahl,
    anleitung: parsedData.anleitung
  });

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });

  };


  useEffect(() => {
    const fd = {
      marke: product.brand.name,
      produktart: parsedData.produktart,
      material: parsedData.material,
      farbe: parsedData.farbe,
      form: parsedData.form,
      anlass: parsedData.anlass,
      thema: parsedData.thema,
      besonderheiten: parsedData.besonderheiten,
      character_famillie: parsedData.character_famillie,
      charakter: parsedData.charakter,
      herstellungsjahr: parsedData.herstellungsjahr,
      herstellernummer: parsedData.herstellernummer,
      muster: parsedData.muster,
      land_region: parsedData.land_region,
      abteilung: parsedData.abteilung,
      personalisieren: parsedData.personalisieren,
      angebotspaket: parsedData.angebotspaket,
      anzahl: product.productLogistic?packagingUnits[product.productLogistic.article_packaging_type_id]:'',
      beschreibung: product.product_contents,
      breite: product.productLogistic?product.productLogistic.article_depth:'',
      gewicht: product.productLogistic?product.productLogistic.article_weight:'',
      grosse: vol,
      herstellungszeitraum: parsedData.herstellungszeitraum,
      hohe: product.productLogistic?product.productLogistic.article_height:'',
      lange: product.productLogistic?product.productLogistic.article_width:'',
      masseinheit: 'mm',
      enhalt: product.product_contents,
      stuckzahl: parsedData.stuckzahl,
      anleitung: parsedData.anleitung
    }

    setFormData({...fd})
    
  }, [productData])

  const submitHandler = async event => {
    event.preventDefault();

    try {
      const { data } = await updateListingMutation({
        variables: {
          input: { 'characteristics' : JSON.stringify(formData) } ,
          product_parent_id: productData.product_parent_id,
          marketplace_id: productData.marketplace_id
        }
      });

      if (!data.updateListing) {
        throw new Error('Server responded with no data');
      }

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setError(err);
    }
  };

  if (error || updateError) {
    throw new Error(error || updateError);
  }

  refetch()
  
  return (
    <form onSubmit={submitHandler}>
      <div className="row row-cols-4 content_section">
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Brand
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Marke</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="marke"
            className="form-control"
            value={formData.marke}
            onChange={changeFieldHandler}
            disabled={true}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">Product type
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Produktart</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="produktart"
            className="form-control"
            value={formData.produktart}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Material
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Material</span>
              </div>
            </div>
          </label>
          {productData && (

            <input
            type="text"
            id="material"
            className="form-control"
            value={formData.material}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Colour
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Farbe</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="farbe"
            className="form-control"
            value={formData.farbe}
            onChange={changeFieldHandler}
            />
          )}
        </div>

        <div className="col-3 mb-2">
          <label htmlFor="title">
            Phorm
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Form</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="form"
            className="form-control"
            value={formData.form}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Reason
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Anlass</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="anlass"
            className="form-control"
            value={formData.anlass}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Theme
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Thema</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="thema"
            className="form-control"
            value={formData.thema}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Special features
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Besonderheiten</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="besonderheiten"
            className="form-control"
            value={formData.besonderheiten}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Character type
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Charakter Familie</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="character_famillie"
            className="form-control"
            value={formData.character_famillie}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Character
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Charakter</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="charakter"
            className="form-control"
            value={formData.charakter}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Creation year
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Herstellungsjahr</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="herstellungsjahr"
            className="form-control"
            value={formData.herstellungsjahr}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Manufacturer number
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Herstellernummer</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="herstellernummer"
            className="form-control"
            value={product.manufacturer_product_id}
            onChange={changeFieldHandler}
            disabled={true}
            />
          )}
        </div>

        <div className="col-3 mb-2">
          <label htmlFor="title">
            Model
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Muster</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="muster"
            className="form-control"
            value={formData.muster}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Country of region
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Herstellungsland und -region</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="land_region"
            className="form-control"
            value={formData.land_region}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Department
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Abteilung</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="abteilung"
            className="form-control"
            value={formData.abteilung}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Personalize
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Personalisieren</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="personalisieren"
            className="form-control"
            value={formData.personalisieren}
            onChange={changeFieldHandler}
            />
          )}
        </div>

        <div className="col-3 mb-2">
          <label htmlFor="title">
            Offer package
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Angebotspaket</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="angebotspaket"
            className="form-control"
            value={formData.angebotspaket}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Article packaging type
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Anzahl der Einheiten</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="anzahl"
            className="form-control"
            value={formData.anzahl}
            onChange={changeFieldHandler}
            disabled={product.productLogistic ? product.productLogistic.article_packaging_type_id??false : false}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Description of the package content
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Beschreibung des Paketinhalts</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="beschreibung"
            className="form-control"
            value={formData.beschreibung}
            onChange={changeFieldHandler}
            disabled={product.product_contents??false}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Width
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Breite</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="breite"
            className="form-control"
            value={formData.breite}
            onChange={changeFieldHandler}
            disabled={product.productLogistic? product.productLogistic.article_depth??false : false}
            />
          )}
        </div>

        <div className="col-3 mb-2">
          <label htmlFor="title">
            Weight
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Gewicht</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="gewicht"
            className="form-control"
            value={formData.gewicht}
            onChange={changeFieldHandler}
            readOnly={true}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Size
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Größe</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="grosse"
            className="form-control"
            value={formData.grosse}
            onChange={changeFieldHandler}
            disabled={vol.length}
            />
            
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Manufacturing period
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Herstellungszeitraum</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="herstellungszeitraum"
            className="form-control"
            value={formData.herstellungszeitraum}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Height
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Höhe</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="hohe"
            className="form-control"
            value={formData.hohe}
            onChange={changeFieldHandler}
            disabled={product.productLogistic? product.productLogistic.article_height??false : false}
            />
          )}
        </div>

        
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Leight
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Länge</span>
              </div>
            </div>  
          </label>
          {productData && (
            <input
            type="text"
            id="lange"
            className="form-control"
            value={formData.lange}
            onChange={changeFieldHandler}
            disabled={product.productLogistic? product.productLogistic.article_width??false : false}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Unit of measurement
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Maßeinheit</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="masseinheit"
            className="form-control"
            value={formData.masseinheit}
            onChange={changeFieldHandler}
            disabled={true}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Contents
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Set enthält</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="enhalt"
            className="form-control"
            value={product.product_contents}
            onChange={changeFieldHandler}
            disabled={true}
            />
          )}
        </div>
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Quantity
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Stückzahl</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="stuckzahl"
            className="form-control"
            value={product?.packagingUnit?.name}
            onChange={changeFieldHandler}
            disabled={true}
            />
          )}
        </div>

        
        <div className="col-3 mb-2">
          <label htmlFor="title">
            Instruction for personalization
            <div className="u_infoButton">
              <img src="/assets/images/info-button.png" alt="" />
              
              <div className="infoBox">
                  <span>Anleitung für Personalisierung</span>
              </div>
            </div>
          </label>
          {productData && (
            <input
            type="text"
            id="anleitung"
            className="form-control"
            value={formData.anleitung}
            onChange={changeFieldHandler}
            />
          )}
        </div>
        
      </div>
      {update && (
        <>
          {loading ? (
            <div className="spinner">Spinner</div>
          ) : (
            <button type="submit" className="btn btn-primary px-3 mt-3">
              Save
            </button>
          )}
        </>
      )}
    </form>
  );
};

export default ProductCharacteristics;
