import React, { useEffect, useState } from 'react';
import ModalForm from '../../../components/modal/ModalForm'
import BundledItemsFormModal from './BundledItemsFormModal'

const BundleInformationForm = ({ productData, refetch,slug, update }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    [...productData.hasParentProducts].forEach((item, index) => {
        if(item.parentProduct === null) productData.hasParentProducts.splice(index, 1);
    });

    setFormData(productData.hasParentProducts)
  }, [productData])


  return (
    <form>
      <div className="overflow-auto">
        <table className="table mt-0">
          <thead>
            <tr>
              <th>PID</th>
              <th>GTIN</th>
              <th>Bundle name</th>
              <th>Packaging unit</th>
              <th>Quantity in a bundle</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((row, index) => row.parentProduct != null && (
              <tr key={index} >
                <td>
                  {row.parentProduct.slug}
                </td>
                <td>
                  {row.parentProduct.gtin??'-'}
                </td>
                <td>
                  <a  style={{color: '#00488c'}} target="_blank" href={`/products/${row.parentProduct.slug}/edit`}>
                    {row.parentProduct.product_name}
                  </a>
                </td>

                <td>
                  {row.parentProduct?.packagingUnit?.name}
                </td>

                <td>
                  {row.product_qty}
                </td>

                <td style={{ minWidth: '150px' }}>
                  {row.parentProduct.status ? (
                    <span className="status-badge status-badge-active">Active</span>
                  ) : (
                    <span className="status-badge status-badge-archived">Archived</span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </form>
  );
};

export default BundleInformationForm;
