import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import ModalError from '../../../../components/modal/ModalError';
import { updateUserEmail } from '../../../../services/api/mutationService';

const EmailFormFields = ({ userId, setOpenModalId, refetch }) => {
  const [formData, setFormData] = useState({
    email: null,
    email_repeat: null
  });
  const [errors, setErrors] = useState({
    email_repeat: null,
    update: null
  });
  const dispatch = useDispatch();
  const [mutationError, setMutationError] = useState();
  const [updateUserEmailMutation, { loading, error: updateError }] = useMutation(updateUserEmail);

  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
    setErrors({
      ...errors,
      [event.target.id]: null
    });
  };

  const submitHandler = async event => {
    event.preventDefault();

    const errors = {
      email_repeat: formData.email !== formData.email_repeat && 'Emails must match'
    };
    if (Object.keys(errors).some(key => errors[key])) {
      setErrors(errors);
      return;
    }

    try {
      const { data } = await updateUserEmailMutation({
        variables: {
          id: userId,
          email: formData.email
        }
      });

      if (!data.updateUserEmail) {
        throw new Error('Server responded with no data');
      }

      const errorMessage = data.updateUserEmail.error;
      if (errorMessage) {
        setErrors({
          ...errors,
          update: errorMessage
        });
        return;
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setOpenModalId(null);
  };

  if (updateError || mutationError) {
    throw new Error(updateError || mutationError);
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          Update email
        </h4>
        <button type="button" className="close" onClick={() => setOpenModalId(null)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError error={errors.email_repeat} />
        <ModalError error={errors.update} />
        <div className="form-group">
          <label htmlFor="email">
            Email <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="email"
            id="email"
            className="form-control"
            required
            defaultValue={formData.email}
            onChange={changeFieldHandler}
          />
        </div>
        <div className="form-group">
          <label htmlFor="email_repeat">
            Email repeat <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="email"
            id="email_repeat"
            className="form-control"
            required
            defaultValue={formData.email_repeat}
            onChange={changeFieldHandler}
          />
        </div>
      </div>
      <div className="modal-footer justify-content-start">
        {loading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <button type="submit" className="btn btn-primary">
            Save
          </button>
        )}
      </div>
    </form>
  );
};

export default EmailFormFields;
