import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useMutation } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { ACCOUNT_TYPE_OPTIONS, STATUS_OPTIONS } from '../../../lib/constants/fields';
import ModalError from '../../../components/modal/ModalError';

import "react-datepicker/dist/react-datepicker.css";
import "./default.scss";
import { DeleteMDF, DeleteSalesTarget } from '../../../services/api/mutationService';
import moment from 'moment';

const DefaultDimensionFormFields = ({
  itemId,
  createMutationId,
  updateMutationId,
  data,
  setModalOpen,
  name,
  manufacturerId,
  createMutation,
  updateMutation,
  refetch,
  isSalesTargets,
  isPortfolio,
  isMDF,
  brands
}) => {
  const [formData, setFormData] = useState({
    name: data && data.name,
    status: data ? data.status : STATUS_OPTIONS[1].id,
  });

  const dispatch = useDispatch();
  const [createItem, { loading: createLoading, error: createError }] = useMutation(createMutation);
  const [updateItem, { loading: updateLoading, error: updateError }] = useMutation(updateMutation);
  const [DeleteSalesTargetMutation,{ loading:deleteLoading }] = useMutation(!isMDF ? DeleteSalesTarget : DeleteMDF);
  
  const [error, setError] = useState(null);
  const [mutationError, setMutationError] = useState();
  const [changesMade, setChangesMade] = useState(false);
  const [startDate, setStartDate] = useState(data && data.period_date ? new Date(data.period_date) : new Date());
  const [dateRange, setDateRange] = useState([null, null]);
  const [mdfStartDate, mdfEndDate] = dateRange;

  const [targetFormData, setTargetFormData] = useState({
    period_date: data ? data.period_date : startDate,
    revenue: data ? data.revenue : null
  })

  const [MDFFormData, setMDFFormData] = useState({
    brand_id: data ? data.brand_id : null,
    amount: data ? data.amount : null,
    period_from: data ? data.period_from : mdfStartDate,
    period_to: data ? data.period_to : mdfEndDate,
    status: data ? data.status : null
  })

  useEffect(() => {
    if(itemId && isMDF){
      let r = data.period_from.split('/')
      let k = data.period_to.split('/')
      let s = new Date(`${r[2]}-${r[1]}-${r[0]}`)
      let e = new Date(`${k[2]}-${k[1]}-${k[0]}`)
      setDateRange([s, e])
    }
  }, [itemId])

  useEffect(() => {
    if(name == 'Sellers'){
      const fd = {...formData}
      fd.short_name = data?.short_name
      fd.account_type = data?.account_type

      setFormData({...fd})
    }
  }, [name])
  
  useEffect(() => {
    if(changesMade){

      window.onbeforeunload = function() {
        return true;
      };
  
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [changesMade]);

  const submitHandler = async event => {
    setChangesMade(false);
    event.preventDefault();
    setError(null);

    if(isMDF){
      console.log(MDFFormData)
      const req = {
        manufacturer_id: manufacturerId,
        brand_id: MDFFormData.brand_id,
        amount: MDFFormData.amount,
        period_from: moment(mdfStartDate).format('YYYY-MM-01'),
        period_to: moment(mdfEndDate).format('YYYY-MM-01'),
        status: MDFFormData.status,
      }

      try {
        if (itemId !== null) {
          const { data } = await updateItem({
            variables: {
              input: { ...req, id: itemId }
            }
          });
  
          if (!data[updateMutationId]) {
            throw new Error('Server responded with no data');
          }
  
          const errorMessage = data[updateMutationId].error;
          if (errorMessage) {
            setError(errorMessage);
            return;
          }
        } else {
          const { data } = await createItem({
            variables: {
              input: {...req}
            }
          });
          if (!data[createMutationId]) {
            throw new Error('Server responded with no data');
          }
        }
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully updated'
          }
        });
        refetch();
      } catch (err) {
        setMutationError(err);
      }
      setModalOpen(false);

      return false;
    }

    if(isSalesTargets){
      const req = {
        revenue: formData.revenue,
        period_date: startDate,
        manufacturer_id: manufacturerId
      }

      try {
        if (itemId !== null) {
          const { data } = await updateItem({
            variables: {
              input: { ...req, id: itemId }
            }
          });
  
          if (!data[updateMutationId]) {
            throw new Error('Server responded with no data');
          }
  
          const errorMessage = data[updateMutationId].error;
          if (errorMessage) {
            setError(errorMessage);
            return;
          }
        } else {
          const { data } = await createItem({
            variables: {
              input: {...req, revenue: formData.revenue}
            }
          });
          if (!data[createMutationId]) {
            throw new Error('Server responded with no data');
          }
        }
        dispatch({
          type: 'UPDATE',
          payload: {
            type: 'success',
            message: 'Recored was successfully updated'
          }
        });
        refetch();
      } catch (err) {
        setMutationError(err);
      }
      setModalOpen(false);

      return false;
    }

    if (name === 'Brand' || isPortfolio) {
      formData.manufacturer_id = manufacturerId;
    }
    

    try {
      if (itemId !== null) {
        const { data } = await updateItem({
          variables: {
            input: { ...formData, id: itemId }
          }
        });

        if (!data[updateMutationId]) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data[updateMutationId].error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createItem({
          variables: {
            input: formData
          }
        });
        if (!data[createMutationId]) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data[createMutationId].error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully updated'
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setModalOpen(false);
  };

  const changeFieldHandler = event => {
    setChangesMade(true);
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeMDFFieldHandler = event => {
    setChangesMade(true);
    setMDFFormData({
      ...MDFFormData,
      [event.target.id]: event.target.value
    });
  };

  const changeMDFSelectHandler = (option, data) => {
    setChangesMade(true);
    if (option) {
      setMDFFormData({
        ...MDFFormData,
        [data.name]: option.value
      });
    } else {
      const updatedFormData = { ...MDFFormData };
      delete updatedFormData[data.name];
      setMDFFormData(updatedFormData);
    }
  };

  const changeSelectHandler = (option, data) => {
    setChangesMade(true);
    if (option) {
      setFormData({
        ...formData,
        [data.name]: option.value
      });
    } else {
      const updatedFormData = { ...formData };
      delete updatedFormData[data.name];
      setFormData(updatedFormData);
    }
  };

  if (createError || updateError || mutationError) {
    throw new Error(createError || updateError || mutationError);
  }


  const deleteHandler = async () => {
    if(itemId && (isSalesTargets || isMDF)){
      const { data } = await DeleteSalesTargetMutation({
        variables: {
          id: itemId
        }
      });

      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: 'Recored was successfully deleted'
        }
      });
      setModalOpen(false);
  
      refetch();

    }
  }

  return (
    <form onSubmit={submitHandler}>
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          { isPortfolio || isMDF ? (
            <>
              { name }
            </>
          ) : (
            <>
              {`${itemId ? 'Edit' : 'Add'} "${name}" dimension`}
            </>
          ) }
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError error={error} setError={setError} />

        {
          isMDF && (
            <>
              <div className="form-group" style={{ position: 'relative' }}>
                <label htmlFor="brand_id">
                  Brands <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  name="brand_id"
                  options={convertDataToOptions(brands)}
                  value={createDefaultOption(brands.find(i => i.id == MDFFormData.brand_id))}
                  onChange={changeMDFSelectHandler}
                  required
                />
                <input type="text" style={{ position: "absolute", bottom: "0", opacity: "0.0009", zIndex: "-1" }} required={true} value={MDFFormData.brand_id}  />
              </div>
              <div className="form-group">
                <label htmlFor="amount">
                  Amount <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="number"
                  id="amount"
                  className="form-control"
                  max={500000}
                  maxLength={7}
                  required
                  defaultValue={MDFFormData?.amount}
                  onChange={changeMDFFieldHandler}
                  disabled={data && data.status === 0}
                />
              </div>
              <div className="form-group d-flex align-items-center justify-content-between" style={{ background: "#f7f7f7", padding: "15px 5px" }}>
                <label htmlFor="name">
                  Period From/To <span style={{ color: 'red' }}>*</span>
                </label>
                <div className="calendarInput" style={{width: '250px'}}>
                  <DatePicker
                      selectsRange={true}
                      startDate={mdfStartDate}
                      endDate={mdfEndDate}
                      isClearable={false}
                      selected={mdfStartDate}
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      className="form-control"
                      required={true}
                    />
                    <img className="calendarIcon" src="/assets/images/calendar-2.png" alt="" />
                  </div>
              </div>

              <div className="form-group" style={{ position: 'relative' }}>
                <label htmlFor="status">
                  Status <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  name="status"
                  options={convertDataToOptions(STATUS_OPTIONS)}
                  value={createDefaultOption(STATUS_OPTIONS.find(i => i.id === MDFFormData.status))}
                  onChange={changeMDFSelectHandler}
                  required
                />
                <input type="text" style={{ position: "absolute", bottom: "0", opacity: "0.0009", zIndex: "-1" }} required={true} value={MDFFormData.status}  />
              </div>
            </>
          )
        }

        {
          isSalesTargets && !isPortfolio && (
            <>
              <div className="form-group d-flex align-items-center justify-content-between" style={{ background: "#f7f7f7", padding: "15px 5px" }}>
                <label htmlFor="name">
                  Period <span style={{ color: 'red' }}>*</span>
                </label>
                <div className="calendarInput" style={{width: '250px'}}>
                  <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="MMMM yyyy"
                      showMonthYearPicker
                      className="form-control"
                    />
                    <img className="calendarIcon" src="/assets/images/calendar-2.png" alt="" />
                  </div>
              </div>
              <div className="form-group  d-flex align-items-center justify-content-between">
                <label htmlFor="status">
                  Target <span style={{ color: 'red' }}>*</span>
                </label>
                <div style={{width: '250px'}}>
                  <input
                    type="number"
                    id="revenue"
                    className="form-control"
                    required
                    defaultValue={targetFormData.revenue}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key) && event.key != 'Backspace') {
                        event.preventDefault();
                      }
                    }}
                    onChange={changeFieldHandler}
                  />
                </div>
              </div>
            </>
          ) }
          {
            !isSalesTargets && isPortfolio &&  (
              <>
                <div className="form-group">
                  <label htmlFor="name">
                    Name <span style={{ color: 'red' }}>*</span>
                  </label>
                  <input
                    type="text"
                    id="name"
                    className="form-control"
                    required
                    defaultValue={formData?.name?.trim()}
                    onChange={changeFieldHandler}
                    disabled={data && data.status === 0}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="status">
                    Status <span style={{ color: 'red' }}>*</span>
                  </label>
                  <Select
                    name="status"
                    options={convertDataToOptions(STATUS_OPTIONS)}
                    defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id === formData.status))}
                    onChange={changeSelectHandler}
                  />
                </div>
              </>
            )
          }
          { !isSalesTargets && !isPortfolio && !isMDF && (
            <>
              <div className="form-group">
                <label htmlFor="name">
                  Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  id="name"
                  className="form-control"
                  required
                  defaultValue={formData.name}
                  onChange={changeFieldHandler}
                  disabled={data && data.status === 0}
                />
              </div>
              { name == 'Sellers' && (
                <>
                  <div className="form-group">
                    <label htmlFor="short_name">
                      Short name 
                    </label>
                    <input
                      type="text"
                      id="short_name"
                      className="form-control"
                      required
                      defaultValue={formData?.short_name}
                      onChange={changeFieldHandler}
                      disabled={data && data.status === 0}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="account_type">
                      Account type 
                    </label>
                    <Select
                      name="account_type"
                      options={convertDataToOptions(ACCOUNT_TYPE_OPTIONS)}
                      value={createDefaultOption(ACCOUNT_TYPE_OPTIONS.find(i => i.id == formData.account_type))}
                      onChange={changeSelectHandler}
                    />
                  </div>
                </>
              ) }
              <div className="form-group">
                <label htmlFor="status">
                  Status <span style={{ color: 'red' }}>*</span>
                </label>
                <Select
                  name="status"
                  options={convertDataToOptions(STATUS_OPTIONS)}
                  defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id === formData.status))}
                  onChange={changeSelectHandler}
                />
              </div>
            </>
          )
        }
        

      </div>
      <div className="modal-footer justify-content-start">
        {createLoading || updateLoading || deleteLoading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <>
            <button type="submit" className="btn btn-primary">
              Save
            </button>

            {(isSalesTargets || isMDF) &&  itemId && (
              <button type="button" className="btn btn-danger" onClick={deleteHandler}>
                Delete
              </button>
            )}
          </>
        )}
      </div>
    </form>
  );
};

export default DefaultDimensionFormFields;
