import React from 'react';

import './ModalForm.scss';

const ModalForm = ({ setModalOpen, children, styles = {} }) => {
  const backdropClickHandler = event => {
    if (event.target.id === 'modal') {
      setModalOpen(false);
    }
  };

  return (
    <>
      <div className="modal-backdrop show" />
      <div
      
        className="modal"
        id="modal"
        tabIndex="-1"
        aria-labelledby="modalLabel"
        style={{ display: 'block', ...styles}}
        onMouseDown={backdropClickHandler}
      >
        <div className="modal-dialog">
          <div className="modal-content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default ModalForm;
