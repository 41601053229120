const FormFieldsComponents = {
  marketplaces: require('./MarketplacesFormFields').default,
  fulfillmentVendors: require('./DefaultDimensionFormFields').default,
  productsTypes: require('./DefaultDimensionFormFields').default,
  packagingUnits: require('./DefaultDimensionFormFields').default,
  productCategories: require('./ProductCategoriesFormFields').default,
  baseUnits: require('./DefaultDimensionFormFields').default,
  articlePackagingTypes: require('./DefaultDimensionFormFields').default,
  sellers: require('./DefaultDimensionFormFields').default,
  shippingUnits: require('./DefaultDimensionFormFields').default,
  shippingCarriers: require('./DefaultDimensionFormFields').default,
  attributes: require('./AttributesFormFields').default
};

export default FormFieldsComponents;
