import React, { useState, useEffect} from 'react';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDispatch } from 'react-redux';

import {
  convertDataToOptions,
  createDefaultOption
} from '../../../services/util/dataHandlerService';
import { createRole, updateRole, deleteRole } from '../../../services/api/mutationService';
import { STATUS_OPTIONS, USER_TYPE_OPTIONS, sections } from '../../../lib/constants/fields';
import ModalError from '../../../components/modal/ModalError';
import { getSections as QUERY } from '../../../services/api/queryService';

const RolesFormFields = ({ itemId, data, setModalOpen, refetch }) => {


  const [subsections, setSubsections] = useState([]);
  const [sectionsData, setsectionsData] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [formData, setFormData] = useState({
    name: data && data.name,
    permissions,
    status: data ? data.status : STATUS_OPTIONS[1].id,
    user_type: data ? data.user_type : 2
  });

  const excludedSections = ['Controlling', 'Operations', 'Settings', 'Accounting', 'PnL', 'Billing']

  
const prepareSections = () => {
  const preparedSections = [];
  sections.forEach(section => {

    section.subsections.forEach(subsection => {

      if(formData.user_type == 1){
        if(!excludedSections.includes(section.name)){
          const preparedSection = { import: false, create: false, read: false, update: false, delete: false };
          preparedSection.id = subsection.id;
          preparedSection.name = subsection.path ? `${section.name} > ${subsection.name}` : subsection.name; 
          preparedSection.access = subsection.access;
          preparedSection.path = subsection.path 
          preparedSection.position = subsection.position 
          preparedSections.push(preparedSection);
        }
      }else{
        const preparedSection = { import: false, create: false, read: false, update: false, delete: false };
        preparedSection.id = subsection.id;
        preparedSection.name = subsection.path ? `${section.name} > ${subsection.name}` : subsection.name; 
        preparedSection.access = subsection.access;
        preparedSection.path = subsection.path 
        preparedSection.position = subsection.position 
        preparedSections.push(preparedSection);
      }
    });
  });
  return preparedSections;
};


  let { data: subsectionData } = useQuery(QUERY, {
    variables: {
      filter: false
    },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    refetch()
    const dp = prepareSections().map((section, index) => {
      if (data) {
        const permissionData = data.permissions[index];
        return { ...section, ...permissionData };
      } else {
        return { ...section };
      }
    });

    dp.sort((a, b) => a.position - b.position)

    if (subsectionData) {
      const newData = {
        getSections: {
          data: subsectionData.getSections,
        }
      }
      if(newData){

        const tempSubsection = [];
        
        newData.getSections.data.forEach(el => {
          if(formData.user_type == 1){
            if(!excludedSections.includes(el.section)){
              tempSubsection.push({
                section: el.section,
                value: el.subsection,
                label: el.subsection,
                roleId: el.roleId
              })
            }
          }else{
            tempSubsection.push({
              section: el.section,
              value: el.subsection,
              label: el.subsection,
              roleId: el.roleId
            })
          }
        });

        const unique = [...new Set(tempSubsection.map(x => `${x.section} > ${x.value}`))]

        const subs = unique.sort().reverse().map((x) => {
          return { label: x, value: x.split('> ')[1], section: x.split(' >')[0] }
        })

        const td = []
        subs.map(x => {
          let rolePerm = false 
          let _id = `9${tempSubsection.find(w => w.value == x.value && w.section == x.section).roleId}`
          if(data){
            _id = `9${tempSubsection.find(w => w.value == x.value && w.section == x.section).roleId}${data.id}`
            rolePerm = data.permissions.find(w => w.id == _id)
          }
          if(!rolePerm) {
            rolePerm = {
              import: false,
              create: false,
              read: false,
              update: false,
              delete: false
            }
          }

          const d = {
            access: "readonly",
            create: rolePerm.create,
            delete: rolePerm.delete,
            id: _id,
            import: rolePerm.import,
            name: `${x.label}`,
            path: `/sales`,
            read: rolePerm.read,
            update: rolePerm.update,
          }
          td.push(d)
        })


        td.sort((a, b) => b.name.localeCompare(a.name)).map(x => dp.unshift(x))

        const pp = []
        dp.forEach(item => {
          pp[item.id] = {
            id: item.id,
            import: item.import,
            create: item.create,
            read: item.read,
            update: item.update,
            delete: item.delete
          };
        });
        setsectionsData(dp)
        setPermissions(pp)     
        

        const fd = {...formData}

        fd.permissions = pp

        setFormData({...fd})

        
      }
    }
  }, [subsectionData, formData.user_type])



  const [checked, setCheceked] = useState(false);
  const [error, setError] = useState(null);
  const [mutationError, setMutationError] = useState();
  const dispatch = useDispatch();
  const [createRoleMutation, { loading: createLoading, error: createError }] = useMutation(
    createRole
  );
  const [updateRoleMutation, { loading: updateLoading, error: updateError }] = useMutation(
    updateRole
  );
  const [deleteRoleMutation, {}] = useMutation(deleteRole);
  const [acceptDelete, setAcceptDelete] = useState(false)


  const changeFieldHandler = event => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value
    });
  };

  const changeSelectHandler = (option, data) => {

    setFormData({
      ...formData,
      [data.name]: option && option.value
    });
  };

  const checkboxChangeHandler = event => {
    const { checked } = event.target;
    setAcceptDelete(checked)
  };
  
  const submitDelete = async e => {
    if(itemId){
      const { data } = await deleteRoleMutation({
        variables: { 
          id: itemId
        }
      });

      if(!data.error){
        refetch();
        setModalOpen(false);
      }

    }
  }

  const changeCheckboxHandler = (sectionId, event) => {
    const updatedSections = { ...formData.permissions };
    
    updatedSections[sectionId][event.target.id] = event.target.checked;
    

    setFormData({
      ...formData,
      permissions: updatedSections
    });
  };

  const handleCheck = event => {

    const { isChecked } = event.target

    const updatedSections = { ...formData.permissions };

    sectionsData.map(x => {
      updatedSections[x.id].read = !checked
      updatedSections[x.id].import = !checked
      updatedSections[x.id].update = !checked
      updatedSections[x.id].create = !checked
      updatedSections[x.id].delete = !checked
    })

    setFormData({
      ...formData,
      permissions: updatedSections
    });

    setCheceked(!checked)
  }

  const submitHandler = async event => {
    event.preventDefault();

    const preparedFormData = {
      ...formData,
      permissions: Object.keys(formData.permissions).map(key => ({
        id: +key,
        ...formData.permissions[key]
      }))
    };


    try {
      if (itemId !== null) {
        const { data } = await updateRoleMutation({
          variables: {
            input: { ...preparedFormData, id: itemId }
          }
        });

        if (!data.updateRole) {
          throw new Error('Server responded with no data');
        }

        const errorMessage = data.updateRole.error;
        if (errorMessage) {
          setError(errorMessage);
          return;
        }
      } else {
        const { data } = await createRoleMutation({
          variables: {
            input: preparedFormData
          }
        });
        if (!data.createRole) {
          throw new Error('Server responded with no data');
        }
      }
      dispatch({
        type: 'UPDATE',
        payload: {
          type: 'success',
          message: `Recored was successfully ${itemId ? 'updated' : 'created'}`
        }
      });
      refetch();
    } catch (err) {
      setMutationError(err);
    }
    setModalOpen(false);
  };

  if (createError || updateError || mutationError) {
    throw new Error(createError || updateError || mutationError);
  }

  return (
    <form onSubmit={submitHandler} className="roles-form">
      <div className="modal-header">
        <h4 className="modal-title" id="modalLabel">
          {`${itemId ? 'Edit' : 'Add'} Role`}
        </h4>
        <button type="button" className="close" onClick={() => setModalOpen(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body modal-form">
        <ModalError error={error} setError={setError} />

        <div className='form-group'>
          <label htmlFor="name">
            Role name <span style={{ color: 'red' }}>*</span>
          </label>
          <input
            type="text"
            id="name"
            className="form-control"
            required
            defaultValue={formData.name}
            onChange={changeFieldHandler}
            disabled={data && data.status === 0}
          />
        </div>
        <div>
          <label htmlFor="user_type">
            User type <span style={{ color: 'red' }}>*</span>
          </label>
          <Select
            name="user_type"
            options={USER_TYPE_OPTIONS}
            defaultValue={formData.user_type ? USER_TYPE_OPTIONS.find(i => i.value === +formData.user_type) : USER_TYPE_OPTIONS[1]}
            onChange={changeSelectHandler}
          />
          <input tabIndex={-1} style={{ opacity: 0, height: 0 }} required={!formData.user_type} />
        </div>
        <div className="permissions-wrapper">
          <p className="mt-3 mb-0 font-weight-bold">Permissions</p>
          <p>This role has the permissions to access the following sections.</p>

          <div style={{flex: 1, marginBottom: '15px'}}>
            <label htmlFor="showFilter" className="mb-0">
              Select all
            </label>
            <>
              <input type="checkbox" className="ml-2" checked={checked} onChange={handleCheck} />
            </>  
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="p-1">Section</th>
                <th className="p-1 text-center">Import</th>
                <th className="p-1 text-center">Create</th>
                <th className="p-1 text-center">Read</th>
                <th className="p-1 text-center">Update</th>
                <th className="p-1 text-center">Delete</th>
              </tr>
            </thead>

            <tbody>
              {sectionsData.length > 0 && permissions.length > 0 && sectionsData.map(section => (
                <tr key={section.id}>
                  <td className={!section.path ? 'pl-50' : ''}>{section.name}</td>
                  <td>
                    {section.name === "PIM > Products" && (
                      <input
                        type="checkbox"
                        id="import"
                        checked={formData.permissions[section.id].import}
                        onChange={changeCheckboxHandler.bind(null, section.id)}
                      />
                    )}
                  </td>
                  <td className="text-center">
                    {section.access === 'full' && (
                      <input
                        type="checkbox"
                        id="create"
                        checked={formData.permissions[section.id].create}
                        onChange={changeCheckboxHandler.bind(null, section.id)}
                      />
                    )}
                  </td>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      id="read"
                      checked={formData.permissions[section.id].read}
                      onChange={changeCheckboxHandler.bind(null, section.id)}
                    />
                  </td>
                  <td className="text-center">
                    {section.access === 'full' && (
                      <input
                        type="checkbox"
                        id="update"
                        checked={formData.permissions[section.id].update}
                        onChange={changeCheckboxHandler.bind(null, section.id)}
                      />
                    )}
                  </td>
                  <td className="text-center">
                    {section.access === 'full' && section.name != 'Manufacturers > Products' && (
                      <input
                        type="checkbox"
                        id="delete"
                        checked={formData.permissions[section.id].delete}
                        onChange={changeCheckboxHandler.bind(null, section.id)}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {(sectionsData.length == 0 || permissions.length == 0) && (
                <div className="spinner">Spinner</div>
              )}
        </div>
        <label htmlFor="status">
          Status <span style={{ color: 'red' }}>*</span>
        </label>
        <Select
          name="status"
          options={convertDataToOptions(STATUS_OPTIONS)}
          defaultValue={createDefaultOption(STATUS_OPTIONS.find(i => i.id === formData.status))}
          onChange={changeSelectHandler}
        />
      </div>
      <div className="modal-footer justify-content-start">
        {createLoading || updateLoading ? (
          <div className="spinner">Spinner</div>
        ) : (
          <div>
            <div>
              <input id="deletion" type="checkbox" className="mr-1" onChange={checkboxChangeHandler} />
              <label htmlFor="deletion">Confirm deletion</label>
            </div>
            <button type="submit" className="btn btn-primary">
              Save
            </button>
            <button type="button" onClick={() => submitDelete(formData)} disabled={!acceptDelete} className="btn btn-danger ml-1">
            Delete
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default RolesFormFields;
