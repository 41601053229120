import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Redirect } from 'react-router-dom';


import Table from '../../../components/table/Table';
import { queryStringHandler, fetchData } from '../../../services/util/dataHandlerService';
import routes from '../../../lib/constants/routes';
import { CONTENT_EXPORT_OPTIONS, PRODUCT_IMPORT_OPTIONS } from '../../../lib/constants/fields';

import { getImportJobs } from '../../../services/api/queryService';

const description = {
  module: 'importJobs',
  description: 'ImportJobs',
  req: 'searchImportJobs',
  url: routes.products.index,
  tab: 'import'
};

const importCountMap = {
  product: 'count',
  cid: 'countCids',
  price: 'countPrices',
  bundle: 'countBundled',
  product_update: 'count',
  product_price_update: 'countPrices',
  content_import: 'countContent',
  amazon: 'countContent',
  ebay: 'countContent',
  kaufland: 'countContent',
  otto: 'countContent',
  cid_update: 'countCids',
};

const ImportJobList = ({ canImport, location, importModalOpen }) => {
  const { first, page, keyword, filter } = queryStringHandler(location.search);
  let convertedData = null;
  let { loading, data, refetch, error } = useQuery(getImportJobs, {
    variables: {
      first,
      page,
      input: keyword,
      order: 'id',
      filter
    },
    fetchPolicy: 'no-cache'
  });

  if (data) {
    convertedData = data[description.req].data.map(item => {
      const convertedItem = {
        ...item,
        user: { ...item.user },
        count: item[importCountMap[item.type]],
        type: [...PRODUCT_IMPORT_OPTIONS, ...CONTENT_EXPORT_OPTIONS].find(i => i.value === item.type)?.label
      };
      delete convertedItem.countCids;
      delete convertedItem.countPrices;
      delete convertedItem.countBundled;
      delete convertedItem.countProductUpdate;
      delete convertedItem.countContent;
      return convertedItem;
    });
  }

  useEffect(() => {
    let intervalId;
    if (!importModalOpen) {
      intervalId = setInterval(() => {
        refetch();
      }, 9000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [refetch, importModalOpen]);

  if (error) {
    throw new Error(error);
  }

  if(!canImport){
    return <Redirect to="/products?tab=search" />;
  }


  return (
    <>
      {!convertedData ? (
        <div className="spinner absolute">Spinner</div>
      ) : (
        <Table
          loading={loading}
          refetch={refetch}
          description={description}
          pagerData={{
            ...fetchData(description.req, data).pagerData,
            search: keyword,
            queryStrFilter: filter,
            tab: 'import'
          }}
          tableData={convertedData}
          filter={filter}
        />
      )}
    </>
  );
};

export default ImportJobList;
