export const SET_TRANSLATIONS = "SET_TRANSLATIONS";
 
const initialState = {
    translate:{},
    lang:'EN'
};

export default (state = initialState , action) => {
    
    switch(action.type){

        case SET_TRANSLATIONS: {
            return {
                translate    : action.payload
            };
        }

        default: {
            return { ...state }
        }
    }
}


