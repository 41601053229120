import React, { useState, useEffect } from 'react';
import Axios from 'axios';

const MarketplaceIssuesModal = ({ sku, marketplaceId, amazonStatus, marketplaceErrors, setModalOpen, handleModalClose }) => {
  const [marketplaceIssues, setMarketplaceIssues] = useState([]);
  const [ openMarketplaceIssueModal, setOpenModal ] = useState(setModalOpen);
  const handleClose = () => {
    setOpenModal(false);
    handleModalClose()
  } 

  /* Move this to fields.js or seperate url.js to be imported and used everywhere */
  let _uri = 'https://staging-api.ebazaaris.io'
  if (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'prod_azure') {
    _uri = 'https://azure-api.ebazaaris.io'
  } else if (process.env.REACT_APP_ENV === 'dev') {
    _uri = 'http://localhost:8000'
  }

  const getMarketplaceIssues = () => {    
    Axios.get(`${_uri}/api/amazon/listing/sku/${sku}/${marketplaceId}/issues`)
    .then((response) => {
      setMarketplaceIssues(current => [...current, response.data]);
    })

    .catch(error => console.error(`Error: ${error}`));
  }

  useEffect(() => { 
    getMarketplaceIssues();
  }, []);


  return (
    <>
      <div className="modal-backdrop show" style={ openMarketplaceIssueModal ? {width: '100vw'} : {width: '0vw'} } />
      <div
        className="modal"
        id="marketplaceIssuesModal"
        tabIndex="-1"
        style={ openMarketplaceIssueModal ? {display: 'block'} : {display: 'none'} }
      >
        <div className="modal-dialog" style={{color:'#525254'}}>
          <div className="modal-content DIN2014" >
            <form>
              <div className="modal-header">
                <h4 style={{color:'#525254'}} className="modal-title DIN2014">{ sku }    SKU Issue description</h4>
                <button type="button" className="close" onClick={handleClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div style={{ overflow: 'hidden',  whiteSpace: 'break-spaces', paddingLeft: '2rem'}} className="modal-body pb-4 ">
                <h5 style={{color:'#525254', fontWeight: 700}} className='DIN2014'>Original error</h5>
                <ul>
                  { marketplaceErrors && marketplaceErrors.length > 0 ?
                    ( marketplaceErrors.map(mError => {
                        return (<li>{mError}</li>);
                      })
                    ) : (<li className="spinner">No errors found</li>)
                  }
                </ul>
                <h5 style={{color:'#525254', fontWeight: 700}} className='DIN2014'>Possible reason descriptions</h5>
                <ul>
                  { marketplaceIssues && marketplaceIssues.length > 0 ?
                    ( marketplaceIssues[0].map(mIssue => {
                      return (<li>{mIssue}</li>);
                      })
                    ) : (<li className="spinner">No issues found</li>)
                  }
                </ul>
                <span style={{color:'#525254'}}>Additional information can be found on Amazon seller center or Amazon debug dashboard <a className="tooltip-link" style={{fontWeight: 700}} href="https://live.ebazaaris.io/analytics?section=operations&subsection=qa-pim&tab=elmo-vs-amazon" target={ '_blank' }>here</a>.</span>
              </div>
              <div className="modal-footer justify-content-start">
                {/* Do we want anything in the footer?  */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketplaceIssuesModal;
